export const facebookPermissionScopes = [
  'ads_management',
  'public_profile',
  'ads_read',
  'email',
];

export const googlePermissionScopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/adwords',
];

export const OAUTH2_API_BASE_URL =
  'https://engage-dev-usw2-external.data.plume.tech';
