export enum Routes {
  Index = '/',
  Login = '/login',
  Generate = '/segments',
  GenerateSpecificTab = '/segments/:activeTab',
  CreateAudience = '/segments/createAudience',
  EditAudience = '/segments/editAudience/:id',
  Overview = '/overview/:activeOverview',
  Settings = '/settings',
  Integrations = '/integrations',
  LoginCallback = '/implicit/callback',
  CrusadeCampaigns = '/campaigns',
  Connections = '/connections',
  AudienceTree = '/audience/tree',
  GenerateDataPreview = `/segments/dataPreview/:segmentId`,
  CreateCampaign = '/campaigns/campaign/add',
  EditCampaign = '/campaigns/campaign/:campaignId',
  FromTemplateCampaign = '/campaigns/campaign/templates',
  NpsOverview = '/overview/:campaignId/nps',
}

export const LOGIN_SCREEN_ROUTE = Routes.Login;

export const HARVEST_GENERATE_BACKEND_URL =
  process.env.REACT_HARVEST_GENERATE_BACKEND_URL || 'http://localhost:3001';
export const HARVEST_CRUSADE_BACKEND_URL =
  process.env.REACT_HARVEST_CRUSADE_BACKEND_URL || 'http://localhost:3002';

export const FACEBOOK_CLIENT_ID =
  process.env.REACT_APP_FACEBOOK_CLIENT_ID ||
  '68b6afe157cfe825edd600ddaf83544f';
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '145880636495-bhmetvtgl5ta9pcu1549q47tetmul9o9.apps.googleusercontent.com';
export const OAUTH2_API_URL =
  process.env.REACT_APP_OAUTH2_API_URL ||
  'https://harvest-dev-usw2-external.data.plume.tech';

export const FACEBOOK_LOGIN_URL =
  process.env.REACT_APP_FACEBOOK_LOGIN_URL || 'https://www.facebook.com/v15.0';

export const APP_URL = process.env.REACT_APP_URL || window.location.origin;

export const OKTA_BASE_URL =
  process.env.REACT_APP_OKTA_BASE || 'https://external-dev.sso.plume.com';
export const OKTA_ISSUER =
  process.env.REACT_APP_OKTA_ISSUER || `${OKTA_BASE_URL}/oauth2/default`;
export const OKTA_CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || '0oa15glvqezXd8EHR0h8';
export const OKTA_REDIRECT_URI =
  process.env.REACT_APP_OKTA_REDIRECT_URI ||
  `${window.location.origin}/implicit/callback`;

export const TRANSLATION_FILE_PATH_TEMPLATE =
  process.env.REACT_APP_TRANSLATION_FILE_PATH_TEMPLATE ||
  '/{{lng}}.json' +
    (process.env.REACT_APP_VERSION
      ? '?version=' + process.env.REACT_APP_VERSION
      : '');

export const MIXPANEL_ID =
  process.env.REACT_APP_MIXPANEL_ID || '9b500ed2196d9e3eabb4544928c20423';

export const DEFAULT_PAGE_SIZE = 10;

export const GENERATE_DATE_FORMAT = 'lll';
export const TIMEOUT_FOR_SYNC_MODAL_CLEANUP = 500;
