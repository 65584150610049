import { Channel } from 'features/configuration/types';
import { SyncStateTypes } from 'features/syncs/types';

export type AudienceSegments = {
  id: string;
  segmentName: string;
  subSegments: number;
  activeCampaigns: number;
  tags: string[];
  lastModified: string;
  lastModifiedBy: string;
};
export type AudienceFlowResponse = {
  data: AudienceSegments[];
  total: number;
};

export type SegmentTreeApiResponse = {
  statusCode: number;
  message: string;
  data?: SegmentTreeData[] | null;
};

export type SegmentTreeDataSyncChannel = {
  id: string;
  name: string;
};

export type SegmentSyncs = {
  channel: Pick<Channel, 'channelId' | 'channelName' | 'channelType'>;
  status: SyncStateTypes;
  syncId: string;
};

export type SegmentTreeData = {
  id: string;
  campaigns: any[];
  partnerId: string;
  segmentId: string;
  segmentName: string;
  parentSegmentIds?: (string | null)[] | null;
  childSegmentIds?: (string | null)[] | null;
  includeFilters: FilterEntity[];
  excludeFilters: FilterEntity[];
  includeNarrowFilters: FilterEntity[][];
  excludeNarrowFilters: FilterEntity[][];
  segmentType: string;
  rowCount: number;
  rowCountLastUpdatedAt: string;
  tags?: null[] | null;
  isArchived: boolean;
  logicalExpression: string;
  sqlExpression: string;
  syncChannels: SegmentTreeDataSyncChannel[];
  updatedAt: string;
  updatedBy: string;
  syncs: SegmentSyncs[];
};

export type SegmentTableEntry = {
  segmentId: string;
  segmentName: string;
  subSegmentCount: number;
  segmentType: string;
  rowCount: number;
  tags?: null[] | null;
  activeCampaignCount: number;
  updatedAt: string;
  updatedBy: string;
};

export enum PatternColumnValue {
  SELECT_VALUE = 'Select value',
}

export type FilterEntity = {
  operator: string;
  columnName: string;
  columnValue: string | { start: string; end: string };
  pattern?: string;
  rowCount?: number;
  // cardFilter gets added to the FilterEntity only to render the correct
  //  PatternMatcher. It is deleted before we send to the backend.
  cardFilter?: string;
};
export type IncludeFiltersEntity = {
  operator: string;
  columnName: string;
  columnValue: string;
};

export type NarrowFilter = FilterEntity[] | NarrowFilter[];

export type TreeGroupItem = {
  type: string;
  title: string;
  items: TreeGroupItem[] | TreeItem[];
  expanded: boolean;
};

export type TreeItem = {
  type: string;
  label: string;
  info: string;
  checked: boolean;
};

export type ParsedMetadataTreeType = {
  title: string;
  items: TreeGroupItem[];
};

export type MetadataTree = {
  id: string;
  columnName: string;
  category: string;
  attribute: string;
  allowedValues: string[];
  pattern: string;
};

export type MetadataTreeResponse = {
  statusCode: number;
  message: string;
  data?: MetadataTree[] | null;
};

export type Node = {
  id: string;
  parent?: string | null;
  data: NodeData;
  level?: number | null;
  sourcePosition?: string | null;
  type: string;
  targetPosition?: string | null;
  position: {
    x: number;
    y: number;
  };
};
export interface NodeData {
  text: string;
  value: number;
  perc: number;
  id: string;
}

export type Edge = {
  id: string;
  source: string;
  target: string;
  type: string;
};

export type SegmentFilter = {
  columnName: string;
  columnValue: string;
  operator: string;
};
export type SegmentData = {
  locationId: string;
  customerId: string;
  accountId: string;
};

export type SegmentDataPreviewResponse = {
  data: SegmentData[];
  statusCode: number;
  message: string;
};

export enum SegmentType {
  Split = 'split',
  EverythingElse = 'Everything Else',
  Custom = 'custom',
  Standard = 'standard',
  SplitEverythingElse = 'splitEverythingElse',
}

export enum NodeConnectionState {
  Online = 'online',
  Offline = 'offline',
  Warning = 'warning',
  Alarm = 'alarm',
}

type ChildSplit = {
  segmentName: string;
  splitPercentage: string;
  tag?: string[];
};

export type SegmentSplitNodeDto = {
  childSplit: ChildSplit[];
};

export type CategoryAudienceSizeResponse = {
  attribute: string;
  columnName: string;
  category: string;
  allowedValues?: { allowedValue: 'string'; rowCount: number }[];
  rowCount?: number;
};

export type AudienceSizeResponse = {
  segmentId: string;
  segmentName: string;
  includeFilters: FilterEntity[];
  excludeFilters: FilterEntity[];
  includeNarrowFilters: NarrowFilter;
  excludeNarrowFilters: NarrowFilter[];
  rowCount: number;
  categories: {
    Account: CategoryAudienceSizeResponse[];
    Adoption: CategoryAudienceSizeResponse[];
    Demographic: CategoryAudienceSizeResponse[];
    Experience: CategoryAudienceSizeResponse[];
    Habit: CategoryAudienceSizeResponse[];
    Interest: CategoryAudienceSizeResponse[];
    Trait: CategoryAudienceSizeResponse[];
  };
};

export type RowCountForAllowedValueResult = {
  columnName: string;
  allowedValue?: string;
  rowCount: number;
};

export interface SegmentSplitNodeResponse {
  data: SegmentSplitNode[];
}

export interface SegmentSplitNode {
  segmentId: string;
  partnerId: string;
  parentSegmentIds?: string[] | null;
  segmentName: string;
  segmentType: string;
  rowCount: number;
  rowCountLastUpdatedAt: string;
  childSegmentIds?: null[] | null;
  excludeFilters?: null[] | null;
  excludeNarrowFilters?: null[] | null;
  includeFilters?: null[] | null;
  includeNarrowFilters?: null[] | null;
  isArchived: boolean;
  logicalExpression: string;
  sqlExpression: string;
  percentage: string;
  tags?: null[] | null;
  updatedAt: string;
  updatedBy: string;
  syncChannels?: null[] | null;
}

export type LoadingStatus = 'loading' | 'loaded' | 'error';

export type SortableSegmentFieldNames =
  | 'segmentName'
  | 'subSegmentCount'
  | 'activeCampaignCount'
  | 'updatedAt';

export type SegmentSummary = {
  segmentName: string;
  segmentId: string;
};

export type GenerateVersionResponse = {
  apiVersion: string;
  imageTag: string;
};
