import { Heading } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

const OverviewHeader: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="OverviewHeader">
      <Heading
        testId="Overview__heading"
        level={1}
        medium
        size="xxxxl"
        classes={(current) => ({
          ...current,
          root: `${current.root} OverviewHeader__title`,
        })}
      >
        {t('overview.title')}
      </Heading>
    </div>
  );
};

export default OverviewHeader;
