import React, { FunctionComponent } from 'react';
import { IntegrationErrorComponentProps } from '../../../integrations/types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import ErrorActionsRenderer from './ErrorActionsRenderer';
import { ErrorRequestTimedOut } from '../../errors';

export const ErrorRequestTimedOutErrorComponent: FunctionComponent<IntegrationErrorComponentProps> = ({
  actions,
}) => {
  return (
    <div className="IntegrationsContainer__error">
      <h1>
        <FormattedMessage id="settings.errors.requestTimedOut.title" />
      </h1>
      <p>
        <FormattedMessage id="settings.errors.requestTimedOut.description" />
      </p>
      <ErrorActionsRenderer actions={actions} error={ErrorRequestTimedOut} />
    </div>
  );
};

export default ErrorRequestTimedOutErrorComponent;
