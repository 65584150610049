import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import {
  Dropdown,
  IconButton,
  Pagination,
  PendingContent,
  Table,
  Icons,
  DropdownItem,
  Button,
  SearchInputField,
  Badge,
  Spinner,
} from 'plume-ui';
import { SegmentTableEntry, SegmentTreeData } from '../../types';
import { useRedirectToRoute } from '../../../../../utils/hooks/useRedirectToRoute';
import moment from 'moment';
import {
  DEFAULT_PAGE_SIZE,
  GENERATE_DATE_FORMAT,
  Routes,
} from '../../../../../config';
import FormattedMessage from '../../../../../utils/components/FormattedMessage';
import { useAudience } from '../../hooks/useAudience';
import {
  selectedSegmentAtom,
  segmentsSearchAtom,
  filteredSegmentsSelector,
  activeNodeAtom,
  currentSegmentsPage,
  segmentsLoadingStatusAtom,
  filteredSegmentsCount,
  segmentsSelectedPageAtom,
  segmentsSortAtom,
  segmentsSelectedFilterAtom,
} from '../../../../../store/state/audienceFlowState';
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

const AudienceList: FunctionComponent = () => {
  const redirectToRoute = useRedirectToRoute();

  const loadingStatus = useRecoilValue(segmentsLoadingStatusAtom);

  const [segmentsSearch, setSegmentsSearch] = useRecoilState(
    segmentsSearchAtom,
  );

  const segments = useRecoilValue(currentSegmentsPage);

  const setActiveNode = useSetRecoilState(activeNodeAtom);
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);

  const gotoEditSegment = (segmentName: string, segmentId: string) => {
    setSelectedSegment({ segmentName: segmentName, segmentId: segmentId });
    redirectToRoute(Routes.CreateAudience);
  };

  const location = useLocation();

  useEffect(() => {
    setSelectedFilter(''); // reset filter on page change
  }, [location]);

  const getFirstLettersOfName = (name: string) => {
    const firstLetters = name
      .split(' ')
      .map((word) => word[0])
      .join('');

    return firstLetters;
  };

  const tableHeader: Record<string, unknown>[] = [
    {
      name: 'Segment name',
      fieldName: 'segmentName',
      sortable: true,
    },
    {
      name: 'Sub-segments',
      fieldName: 'subSegmentCount', //childSegmentIds.length
      sortable: true,
    },
    {
      name: 'Active campaigns',
      fieldName: 'activeCampaignCount',
      sortable: true,
    },
    {
      name: 'Last modified',
      fieldName: 'updatedAt',
      render: (segment: SegmentTreeData) => (
        <div className="AudienceList__lastModified">
          <Badge
            badgeContent={getFirstLettersOfName(segment.updatedBy)}
            size="standard"
            hex="#64022D"
          />
          {moment.utc(segment.updatedAt).local().format(GENERATE_DATE_FORMAT)}
        </div>
      ),
      sortable: true,
    },
    {
      name: '',
      render: (segment: SegmentTreeData) => (
        <Dropdown
          listPosition="right"
          closeOnItemClick
          openInPortal
          button={
            <IconButton>
              <Icons.DotsVerticalIcon />
            </IconButton>
          }
        >
          <DropdownItem
            onClick={() => {
              setActiveNode(segment.segmentId);
              gotoEditSegment(segment.segmentName, segment.segmentId);
            }}
          >
            <FormattedMessage id="generate.editInWorkspace" />
          </DropdownItem>
        </Dropdown>
      ),
    },
  ];

  /**
   * Populate segments tree with data from backend
   */
  const { loading: segmentsLoading, error: segmentsError } = useAudience();
  const [allSegments, setAllSegments] = useState<SegmentTableEntry[]>();
  const filteredSegments = useRecoilValue(filteredSegmentsSelector);
  const totalItems = useRecoilValue(filteredSegmentsCount);
  const [selectedPage, setSelectedPage] = useRecoilState(
    segmentsSelectedPageAtom,
  );
  const setSegmentSort = useSetRecoilState(segmentsSortAtom);
  const [selectedFilter, setSelectedFilter] = useRecoilState(
    segmentsSelectedFilterAtom,
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setSelectedFilter(value);
    setSelectedPage(0);
  };

  return (
    <>
      <div className="AudienceList__options">
        <div className="AudienceList__search">
          <SearchInputField
            classes={(current) => ({
              ...current,
              root: `${current.root} AudienceList__search-input`,
            })}
            name="search"
            placeholder="Search Segments"
            value={segmentsSearch}
            onInput={debounce(handleSearch, 400)}
            icon={<Icons.SearchIcon />}
            rounded
          />
        </div>
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} AudienceList__add-btn`,
          })}
          styleVariant="superprimary"
          onClick={() => redirectToRoute(Routes.CreateAudience)}
        >
          <FormattedMessage id="generate.enterWorkspace" />
        </Button>
      </div>
      <PendingContent
        loading={loadingStatus === 'loading'}
        loader={Spinner}
        isError={loadingStatus === 'error'}
        hideContent
      >
        <Table
          // NEW RELIC MONITORING PLEASE DO NOT DELETE THIS ID
          testId="Generate__table"
          classes={(current) => ({
            ...current,
            root: `${current.root} AudienceList__table`,
            headerRow: `${current.headerRow} AudienceList__table-header-row`,
          })}
          externalSort
          headerRow={tableHeader}
          dataRows={[...segments] as any}
          onSortChange={setSegmentSort}
        />
        <Pagination
          classes={(current) => ({
            ...current,
            root: `${current.root} AudienceList__pagination`,
          })}
          totalPageCount={Math.ceil(totalItems / DEFAULT_PAGE_SIZE) || 1}
          onPageSelect={setSelectedPage}
          expandDirection="top"
          currentPage={selectedPage}
        />
      </PendingContent>
    </>
  );
};

export default AudienceList;
