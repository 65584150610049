import { DependencyContainer } from 'DependencyContainer';
import { useChannels } from 'features/configuration/hooks/useChannels';
import {
  AvailableIntegrationTypes,
  Channel,
} from 'features/configuration/types';
import { useAudience } from 'features/generate/audience/hooks/useAudience';
import { SegmentTreeData } from 'features/generate/audience/types';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import {
  Button,
  Dropdown,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  Panel,
  PanelFooter,
  PendingContent,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  segmentTreeAtom,
  selectedSegmentAtom,
} from 'store/state/audienceFlowState';
import { channelsAtom } from 'store/state/channelState';
import { generateKey } from '../../utils/helpers';
import classNames from 'classnames';
import { useSyncs } from 'features/syncs/hooks/useSyncs';
import { useRecoilState } from 'recoil';
import { syncsAtom } from 'store/state/syncsState';
import FormattedMessage from '../../utils/components/FormattedMessage';
import SyncTestResult from './SyncTestResult';
import { TIMEOUT_FOR_SYNC_MODAL_CLEANUP } from '../../config';

import { SyncStateTypes } from 'features/syncs/types';
import FieldsToExport from './FieldsToExport';
import { PanelStyles } from 'plume-ui/dist/components/Panel/Panel';
import { TestChannelResponse } from 'features/configuration/integrationsState';

const { integrationsService } = new DependencyContainer();

const defaultChannel = {
  channelId: '',
  channelType: '',
  channelDescription: '',
  channelName: '',
  createdBy: '',
  createdAt: '',
  adAccountId: '',
};

const mapStatuses: Record<string, string> = {
  success: 'success',
  failure: 'error',
  error: 'error',
};

const mapChannelTypeToIntegrationType: Record<
  string,
  AvailableIntegrationTypes
> = {
  facebookAds: AvailableIntegrationTypes.Facebook,
  googleAds: AvailableIntegrationTypes.Google,
};

export const CreateSyncModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<Channel>(
    defaultChannel,
  );

  const [selectedSegment, setSelectedSegment] = useState<
    SegmentTreeData | undefined
  >();
  const [selectedSegmentInStore, setSelectedSegmentInStore] = useRecoilState(
    selectedSegmentAtom,
  );
  const { loading: channelLoading, error: channelError } = useChannels();
  const { loading: segmentsLoading, error: segmentsError } = useAudience();
  const fetchedChannels = useRecoilValue(channelsAtom);
  const segments = useRecoilValue(segmentTreeAtom);
  const { syncsService } = new DependencyContainer();
  const partnerId = useRecoilValue(partnerIdAtom);

  const syncs = useRecoilValue(syncsAtom);
  const [syncTestLoading, setSyncTestLoading] = useState(false);
  const [syncSaveComplete, setSyncSaveComplete] = useState(false);
  const [syncIsSaving, setSyncIsSaving] = useState(false);
  const [syncTestError, setSyncTestError] = useState<any>();
  const [syncTestResult, setSyncTestResult] = useState<TestChannelResponse>();
  const { runFetch } = useSyncs({
    initiallyThrottled: syncSaveComplete,
  });

  const { closeAllModals } = useGlobalModalContext();

  const scrollContainer = {
    marginRight: '2px',
    maxHeight: '620px',
    padding: '40px',
  };

  const mockChannelForFieldsToExport: Channel = {
    channelId: 'id',
    channelType: 'facebookAds',
    channelDescription: 'description',
    channelName: 'Facebook - Plume',
    createdBy: '',
    createdAt: '',
    fieldsToExport: ['email'],
    adAccountId: 'addAccountId',
  };

  useEffect(() => {
    if (selectedSegmentInStore || selectedSegment) {
      setChannels(fetchedChannels);
    }
  }, [selectedSegmentInStore, selectedSegment, fetchedChannels]);

  const getStatus = () => {
    if (syncTestError) {
      return 'error';
    }
    if (!syncTestResult?.status || syncTestLoading) {
      return 'loading';
    }
    return mapStatuses[syncTestResult.status];
  };

  const handleSegmentSelect = (segment: SegmentTreeData) => {
    setSelectedSegment(segment);
    setSelectedSegmentInStore(segment);
  };

  const handleRunTest = async () => {
    const { channelId, channelType } = selectedChannel;

    if (!channelType || channelType === '' || !partnerId) {
      return;
    }
    const integrationType = mapChannelTypeToIntegrationType[channelType];
    try {
      setSyncTestLoading(true);
      setSyncTestError('');
      const result = await integrationsService.testChannel(
        integrationType,
        channelId,
        partnerId,
      );

      setSyncTestResult(result);
    } catch (error) {
      setSyncTestError(error);
    } finally {
      setSyncTestLoading(false);
    }
  };

  const handleChannelSelect = (channel: Channel) => {
    if (!selectedChannel.channelId) {
      setSelectedChannel(channel);
      const currentChannels = [...channels];
      const updatedChannels = currentChannels.filter((currChanel) => {
        return currChanel.channelId !== channel.channelId ? currChanel : false;
      });

      setChannels(updatedChannels);
    } else {
      setSelectedChannel(channel);

      const allChannels = fetchedChannels;

      const updatedChannels = allChannels.filter((ch) => {
        if (ch.channelId !== channel.channelId) {
          return ch;
        } else {
          return false;
        }
      });
      setChannels(updatedChannels);
    }
  };

  const handleRemoveChannel = (dropdown: Channel) => {
    setSelectedChannel(defaultChannel);

    if (dropdown.channelId !== '') {
      const currentChannels = [...channels];
      setChannels([...currentChannels, dropdown]);
    }
  };

  const clearModalState = () => {
    setChannels([]);
    setSelectedChannel(defaultChannel);
  };

  const handleCloseModal = async () => {
    clearModalState();
    await runFetch();
    closeAllModals();

    setTimeout(() => {
      setSyncSaveComplete(false);
      setSelectedSegment(undefined);
      setSelectedSegmentInStore(undefined);
    }, TIMEOUT_FOR_SYNC_MODAL_CLEANUP);
  };

  const handleSave = async () => {
    if (syncIsSaving) {
      return;
    } else {
      setSyncIsSaving(true);
    }

    if (!selectedSegmentInStore) {
      return;
    }
    try {
      let segmentIdToSync: string | undefined;

      segmentIdToSync = selectedSegmentInStore.segmentId
        ? selectedSegmentInStore.segmentId
        : selectedSegment?.segmentId;

      if (segmentIdToSync && selectedChannel) {
        const selectedChannelId = selectedChannel.channelId;

        const sync = [
          {
            channelId: selectedChannelId,
            state: 'active' as SyncStateTypes,
          },
        ];

        await syncsService.addSync(partnerId, segmentIdToSync, sync);
        setSyncSaveComplete(true);
        notify({
          id: 'syncCreated',
          title: t('syncModal.syncCreated'),
          body: '',
          type: 'success',
          showDismiss: false,
          mustDismiss: false,
        });

        await handleCloseModal();
      }
    } catch (error) {
      setSyncIsSaving(false);
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const hasSync = (channel: Channel): boolean => {
    if (!syncs) {
      return false;
    }

    const segmentSyncs = syncs.filter((s) => {
      return (
        s.segmentId === selectedSegmentInStore?.segmentId ||
        s.segmentId === selectedSegment?.segmentId
      );
    });

    const sync = segmentSyncs.find((s) => {
      return s.channel.channelId === channel.channelId;
    });

    return Boolean(sync);
  };

  const renderChannelDropdown = (channel: any) => {
    return (
      <Dropdown
        classes={(current) => ({
          ...current,
          root: `${current.root} CreateSyncModal__channelDropdown`,
        })}
        label={channel.channelName !== '' ? channel.channelName : t('select')}
        closeOnItemClick={true}
      >
        {channels.length > 0 ? (
          channels.map((channel, index) => {
            const channelHasSync = hasSync(channel);
            return (
              <DropdownItem
                key={index}
                onClick={() => handleChannelSelect(channel)}
                disabled={channelHasSync}
                classes={(current) => ({
                  ...current,
                  root: classNames(`${current.root}`, {
                    [`CreateSyncModal__channelDropdownItem-disabled`]: channelHasSync,
                  }),
                })}
              >
                {`${channel.channelName} - ${channel.createdBy}`}{' '}
                {channelHasSync && (
                  <FormattedMessage id="syncModal.existingSyncPresent" />
                )}
              </DropdownItem>
            );
          })
        ) : (
          <DropdownItem
            key={generateKey()}
            onClick={() => handleRemoveChannel(channel)}
          >
            <FormattedMessage id="cancel" />
          </DropdownItem>
        )}
      </Dropdown>
    );
  };

  return (
    <PendingContent
      loading={channelLoading || segmentsLoading}
      isError={Boolean(channelError) || Boolean(segmentsError)}
    >
      <Panel
        classes={(current: PanelStyles) => ({
          ...current,
          root: `${current.root} CreateSyncModal`,
        })}
        title={t('syncModal.createSync')}
        open
        setOpen={() => handleCloseModal()}
      >
        <div className="CreateSyncModal__body">
          {selectedSegmentInStore && (
            <div className="CreateSyncModal__bodyTitle">
              <FormattedMessage id="syncModal.syncing" />{' '}
              {selectedSegmentInStore.segmentName}
            </div>
          )}
          {!selectedSegmentInStore && (
            <>
              <div>
                <FormattedMessage id="syncModal.selectSegment" />
              </div>
              <Dropdown
                classes={(current) => ({
                  ...current,
                  root: `${current.root} CreateSyncModal__segmentDropdown`,
                })}
                label={
                  selectedSegment
                    ? selectedSegment.segmentName
                    : t('syncModal.selectSegment')
                }
                closeOnItemClick
                searchPlaceholder=""
                searchBar
              >
                {segments.map((segment) => {
                  return (
                    <DropdownItem
                      key={generateKey()}
                      onClick={() => handleSegmentSelect(segment)}
                    >
                      {segment.segmentName}
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            </>
          )}
          <div>
            <FormattedMessage id="syncModal.selectChannel" />
          </div>
          {renderChannelDropdown(selectedChannel)}
          <>
            {selectedChannel.channelId !== '' && (
              <>
                <FieldsToExport
                  channel={mockChannelForFieldsToExport}
                ></FieldsToExport>
              </>
            )}
            <div className="CreateSyncModal__runTestBtn">
              <Button
                disabled={selectedChannel.channelType === ''}
                onClick={() => handleRunTest()}
              >
                <FormattedMessage
                  id={
                    syncTestLoading
                      ? 'loading'
                      : 'settings.channel.runTestButton'
                  }
                />
              </Button>
            </div>
          </>
          {(syncTestLoading || syncTestResult || syncTestError) && (
            <>
              <div className="CreateSyncModal__testResultHeading">
                <FormattedMessage id="settings.channel.testingConnection" />
              </div>
              <SyncTestResult
                status={getStatus()}
                syncTestResult={{
                  result: getStatus(),
                  message: syncTestResult?.error_message,
                }}
              />
            </>
          )}
        </div>
        <PanelFooter>
          <Button
            onClick={() => handleCloseModal()}
            styleVariant="tertiary-grey"
          >
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            onClick={handleSave}
            styleVariant="superprimary"
            disabled={
              !selectedChannel ||
              selectedChannel.channelId === '' ||
              syncIsSaving
            }
          >
            <FormattedMessage id="save" />
          </Button>
        </PanelFooter>
      </Panel>
    </PendingContent>
  );
};
