import { DEFAULT_PAGE_SIZE, Routes } from 'config';
import {
  Button,
  Heading,
  Icons,
  Pagination,
  PendingContent,
  SearchInputField,
  Spinner,
  Table,
} from 'plume-ui';
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  segmentForPreviewAtom,
  segmentDataPreview,
  getSegmentTreeByIdSelector,
} from 'store/state/audienceFlowState';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { CSVLink } from 'react-csv';
import { useParams } from 'react-router-dom';
import FormattedMessage from 'utils/components/FormattedMessage';
import { PaginationStyles } from 'plume-ui/dist/components/Pagination/Pagination';
import { useDataPreview } from 'hooks/useDataPreview';
import {
  currentDataPreviewPage,
  dataPreviewAtom,
  dataPreviewLoadingStatusAtom,
  dataPreviewSelectedFilterAtom,
  dataPreviewSelectedPageAtom,
  filteredDataPreviewCount,
} from 'store/state/dataPreviewState';
import { debounce } from 'lodash';

const SegmentDataPreview: FunctionComponent = () => {
  const { t } = useTranslation();
  const { loading, error } = useDataPreview();
  const setSegmentDataPreview = useSetRecoilState(segmentDataPreview);
  const csvDataPreview = useRecoilValue(dataPreviewAtom);

  const [
    segmentSelectedForPreview,
    setSegmentSelectedForPreview,
  ] = useRecoilState(segmentForPreviewAtom);
  const segmentId = useParams<{ segmentId: string }>();
  const segmentLookup = useRecoilValue(
    getSegmentTreeByIdSelector(segmentId?.segmentId),
  );
  const redirectToRoute = useRedirectToRoute();
  const loadingStatus = useRecoilValue(dataPreviewLoadingStatusAtom);

  const [dataPreviewSelectedPage, setDataPreivewSelectedPage] = useRecoilState(
    dataPreviewSelectedPageAtom,
  );
  const [
    dataPreviewSelectedFilter,
    setDataPreivewSelectedFilter,
  ] = useRecoilState(dataPreviewSelectedFilterAtom);

  const totalFilteredItems = useRecoilValue(filteredDataPreviewCount);
  const currentDataPreview = useRecoilValue(currentDataPreviewPage);

  useEffect(() => {
    if (Object.keys(segmentSelectedForPreview).length === 0 && segmentLookup) {
      // TODO need to only lookup the segmentTree to get the segment name on page refresh (throttleUntil)
      setSegmentSelectedForPreview({
        segmentId: segmentId.segmentId,
        segmentName: segmentLookup.segmentName,
      });
    }
  }, [segmentLookup]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value === undefined ? '' : e.target.value;
    setDataPreivewSelectedFilter(value);
    setDataPreivewSelectedPage(0);
  };

  const segmentsPreviewHeaderRow = [
    {
      name: 'Account Id',
      fieldName: 'accountId',
      copyable: true,
      sortable: false,
    },
  ];

  return (
    <>
      <div className="SegmentDataPreview p-xl">
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} SegmentDataPreview__backBtn`,
          })}
          styleVariant="tertiary-grey"
          icon={<Icons.LeftArrowIcon />}
          onClick={() => {
            setDataPreivewSelectedFilter('');
            setDataPreivewSelectedPage(0);
            setSegmentSelectedForPreview({
              segmentId: '',
              segmentName: '',
            });
            setSegmentDataPreview([]);
            redirectToRoute(Routes.CreateAudience);
          }}
        >
          <FormattedMessage id="back" />
        </Button>
        <Heading size="xl">
          <FormattedMessage
            id="dataPreviewResults.title"
            params={{
              segmentName: segmentSelectedForPreview?.segmentName,
            }}
          />
        </Heading>
        <PendingContent
          loading={loadingStatus === 'loading'}
          isError={loadingStatus === 'error'}
          loader={Spinner}
        >
          <div className="SegmentDataPreview__totalCount">
            {
              <FormattedMessage
                id="dataPreviewResults.rowsAvailable"
                params={{
                  totalCount: totalFilteredItems,
                }}
              />
            }
          </div>
          <div className="SegmentDataPreview__headerActions">
            <SearchInputField
              rounded
              placeholder={t('search')}
              onInput={debounce(handleSearch, 400)}
              classes={(current) => ({
                ...current,
                root: `${current.root} search-input`,
              })}
            />
            <CSVLink
              data={csvDataPreview}
              filename={'segment-data-preview.csv'}
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <Button styleVariant="superprimary" icon={<Icons.DownloadIcon />}>
                <FormattedMessage id="download" />
              </Button>
            </CSVLink>
          </div>
          <div>
            <Table
              classes={(current) => ({
                ...current,
                root: `${current.root} SegmentDataPreview__dataTable`,
                cell: `${current.cell} SegmentDataPreview__dataTable_cell`,
              })}
              tableType="dataGrid"
              headerRow={segmentsPreviewHeaderRow}
              dataRows={[...currentDataPreview] as Record<string, any>[]}
              tooltipLabel="copied"
              truncateCellContent={false}
              noResultsMessage={t('noResults')}
            />
            <Pagination
              classes={(current: PaginationStyles) => ({
                ...current,
                root: `${current.root} SegmentDataPreview__pagination`,
              })}
              totalPageCount={
                Math.ceil(totalFilteredItems / DEFAULT_PAGE_SIZE) || 1
              }
              onPageSelect={setDataPreivewSelectedPage}
              expandDirection="top"
              currentPage={dataPreviewSelectedPage}
            />
          </div>
        </PendingContent>
      </div>
    </>
  );
};

export default SegmentDataPreview;
