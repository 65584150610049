import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { channelsAtom } from 'store/state/channelState';

type UseChannelValues = {
  loading: boolean;
  error: any;
  runFetch: (immediately?: boolean) => Promise<void>;
};

const { channelsService } = new DependencyContainer();

export const useChannels = (isThrottled: boolean = false): UseChannelValues => {
  const setChannels = useSetRecoilState(channelsAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const [loading, error, _, runFetch] = useFetchData(
    async () => {
      return await channelsService.getChannels(partnerId);
    },
    (channels) => setChannels(channels.data),
    [partnerId],
    () => isThrottled || partnerId === undefined,
  );

  return {
    loading,
    error,
    runFetch,
  };
};
