import {
  AddChannelModalTabs,
  AvailableIntegration,
  AvailableIntegrationTypes,
} from './types';
import { Icons } from 'plume-ui';
import React from 'react';

export const tabBarTitleIds: Record<AddChannelModalTabs, string> = {
  [AddChannelModalTabs.Choose]: 'settings.channel.chooseLabel',
  [AddChannelModalTabs.Configure]: 'settings.channel.configureLabel',
  [AddChannelModalTabs.Test]: 'settings.channel.testLabel',
};

export const availableIntegrations: AvailableIntegration[] = [
  {
    id: AvailableIntegrationTypes.Facebook,
    titleId: 'settings.channel.facebookAds',
    icon: <Icons.FacebookIcon />,
  },
  {
    id: AvailableIntegrationTypes.Google,
    titleId: 'settings.channel.googleAds',
    icon: <Icons.GoogleIcon />,
  },
];

export const scrollContainer = {
  marginRight: '2px',
  maxHeight: '628px',
  padding: '32px',
  height: '100vh',
};
