import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { DependencyContainer } from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import {
  editingCampaignEmailData,
  emailIntroEditInProgressAtom,
} from '../../../store/state/campaignState';
import { CampaignType, CampaignTypes } from '../types';

const { crusadeJourneyService } = new DependencyContainer();

export const useCampaignEmailData = (
  campaignId?: string,
  campaignType?: CampaignTypes,
) => {
  const setEmailData = useSetRecoilState(editingCampaignEmailData);
  const partnerId = useRecoilValue(partnerIdAtom);
  const setEditInProgress = useSetRecoilState(emailIntroEditInProgressAtom);

  const [loading, _, error, runFetch] = useFetchData(
    async () => {
      return await crusadeJourneyService.getCrusadeCampaignEmailData(
        partnerId,
        campaignId,
      );
    },
    (response) => {
      setEmailData(response.data);
      setEditInProgress(false);
    },
    [partnerId, campaignId],
    () =>
      campaignId === undefined ||
      partnerId === undefined ||
      campaignType === CampaignType.PUSH_NOTIFICATION,
  );

  return {
    loading,
    error,
    runFetch,
  };
};
