import { AudienceCard } from 'features/crusade/types';
import i18next from 'i18next';

export const CampaignAudienceCardDropdown = (): Array<AudienceCard> => {
  return [
    {
      title: 'crusade.campaigns.audience.sendToUser',
      label: 'crusade.campaigns.audience.fixtureOptions.subscribedOrOptedIn',
      items: [
        {
          title: i18next.t(
            'crusade.campaigns.audience.fixtureOptions.subscribedOrOptedIn',
          ),
        },
        {
          title: i18next.t(
            'crusade.campaigns.audience.fixtureOptions.optedInUsers',
          ),
        },
        {
          title: i18next.t(
            'crusade.campaigns.audience.fixtureOptions.allUsers',
          ),
        },
      ],
    },
    {
      title: 'crusade.campaigns.audience.timeLabel',
      label: 'crusade.campaigns.audience.fixtureOptions.after',
      items: [
        {
          title: i18next.t('crusade.campaigns.audience.fixtureOptions.after'),
          dbValue: '>',
        },
        {
          title: i18next.t('crusade.campaigns.audience.fixtureOptions.before'),
          dbValue: '<',
        },
        {
          title: i18next.t('crusade.campaigns.audience.fixtureOptions.never'),
          dbValue: 'never',
        },
      ],
    },
    {
      title: 'crusade.campaigns.audience.fixtureOptions.status',
      label: 'crusade.campaigns.audience.fixtureOptions.hasNot',
      items: [
        {
          title: i18next.t('crusade.campaigns.audience.fixtureOptions.hasNot'),
        },
        {
          title: i18next.t('crusade.campaigns.audience.fixtureOptions.has'),
        },
      ],
    },
    {
      title: 'crusade.campaigns.audience.operatorLabel',
      label: 'crusade.campaigns.audience.fixtureOptions.anytimeInThePast',
      items: [
        {
          title: i18next.t(
            'crusade.campaigns.audience.fixtureOptions.anytimeInThePast',
          ),
          dbValue: '<',
        },
      ],
    },
    {
      title: 'booleanOptions',
      label: 'true',
      items: [
        {
          title: i18next.t('true'),
          dbValue: true,
        },
        {
          title: i18next.t('false'),
          dbValue: false,
        },
      ],
    },
  ];
};
