import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { campaignHistoryAtom } from 'store/state/overviewState';

type UseCampaignHistoryValues = {
  loading: boolean;
  error: any;
  runFetchCampaignHistory: (params?: any) => Promise<any>;
};

const { overviewService } = new DependencyContainer();

export const useCampaignHistory = (): UseCampaignHistoryValues => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const setCampaignHistory = useSetRecoilState(campaignHistoryAtom);

  const [loading, , error, runFetchCampaignHistory] = useFetchData(
    async () => {
      return await overviewService.getCampaignGrowthHistory(partnerId);
    },
    (history) => {
      setCampaignHistory(history.data);
    },
    [partnerId],
    () => partnerId === undefined,
  );

  return {
    loading,
    error,
    runFetchCampaignHistory,
  };
};
