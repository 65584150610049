import { Operator } from './OperatorEnum';

export const numericPatternOperators = [
  {
    label: 'Equal',
    value: Operator.EQUAL,
  },
  {
    label: 'At least',
    value: Operator.AT_LEAST,
  },
  {
    label: 'At most',
    value: Operator.AT_MOST,
  },
  {
    label: 'More than',
    value: Operator.MORE_THAN,
  },
  {
    label: 'Less than',
    value: Operator.LESS_THAN,
  },
  {
    label: 'Not equal to',
    value: Operator.NOT_EQUAL,
  },
  {
    label: 'Between ... and ...',
    value: Operator.BETWEEN,
  },
];

export const timePatternOperators = [
  {
    label: 'Now',
    value: Operator.NOW,
  },
  {
    label: 'Anytime in the past',
    value: Operator.ANY_TIME_PAST,
  },
  {
    label: 'Days ago',
    value: Operator.DAYS_AGO,
  },
  {
    label: 'Each of last',
    value: Operator.EACH_OF_LAST,
  },
  {
    label: 'Between',
    value: Operator.BETWEEN_DAYS,
  },
];
