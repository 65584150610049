import { DEFAULT_PAGE_SIZE } from 'config';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector, selectorFamily } from 'recoil';
import { Maybe } from 'types';
import {
  CampaignJourney,
  CampaignObjective,
  CampaignPushNotificationCardValues,
  CampaignStatus,
  CampaignTemplateResponse,
  CampaignTypes,
  CrusadeCampaign,
  CrusadeCampaignAudienceSingleType,
  CrusadeCampaignAudienceType,
  CrusadeCampaignInfo,
  CrusadeJourneys,
  CrusadeJourneysAndCampaigns,
  SegmentOrigin,
  SortableCampaignFieldNames,
  TargetAudienceResponse,
} from '../../features/crusade/types';
import {
  campaignExists,
  makeCamelCase,
  parseUpdatedByValue,
} from '../../features/crusade/util/crusadeHelpers';

export enum ModalState {
  Open = 'open',
  Success = 'success',
  Dismissed = 'dismissed',
}

export const journeysAtom = atom<CrusadeJourneys[]>({
  key: 'journeysAtom',
  default: [],
});

export const journeysAndCampaignsAtom = atom<CrusadeJourneysAndCampaigns[]>({
  key: 'journeysAndCampaignsAtom',
  default: [],
});

export const campaignsSearchAtom = atom<string>({
  key: 'campaignsSearchAtom',
  default: '',
});

export const campaignsFilterAtom = atom<string>({
  key: 'campaignsFilterAtom',
  default: '',
});

export const targetAudienceAtom = atom<TargetAudienceResponse[]>({
  key: 'targetAudienceAtom',
  default: [],
});

export const editingCampaignEmailData = atom<CrusadeCampaignInfo>({
  key: 'editingCampaignEmailData',
  default: {} as CrusadeCampaignInfo,
});

export const campaignLaunchInProgress = atom<boolean>({
  key: 'campaignLaunchInProgress',
  default: false,
});

export const campaignLaunchComplete = atom<boolean>({
  key: 'campaignLaunchComplete',
  default: false,
});

export const editingCampaignAtom = atom<Maybe<CrusadeCampaign>>({
  key: 'editingCampaignAtom',
  default: undefined,
});

export const emailIntroEditInProgressAtom = atom<boolean>({
  key: 'emailIntroEditInProgressAtom',
  default: false,
});

export const campaignTemplatesAtom = atom<CampaignTemplateResponse[]>({
  key: 'campaignTemplatesAtom',
  default: [],
});

export const campaignObjectivesAtom = atom<CampaignObjective[]>({
  key: 'campaignObjectivesAtom',
  default: [],
});

export const campaignIsFromTemplate = atom<boolean>({
  key: 'campaignIsFromTemplate',
  default: false,
});

export const campaignListFromTemplateModal = atom<boolean>({
  key: 'campaignListFromTemplateModal',
  default: false,
});

export const campaignTabNavigationIndex = atom<number>({
  key: 'campaignTabNavigationIndex',
  default: 0,
});

// this is the default journey for new campaigns
export const unclassifiedJourneyId = selector({
  key: 'unclassifiedJourneyId',
  get: ({ get }) => {
    const journeys = get(journeysAtom);

    const unclassified = journeys.find(
      (journey) => journey.journeyName === CampaignJourney.UNCLASSIFIED,
    );

    return unclassified?.journeyId ? unclassified?.journeyId : '';
  },
});

export const campaignSortAtom = atom<FieldSort | null>({
  key: 'campaignSortAtom',
  default: null,
});

export const campaignSelectedPageAtom = atom<number>({
  key: 'campaignSelectedPageAtom',
  default: 0,
});

export const formUpdated = atom<boolean>({
  key: 'formUpdated',
  default: false,
});

// a list of target audience segments excluding 'Everything else'
//  segments which are created by default
export const audienceListSelector = selector({
  key: 'audienceListSelector',
  get: ({ get }) => {
    const audienceList = get(targetAudienceAtom);

    return audienceList.filter(
      (audience) => audience.segmentName !== 'Everything Else',
    );
  },
});

// Select target audiences with Segment(Generate) segment origin
export const audienceListSegmentsSelector = selector({
  key: 'audienceListSegmentsSelector',
  get: ({ get }) => {
    const audienceList = get(targetAudienceAtom);

    return audienceList.filter(
      (audience) =>
        audience.segmentOrigin === SegmentOrigin.Generate &&
        audience.segmentName !== 'Everything Else',
    );
  },
});

// Select target audiences with Monitor(Signal) segment origin
export const audienceListMonitorsSelector = selector({
  key: 'audienceListMonitorsSelector',
  get: ({ get }) => {
    const audienceList = get(targetAudienceAtom);

    return audienceList.filter(
      (audience) =>
        audience.segmentOrigin === SegmentOrigin.Signal &&
        audience.segmentName !== 'Everything Else',
    );
  },
});

export const campaignsForTableSelector = selector({
  key: 'campaignsForTableSelector',
  get: ({ get }) => {
    const journeysAndCampaigns = get(journeysAndCampaignsAtom);
    let parsedData: CrusadeCampaign[] = [];

    journeysAndCampaigns.forEach((journey) => {
      journey.campaigns.forEach((campaign) => {
        const found = campaignExists(parsedData, campaign.campaignId);

        const audienceSegmentId =
          campaign.audience && campaign.audience.length > 0
            ? (campaign.audience[0] as CrusadeCampaignAudienceType).length
              ? (campaign.audience[0] as CrusadeCampaignAudienceType)[0]
                  .segmentId
              : (campaign.audience[0] as CrusadeCampaignAudienceSingleType)
                  .segmentId
            : '';

        const audienceSegmentName =
          campaign.audience && campaign.audience.length > 0
            ? (campaign.audience[0] as CrusadeCampaignAudienceType).length
              ? (campaign.audience[0] as CrusadeCampaignAudienceType)[0]
                  .segmentName
              : (campaign.audience[0] as CrusadeCampaignAudienceSingleType)
                  .segmentName
            : '';

        const updatedByInitials =
          campaign.updatedBy && parseUpdatedByValue(campaign.updatedBy);

        const updatedCampaign: CrusadeCampaign = {
          campaignId: campaign.campaignId,
          journeyName: journey.journeyName,
          status: campaign.status,
          lastEdited: campaign.lastEdited,
          campaignType: campaign.campaignType,
          campaignName: campaign.campaignName,
          journeyId: journey.journeyId,
          description: campaign.description,
          dynamicContent: campaign.dynamicContent,
          tags: campaign.tags,
          audienceSegments: [
            {
              segmentId: audienceSegmentId,
              segmentName: audienceSegmentName,
            },
          ],
          audienceSummary: campaign.audienceSummary,
          audienceSize: campaign.audienceSize,
          objectives: campaign.objectives,
          delivery: campaign.delivery && {
            recurrence: campaign.delivery?.recurrence,
            endDate: campaign.delivery?.endDate,
            startDate: campaign.delivery?.startDate,
            endTime: campaign.delivery?.endTime,
            startTime: campaign.delivery?.startTime,
            endRecurrence: campaign.delivery?.endRecurrence,
            frequency: campaign.delivery?.frequency,
            weekly: campaign.delivery?.weekly,
          },
          immediately: campaign.immediately,
          sendToTheseUsers: campaign.sendToTheseUsers,
          warmup: campaign.warmup,
          updatedBy: updatedByInitials,
        };

        if (campaign.campaignType === ('pushNotification' as CampaignTypes)) {
          const messageObj = {
            title: (campaign.message as CampaignPushNotificationCardValues)
              .title,
            messageBody: (campaign.message as CampaignPushNotificationCardValues)
              .messageBody,
            onClickBehavior: {
              action: (campaign.message as CampaignPushNotificationCardValues)
                .onClickBehavior?.action,
              redirectURL: (campaign.message as CampaignPushNotificationCardValues)
                .onClickBehavior?.redirectURL,
            },
          };

          updatedCampaign.message = messageObj;
        } else {
          updatedCampaign.from = campaign.from;
          updatedCampaign.subject = campaign.subject;
          updatedCampaign.emailBody = campaign.emailBody;
          updatedCampaign.emailPreheaderOverwrite =
            campaign.emailPreheaderOverwrite;
          updatedCampaign.dynamicContent = campaign.dynamicContent;
        }

        if (!found) {
          parsedData.push(updatedCampaign);
        }
      });
    });

    return parsedData;
  },
});

export const filteredCampaignsSelector = selector({
  key: 'filteredCampaignsSelector',
  get: ({ get }) => {
    const filter = get(campaignsFilterAtom);
    const searchFilter = get(campaignsSearchAtom);
    let campaigns: CrusadeCampaign[] = get(sortedCampaigns);

    if (filter || searchFilter) {
      const camelCaseFilter = makeCamelCase(filter);
      campaigns = campaigns.filter((campaign) => {
        if (
          (!searchFilter ||
            campaign.campaignName
              .toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            campaign.journeyName
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase()) ||
            campaign.objectives
              ?.join(' ')
              ?.toLowerCase()
              .includes(searchFilter.toLowerCase())) &&
          (!camelCaseFilter ||
            campaign.campaignType === camelCaseFilter ||
            campaign.status.toLowerCase() === camelCaseFilter ||
            campaign.objectives?.includes(filter))
        ) {
          return campaign;
        } else {
          return null;
        }
      });
    }

    return campaigns;
  },
});

export const sortCampaigns = (
  campaigns: CrusadeCampaign[],
  sort: FieldSort,
) => {
  const fieldName = sort.fieldName as SortableCampaignFieldNames;

  if (fieldName === 'status') {
    return campaigns.sort((a, b) => {
      const aStatus =
        a.status === CampaignStatus.Completed ? 'Ended' : a.status;
      const bStatus =
        b.status === CampaignStatus.Completed ? 'Ended' : b.status;

      return aStatus.localeCompare(bStatus) * sort.direction;
    });
  } else if (fieldName !== 'lastEdited') {
    const sortField = fieldName === 'journey' ? 'journeyName' : fieldName;

    return campaigns.sort(
      (a, b) => a[sortField]!.localeCompare(b[sortField]!) * sort.direction,
    );
  } else {
    return campaigns.sort((a, b) => {
      const aDate = new Date(a.lastEdited!);
      const bDate = new Date(b.lastEdited!);
      return (aDate.getTime() - bDate.getTime()) * sort.direction;
    });
  }
};

export const sortedCampaigns = selector<CrusadeCampaign[]>({
  key: 'sortedCampaigns',
  get: ({ get }) => {
    let campaigns = get(campaignsForTableSelector);

    const sort = get(campaignSortAtom);
    if (sort) {
      return sortCampaigns([...campaigns], sort);
    }
    return campaigns;
  },
});

export const currentCampaignsPage = selector<CrusadeCampaign[]>({
  key: 'currentCampaignsPage',
  get: ({ get }) => {
    let campaigns = get(filteredCampaignsSelector);
    let selectedPage = get(campaignSelectedPageAtom);

    return campaigns.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const findSingleCampaign = selectorFamily<
  CrusadeCampaign | undefined,
  { id: string }
>({
  key: 'findSingleCampaign',
  get: ({ id }) => ({ get }) => {
    const campaigns = get(campaignsForTableSelector);

    return campaigns.find(
      (campaign: CrusadeCampaign) => campaign.campaignId === id,
    );
  },
});
