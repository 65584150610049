import { Routes } from '../../config';
import { useHistory } from 'react-router-dom';
import { getRouteAddress } from '../helpers';

export const useRedirectToRoute = () => {
  const history = useHistory();

  return (routeName: Routes, params?: any) => {
    const route = getRouteAddress(routeName, params);
    history.push(route);
  };
};
