import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useFetchData } from '../../../../hooks/useFetchData';
import {
  segmentsLoadingStatusAtom,
  segmentTreeAtom,
} from '../../../../store/state/audienceFlowState';
import { SegmentTreeData } from '../types';

type UseAudienceValues = {
  tree: SegmentTreeData[];
  loading: boolean;
  error: any;
  runFetch: (params?: any) => Promise<any>;
};

const { audienceService } = new DependencyContainer();

export const useAudience = (runOnInit = true): UseAudienceValues => {
  const [tree, setTreeData] = useRecoilState(segmentTreeAtom);
  const partnerId = useRecoilValue(partnerIdAtom);
  const setSegmentsLoadingStatus = useSetRecoilState(segmentsLoadingStatusAtom);
  const [loading, , error, runFetch] = useFetchData<any>(
    async () => {
      return await audienceService.getSegmentDataTree(partnerId);
    },
    (tree) => {
      setSegmentsLoadingStatus('loaded');
      return setTreeData(tree.data);
    },
    [partnerId],
    () => partnerId === undefined,
    runOnInit,
  );

  useEffect(() => {
    if (loading) {
      setSegmentsLoadingStatus('loading');
    }
    if (error) {
      setSegmentsLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    tree,
    loading,
    error,
    runFetch,
  };
};
