import { useRecoilValue } from 'recoil';
import { introspectAtom } from 'store/state/introspect';
import { HarvestProduct } from '../types';

export const useProduct = () => {
  const introspect = useRecoilValue(introspectAtom);

  const hasProduct = (product: HarvestProduct): boolean => {
    if (!introspect) {
      return false;
    }
    return introspect.harvestGranularSuiteAccess.includes(product);
  };

  const runForProduct = (
    product: HarvestProduct,
    callback: CallableFunction,
  ) => {
    if (!introspect) {
      return;
    }
    if (!introspect.harvestGranularSuiteAccess.includes(product)) {
      return;
    }
    callback();
  };

  return { hasProduct, runForProduct };
};
