import { CriteriaCollectionType } from 'components/CriteriaCollapseCard/CriteriaCollapseCard';
import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';

export type DynamicTraitsParams = {
  segmentIdForDynamicMetadata: string;
  criteriaCollection: CriteriaCollectionType;
  deps?: any[];
  isNewNode: boolean;
  runOnInit?: boolean;
};

type UseDynamicTraitsType = {
  popSize: number;
  queryOutput: string;
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { traitsService } = new DependencyContainer();

export const UseDynamicTraits = (
  params: DynamicTraitsParams,
): UseDynamicTraitsType => {
  const segmentIdForDynamicMetadata = params.segmentIdForDynamicMetadata;
  const criteriaCollection = params.criteriaCollection;
  const isNewNode = params.isNewNode;
  const partnerId = useRecoilValue(partnerIdAtom);
  const [popSize, setPopSize] = useState(-1);
  const [queryOutput, setQueryOutput] = useState('');

  const [loading, , error, runFetch] = useFetchData<any>(
    async () => {
      return await traitsService.getDynamicMetadataTree(
        partnerId,
        segmentIdForDynamicMetadata,
        criteriaCollection,
        isNewNode,
      );
    },
    (response) => {
      setPopSize(response.data.rowCount);
      setQueryOutput(response.data.logicalExpression);
    },
    [partnerId, params.deps ?? []],
    () => partnerId === undefined,
    params.runOnInit,
  );

  return {
    popSize,
    queryOutput,
    loading,
    error,
    runFetch,
  };
};
