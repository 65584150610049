import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import {
  emailCampaignHeroMetrics,
  pushCampaignHeroMetrics,
} from '../../ChartOptions';
import {
  CampaignHistory,
  EmailChartHeroMetricEnum,
  PushChartHeroMetricEnum,
} from '../../types';
import { CampaignType } from 'features/crusade/types';

export type CampaignOverviewChartLegendProps = {
  campaign: CampaignHistory;
  campaignHeroMetrics?: any;
};

const CampaignOverviewChartLegend: FunctionComponent<CampaignOverviewChartLegendProps> = ({
  campaign,
  campaignHeroMetrics,
}) => {
  const { t } = useTranslation();

  const legendValues: string[] =
    campaign.campaignType === CampaignType.EMAIL
      ? emailCampaignHeroMetrics
      : pushCampaignHeroMetrics;

  const nonChartValues: string[] = [
    EmailChartHeroMetricEnum.EMAILS_SENT,
    EmailChartHeroMetricEnum.DELIVERED,
    PushChartHeroMetricEnum.MESSAGES_SENT,
  ];

  const mapResponseToLegendLabel = (
    value: EmailChartHeroMetricEnum | PushChartHeroMetricEnum,
  ) => {
    switch (value) {
      case EmailChartHeroMetricEnum.EMAILS_SENT:
        return 'sent';
      case PushChartHeroMetricEnum.MESSAGES_SENT:
        return 'sent';
      default:
        return value;
    }
  };

  return (
    <div
      className={classNames([
        `CampaignOverview__chartLegend-${campaign.campaignType}`,
      ])}
    >
      {campaignHeroMetrics &&
        legendValues.map((value, idx) => {
          return (
            <div key={`${value}-${idx}`}>
              <div
                className={classNames(
                  `CampaignOverview__chartLegend-${campaign.campaignType}-value`,
                  [
                    `CampaignOverview__chartLegend-${campaign.campaignType}-value-${value}`,
                    `CampaignOverview__chartLegend-${campaign.campaignType}-value`,
                  ],
                )}
              >
                {!nonChartValues.includes(value) && (
                  <div
                    className={classNames(
                      `CampaignOverview__chartLegend-${campaign.campaignType}-indicator`,
                      [
                        `CampaignOverview__chartLegend-${campaign.campaignType}-indicator-${value}`,
                      ],
                    )}
                  />
                )}
                {t(`overview.campaigns.${value}`)}
              </div>
              <div
                className={classNames(
                  `CampaignOverview__chartLegend-${campaign.campaignType}-heroMetricValue`,
                  {
                    [`CampaignOverview__chartLegend-${campaign.campaignType}-heroMetricValue-chartValue`]: !nonChartValues.includes(
                      value,
                    ),
                  },
                )}
              >
                {
                  campaignHeroMetrics[
                    mapResponseToLegendLabel(
                      value as
                        | EmailChartHeroMetricEnum
                        | PushChartHeroMetricEnum,
                    )
                  ]
                }
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CampaignOverviewChartLegend;
