import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { DependencyContainer } from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { targetAudienceAtom } from '../../../store/state/campaignState';

type UseTargetAudienceValues = {
  targetAudienceLoading: boolean;
  targetAudienceError: any;
};

const { campaignAudienceService } = new DependencyContainer();

export const useTargetAudience = (): UseTargetAudienceValues => {
  const setTargetAudience = useSetRecoilState(targetAudienceAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const [targetAudienceLoading, , targetAudienceError] = useFetchData(
    async () => {
      return await campaignAudienceService.getTargetAudience(partnerId);
    },
    (response) => setTargetAudience(response.data),
    [partnerId],
    () => partnerId === undefined,
  );

  return {
    targetAudienceLoading,
    targetAudienceError,
  };
};
