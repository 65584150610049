import { time } from 'console';
import { CampaignType } from 'features/crusade/types';
import { convertToDateObject, formatGraphDateLabel } from './ChartDateHelpers';
import { getDatesForSelectedTimeRange } from './SegmentHelpers';
import { EmailCampaignStatType, PushCampaignStatType } from './types';

const getDatesInResponse = (
  stats: (EmailCampaignStatType | PushCampaignStatType)[],
) => {
  return stats.map((stat) => stat.dt);
};

export const fillMissingDates = (
  campaignDailyStats: (EmailCampaignStatType | PushCampaignStatType)[],
  duration: number,
  campaignType: CampaignType,
) => {
  const timeRangeDateArray = getDatesForSelectedTimeRange(duration);
  const dates = getDatesInResponse(campaignDailyStats);

  return timeRangeDateArray.map((date) => {
    if (dates.includes(date)) {
      const existingStat = campaignDailyStats.find((stat) => stat.dt === date);
      return existingStat;
    } else {
      if (campaignType === CampaignType.EMAIL) {
        return {
          dt: date,
          segmentSize: 0,
          clicks: 0,
          opens: 0,
          bounces: 0,
          unsubscribes: 0,
        };
      } else {
        return {
          dt: date,
          segmentSize: 0,
          opens: 0,
          bounces: 0,
        };
      }
    }
  });
};

export const createComposedChartEmailData = (
  emailCampaignDailyStats: EmailCampaignStatType[],
  duration: number,
  campaignType: CampaignType,
) => {
  const fullDataSet = fillMissingDates(
    emailCampaignDailyStats,
    duration,
    campaignType,
  ) as EmailCampaignStatType[];

  return fullDataSet.map((stat: EmailCampaignStatType) => {
    const statDateAsDateObject = convertToDateObject(stat.dt);
    return {
      name: formatGraphDateLabel(statDateAsDateObject),
      values: [
        stat.segmentSize === undefined || stat.segmentSize === null
          ? 0
          : stat.segmentSize,
        stat.clicks,
        stat.opens,
        stat.bounces,
        stat.unsubscribes,
      ],
    };
  });
};

export const createComposedChartPushData = (
  pushCampaignDailyStats: PushCampaignStatType[],
  duration: number,
  campaignType: CampaignType,
) => {
  const fullDataSet = fillMissingDates(
    pushCampaignDailyStats,
    duration,
    campaignType,
  ) as PushCampaignStatType[];

  return fullDataSet.map((stat: PushCampaignStatType) => {
    const statDateAsDateObject = convertToDateObject(stat.dt);
    return {
      name: formatGraphDateLabel(statDateAsDateObject),
      values: [
        stat.segmentSize === undefined || stat.segmentSize === null
          ? 0
          : stat.segmentSize,
        stat.opens,
        stat.bounces,
      ],
    };
  });
};
