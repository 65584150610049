import { Button, CollapseCard, Heading } from 'plume-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import FormattedMessage from 'utils/components/FormattedMessage';
import RichTextEditor from 'components/RichTextEditor/RichTextEditor';
import { editorHtmlValue } from 'components/RichTextEditor/mockEditorHtml';
import {
  campaignEmailConfigAtom,
  customFooterAtom,
} from 'store/state/configurationState';
import { useRecoilState, useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { t } from 'i18next';

const FooterCard: FunctionComponent = () => {
  // const [email, setEmail] = useState('');
  // const [emailList, setEmailList] = useState<string[]>([]);
  const partnerId = useRecoilValue(partnerIdAtom);
  const [customFooter, setCustomFooter] = useRecoilState(customFooterAtom);
  const [richTextEditorValue, setRichTextEditorValue] = useState<string>();

  useEffect(() => {
    if (customFooter.customFooterContent !== null) {
      setRichTextEditorValue(customFooter.customFooterContent);
    }
  }, [customFooter]);

  const scrollContainer = {
    marginRight: '2px',
    maxHeight: '130px',
    padding: '10px',
  };

  // Email disabled for v1/GA
  // const handleEmailInput = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const currentEmailList: string[] = [...emailList];

  //   const isCommaSeparated = email.includes(',');

  //   if (!isCommaSeparated) {
  //     currentEmailList.push(email);
  //   } else {
  //     email.split(',').forEach((email) => {
  //       currentEmailList.push(email);
  //     });
  //   }
  //   setEmailList(currentEmailList);
  //   setEmail('');
  // };

  // const handleRemoveEmail = (removableEmail: string) => {
  //   const currentEmailList: string[] = [...emailList];
  //   const filtered = currentEmailList.filter(
  //     (email) => email !== removableEmail,
  //   );
  //   setEmailList(filtered);
  // };

  const updateEditorValue = (html: string) => {
    if (partnerId === undefined) return;

    setRichTextEditorValue(html);

    const currentCustomFooter = customFooter;
    setCustomFooter({
      ...currentCustomFooter,
      partnerId: partnerId,
      customFooterContent: html,
    });
  };

  const handleReset = () => {
    if (partnerId === undefined) return;
    setRichTextEditorValue(editorHtmlValue);

    const currentCustomFooter = customFooter;
    setCustomFooter({
      ...currentCustomFooter,
      partnerId: partnerId,
      customFooterContent: editorHtmlValue,
    });
  };

  return (
    <div className="FooterCard__cardMain">
      <CollapseCard
        open={false}
        title={
          <Heading size="m">
            <FormattedMessage id="settings.campaignSetting.FooterCard.title" />
          </Heading>
        }
        subtitle={t('settings.campaignSetting.FooterCard.subtitle')}
      >
        <div className="FooterCard">
          <div className="FooterCard__head">
            <p className="FooterCard__headTitle">
              <FormattedMessage id="settings.campaignSetting.FooterCard.headTitle" />
            </p>
            <p className="FooterCard__headInfo">
              <FormattedMessage id="settings.campaignSetting.FooterCard.headInfo" />
            </p>
          </div>
          <div className="FooterCard__textEditorWrapper">
            <div className="FooterCard__textEditor">
              <p className="FooterCard__textEditorTitle">
                <FormattedMessage id="settings.campaignSetting.FooterCard.composeLabel" />
              </p>
              <div>
                <RichTextEditor
                  richTextEditorValue={richTextEditorValue}
                  setEditorHtml={updateEditorValue}
                  readOnly={false}
                />
              </div>
            </div>

            {/* --------- Disabled in v1/GA --------  */}

            {/* <div className="FooterCard__sendTestWrapper">
              <p className="FooterCard__textEditorTitle">
                <FormattedMessage id="settings.campaignSetting.FooterCard.testLabel" />
              </p>
              <div className="FooterCard__sendTest">
                <div className="FooterCard__InputEmail">
                  <InputField
                    label={t(
                      'settings.campaignSetting.FooterCard.inputFieldLabel',
                    )}
                    onInput={(e) =>
                      setEmail((e.target as HTMLInputElement).value)
                    }
                    value={email}
                  />
                  {email && (
                    <Button
                      styleVariant="quaternary"
                      onClick={(e: any) => handleEmailInput(e)}
                    >
                      <Icons.RightArrowIcon className="FooterCard__addButton" />
                    </Button>
                  )}
                </div>
                <PerfectScrollbar
                  style={scrollContainer}
                  options={{ maxScrollbarLength: 20 }}
                >
                  <div className="FooterCard__chipContainer">
                    {emailList.map((email) => {
                      return (
                        <Chip
                          classes={(current) => ({
                            ...current,
                            root: `${current.root} FooterCard__chip`,
                          })}
                          key={generateKey()}
                          type="mini"
                          removable={true}
                          color="solid"
                          onClick={() => handleRemoveEmail(email)}
                        >
                          {email}
                        </Chip>
                      );
                    })}
                  </div>
                </PerfectScrollbar>
                <div className="FooterCard__sendTestButton">
                  <Button disabled={!emailList?.length}>
                    <FormattedMessage id="settings.campaignSetting.FooterCard.sendTestButton" />
                  </Button>
                </div>
              </div>
            </div> */}
          </div>
          <div>
            <p className="FooterCard__sizeInfoLabel">
              <FormattedMessage id="settings.campaignSetting.FooterCard.sizeInfoLabel" />
            </p>
            <p className="FooterCard__attributeInfoLabel">
              <FormattedMessage id="settings.campaignSetting.FooterCard.attributeInfoLabel" />
            </p>
            <div className="FooterCard__resetButtonWrapper">
              <Button onClick={handleReset}>
                <FormattedMessage id="settings.campaignSetting.FooterCard.resetButton" />
              </Button>
            </div>
          </div>
        </div>
      </CollapseCard>
    </div>
  );
};

export default FooterCard;
