import React, { FunctionComponent } from 'react';
import { Button, Icons, Heading } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { Routes } from 'config';
import { NpsTimeRangeEnum, OverviewEnum } from 'features/overview/types';
import { useSetRecoilState } from 'recoil';
import { npsOverviewGlobalTimeRangeAtom } from 'store/state/overviewState';

export type NpsOverviewProps = {
  campaignName?: string;
};

const NpsOverviewHeader: FunctionComponent<NpsOverviewProps> = ({
  campaignName,
}) => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const setNpsTimeRange = useSetRecoilState(npsOverviewGlobalTimeRangeAtom);

  const handleBack = () => {
    setNpsTimeRange({
      label: NpsTimeRangeEnum.THIRTY_DAYS,
      value: 30,
    });
    redirectToRoute(Routes.Overview, {
      activeOverview: OverviewEnum.CAMPAIGN_OVERVIEW,
    });
  };

  return (
    <div className="NpsOverview__header">
      <Button
        classes={(current) => ({
          ...current,
          root: `${current.root} NpsOverview__header-backBtn`,
        })}
        styleVariant="tertiary-grey"
        icon={<Icons.ArrowLeftIcon />}
        onClick={handleBack}
      >
        {t('back')}
      </Button>
      <Heading
        classes={(current) => ({
          ...current,
          root: `${current.root} NpsOverview__header-title`,
        })}
        size="xl"
        medium
      >
        {campaignName}
      </Heading>
    </div>
  );
};

export default NpsOverviewHeader;
