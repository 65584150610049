import { CriteriaCollectionType } from '../CriteriaCollapseCard/CriteriaCollapseCard';
import { Maybe } from '../../types';

export type Segment = CriteriaCollectionType & {
  segmentName: string;
  parentSegmentIds: Maybe<string>[];
};

export interface SegmentDtoProtocol extends Segment {}

export enum SegmentModeTypes {
  NEW = 'new',
  EDIT = 'edit',
}
