import { AxiosResponse } from 'axios';
import { Maybe } from 'yup/lib/types';
import { DependencyContainer } from '../../../../DependencyContainer';
import { HttpClient } from '../../../../HttpClient';
import { HarvestApiUrls } from '../../../../urls';
import { AudienceSizeResponse, MetadataTreeResponse } from '../types';

export default class TraitsClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getMetadataTree(): Promise<any> {
    return this.get<AxiosResponse<MetadataTreeResponse>>(
      HarvestApiUrls.getMetadataTree(),
    );
  }

  async getDynamicMetadataTree(
    partnerId: Maybe<string>,
    segmentNodeId: string,
    payload: any,
  ): Promise<any> {
    return this.post<AxiosResponse<MetadataTreeResponse>>(
      HarvestApiUrls.getDynamicMetadataTree(partnerId, segmentNodeId),
      payload,
    );
  }

  async getAudienceSize(partnerId: string, segmentId: string): Promise<any> {
    return this.get<AxiosResponse<AudienceSizeResponse>>(
      HarvestApiUrls.getSegmentSize(partnerId, segmentId),
    );
  }

  async getMetadataAllowedValues(
    partnerId: string,
    columnName: string,
  ): Promise<any> {
    return this.get<AxiosResponse<string[]>>(
      HarvestApiUrls.getMetadataAllowedValues(partnerId, columnName),
    );
  }
}
