import React, { FunctionComponent, useEffect, useState } from 'react';

import { useOktaAuth } from '@okta/okta-react';
import { getEnvironment } from 'environment';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { Menu, Notifications, TopBar } from 'plume-ui';
import { MenuStyles } from 'plume-ui/dist/components/Menu/Menu';

import { MixpanelService } from 'features/trackingAnalytics/services/mixPanel.service';
import { useApiVersion } from 'hooks/useApiVersion';
import { partnerIdAtom } from 'store/state/appState';
import { Introspect, introspectAtom } from 'store/state/introspect';

import { DependencyContainer } from '../DependencyContainer';
import { APP_URL, Routes } from '../config';
import { useTrackEvent } from '../features/trackingAnalytics/hooks/useTrackEvent';
import useMenu from '../hooks/useMenu';
import { MixPanelEvents } from '../mixPanelEvents';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';

const { loginService, introspectService } = new DependencyContainer();

const getUserNameForDropdown = () => {
  const { firstName, lastName } = loginService.getUser();
  return `${firstName} ${lastName.slice(0, 1)}`;
};

const getBadgeInitials = () => {
  const { firstName, lastName } = loginService.getUser();
  return `${firstName[0]}${lastName[0]}`;
};

// TODO: Update the AutoComplete search and onItemClick when we have data
// TODO: Update the IconButton with onClick
const AppLayout: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const { oktaAuth } = useOktaAuth();
  const getCurrentRoute = useGetCurrentRoute();
  const getMenuItems = useMenu();
  const setIntrospect = useSetRecoilState(introspectAtom);
  const setPartnerId = useSetRecoilState(partnerIdAtom);
  const [currentRoute, setCurrentRoute] = useState('');
  const [showMainMenu, setShowMainMenu] = useState(true);

  const { closeAllModals } = useGlobalModalContext();

  useApiVersion();

  useEffect(() => {
    const route = getCurrentRoute();
    setCurrentRoute(route);
  }, [currentRoute, getCurrentRoute()]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setShowMainMenu(false);
    }
  }, []);

  useEffect(() => {
    introspectService
      .getIntrospect()
      .then((introspect: Introspect) => {
        setIntrospect(introspect);
        if (introspect.partnerId) {
          setPartnerId(introspect.partnerId);
          MixpanelService.getInstance().setPartnerId(introspect.partnerId);
        }
        MixpanelService.getInstance().setEnvironment(getEnvironment().id);
        MixpanelService.getInstance().setRole(introspect.role);
      })
      .catch((error: any) => console.error('error getting introspect'));

    if (currentRoute === Routes.Index) {
      return;
    }
  }, []);

  const logout = async () => {
    closeAllModals();
    loginService.removeAuthError();
    trackEvent({
      eventName: MixPanelEvents.LOGOUT,
    });
    await oktaAuth.signOut({
      postLogoutRedirectUri: APP_URL,
    });
  };

  return (
    <div className="AppLayout">
      <TopBar
        userDropdown={{
          username: getUserNameForDropdown(),
          signOutAction: () => logout(),
          variant: 'withAvatar',
          badge: {
            badgeContent: getBadgeInitials(),
            size: 'standard',
          },
        }}
      />
      <div className="AppLayout__row">
        <Menu
          classes={(current: MenuStyles) => ({
            ...current,
            wrapper: `${current.wrapper} AppLayout__menu--wrapper`,
            appName: `${current.appName} AppLayout__menu--appName`,
          })}
          groupedItems={getMenuItems()}
          appName={t('appName')}
        />
        <div className="AppLayout__content" style={{ width: '100%' }}>
          {children}
        </div>
      </div>
      <Notifications
        position="bottom-right"
        containerStyle={{
          marginRight: '16px',
          marginTop: '16px',
          zIndex: '10000',
        }}
      />
    </div>
  );
};

export default AppLayout;
