import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { npsAllTimeHistoryAtom } from 'store/state/overviewState';

type UseNps30AllTimeHistoryValues = {
  loading: boolean;
  error: any;
};

const { overviewService } = new DependencyContainer();

export const useNpsAllTimeHistory = (
  campaignId: string,
): UseNps30AllTimeHistoryValues => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const setAllTimeHistory = useSetRecoilState(npsAllTimeHistoryAtom);

  const [loading, , error] = useFetchData(
    async () => {
      return await overviewService.getNpsAllTimeHistory(partnerId, campaignId);
    },
    (history) => {
      setAllTimeHistory(history.data);
    },
    [partnerId, campaignId],
  );

  return {
    loading,
    error,
  };
};
