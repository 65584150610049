import { useCampaignConfigEmailDomainList } from 'features/configuration/hooks/useCampaignConfigEmailDomainList';
import {
  Button,
  CollapseCard,
  Dropdown,
  DropdownItem,
  DropdownWrapWithLabel,
  Heading,
  Icons,
  InputField,
  PendingContent,
} from 'plume-ui';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  campaignConfigEmailDomainListAtom,
  displayNameAddressAtom,
} from 'store/state/configurationState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { generateKey } from 'utils/helpers';

enum InputTypes {
  LOCAL_PART = 'localPart',
  DISPLAY_NAME = 'displayName',
}

const NameAddressCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [displayNameAddressList, setDisplayNameAddressList] = useRecoilState(
    displayNameAddressAtom,
  );
  const { loading, error } = useCampaignConfigEmailDomainList();
  const emailDomainList = useRecoilValue(campaignConfigEmailDomainListAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const entryFieldErrorMessage = (
    inputString: string | null,
    field: string,
  ) => {
    return !inputString
      ? {
          status: 'error' as InputMessageStatus,
          message: t('fieldRequired'),
        }
      : field === InputTypes.LOCAL_PART && inputString?.includes(' ')
      ? {
          status: 'error' as InputMessageStatus,
          message: t('fieldCannotContainSpace'),
        }
      : {};
  };

  const addNameAddress = () => {
    if (!partnerId) return;

    const currentList = [...displayNameAddressList];
    currentList.push({
      partnerId: partnerId,
      fromDisplayName: '',
      localPart: '',
      isDefault: false,
      emailDomain: '',
      emailIpPool: '',
    });
    setDisplayNameAddressList(currentList);
  };

  const removeNameAddress = (addressIdx: number) => {
    const currentList = [...displayNameAddressList];
    currentList.splice(addressIdx, 1);
    setDisplayNameAddressList(currentList);
  };

  const defaultHandler = (addressIdx: number) => {
    const currentList = [...displayNameAddressList];
    const updatedList = currentList.map((element, idx) => {
      return {
        ...element,
        isDefault: addressIdx === idx ? true : false,
      };
    });
    setDisplayNameAddressList(updatedList);
  };

  const handleFromDisplayNameInput = (addressIdx: number, value: string) => {
    const currentList = [...displayNameAddressList];
    currentList.splice(addressIdx, 1, {
      ...currentList[addressIdx],
      fromDisplayName: value === undefined ? null : value.trim(),
    });

    setDisplayNameAddressList(currentList);
  };

  const handleLocalPartInput = (addressIdx: number, value: string) => {
    const currentList = [...displayNameAddressList];
    currentList.splice(addressIdx, 1, {
      ...currentList[addressIdx],
      localPart: value === undefined ? null : value.trim(),
    });

    setDisplayNameAddressList(currentList);
  };

  const handleSelectDomain = (addressIdx: number, domainObj: any) => {
    const currentList = [...displayNameAddressList];
    currentList.splice(addressIdx, 1, {
      ...currentList[addressIdx],
      emailDomain: domainObj.domain,
      emailIpPool: domainObj.ipPool,
    });

    setDisplayNameAddressList(currentList);
  };

  return (
    <div className="NameAddressCard__cardMain">
      <CollapseCard
        classes={(current) => ({
          ...current,
          innerContent: `${current.innerContent} NameAddressCard`,
        })}
        open={false}
        title={
          <Heading size="m">
            {t('settings.campaignSetting.NameAddressCard.title')}
          </Heading>
        }
        subtitle={t('settings.campaignSetting.NameAddressCard.subtitle')}
      >
        {displayNameAddressList && displayNameAddressList.length > 0 && (
          <div className="NameAddressTable-headerContainer">
            <div className="NameAddressTable-header">
              <FormattedMessage id="settings.campaignSetting.NameAddressCard.fromDisplayNameLabel" />
            </div>
            <div className="NameAddressTable-header">
              <FormattedMessage id="settings.campaignSetting.NameAddressCard.localPartLabel" />
            </div>
            <div className="NameAddressTable-header" />
            <div className="NameAddressTable-header">
              <FormattedMessage id="settings.campaignSetting.NameAddressCard.domainIPPoolLabel" />
            </div>
          </div>
        )}
        <div className="NameAddressTable">
          {displayNameAddressList.map((nameAddress, idx) => {
            return (
              <div
                className="NameAddressTable-row"
                key={`${nameAddress}-${idx}`}
              >
                <div className="NameAddressTable-row-input">
                  <InputField
                    value={nameAddress.fromDisplayName || ''}
                    onInput={(e) =>
                      handleFromDisplayNameInput(idx, e.currentTarget.value)
                    }
                    placeholder={t(
                      'settings.campaignSetting.NameAddressCard.fromDisplayNamePlaceholder',
                    )}
                    messages={[
                      entryFieldErrorMessage(
                        nameAddress.fromDisplayName,
                        InputTypes.DISPLAY_NAME,
                      ),
                    ]}
                  />
                </div>
                <div className="NameAddressTable-row-input">
                  <InputField
                    value={nameAddress.localPart || ''}
                    onInput={(e) =>
                      handleLocalPartInput(idx, e.currentTarget.value)
                    }
                    placeholder={t(
                      'settings.campaignSetting.NameAddressCard.localPartPlaceholder',
                    )}
                    messages={[
                      entryFieldErrorMessage(
                        nameAddress.localPart,
                        InputTypes.LOCAL_PART,
                      ),
                    ]}
                  />
                </div>
                <Heading
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} NameAddressTable-row-at`,
                  })}
                  size="xl"
                >
                  @
                </Heading>
                <PendingContent
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} NameAddressTable-row-dropdown`,
                  })}
                  loading={loading}
                  isError={Boolean(error)}
                >
                  <Dropdown
                    listPosition="left"
                    closeOnItemClick
                    openInPortal={true}
                    label={
                      nameAddress.emailDomain ||
                      t('settings.campaignSetting.NameAddressCard.selectDomain')
                    }
                  >
                    {emailDomainList?.length > 0 &&
                      emailDomainList.map((domainObj: any) => {
                        return (
                          <DropdownItem
                            key={generateKey()}
                            onClick={() => handleSelectDomain(idx, domainObj)}
                          >
                            {`${domainObj.domain} | ${domainObj.ipPool}`}
                          </DropdownItem>
                        );
                      })}
                  </Dropdown>
                </PendingContent>
                <Button
                  classes={(current) => ({
                    ...current,
                    container: `${current.container} NameAddressTable-row-defaultBtnContainer`,
                  })}
                  styleVariant="tertiary-grey"
                  onClick={() => defaultHandler(idx)}
                >
                  {nameAddress.isDefault
                    ? t('settings.campaignSetting.defaultButton')
                    : t('settings.campaignSetting.makeDefaultButton')}
                </Button>
                <div
                  className="NameAddressTable-row-trashIcon"
                  onClick={() => removeNameAddress(idx)}
                >
                  <Icons.TrashIcon width={24} />
                </div>
              </div>
            );
          })}
        </div>
        <div className="action">
          <div className="addNameAddressButton">
            <Button onClick={() => addNameAddress()}>
              {t('settings.campaignSetting.NameAddressCard.addButton')}
            </Button>
          </div>
        </div>
      </CollapseCard>
    </div>
  );
};

export default NameAddressCard;
