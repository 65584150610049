import { Grid, GridItem } from 'plume-ui';
import React, { FunctionComponent, useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import {
  campaignListFromTemplateModal,
  campaignTabNavigationIndex,
} from 'store/state/campaignState';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import CampaignTabNavigationHeader from '../components/CampaignTabNavigationHeader';
import CampaignTable from '../components/CampaignTable';
import { useCampaignObjectives } from '../hooks/useCampaignObjectives';
import { useCampaignTemplates } from '../hooks/useCampaignTemplates';

const CrusadeCampaignsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const setCampaignListFromTemplateModal = useSetRecoilState(
    campaignListFromTemplateModal,
  );
  const setActiveTab = useSetRecoilState(campaignTabNavigationIndex);
  useCampaignTemplates();

  const { runFetch: fetchObjectives } = useCampaignObjectives();

  useLayoutEffect(() => {
    setActiveTab(0);
  }, []);

  useEffect(() => {
    setCampaignListFromTemplateModal(false);
    fetchObjectives();
  }, []);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.CrusadeCampaigns,
      },
    });
  }, []);

  return (
    <div className="CrusadeCampaignsContainer">
      <Helmet>
        <title>{t('crusade.title')}</title>
      </Helmet>
      <Grid>
        <GridItem colSpan="12" tabletColSpan="6">
          <CampaignTabNavigationHeader />
        </GridItem>
        <CampaignTable />
      </Grid>
    </div>
  );
};

export default CrusadeCampaignsContainer;
