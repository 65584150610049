import { atom } from 'recoil';
import { Maybe } from '../../types';
import { IntegrationStrategy } from '../../persistent-state/persistent-state';
import { IntegrationError } from '../../features/integrations/types';

export type AdAccount = {
  id: string;
  name: string;
  timezone: string;
  currency: string;
};

export type AdAccountResponseItem = {
  ad_account: AdAccount;
  ad_account_id: string;
  login_ad_account_id?: string;
  error_message?: string;
};

export type AdConnectedAccountResponse = {
  channel_id: string;
  channel_type: string;
  channel_name: string;
  custom_properties: any;
  channel_description: string;
  created_by: string;
  created_at: string;
};

export type TestChannelResponse = {
  status: 'success' | 'error';
  error_message?: any;
};

export const adAccountsResponseAtom = atom<AdAccountResponseItem[]>({
  key: 'adAccountsResponseAtom',
  default: [],
});

export const integrationCacheAtom = atom<Maybe<IntegrationStrategy>>({
  key: 'integrationCacheAtom',
  default: undefined,
});

export const integrationErrorAtom = atom<Maybe<IntegrationError>>({
  key: 'integrationErrorAtom',
  default: undefined,
});
