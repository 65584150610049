import { npsChartLegendOptions } from 'features/overview/ChartOptions';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tooltip, Icons, Heading } from 'plume-ui';
import { NpsEnum, NpsHeroMetricType } from 'features/overview/types';

export type NpsOverviewChartLegendProps = {
  heroMetrics: NpsHeroMetricType;
};

const NpsOverviewChartLegend: FunctionComponent<NpsOverviewChartLegendProps> = ({
  heroMetrics,
}) => {
  const { t } = useTranslation();

  return (
    <div className="NpsOverview__chartLegend">
      <div className="NpsOverview__chartLegend-npsItem">
        <div
          className={classNames(`NpsOverview__chartLegend__label`, [
            `NpsOverview__chartLegend__label-npsScore`,
          ])}
        >
          {`${t(`overview.nps.npsScore`)}:`}
        </div>
        <div
          className={classNames('NpsOverview__chartLegend__value', [
            `NpsOverview__chartLegend__value-npsScore`,
          ])}
        >{`${heroMetrics['npsScore']}`}</div>
        <Tooltip
          classes={(current) => ({
            ...current,
            root: `${current.root} NpsOverview__chartLegend__npsTooltip`,
          })}
          label={t('overview.nps.tooltips.nps')}
          overrideMaxCharacters
        >
          <Icons.InfoIcon width={16} />
        </Tooltip>
      </div>
      <div className="NpsOverview__chartLegend-items">
        {npsChartLegendOptions.map((legendOption, idx) => (
          <div
            className="NpsOverview__chartLegend-item"
            key={`${legendOption}-${idx}`}
          >
            <div className="NpsOverview__chartLegend-labelGroup">
              <div
                className={classNames(`NpsOverview__chartLegend__label`, [
                  `NpsOverview__chartLegend__label-${legendOption}`,
                ])}
              >
                {`${t(`overview.nps.${legendOption}`)}`}
              </div>
              <Tooltip
                classes={(current) => ({
                  ...current,
                  root: `${current.root} NpsOverview__chartLegend__tooltip`,
                })}
                label={t(`overview.nps.tooltips.${legendOption}`)}
                overrideMaxCharacters
              >
                <Icons.InfoIcon width={12} />
              </Tooltip>
            </div>
            <div
              className={classNames('NpsOverview__chartLegend__value', [
                `NpsOverview__chartLegend__value-${legendOption}`,
              ])}
            >{`${heroMetrics[legendOption as NpsEnum]}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NpsOverviewChartLegend;
