import faker from 'faker';
import moment from 'moment';
import { AudienceSegments } from '../../features/generate/audience/types';
import { randomString } from '../testUtils';

export const createAudienceFlow = (
  override?: Partial<AudienceSegments>,
): AudienceSegments => ({
  id: faker.datatype.string(10),
  segmentName: faker.company.companyName(),
  subSegments: faker.datatype.number(10),
  activeCampaigns: faker.datatype.number(10),
  tags: [faker.datatype.string(), faker.datatype.string()],
  lastModified: moment().unix().toString(),
  lastModifiedBy: randomString(),
  ...override,
});

export const createAudienceFlows = (count: number): AudienceSegments[] => {
  const audienceFlows: AudienceSegments[] = [];
  for (let i = 0; i < count; i++) {
    audienceFlows.push(createAudienceFlow());
  }
  return audienceFlows;
};
