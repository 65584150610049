import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilState } from 'recoil';
import { metadataAllowedValuesAtom } from 'store/state/metadataTreeState';
import { Maybe } from 'types';

type UseMetadataAllowedValues = {
  metadataAllowedValues: string[];
  loading: boolean;
  error: any;
  runFetch: (params?: any) => Promise<any>;
};

const { traitsService } = new DependencyContainer();

export const useMetadataAllowedValues = (
  partnerId: Maybe<string>,
  columnName: string,
): UseMetadataAllowedValues => {
  const [metadataAllowedValues, setMetadataAllowedValues] = useRecoilState(
    metadataAllowedValuesAtom,
  );

  const [loading, , error, runFetch] = useFetchData<any>(
    async () => {
      return await traitsService.getMetadataAllowedValues(
        partnerId,
        columnName,
      );
    },
    (metadataAllowedValues) => {
      return setMetadataAllowedValues(metadataAllowedValues.data);
    },
    [partnerId],
    () => partnerId === undefined,
  );

  return {
    metadataAllowedValues,
    loading,
    error,
    runFetch,
  };
};
