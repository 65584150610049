import React, { FunctionComponent } from 'react';
import { ConfirmationModal } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import classNames from 'classnames';

type HarvestConfirmationModalProps = {
  title: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  isOpen: boolean;
  onConfirm: () => void;
  onRequestClose: () => void;
  body: string;
  multipleLines: boolean;
};

export const HarvestConfirmationModal: FunctionComponent<HarvestConfirmationModalProps> = ({
  title,
  body,
  cancelButtonLabel,
  confirmButtonLabel,
  isOpen,
  multipleLines,
  ...props
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onCancel = () => {
    hideModal(MODAL_TYPES.CONFIRMATION_MODAL);
  };

  const onConfirm = () => {
    props.onConfirm();
    hideModal(MODAL_TYPES.CONFIRMATION_MODAL);
  };

  return (
    <ConfirmationModal
      title={title || t('confirmation.title')}
      isOpen={isOpen}
      onConfirm={() => onConfirm()}
      onRequestClose={() => onCancel()}
      confirmTitle={confirmButtonLabel || t('delete')}
    >
      <div
        className={classNames({
          'HarvestConfirmationModal-multipleLines': multipleLines,
        })}
      >
        {body || <FormattedMessage id="confirmation.body" />}
      </div>
    </ConfirmationModal>
  );
};
