import { Heading, Tooltip } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

export type CampaignOverviewCardHeaderTitleProps = {
  campaignName: string;
  showRightButton: boolean;
};

const CampaignOverviewCardHeaderTitle: FunctionComponent<CampaignOverviewCardHeaderTitleProps> = ({
  campaignName,
  showRightButton,
}) => {
  return (
    <div>
      {campaignName.length > 25 ? (
        <Tooltip label={campaignName} overrideMaxCharacters={true}>
          <div
            className={classNames('CampaignOverview__cardHeader-longTitle', {
              'CampaignOverview__cardHeader-longTitle-withRightBtn': showRightButton,
            })}
          >
            {campaignName}
          </div>
        </Tooltip>
      ) : (
        <Heading
          medium
          size="m"
          classes={(current) => ({
            ...current,
            root: `${current.root} CampaignOverview__cardHeader-title`,
          })}
        >
          {campaignName}
        </Heading>
      )}
    </div>
  );
};

export default CampaignOverviewCardHeaderTitle;
