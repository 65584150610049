import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { syncsAtom } from 'store/state/syncsState';

type UseSyncsValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { syncsService } = new DependencyContainer();

export type UseSyncsParams = {
  initiallyThrottled?: boolean;
};

export const useSyncs = (params?: UseSyncsParams): UseSyncsValues => {
  const setSyncs = useSetRecoilState(syncsAtom);
  const partnerId = useRecoilValue(partnerIdAtom);
  const initiallyThrottled = params?.initiallyThrottled || false;

  const [loading, , error, runFetch] = useFetchData(
    async () => {
      return await syncsService.getSyncs(partnerId);
    },
    (syncs) => setSyncs(syncs.data),
    [partnerId],
    () => initiallyThrottled || partnerId === undefined,
  );

  return {
    loading,
    error,
    runFetch,
  };
};
