import React, { FunctionComponent } from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Button } from 'plume-ui';

type AddChannelConfigureErrorProps = {
  onReAuthorize: () => void;
  error: any;
};

export const AddChannelConfigureError: FunctionComponent<AddChannelConfigureErrorProps> = ({
  onReAuthorize,
}) => {
  return (
    <>
      <p>
        <FormattedMessage id="settings.errors.unableToRetrieveAccounts" />
      </p>
      <Button onClick={() => onReAuthorize()}>
        <FormattedMessage id="settings.errors.reAuthorize" />
      </Button>
    </>
  );
};

export default AddChannelConfigureError;
