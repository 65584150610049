import { MixPanelEvents } from '../../mixPanelEvents';

export type TrackingUser = {
  id?: string;
  emailAddress?: string;
  role?: string;
};

export enum AvailableScreens {
  Audience = 'Audience',
  Portfolio = 'Portfolio',
  Generate = 'Generate',
  CampaignOverview = 'CampaignOverview',
  SegmentOverview = 'SegmentOverview',
  CrusadeCampaigns = 'CrusadeCampaigns',
  Configuration = 'Configuration',
  Login = 'Login',
  Syncs = 'Syncs',
}
type GenericTrackEventParams = {
  eventName: MixPanelEvents.LOGOUT | MixPanelEvents.AUTHENTICATION_SUCCESS;
  additionalContent?: Record<string, any>;
};

export type TrackEventParams =
  | GenericTrackEventParams
  | {
      eventName: MixPanelEvents.SCREEN;
      additionalContent: {
        SCREEN: AvailableScreens;
      };
    }
  | {
      eventName:
        | MixPanelEvents.CAMPAIGN_SAVE_SUCCESS
        | MixPanelEvents.CAMPAIGN_SAVE_FAILURE
        | MixPanelEvents.CAMPAIGN_LAUNCH_SUCCESS
        | MixPanelEvents.CAMPAIGN_LAUNCH_FAILURE
        | MixPanelEvents.EMAIL_CONFIG_SAVE_SUCCESS
        | MixPanelEvents.EMAIL_CONFIG_SAVE_FAILURE;
      additionalContent: {
        CAMPAIGN_ID?: string;
        ERROR_MESSAGE?: string;
        CAMPAIGN_TYPE?: string;
      };
    }
  | {
      eventName:
        | MixPanelEvents.SEGMENT_CREATE_SUCCESS
        | MixPanelEvents.SEGMENT_CREATE_FAILURE
        | MixPanelEvents.SEGMENT_EDIT_SUCCESS
        | MixPanelEvents.SEGMENT_EDIT_FAILURE
        | MixPanelEvents.SEGMENT_DELETE_SUCCESS
        | MixPanelEvents.SEGMENT_DELETE_FAILURE;
      additionalContent: {
        SEGMENT_ID?: string;
        SEGMENT_NAME?: string;
        ERROR_MESSAGE?: string;
        SEGMENT_TYPE?: string;
      };
    };
