import React, { FunctionComponent, useEffect, useState } from 'react';

import { DEFAULT_PAGE_SIZE, Routes } from 'config';

import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownSectionItem,
  DropdownSelectableItem,
  GridItem,
  IconButton,
  Icons,
  Pagination,
  PendingContent,
  SearchInputField,
  Spinner,
  Table,
  notify,
} from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { mapStatusToMessageId } from '../hooks/useCampaignStatusMapping';
import { CampaignStatus, CrusadeCampaign } from '../types';

import { useCampaignEmailConfiguration } from 'features/configuration/hooks/useCampaignEmailConfiguration';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';

import { partnerIdAtom } from 'store/state/appState';
import { brandingCompanyNameAtom } from 'store/state/configurationState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { DependencyContainer } from '../../../DependencyContainer';
import {
  campaignIsFromTemplate,
  campaignLaunchComplete,
  campaignLaunchInProgress,
  campaignObjectivesAtom,
  campaignSelectedPageAtom,
  campaignSortAtom,
  campaignsFilterAtom,
  campaignsForTableSelector,
  campaignsSearchAtom,
  currentCampaignsPage,
  editingCampaignAtom,
  filteredCampaignsSelector,
} from '../../../store/state/campaignState';
import { CAMPAIGN_IN_LOCAL_STORAGE } from '../containers/CrusadeCampaignCardsContainer';
import { useCrusadeJourneysAndCampaigns } from '../hooks/useCrusadeJourneysAndCampaigns';
import { defaultCampaign } from '../util/campaignFormikHelpers';
import { formatDateForTable } from '../util/crusadeHelpers';

const CampaignTable: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();

  const [campaignsFilter, setCampaignsFilter] = useRecoilState(
    campaignsFilterAtom,
  );
  const [campaignsSearchFilter, setCampaignsSearchFilter] = useRecoilState(
    campaignsSearchAtom,
  );
  const { loading, error, runFetch } = useCrusadeJourneysAndCampaigns();
  const campaignsInStore = useRecoilValue(campaignsForTableSelector);
  const filteredCampaigns = useRecoilValue(filteredCampaignsSelector);
  const setEditingCampaign = useSetRecoilState(editingCampaignAtom);
  const partnerId = useRecoilValue(partnerIdAtom);
  const allObjectives = useRecoilValue(campaignObjectivesAtom);

  const { crusadeJourneyService } = new DependencyContainer();
  const [totalItems, setTotalItems] = useState(campaignsInStore?.length);
  const launchInProgress = useRecoilValue(campaignLaunchInProgress);
  const [launchComplete, setLaunchComplete] = useRecoilState(
    campaignLaunchComplete,
  );

  const { showModal } = useGlobalModalContext();

  const setCampaignSort = useSetRecoilState(campaignSortAtom);
  const [selectedPage, setSelectedPage] = useRecoilState(
    campaignSelectedPageAtom,
  );
  const setCampaignIsFromTemplate = useSetRecoilState(campaignIsFromTemplate);
  // call this hook to set the Company name atom
  useCampaignEmailConfiguration();
  const companyNameInStore = useRecoilValue(brandingCompanyNameAtom);

  useEffect(() => {
    setEditingCampaign(defaultCampaign(companyNameInStore));
  }, []);

  useEffect(() => {
    localStorage.removeItem(CAMPAIGN_IN_LOCAL_STORAGE);
    setCampaignIsFromTemplate(false);
  }, []);

  useEffect(() => {
    if (launchComplete) {
      runFetch();
      setLaunchComplete(false);
    }
  }, [launchComplete]);

  useEffect(() => {
    if (filteredCampaigns.length !== totalItems) {
      setSelectedPage(0);
      setTotalItems(filteredCampaigns.length);
    }
  }, [filteredCampaigns]);

  const handleEditCampaign = (campaign: CrusadeCampaign) => {
    setEditingCampaign(campaign);
    redirectToRoute(Routes.EditCampaign, { campaignId: campaign.campaignId });
  };

  const handleDuplicateCampiagn = async (campaign: CrusadeCampaign) => {
    try {
      await crusadeJourneyService.duplicateCrusadeCampaign(
        partnerId,
        campaign.campaignId,
      );

      notify({
        title: t('success'),
        body: t('crusade.campaigns.duplicateCampaignSuccess'),
        type: 'success',
      });
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
    runFetch();
  };

  const handleDeleteCampaign = async (campaign: CrusadeCampaign) => {
    showModal(
      MODAL_TYPES.DELETE_MODAL,
      {
        deletingItem: 'campaign',
        isOpen: true,
        onConfirm: async () => {
          try {
            await crusadeJourneyService.deleteCrusadeCampaign(
              partnerId,
              campaign.campaignId,
            );

            notify({
              title: t('success'),
              body: t('crusade.campaigns.campaignDeleted'),
              type: 'success',
            });
          } catch (error) {
            notify({
              title: t('error'),
              body: t('somethingWentWrong'),
              type: 'error',
            });
          }
          runFetch();
        },
      },
      MODAL_TYPES.DELETE_MODAL,
    );
  };

  const handleFilter = (filter: string) => {
    campaignsFilter === filter
      ? setCampaignsFilter('')
      : setCampaignsFilter(filter);
  };

  const allCampaigns: Pick<
    CrusadeCampaign,
    'campaignName' | 'status' | 'journeyName' | 'lastEdited'
  >[] = useRecoilValue(currentCampaignsPage);

  const tableHeaderRow: Record<string, unknown>[] = [
    {
      name: t('crusade.campaigns.table.name'),
      fieldName: 'campaignName',
      sortable: true,
      render: (row: CrusadeCampaign) => {
        return (
          <p className="CrusadeCampaignsContainer__table-campaignName">
            {row.campaignName}
          </p>
        );
      },
    },
    {
      name: t('crusade.campaigns.table.objective'),
      fieldName: 'objective',
      sortable: false,
      render: (row: CrusadeCampaign) => (
        <p className="CrusadeCampaignsContainer__table-objective">
          {row.objectives && Boolean(row.objectives.length)
            ? `${row.objectives[0]}${
                row.objectives.length > 1
                  ? ` (+${row.objectives.length - 1})`
                  : ''
              }`
            : t('none')}
        </p>
      ),
    },
    {
      name: t('crusade.campaigns.table.journey'),
      fieldName: 'journey',
      sortable: true,
      render: (row: CrusadeCampaign) => row.journeyName,
    },
    {
      name: t('crusade.campaigns.table.lastEdited'),
      fieldName: 'lastEdited',
      sortable: true,
      render: (row: CrusadeCampaign) => (
        <div className="CrusadeCampaignsContainer__table-lastEditedCol">
          {row.updatedBy ? (
            <Badge
              classes={(current) => ({
                ...current,
                root: `${current.root} CrusadeCampaignsContainer__table-lastEditedCol-badge`,
              })}
              badgeContent={row.updatedBy}
              size="standard"
            />
          ) : (
            <div className="CrusadeCampaignsContainer__table-lastEditedCol-emptyBadge" />
          )}
          {formatDateForTable(row.lastEdited)}
        </div>
      ),
    },
    {
      name: '',
      fieldName: 'actions',
      render: (row: CrusadeCampaign) => (
        <Dropdown
          listPosition="right"
          closeOnItemClick
          openInPortal
          button={
            <IconButton>
              <Icons.DotsVerticalIcon />
            </IconButton>
          }
        >
          <DropdownItem onClick={() => handleEditCampaign(row)}>
            <FormattedMessage id="crusade.campaigns.table.actions.edit" />
          </DropdownItem>
          <DropdownItem onClick={() => handleDuplicateCampiagn(row)}>
            <FormattedMessage id="crusade.campaigns.table.actions.duplicate" />
          </DropdownItem>
          <DropdownItem onClick={() => handleDeleteCampaign(row)}>
            <FormattedMessage id="crusade.campaigns.table.actions.delete" />
          </DropdownItem>
        </Dropdown>
      ),
    },
  ];

  const onInput = (query: string) => {
    const value = query === undefined ? '' : query;
    setCampaignsSearchFilter(value);
  };

  const handleNewCampaign = () => {
    setEditingCampaign(undefined);
    showModal(
      MODAL_TYPES.CREATE_CAMPAIGN_MODAL,
      {},
      MODAL_TYPES.CREATE_CAMPAIGN_MODAL,
    );
  };

  const renderFilterLabel = () => {
    const filter = campaignsFilter;
    let label: string;

    if (
      [
        t('crusade.campaigns.channel.email'),
        t('crusade.campaigns.channel.pushNotification'),
      ].includes(filter)
    ) {
      label = filter;
    } else if (
      [
        CampaignStatus.Active,
        CampaignStatus.Paused,
        CampaignStatus.Draft,
        CampaignStatus.Completed,
      ].includes(campaignsFilter as CampaignStatus)
    ) {
      label = t(`${mapStatusToMessageId[campaignsFilter as CampaignStatus]}`);
    } else if (
      allObjectives.find((objective) => objective.content === filter)
    ) {
      label = filter;
    } else {
      label = t('crusade.campaigns.filter');
    }

    return label;
  };

  return (
    <GridItem colSpan="12" tabletColSpan="6">
      <div className="CrusadeCampaignsContainer__options">
        <Dropdown
          classes={(current) => ({
            ...current,
            root: `${current.root} CrusadeCampaignsContainer__filter-dropdown`,
          })}
          openInPortal
          listPosition="left"
          closeOnItemClick={true}
          label={renderFilterLabel()}
          iconLeft={<Icons.FilterIcon />}
          icon={<></>}
        >
          <DropdownSectionItem
            label={t('crusade.campaigns.channel.title')}
          ></DropdownSectionItem>
          <DropdownSelectableItem
            onClick={() => handleFilter(t('crusade.campaigns.channel.email'))}
            selected={campaignsFilter === t('crusade.campaigns.channel.email')}
          >
            {t('crusade.campaigns.channel.email')}
          </DropdownSelectableItem>
          <DropdownSelectableItem
            onClick={() =>
              handleFilter(t('crusade.campaigns.channel.pushNotification'))
            }
            selected={
              campaignsFilter ===
              t('crusade.campaigns.channel.pushNotification')
            }
          >
            {t('crusade.campaigns.channel.pushNotification')}
          </DropdownSelectableItem>
          <DropdownSectionItem
            label={t('crusade.campaigns.status.title')}
          ></DropdownSectionItem>
          <DropdownSelectableItem
            onClick={() => handleFilter(CampaignStatus.Draft)}
            selected={campaignsFilter === CampaignStatus.Draft}
          >
            {CampaignStatus.Draft}
          </DropdownSelectableItem>
          <DropdownSelectableItem
            onClick={() => handleFilter(CampaignStatus.Active)}
            selected={campaignsFilter === CampaignStatus.Active}
          >
            {CampaignStatus.Active}
          </DropdownSelectableItem>
          <DropdownSelectableItem
            onClick={() => handleFilter(CampaignStatus.Paused)}
            selected={campaignsFilter === CampaignStatus.Paused}
          >
            {CampaignStatus.Paused}
          </DropdownSelectableItem>
          <DropdownSelectableItem
            onClick={() =>
              handleFilter(CampaignStatus.Completed as CampaignStatus)
            }
            selected={
              campaignsFilter === (CampaignStatus.Completed as CampaignStatus)
            }
          >
            {t(
              `${
                mapStatusToMessageId[CampaignStatus.Completed as CampaignStatus]
              }`,
            )}
          </DropdownSelectableItem>
          {Boolean(allObjectives.length) && (
            <DropdownSectionItem
              label={t('crusade.campaigns.objective.title')}
            />
          )}
          {allObjectives.map((objective) => (
            <DropdownSelectableItem
              key={`objective-filter-${objective.id}`}
              onClick={() => handleFilter(objective.content)}
              selected={campaignsFilter === objective.content}
            >
              {objective.content}
            </DropdownSelectableItem>
          ))}
        </Dropdown>
        <div className="CrusadeCampaignsContainer__search">
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <SearchInputField
              classes={(current) => ({
                ...current,
                root: `${current.root} CrusadeCampaignsContainer__search-input`,
              })}
              name="search"
              placeholder={t('crusade.campaigns.searchPlaceholder')}
              value={campaignsSearchFilter}
              onInput={(e) => onInput(e.currentTarget.value)}
              rounded
            />
          </form>
        </div>
        <Button
          classes={(current) => ({
            ...current,
            container: `${current.container} CrusadeCampaignsContainer__addBtn-container`,
          })}
          styleVariant="superprimary"
          onClick={handleNewCampaign}
        >
          {t('crusade.campaigns.createCampaign')}
        </Button>
      </div>
      <PendingContent
        loading={loading || launchInProgress}
        loader={Spinner}
        isError={Boolean(error)}
      >
        <Table
          // NEW RELIC MONITORING PLEASE DO NOT DELETE THIS ID
          testId="Nurture__table"
          headerRow={tableHeaderRow}
          dataRows={([...allCampaigns] || []) as any}
          truncateCellContent={false}
          classes={(current) => ({
            ...current,
            root: `${current.root} CrusadeCampaignsContainer__table`,
            heading: `${current.heading} CrusadeCampaignsContainer__table-heading`,
            cell: `${current.cell} CrusadeCampaignsContainer__table-cell`,
          })}
          noResultsMessage={t('noResults')}
          onSortChange={setCampaignSort}
          externalSort
        />
        <Pagination
          classes={(current) => ({
            ...current,
            root: `${current.root} CrusadeCampaignsContainer__pagination`,
          })}
          totalPageCount={Math.ceil(totalItems / DEFAULT_PAGE_SIZE) || 1}
          onPageSelect={setSelectedPage}
          expandDirection="top"
          currentPage={selectedPage}
        />
      </PendingContent>
    </GridItem>
  );
};

export default CampaignTable;
