import { Button, CollapseCard, Heading, Icons, InputField } from 'plume-ui';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { replyToAddressAtom } from 'store/state/configurationState';
import FormattedMessage from 'utils/components/FormattedMessage';

const ReplyToAddressCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [replyToAddressList, setReplyToAddressList] = useRecoilState(
    replyToAddressAtom,
  );

  const editReplyToAddress = (addressIdx: number, value: string) => {
    const currentList = [...replyToAddressList];

    currentList.splice(addressIdx, 1, {
      fromDisplayName: value === undefined ? null : value.trim(),
      isDefault: true,
    });

    setReplyToAddressList(currentList);
  };

  const entryFieldErrorMessage = (inputString: string | null) => {
    return !inputString
      ? [
          {
            status: 'error' as InputMessageStatus,
            message: t('fieldRequired'),
          },
        ]
      : [{}];
  };

  return (
    <div className="ReplyToAddressCard__cardMain">
      <CollapseCard
        classes={(current) => ({
          ...current,
          innerContent: `${current.innerContent} ReplyToAddressCard`,
        })}
        open={false}
        title={
          <Heading size="m">
            <FormattedMessage id="settings.campaignSetting.ReplyToAddressCard.title" />
          </Heading>
        }
        subtitle={t('settings.campaignSetting.ReplyToAddressCard.subtitle')}
      >
        <div className="ReplyToAddressTable">
          {replyToAddressList.map((address, idx) => {
            return (
              <div className="ReplyToAddressTableRow" key={`${address}-${idx}`}>
                <div className="ReplyToAddressTableRow-inputWrapper">
                  <InputField
                    value={address.fromDisplayName || ''}
                    onInput={(e) =>
                      editReplyToAddress(idx, e.currentTarget.value)
                    }
                    messages={entryFieldErrorMessage(address.fromDisplayName)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </CollapseCard>
    </div>
  );
};

export default ReplyToAddressCard;
