import { Grid, GridItem, Icons, Tooltip } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { campaignTemplatesAtom } from 'store/state/campaignState';
import { generateKey } from 'utils/helpers';
import { makeCamelCase } from '../../util/crusadeHelpers';
import TemplatePreviewCard from './TemplatePreviewCard';

const TemplateList: FunctionComponent = () => {
  const { t } = useTranslation();
  const templateList = useRecoilValue(campaignTemplatesAtom);

  return (
    <>
      {templateList.map((journey) => (
        <div className="TemplateList__journeyGroup" key={generateKey()}>
          <div className="TemplateList__journeyNameContainer">
            <div className="TemplateList__journeyName">
              {journey.journeyName}
            </div>
            <Tooltip
              label={journey.description}
              overrideMaxCharacters
              position="below"
            >
              <Icons.InfoIcon width={20} color="#c6c6c6" />
            </Tooltip>
          </div>
          <Grid>
            {journey.templates.map((template) => (
              <GridItem colSpan="4" key={generateKey()}>
                <TemplatePreviewCard template={template} />
              </GridItem>
            ))}
          </Grid>
        </div>
      ))}
    </>
  );
};

export default TemplateList;
