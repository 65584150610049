import { FunctionComponent } from 'enzyme';
import { Channel } from 'features/configuration/types';
import { Chip } from 'plume-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from 'utils/helpers';

export type FieldsToExportProps = {
  channel: Channel;
};

/**
 * In CreateSyncModal, map over the selected channels and render this component
 * for each selected channel
 *
 * Depending on how this is returned by the API, we'll have to clean up the labels.
 *  (i.e. we might get 'zipCode' and that should be rendered 'Zip code')
 */

const FieldsToExport: FunctionComponent<FieldsToExportProps> = ({
  channel,
}) => {
  const { t } = useTranslation();

  return (
    <div className="CreateSyncModal__exportFieldsGroup" key={generateKey()}>
      <div>{`${t('syncModal.fieldsToSync')}`}</div>
      <div className="CreateSyncModal__exportFieldChipContainer">
        {channel.fieldsToExport?.map((field: string) => {
          return (
            <Chip
              key={generateKey()}
              classes={(current) => ({
                ...current,
                root: `${current.root} CreateSyncModal__exportFieldChip`,
              })}
              color="goodX"
              removable={false}
            >
              {field}
            </Chip>
          );
        })}
      </div>
    </div>
  );
};

export default FieldsToExport;
