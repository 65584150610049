import React, { FunctionComponent } from 'react';
import { Icons, Modal, ModalHeader, ModalBody } from 'plume-ui';
import SquareButtonWithIcon from 'components/SquareButtonWithIcon/SquareButtonWithIcon';
import { ReactComponent as PushNotificationIcon } from '../../../../assets/svg/pushNotificationIcon.svg';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { Routes } from 'config';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { campaignListFromTemplateModal } from 'store/state/campaignState';

const emailIcon = <Icons.EmailIcon />;
const pushIcon = <PushNotificationIcon />;

export const TemplateModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const redirectToRoute = useRedirectToRoute();
  const setCampaignListFromTemplateModal = useSetRecoilState(
    campaignListFromTemplateModal,
  );

  const handleEmailBtnClick = () => {
    setCampaignListFromTemplateModal(true);
    redirectToRoute(Routes.FromTemplateCampaign);
  };

  const handlePushBtnClick = () => {
    redirectToRoute(Routes.CreateCampaign);
  };

  return (
    <Modal
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} TemplateModal`,
      })}
      isOpen={true}
      onRequestClose={() => hideModal(MODAL_TYPES.CREATE_CAMPAIGN_MODAL)}
    >
      <ModalHeader title={t('crusade.campaigns.createCampaign')} />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} TemplateModal__modalBody`,
        })}
      >
        <div className="TemplateModal__btnContainer">
          <SquareButtonWithIcon
            icon={emailIcon}
            buttonType="email"
            onClick={handleEmailBtnClick}
          />
          <SquareButtonWithIcon
            icon={pushIcon}
            buttonType="pushNotification"
            onClick={handlePushBtnClick}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
