import { DependencyContainer } from '../../../DependencyContainer';

import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../HttpClient';
import { CrusadeApiUrls } from '../../../urls';
import {
  CampaignPushNotificationCardValues,
  CampaignStatus,
  CreateUpdateCampaignRequest,
  CrusadeCampaignInfoResponse,
  CrusadeJourneysAndCampaignsResponse,
  CrusadeJourneysResponse,
  CrusadeValidateEmailResponse,
  CrusadeVersionResponse,
  IPWarmupParams,
} from '../types';

export default class CrusadeJourneyClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getCrusadeJourneys(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<CrusadeJourneysResponse>>(
      CrusadeApiUrls.getCrusadeJourneys(partnerId),
    );
  }

  async getCrusadeJourneysAndCampaigns(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<CrusadeJourneysAndCampaignsResponse>>(
      CrusadeApiUrls.getCrusadeJourneysAndCampaigns(partnerId),
    );
  }

  async getCrusadeCampaignEmailData(
    partnerId: string,
    campaignId: string,
  ): Promise<any> {
    return this.get<AxiosResponse<CrusadeCampaignInfoResponse>>(
      CrusadeApiUrls.getCrusadeCampaignEmailData(partnerId, campaignId),
    );
  }

  async deleteCrusadeCampaign(
    partnerId: string,
    campaignId: string,
  ): Promise<any> {
    return this.delete<AxiosResponse<CrusadeCampaignInfoResponse>>(
      CrusadeApiUrls.getPatchDeleteCrusadeCampaignId(partnerId, campaignId),
    );
  }

  async duplicateCrusadeCampaign(
    partnerId: string,
    campaignId: string,
  ): Promise<any> {
    return this.put<AxiosResponse<CrusadeCampaignInfoResponse>>(
      CrusadeApiUrls.getDuplicateCrusadeCampaign(partnerId, campaignId),
      {},
    );
  }

  async sendPreviewTestMessage(
    partnerId: string,
    campaignId: string,
    emails: string[],
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      CrusadeApiUrls.sendPreviewTestMessage(partnerId, campaignId),
      { testRecipients: emails },
    );
  }

  async sendPreviewTestNotification(
    partnerId: string,
    emails: string[],
    message: CampaignPushNotificationCardValues,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      CrusadeApiUrls.sendPreviewTestNotification(partnerId),
      {
        testRecipients: emails,
        message,
      },
    );
  }

  async createCrusadePushCampaign(
    partnerId: string,
    newPushCampaign: CreateUpdateCampaignRequest,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      CrusadeApiUrls.createPushNotification(partnerId),
      newPushCampaign,
    );
  }

  async editCrusadeCampaign(
    partnerId: string,
    campaignId: string,
    updatedCampaign: CreateUpdateCampaignRequest,
  ): Promise<AxiosResponse<any>> {
    return await this.patch<AxiosResponse<any>>(
      CrusadeApiUrls.editPushNotification(partnerId, campaignId),
      updatedCampaign,
    );
  }

  async launchCrusadePushCampaign(
    partnerId: string,
    campaignId: string,
    status: CampaignStatus,
  ): Promise<AxiosResponse<any>> {
    const res = await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.launchPushNotification(partnerId, campaignId, status),
    );

    return res;
  }

  async getIpWarmupPreview(
    partnerId: string,
    warmupParams: IPWarmupParams,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      CrusadeApiUrls.getIpWarmupPreview(partnerId),
      warmupParams,
    );
  }

  async getApiVersion(): Promise<AxiosResponse<CrusadeVersionResponse>> {
    return await this.get<AxiosResponse<CrusadeVersionResponse>>(
      CrusadeApiUrls.getApiVersionUrl(),
    );
  }

  async validateCampaignTestEmail(
    partnerId: string,
    testRecipients: {},
  ): Promise<AxiosResponse<CrusadeValidateEmailResponse>> {
    return await this.post<AxiosResponse<CrusadeValidateEmailResponse>>(
      CrusadeApiUrls.validateCampaignTestEmail(partnerId),
      testRecipients,
    );
  }
}
