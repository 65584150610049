import { t } from 'i18next';
import { Dropdown, DropdownSelectableItem, Heading } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { generateKey } from 'utils/helpers';
import { npsTimeRangeOptions, timeRangeOptions } from '../ChartOptions';
import { OverviewTimeRange } from '../types';

export type TimeRangeDropdownProps = {
  timeRange: OverviewTimeRange;
  nps: boolean;
  setTimeRange: (timeRange: OverviewTimeRange) => void;
};

const TimeRangeDropdown: FunctionComponent<TimeRangeDropdownProps> = ({
  timeRange,
  nps,
  setTimeRange,
}) => {
  const timeRangeDropdownOptions = nps ? npsTimeRangeOptions : timeRangeOptions;

  return (
    <div className="OverviewContent__timeRange">
      <Heading
        size="m"
        classes={(current) => ({
          ...current,
          root: `${current.root} OverviewContent__timeRange-label`,
        })}
      >
        {t('overview.timeRangeLabel')}
      </Heading>
      <Dropdown
        expandDirection="auto"
        label={timeRange?.label || timeRangeDropdownOptions[0].label}
        classes={(current) => ({
          ...current,
          root: `${current.root} OverviewContent__timeRange-dropdown`,
        })}
        closeOnItemClick
      >
        {timeRangeDropdownOptions.map((timeRange) => {
          return (
            <DropdownSelectableItem
              key={generateKey()}
              onClick={() => setTimeRange(timeRange)}
            >
              {timeRange.label}
            </DropdownSelectableItem>
          );
        })}
      </Dropdown>
    </div>
  );
};

export default TimeRangeDropdown;
