import { DependencyContainer } from 'DependencyContainer';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Button,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
} from 'plume-ui';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { channelsAtom } from 'store/state/channelState';
import { Channel, EditChannelDescriptionParameter } from '../types';

export type DescriptionModalProps = {
  channel: Channel | undefined;
};

export const DescriptionModal: FunctionComponent<DescriptionModalProps> = ({
  channel,
}) => {
  const { t } = useTranslation();
  const [channelDescription, setChannelDescription] = useState(
    channel?.channelDescription,
  );
  const setChannels = useSetRecoilState(channelsAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const { channelsService } = new DependencyContainer();
  const { hideModal } = useGlobalModalContext();

  const resetChannelData = async () => {
    const response = await channelsService.getChannels(partnerId);
    setChannels(response.data);
  };

  const editChannelDescription = async (
    parameter: EditChannelDescriptionParameter,
    channelId: string,
  ) => {
    try {
      const response = await channelsService.editChannelDescription(
        partnerId,
        channelId,
        parameter,
      );

      if (response.data) {
        hideModal(MODAL_TYPES.DESCRIPTION_MODAL);
        resetChannelData();
      }
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };

  const handleSave = () => {
    const parameter = {
      channelDescription:
        channelDescription === undefined ? '' : channelDescription,
    };
    editChannelDescription(parameter, `${channel?.channelId}`);
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => hideModal(MODAL_TYPES.DESCRIPTION_MODAL)}
      classes={(current: any) => ({
        ...current,
        root: `${current.root} DescriptionModal`,
      })}
    >
      <ModalHeader
        title={
          channel?.channelDescription
            ? t('settings.channel.editDescription')
            : t('settings.channel.addDescription')
        }
      />
      <ModalBody>
        <InputField
          classes={(current) => ({
            ...current,
            root: `${current.root} DescriptionModal__inputField`,
          })}
          label={t('enterDescription')}
          value={channel?.channelDescription}
          onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
            setChannelDescription(e.target.value)
          }
        />
      </ModalBody>
      <ModalFooter>
        <>
          <Button
            styleVariant="tertiary-grey"
            onClick={() => hideModal(MODAL_TYPES.DESCRIPTION_MODAL)}
          >
            {t('cancel')}
          </Button>
          <Button styleVariant="superprimary" onClick={handleSave}>
            {t('save')}
          </Button>
        </>
      </ModalFooter>
    </Modal>
  );
};
