import { FunctionComponent } from 'enzyme';
import { SelectValue } from 'features/generate/audience/consts';
import { useMetadataAllowedValues } from 'features/generate/audience/hooks/useMetadataAllowedValues';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  AutoComplete,
  Button,
  Checkbox,
  Divider,
  Icons,
  Modal,
  ModalFooter,
  ModalHeader,
  PendingContent,
  Space,
  Spinner,
} from 'plume-ui';
import { AutoCompleteResultsItem } from 'plume-ui/dist/components/AutoComplete/AutoCompleteResults';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useRecoilState, useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  getMetadataByColumnNameSelector,
  metadataAllowedValuesAtom,
} from 'store/state/metadataTreeState';
import FormattedMessage from '../../utils/components/FormattedMessage';

export type PatternStringModalProps = {
  columnName: string;
  patternSelection: any;
  onSave: (values: string[]) => void;
};

export const PatternStringModal: FunctionComponent<PatternStringModalProps> = (
  props: PatternStringModalProps,
) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const partnerId = useRecoilValue(partnerIdAtom);
  const { loading, error, runFetch } = useMetadataAllowedValues(
    partnerId,
    props.columnName,
  );
  const [metaDataAllowedValues, setMetaDataAllowedValues] = useRecoilState(
    metadataAllowedValuesAtom,
  );
  const metaData = useRecoilValue(
    getMetadataByColumnNameSelector(props.columnName),
  );
  const [fetching, setFetching] = useState(false);
  const previousData = useRef<string[][]>([]);
  const previousQuery = useRef('');
  const [activeBulkInput, setActiveBulkInput] = useState(false);
  const [bulkItems, setBulkItems] = useState('');

  const scrollContainer = {
    maxHeight: '200px',
    marginTop: '24px',
    marginBottom: '24px',
  };

  const [selectedAllowedValues, setSelectedAllowedValues] = React.useState<
    string[]
  >([]);

  const handleBulkInput = () => {
    setActiveBulkInput(!activeBulkInput);
  };

  const handleCloseModal = () => {
    hideModal(MODAL_TYPES.PATTERN_STRING_MODAL);
  };

  const handleSave = () => {
    const pattern = {
      ...props.patternSelection,
      columnValue: selectedAllowedValues,
      operator: 'in',
      patternType: 'in',
      count: selectedAllowedValues.length,
    };
    props.onSave(pattern);
    handleCloseModal();
  };

  const preloadMetaDataAllowedValues = async () => {
    setFetching(true);
    await runFetch();
    setFetching(false);
  };

  useEffect(() => {
    if (props.patternSelection.columnValue !== SelectValue) {
      setSelectedAllowedValues([...props.patternSelection.columnValue]);
    }
    preloadMetaDataAllowedValues();

    return () => {
      setMetaDataAllowedValues([]);
    };
  }, [props.patternSelection]);

  const handleSelectedValues = (selectedValue: string) => {
    const isPresent = selectedAllowedValues?.find(
      (selectedItem) => selectedItem === selectedValue,
    );
    if (isPresent === undefined) {
      setSelectedAllowedValues([...selectedAllowedValues, selectedValue]);
    }
  };

  const handleRemoveValue = (selectedValue: string) => {
    setSelectedAllowedValues(
      selectedAllowedValues.filter((value) => value !== selectedValue),
    );
  };

  const getData = async (query: string): Promise<AutoCompleteResultsItem[]> => {
    if (query.length === 0) {
      previousData.current = [metaDataAllowedValues];
      previousQuery.current = '';
      return [];
    }
    query = query.toLowerCase();
    if (previousData.current.length === 0) {
      previousData.current.push(metaDataAllowedValues);
    }

    const allowedValues =
      previousData.current[previousData.current.length - 1] || [];
    const data =
      allowedValues.filter((item: any) => {
        if (Array.isArray(item)) {
          return item.filter((arrayItem) =>
            arrayItem.toLowerCase().includes(query),
          ).length;
        } else {
          return item.toLowerCase().startsWith(query);
        }
      }) || [];

    if (query.length > previousQuery.current.length) {
      previousData.current.push(data);
    } else {
      previousData.current.pop();
    }
    previousQuery.current = query;
    return (
      data.map((item: any) => ({ id: item, label: item.toString() })) || []
    );
  };

  const addBulkItems = () => {
    if (bulkItems !== '') {
      const separatedBulkItems = bulkItems.split(/\r?\n/);
      // ------ set value without duplication ------
      setSelectedAllowedValues([
        ...new Set([...selectedAllowedValues, ...separatedBulkItems]),
      ]);
      setBulkItems('');
    }
  };

  const handleClear = () => {
    setSelectedAllowedValues([]);
  };

  return (
    <Modal
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} PatternStringModal`,
      })}
      isOpen
      onRequestClose={() => handleCloseModal()}
    >
      <ModalHeader
        title={t('patternStringModal.title', {
          columnName: metaData?.attribute,
          interpolation: { escapeValue: false },
        })}
        classes={(current) => ({
          ...current,
          root: `${current.root} ZipModal__header`,
        })}
      />
      <PendingContent loading={fetching} loader={Spinner} hideContent>
        <div className="PatternStringModal__modalBody">
          <div className="patternStringModal__bodycontent">
            <div
              className={
                selectedAllowedValues.length === 0
                  ? 'PatternStringModal__showcontent'
                  : 'PatternStringModal__content'
              }
            >
              <div className="PatternStringModal__selected-title-values-wrapper">
                <div className="PatternStringModal__selected-title-values-Selected">
                  {selectedAllowedValues.length + ' selected'}
                </div>
                <div
                  className="PatternStringModal__selected-title-values-clear"
                  onClick={() => handleClear()}
                >
                  <div className="">{'Clear all'}</div>
                </div>
              </div>
              <PerfectScrollbar style={scrollContainer}>
                {selectedAllowedValues &&
                  selectedAllowedValues.map((value: string, index) => (
                    <>
                      <div
                        className="PatternStringModal__selected-values-wrapper"
                        key={index}
                      >
                        <div className="PatternStringModal__selected-value">
                          {value.toString()}
                        </div>
                        <div className="PatternStringModal__selected-value-remove">
                          <Icons.CrossIcon
                            height={24}
                            width={24}
                            onClick={() => handleRemoveValue(value)}
                          />
                        </div>
                      </div>
                    </>
                  ))}
              </PerfectScrollbar>
              <Divider />
              <Space size="m" />
            </div>

            {activeBulkInput ? (
              <div className="PatternStringModal__textareaWrapper">
                <textarea
                  name="bulkItems"
                  value={bulkItems}
                  onChange={(e) => setBulkItems(e.target.value)}
                  placeholder={t('patternStringModal.bulkPlaceholder')}
                />
                <Icons.RightArrowIcon
                  className="PatternStringModal__textareaBtn"
                  onClick={() => addBulkItems()}
                />
              </div>
            ) : (
              <AutoComplete
                onSearch={getData}
                onItemClick={(item) => handleSelectedValues(item.id)}
                configuration={{
                  maxHeight: 150,
                  minCharactersToSearch: 0,
                  clearQuery: true,
                  placeholderText: t('patternStringModal.placeholder', {
                    columnName: metaData?.attribute,
                    interpolation: { escapeValue: false },
                  }),
                  icon: <Icons.SearchIcon />,
                }}
              />
            )}
            <div className="PatternStringModal__checkboxWrapper">
              <Space size="m" />
              <Checkbox
                name="test"
                label={t('patternStringModal.bulkCheckboxLabel')}
                onChange={handleBulkInput}
              />
            </div>
          </div>
        </div>
      </PendingContent>
      <ModalFooter
        classes={(current) => ({
          ...current,
          root: `${current.root} PatternStringModal__modalFooter`,
        })}
      >
        <Button onClick={() => handleCloseModal()} styleVariant="tertiary-grey">
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          onClick={() => handleSave()}
          styleVariant="superprimary"
          disabled={!selectedAllowedValues.length}
        >
          <FormattedMessage id="save" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
