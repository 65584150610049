import React, { FunctionComponent, useEffect } from 'react';
import persistentState from './persistent-state';
import { useSetRecoilState } from 'recoil';

const PersistentStateRoot: FunctionComponent = (props) => {
  const setIntegrationStrategy = useSetRecoilState(
    persistentState.getAtom('integrationStrategy'),
  );

  useEffect(() => {
    const unsubscribe = persistentState.subscribe((key: any, value: any) => {
      switch (key) {
        case 'integrationStrategy':
          setIntegrationStrategy(value);
          break;
      }
    });
    return unsubscribe;
  }, []);

  return <>{props.children}</>;
};

export default PersistentStateRoot;
