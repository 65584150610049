import { DependencyContainer } from 'DependencyContainer';
import { useFormikContext } from 'formik';
import moment from 'moment';
import {
  Divider,
  Dropdown,
  DropdownSelectableItem,
  IconButton,
  Icons,
  DateInputField,
  StaticCard,
} from 'plume-ui';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { editingCampaignAtom, formUpdated } from 'store/state/campaignState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { CampaignDeliveryCardDropdown } from 'utils/fixtures/CampaignDeliveryCard.fixture';
import {
  CampaignDeliveryCardValues,
  CampaignDtoType,
  CampaignFormikFields,
  CampaignGenericFormField,
  CampaignType,
  DeliveryIPWarmupCadenceEnum,
  DeliveryRecurrenceEnum,
  EndRecurrenceConditionEnum,
  EndRecurrenceConditionTypes,
  IPWarmupResponse,
} from '../types';
import { defaultDeliverySettings } from '../util/campaignFormikHelpers';
import { startInputIsAfterCurrentTime } from '../util/crusadeHelpers';
import { SendCadenceEnum } from './CampaignEnum';

const MIN_DATE = moment().subtract(1, 'months');

const CampaignDeliveryCard: FunctionComponent<CampaignGenericFormField<
  CampaignDeliveryCardValues
>> = ({ field, form }) => {
  const { values } = useFormikContext<CampaignDtoType>();
  const { t, ready } = useTranslation();
  const [inEditMode, setInEditMode] = useState(false);
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const dropdownData = CampaignDeliveryCardDropdown();
  const [isValidEndingDate, setIsValidEndingDate] = useState(true);
  const [ipWarmingDateArray, setIpWarmingDateArray] = useState<
    IPWarmupResponse[]
  >([]);
  const [warmingEndDate, setWarmingEndDate] = useState('');
  const [totalWarmingDays, setTotalWarmingDays] = useState<
    number | undefined
  >();
  const partnerId = useRecoilValue(partnerIdAtom);
  const { crusadeJourneyService } = new DependencyContainer();
  const setFormFieldsUpdated = useSetRecoilState(formUpdated);

  const startInputErrorMsg = useMemo(() => {
    if (!field.value?.delivery?.startDate || !field.value?.delivery?.startTime)
      return;

    return !startInputIsAfterCurrentTime(
      field.value?.delivery?.startDate,
      field.value?.delivery?.startTime,
    )
      ? t('startInputError')
      : '';
  }, [field.value?.delivery?.startDate, field.value?.delivery?.startTime]);

  useEffect(() => {
    setInEditMode(!editingCampaign);
  }, [editingCampaign]);

  useEffect(() => {
    if (values?.campaignAudienceCard?.audienceSegments[0]?.segmentId === '') {
      setIpWarmingDateArray([]);
      setTotalWarmingDays(undefined);
      setWarmingEndDate('');
    }
  }, [values?.campaignAudienceCard.audienceSegments]);

  useEffect(() => {
    const fetchIpWarmupPreview = async () => {
      try {
        const preview = await crusadeJourneyService.getIpWarmupPreview(
          partnerId,
          {
            warmup: field.value.warmup,
            delivery: {
              startDate: field.value?.delivery?.startDate,
              startTime: field.value?.delivery?.startTime,
              endDate: field.value?.delivery?.endDate,
              endTime: field.value?.delivery?.endTime,
            },
            campaignType: values?.campaignInformationCard.campaignType,
            audienceSegments: values?.campaignAudienceCard.audienceSegments,
            sendToTheseUsers: values?.campaignAudienceCard.sendToTheseUsers,
          },
        );
        const result = await preview;
        setIpWarmingDateArray(result.data.warmupSchedules);
      } catch (error) {
        throw error;
      }
    };

    if (
      field.value?.warmup &&
      values?.campaignAudienceCard?.audienceSegments[0]?.segmentId !== ''
    ) {
      fetchIpWarmupPreview().catch((error) => {
        console.error(error);
      });
    }
  }, [
    field.value.warmup,
    values?.campaignAudienceCard.audienceSegments,
    values?.campaignAudienceCard.sendToTheseUsers,
  ]);

  useEffect(() => {
    if (!ipWarmingDateArray?.length) {
      setIsValidEndingDate(true);
      return;
    }
    const lastWarmingDay = ipWarmingDateArray[ipWarmingDateArray.length - 1];
    if (!lastWarmingDay.sendDay) return;
    const formattedLastDate = lastWarmingDay.sendDay?.replace(/-/g, '/');

    setTotalWarmingDays(ipWarmingDateArray.length);
    setWarmingEndDate(formattedLastDate);

    const lastDate = new Date(lastWarmingDay.sendDay);
    const endingDate = new Date(field.value?.delivery?.endDate);

    if (field.value?.delivery?.endDate !== '') {
      setIsValidEndingDate(
        endingDate.valueOf() >= lastDate.valueOf() ? true : false,
      );
    }
  }, [
    ipWarmingDateArray,
    field.value?.delivery.endDate,
    values?.campaignAudienceCard?.audienceSegments,
    field.value?.immediately,
  ]);

  const sendRecurrenceOptions = dropdownData?.find(
    (item: any) => item.title === 'crusade.campaigns.deliveryCard.sendLabel',
  );

  const startTimeOptions = dropdownData?.find(
    (item: any) =>
      item.title === 'crusade.campaigns.deliveryCard.startTimeLabel',
  );

  const endTimeOptions = dropdownData?.find(
    (item: any) => item.title === 'crusade.campaigns.deliveryCard.endTimeLabel',
  );

  const endingParameterOptions = dropdownData?.find(
    (item: any) =>
      item.title === 'crusade.campaigns.deliveryCard.endingParametersLabel',
  );

  const recurrenceOptions = dropdownData?.find(
    (item: any) =>
      item.title === 'crusade.campaigns.deliveryCard.recurrenceLabel',
  );

  const daysOptions = dropdownData?.find(
    (item: any) =>
      item.title === 'crusade.campaigns.deliveryCard.selectDaysLabel',
  );

  const beginOptions = dropdownData?.find(
    (item: any) => item.title === 'crusade.campaigns.deliveryCard.beginLabel',
  );

  const cardActions = () => {
    if (!editingCampaign) {
      return undefined;
    } else if (!inEditMode) {
      return [
        <IconButton
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__editIcon`,
          })}
          onClick={() => setInEditMode(true)}
        >
          <Icons.EditIcon />
        </IconButton>,
      ];
    }
  };

  const setSendAsSoonAs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxState = e.target.checked;
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      immediately: checkboxState ? true : false,
      warmup: false,
      delivery: defaultDeliverySettings,
    });
  };

  const setSendAtDesignatedTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxState = e.target.checked;
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      immediately: checkboxState ? false : true,
      warmup: false,
      delivery: defaultDeliverySettings,
    });
  };

  const setIP = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxState = e.target.checked;
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      warmup: checkboxState ? true : false,
      immediately: true,
      delivery: defaultDeliverySettings,
    });
  };

  const onSendRecurranceSelect = (value: string): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        recurrence: value,
      },
    });
  };

  const onStartTimeSISelect = (value: string): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        startTime: value,
      },
    });
  };

  const onEndTimeSISelect = (value: string): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        endTime: value,
      },
    });
  };

  const onEndingParameterSelect = (
    value: EndRecurrenceConditionTypes,
  ): void => {
    if (value === EndRecurrenceConditionEnum.NEVER_ENDS) {
      setFormFieldsUpdated(true);
      form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
        ...field.value,
        delivery: {
          ...field.value.delivery,
          endRecurrence: {
            ...field.value.delivery.endRecurrence,
            condition: value as EndRecurrenceConditionTypes,
            endDate: '',
          },
        },
      });
    } else {
      setFormFieldsUpdated(true);
      form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
        ...field.value,
        delivery: {
          ...field.value.delivery,
          endRecurrence: {
            ...field.value.delivery.endRecurrence,
            condition: value as EndRecurrenceConditionTypes,
          },
        },
      });
    }
  };

  const onRecurranceSelect = (value: string): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        frequency: parseInt(value),
      },
    });
  };

  const onStartDateSelect = (date: any): void => {
    const saveDate =
      date < MIN_DATE
        ? moment().format('YYYY-MM-DD')
        : moment(date).format('YYYY-MM-DD');

    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        startDate: saveDate,
      },
    });
  };

  const onIpEndDateSelect = (date: any): void => {
    const saveDate =
      date < MIN_DATE
        ? moment().format('YYYY-MM-DD')
        : moment(date).format('YYYY-MM-DD');

    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        endDate: saveDate,
      },
    });
  };

  const onEndDateSelect = (date: any): void => {
    const saveDate =
      date < MIN_DATE
        ? moment().format('YYYY-MM-DD')
        : moment(date).format('YYYY-MM-DD');
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      delivery: {
        ...field.value.delivery,
        endRecurrence: {
          condition: field.value.delivery?.endRecurrence
            ?.condition as EndRecurrenceConditionTypes,
          endDate: saveDate,
        },
      },
    });
  };

  // The value front-end should pass in the week attribute:
  // Sunday 0, Monday 1, Tuesday 2, Wednesday 3, Thursday 4, Friday 5, Saturday 6
  const onSelectDaysOfWeek = (
    e: React.ChangeEvent<HTMLInputElement>,
    daysOfWeek: number,
  ) => {
    const checkboxState = e.target.checked;

    if (field.value?.delivery?.weekly) {
      const currentWeeklyArray = [...field.value?.delivery?.weekly];

      if (checkboxState) {
        currentWeeklyArray.push(daysOfWeek);
        setFormFieldsUpdated(true);
        form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
          ...field.value,
          delivery: {
            ...field.value.delivery,
            weekly: currentWeeklyArray,
          },
        });
      } else {
        const updatedArray = currentWeeklyArray.filter(
          (item) => item !== daysOfWeek,
        );
        setFormFieldsUpdated(true);
        form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
          ...field.value,
          delivery: {
            ...field.value.delivery,
            weekly: updatedArray,
          },
        });
      }
    }
  };

  const isDayOfWeekSelected = (item: any): boolean => {
    const selectedDays = field.value?.delivery?.weekly;

    if (!selectedDays?.length) return false;

    return selectedDays.includes(item.dbValue);
  };

  const onBeginSelect = (value: string): void => {
    const immediatelyValue =
      value === DeliveryIPWarmupCadenceEnum.IMMEDIATELY ? true : false;
    let deliveryOptions = field.value.delivery;

    if (value === DeliveryIPWarmupCadenceEnum.CHOOSE_DATE) {
      deliveryOptions = {
        ...deliveryOptions,
        startDate: '',
        startTime: '',
      };
    }
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_DELIVERY_CARD, {
      ...field.value,
      immediately: immediatelyValue,
      delivery: deliveryOptions,
    });
  };

  const renderRepeatLabel = (recurrence: string, frequency: string) => {
    const getLabel = (
      recurrenceValue: string,
      frequencyValue: string,
      unit: string,
    ) => {
      const frequencyNumber = parseInt(frequencyValue);
      const recurrenceNumber = parseInt(recurrenceValue);
      const isMultiple =
        (frequencyValue && frequencyNumber > 1) || recurrenceNumber > 1;
      const value = frequencyValue || recurrenceValue;

      return isMultiple
        ? t('crusade.campaigns.deliveryCard.willRepeatEveryLabel', {
            frequency: value,
            unit,
          })
        : t(`crusade.campaigns.deliveryCard.${unit}RepeatLabel`);
    };

    switch (recurrence) {
      case SendCadenceEnum.DAILY:
        return (
          <p className="EditCard__RepeatLabel">
            {getLabel(field.value?.delivery?.recurrence, frequency, 'days')}
          </p>
        );
      case SendCadenceEnum.WEEKLY:
        return (
          <p className="EditCard__RepeatLabel">
            {getLabel(field.value?.delivery?.recurrence, frequency, 'weeks')}
          </p>
        );
      case SendCadenceEnum.MONTHLY:
        return (
          <p className="EditCard__RepeatLabel">
            {getLabel(field.value?.delivery?.recurrence, frequency, 'months')}
          </p>
        );
      default:
        return null;
    }
  };

  const convertDateForDatePicker = (
    inputDate: string | undefined,
  ): Date | undefined => {
    const dateInStore = inputDate;

    if (!dateInStore) return undefined;

    const year = parseInt(dateInStore.split('-')[0]);
    const month = parseInt(dateInStore.split('-')[1]) - 1;
    const date = parseInt(dateInStore.split('-')[2]);

    const dateAsDate = new Date(year, month, date);
    return dateAsDate;
  };

  const renderStartingDate = () => {
    return (
      <div className="EditCard__Dropdown">
        <p className="EditCard__DropdownText">
          <FormattedMessage id="crusade.campaigns.deliveryCard.startingDateLabel" />
        </p>
        <DateInputField
          value={convertDateForDatePicker(field.value?.delivery?.startDate)}
          onChange={(e: any) => onStartDateSelect(e)}
        />
        <p className="EditCard__dateErrorMsg">{startInputErrorMsg}</p>
      </div>
    );
  };

  const renderStartTime = () => {
    return (
      <div className="EditCard__Dropdown">
        <p className="EditCard__DropdownText">
          <FormattedMessage id="crusade.campaigns.deliveryCard.startTimeLabel" />
        </p>
        <Dropdown
          listPosition="left"
          openInPortal={true}
          label={
            field.value?.delivery?.startTime
              ? startTimeOptions?.items?.find(
                  (item) => item.dbValue === field.value?.delivery?.startTime,
                )?.title
              : t(`${startTimeOptions?.label}`)
          }
          closeOnItemClick
          expandDirection="auto"
        >
          {startTimeOptions?.items?.map((item, index) => (
            <DropdownSelectableItem
              key={index}
              selected={field.value?.delivery?.startTime === item.dbValue}
              onClick={() => onStartTimeSISelect(item.dbValue as string)}
            >
              {item.title}
            </DropdownSelectableItem>
          ))}
        </Dropdown>
      </div>
    );
  };

  const endDateErrorMsg = (
    endDateType: 'endDate' | 'endRecurrence',
  ): string => {
    let error = false;
    if (endDateType === 'endRecurrence') {
      if (
        field.value.delivery?.endRecurrence?.endDate &&
        Date.parse(field.value.delivery?.startDate) >=
          Date.parse(field.value.delivery?.endRecurrence?.endDate)
      ) {
        error = true;
      }
    } else {
      if (
        Date.parse(field.value.delivery?.startDate) >=
        Date.parse(field.value.delivery?.endDate)
      ) {
        error = true;
      }
    }

    return error ? t('endDateError') : '';
  };

  return (
    <div className="EditCard">
      <StaticCard
        title={<FormattedMessage id="crusade.campaigns.deliveryCard.title" />}
        actions={cardActions()}
        classes={(current) => ({
          ...current,
          title: `${current.title} EditCard__cardHeader-title`,
        })}
      >
        <div className="EditCard__wrapper">
          <p className="EditCard__label">
            {`${
              inEditMode
                ? t('crusade.campaigns.deliveryCard.chooseTiming')
                : t('crusade.campaigns.deliveryCard.timing')
            } :`}
          </p>
          {inEditMode ? (
            <div className="EditCard__DeliveryEditWrapper">
              <div className="EditCard__CheckboxContainer">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      field.value?.immediately === true &&
                      field.value.warmup === false
                    }
                    onChange={(e) => setSendAsSoonAs(e)}
                  />
                  <div className="checkmark"></div>
                  <span>
                    <FormattedMessage id="crusade.campaigns.deliveryCard.sendAsSoonLabel" />
                  </span>
                </label>
              </div>
              <div className="EditCard__CheckboxContainer">
                <label>
                  <input
                    type="checkbox"
                    checked={
                      field.value?.immediately === false &&
                      field.value?.warmup === false
                    }
                    onChange={(e) => setSendAtDesignatedTime(e)}
                  />
                  <div className="checkmark"></div>
                  <span>
                    <FormattedMessage id="crusade.campaigns.deliveryCard.sendAtTimeLabel" />
                  </span>
                </label>
                <div className="EditCard__TimeInstruction">
                  <p>
                    <FormattedMessage id="crusade.campaigns.deliveryCard.sendAtTimeLabelInstruction" />
                  </p>
                </div>
              </div>
              {values?.campaignInformationCard.campaignType ===
                CampaignType.EMAIL && (
                <div className="EditCard__CheckboxContainer">
                  <label>
                    <input
                      type="checkbox"
                      checked={field.value?.warmup}
                      onChange={(e) => setIP(e)}
                    />
                    <div className="checkmark"></div>
                    <span>
                      <FormattedMessage id="crusade.campaigns.deliveryCard.IPLabel" />
                    </span>
                  </label>
                  <div className="EditCard__TimeInstruction">
                    <p>
                      <FormattedMessage id="crusade.campaigns.deliveryCard.IPLabelInstruction" />
                    </p>
                  </div>
                </div>
              )}
              {!field.value?.immediately && !field.value?.warmup && (
                <>
                  <div className="EditCard__divider">
                    <Divider />
                  </div>
                  <div className="EditCard__DropdownContainer">
                    <div className="EditCard__Dropdown">
                      <p className="EditCard__DropdownText">
                        <FormattedMessage id="crusade.campaigns.deliveryCard.sendLabel" />
                      </p>
                      <Dropdown
                        listPosition="left"
                        openInPortal={true}
                        label={
                          field.value?.delivery?.recurrence
                            ? sendRecurrenceOptions?.items?.find(
                                (item: any) =>
                                  item.dbValue ===
                                  field.value?.delivery?.recurrence,
                              )?.title
                            : t(`${sendRecurrenceOptions?.label}`)
                        }
                        closeOnItemClick
                        expandDirection="auto"
                      >
                        {sendRecurrenceOptions?.items?.map((item, index) => (
                          <DropdownSelectableItem
                            key={index}
                            selected={
                              field.value?.delivery?.recurrence === item.title
                            }
                            onClick={() =>
                              onSendRecurranceSelect(item.dbValue as string)
                            }
                          >
                            {item.title}
                          </DropdownSelectableItem>
                        ))}
                      </Dropdown>
                    </div>
                    {renderStartTime()}
                  </div>
                  {field.value.delivery?.recurrence !== '' &&
                    field.value.delivery?.recurrence !==
                      SendCadenceEnum.ONCE && (
                      <div className="EditCard__DropdownContainer">
                        <div className="EditCard__Dropdown">
                          <p className="EditCard__DropdownText">
                            <FormattedMessage id="crusade.campaigns.deliveryCard.recurrenceLabel" />
                          </p>
                          <Dropdown
                            listPosition="left"
                            openInPortal={true}
                            label={
                              field.value?.delivery?.frequency
                                ? recurrenceOptions?.items?.find(
                                    (item: any) =>
                                      item.title ===
                                      `${field.value?.delivery?.frequency}`,
                                  )?.title
                                : t(`${recurrenceOptions?.label}`)
                            }
                            closeOnItemClick
                            expandDirection="auto"
                          >
                            {recurrenceOptions?.items?.map((item, index) => (
                              <DropdownSelectableItem
                                key={index}
                                selected={
                                  `${field.value?.delivery?.frequency}` ===
                                  item.title
                                }
                                onClick={() => onRecurranceSelect(item.title)}
                              >
                                {item.title}
                              </DropdownSelectableItem>
                            ))}
                          </Dropdown>
                        </div>
                        <div className="EditCard__Dropdown">
                          {renderRepeatLabel(
                            field.value?.delivery?.recurrence,
                            `${field.value?.delivery?.frequency}`,
                          )}
                        </div>
                      </div>
                    )}
                  {field.value.delivery?.recurrence ===
                    SendCadenceEnum.WEEKLY && (
                    <div className="EditCard__DayWrapper">
                      <p className="EditCard__DropdownText">
                        <FormattedMessage id="crusade.campaigns.deliveryCard.selectDaysLabel" />
                      </p>
                      <div className="EditCard__DayContainer">
                        {daysOptions?.items?.map((item, index) => (
                          <div
                            className="EditCard__CheckboxContainer"
                            key={index}
                          >
                            <label>
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  onSelectDaysOfWeek(e, item.dbValue as number)
                                }
                                checked={isDayOfWeekSelected(item)}
                              />
                              <div className="checkmark"></div>
                              <span className="EditCard__DaysLabel">
                                {item.title}
                              </span>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="EditCard__DropdownContainer">
                    {renderStartingDate()}
                    {field.value.delivery?.recurrence !== '' &&
                      field.value.delivery?.recurrence !==
                        SendCadenceEnum.ONCE && (
                        <div className="EditCard__Dropdown">
                          <p className="EditCard__DropdownText">
                            <FormattedMessage id="crusade.campaigns.deliveryCard.endingParametersLabel" />
                          </p>
                          <Dropdown
                            listPosition="left"
                            openInPortal={true}
                            label={
                              field.value?.delivery?.endRecurrence?.condition
                                ? endingParameterOptions?.items?.find(
                                    (item: any) =>
                                      item.dbValue ===
                                      field.value?.delivery?.endRecurrence
                                        ?.condition,
                                  )?.title
                                : t(`${endingParameterOptions?.label}`)
                            }
                            closeOnItemClick
                            expandDirection="auto"
                          >
                            {endingParameterOptions?.items?.map(
                              (item, index) => (
                                <DropdownSelectableItem
                                  key={index}
                                  selected={
                                    field.value?.delivery?.endRecurrence
                                      ?.condition === item.dbValue
                                  }
                                  onClick={() =>
                                    onEndingParameterSelect(
                                      item.dbValue as EndRecurrenceConditionTypes,
                                    )
                                  }
                                >
                                  {item.title}
                                </DropdownSelectableItem>
                              ),
                            )}
                          </Dropdown>
                        </div>
                      )}
                  </div>
                  {field.value?.delivery?.endRecurrence?.condition ===
                    EndRecurrenceConditionEnum.ON_SPECIFIC_DATE &&
                    field.value?.delivery.recurrence !==
                      DeliveryRecurrenceEnum.ONCE && (
                      <div className="EditCard__Dropdown">
                        <p className="EditCard__DropdownText">
                          <FormattedMessage id="crusade.campaigns.deliveryCard.endingDateLabel" />
                        </p>
                        <DateInputField
                          classes={(current) => ({
                            ...current,
                            root: `${current.root} EditCard__endDatePicker`,
                          })}
                          value={convertDateForDatePicker(
                            field.value?.delivery?.endRecurrence?.endDate,
                          )}
                          onChange={(e: any) => onEndDateSelect(e)}
                        />
                        <p className="EditCard__dateErrorMsg">
                          {endDateErrorMsg('endRecurrence')}
                        </p>
                      </div>
                    )}
                  <div className="EditCard__divider">
                    <Divider />
                  </div>
                  <div>
                    <p className="EditCard__TimeOutput">
                      {t('crusade.campaigns.deliveryCard.nextSendTimeLabel')}
                      {field.value?.immediately ? (
                        t('crusade.campaigns.deliveryCard.immediatelyOnLaunch')
                      ) : (
                        <>
                          {field.value?.delivery?.startDate
                            ? moment(field.value?.delivery?.startDate).format(
                                'L',
                              )
                            : 'xx/xx/xxxx'}
                          {t('crusade.campaigns.deliveryCard.atLabel')}
                          {field.value?.delivery?.startDate !== ''
                            ? startTimeOptions?.items?.find(
                                (item) =>
                                  item.dbValue ===
                                  field.value?.delivery?.startTime,
                              )?.title
                            : 'xx:xx'}
                        </>
                      )}
                    </p>
                  </div>
                </>
              )}
              {field.value?.warmup && (
                <>
                  <div className="EditCard__divider">
                    <Divider />
                  </div>
                  <div className="EditCard__DropdownContainer">
                    <div className="EditCard__Dropdown">
                      <p className="EditCard__DropdownText">
                        <FormattedMessage id="crusade.campaigns.deliveryCard.beginLabel" />
                      </p>
                      <Dropdown
                        listPosition="left"
                        openInPortal={true}
                        label={
                          field.value?.immediately &&
                          field.value.warmup === true
                            ? beginOptions?.items?.find(
                                (item: any) =>
                                  item.dbValue ===
                                  DeliveryIPWarmupCadenceEnum.IMMEDIATELY,
                              )?.title
                            : beginOptions?.items?.find(
                                (item: any) =>
                                  item.dbValue ===
                                  DeliveryIPWarmupCadenceEnum.CHOOSE_DATE,
                              )?.title
                        }
                        closeOnItemClick
                        expandDirection="auto"
                      >
                        {beginOptions?.items?.map((item, index) => (
                          <DropdownSelectableItem
                            key={index}
                            selected={
                              field.value?.immediately === true &&
                              item.title ===
                                DeliveryIPWarmupCadenceEnum.IMMEDIATELY
                            }
                            onClick={() =>
                              onBeginSelect(item.dbValue as string)
                            }
                          >
                            {item.title}
                          </DropdownSelectableItem>
                        ))}
                      </Dropdown>
                    </div>
                  </div>
                  {field.value?.warmup === true &&
                    field.value?.immediately === false && (
                      <>
                        <div className="EditCard__DropdownContainer">
                          {renderStartingDate()}
                          {renderStartTime()}
                        </div>
                        <div className="EditCard__DropdownContainer">
                          <div className="EditCard__Dropdown">
                            <p className="EditCard__DropdownText">
                              <FormattedMessage id="crusade.campaigns.deliveryCard.endingDateLabel" />
                            </p>
                            <DateInputField
                              classes={(current) => ({
                                ...current,
                                root: `${current.root} EditCard__endDatePicker`,
                              })}
                              value={convertDateForDatePicker(
                                field.value?.delivery?.endDate,
                              )}
                              onChange={(e: any) => onIpEndDateSelect(e)}
                            />
                            <p className="EditCard__dateErrorMsg">
                              {endDateErrorMsg('endDate')}
                            </p>
                          </div>
                          <div className="EditCard__Dropdown">
                            <p className="EditCard__DropdownText">
                              <FormattedMessage id="crusade.campaigns.deliveryCard.endTimeLabel" />
                            </p>
                            <Dropdown
                              listPosition="left"
                              openInPortal={true}
                              label={
                                field.value?.delivery?.endTime
                                  ? endTimeOptions?.items?.find(
                                      (item) =>
                                        item.dbValue ===
                                        field.value?.delivery?.endTime,
                                    )?.title
                                  : t(`${endTimeOptions?.label}`)
                              }
                              closeOnItemClick
                              expandDirection="auto"
                            >
                              {endTimeOptions?.items?.map((item, index) => (
                                <DropdownSelectableItem
                                  key={index}
                                  selected={
                                    field.value?.delivery?.endTime ===
                                    item.dbValue
                                  }
                                  onClick={() =>
                                    onEndTimeSISelect(item.dbValue as string)
                                  }
                                >
                                  {item.title}
                                </DropdownSelectableItem>
                              ))}
                            </Dropdown>
                          </div>
                        </div>
                        {!isValidEndingDate && (
                          <div className="EditCard__IPWarmingError">
                            <Icons.ExclamationIcon className="EditCard__ErrorIcon" />
                            <p className="EditCard__ErrorText">
                              <FormattedMessage id="crusade.campaigns.deliveryCard.ipWarmingErrorMessage" />
                            </p>
                          </div>
                        )}
                      </>
                    )}
                  <div className="EditCard__TimeOutput">
                    {ipWarmingDateArray?.length > 0 && (
                      <p>{` ${t(
                        'crusade.campaigns.deliveryCard.warmingScheduleMessage',
                        {
                          totalDays: totalWarmingDays,
                        },
                      )}
                    ${t(
                      'crusade.campaigns.deliveryCard.warmingWillConcludeOn',
                      {
                        interpolation: { escapeValue: false },
                        warmEndDate: warmingEndDate,
                      },
                    )}`}</p>
                    )}
                  </div>
                </>
              )}
              {field.value?.immediately && (
                <>
                  <div className="EditCard__divider">
                    <Divider />
                  </div>
                  <div className="EditCard__TimeOutput">
                    <FormattedMessage id="crusade.campaigns.deliveryCard.nextSendTimeLabel" />
                    <FormattedMessage id="crusade.campaigns.deliveryCard.immediatelyOnLaunch" />
                  </div>
                </>
              )}
            </div>
          ) : field.value?.immediately ? (
            <p className="EditCard__campaignNonEditCardValue">
              {t(
                'crusade.campaigns.deliveryCard.sendOnceAsSoonAsCampaignIsLaunched',
              )}
            </p>
          ) : field.value?.delivery?.startDate === undefined ||
            field.value?.delivery?.recurrence === undefined ||
            field.value?.delivery?.startTime === undefined ? (
            <p>{t('notConfigured')}</p>
          ) : (
            <p className="EditCard__campaignInfoCardValue">{`${t(
              'crusade.campaigns.deliveryCard.sendLabel',
            )} ${
              !field.value.warmup
                ? field.value?.delivery?.recurrence?.toLowerCase()
                : ''
            } ${moment(field.value?.delivery?.startDate).format('L')} ${t(
              'crusade.campaigns.deliveryCard.atLabel',
            )} ${
              startTimeOptions?.items?.find(
                (item) => item.dbValue === field.value?.delivery?.startTime,
              )?.title
            }`}</p>
          )}
        </div>
      </StaticCard>
    </div>
  );
};

export default CampaignDeliveryCard;
