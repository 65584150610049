import React, { useState, createContext, useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import { selectedSegmentAtom } from 'store/state/audienceFlowState';
import { MODAL_COMPONENTS } from './ModalComponents';

type GlobalModalContextType = {
  showModal: (modalType: string, modalProps?: any, id?: string) => void;
  hideModal: (id?: string) => void;
  closeAllModals: () => void;
  switchModal: (
    closingModalType: string,
    openModalType: string,
    openModalProps: any,
    openModalId: string,
  ) => void;
  store: any;
};

const initalState: GlobalModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  closeAllModals: () => {},
  switchModal: () => {},
  store: {},
};

type ModalStore = {
  [key: string]: {
    modalType: string;
    modalProps: Record<string, unknown>;
  };
};

export const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{}> = ({ children }) => {
  const [store, setStore] = useState<ModalStore>({});
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);

  const showModal = (
    modalType: string,
    modalProps: any,
    id: string = 'default_id',
  ) => {
    setStore({
      ...store,
      [id]: {
        modalType,
        modalProps,
      },
    });
  };

  const hideModal = (id: string = 'default_id') => {
    const newStore = { ...store };
    delete newStore[id];
    setStore(newStore);
  };

  const switchModal = (
    closingModalType: string,
    openModalType: string,
    openModalProps: any,
    openModalId: string,
  ) => {
    const newStore = { ...store };
    delete newStore[closingModalType];

    setStore({
      ...newStore,
      [openModalId]: {
        modalType: openModalType,
        modalProps: openModalProps,
      },
    });
  };

  const closeAllModals = () => {
    setStore({});
    if (store.SEGMENTS_MODAL || store.SPLIT_SEGMENTS_MODAL) {
      setSelectedSegment(undefined);
    }
  };

  const renderComponent = () => {
    const modalIds = Object.keys(store);

    return (
      <div>
        {modalIds.map((id) => {
          const ModalComponent = MODAL_COMPONENTS[store[id].modalType];
          if (!store[id].modalType || !ModalComponent) {
            return null;
          }

          return <ModalComponent id={id} {...store[id].modalProps} key={id} />;
        })}
      </div>
    );
  };

  return (
    <GlobalModalContext.Provider
      value={{ store, showModal, hideModal, closeAllModals, switchModal }}
    >
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
