import { Button, SearchInputField } from 'plume-ui';
import React, { Dispatch, FormEvent, forwardRef, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useClickOutside } from '../../hooks/useClickOutside';
import { parsedMetadataTreeSelector } from '../../store/state/metadataTreeState';
import { SearchMethodTypes } from '../CriteriaCollapseCard/CriteriaCollapseCard';
import FormattedMessage from '../../utils/components/FormattedMessage';

export type TraitsSelectorsProps = {
  activeBtn?: string;
  openBrowse: boolean;
  onOpenBrowse: Dispatch<SetStateAction<boolean>>;
  onSelectSearchMethod: Dispatch<SetStateAction<SearchMethodTypes>>;
  onFetchSearchResults: Dispatch<SetStateAction<any[]>>;
  searchInput: string | undefined;
  setSearchInput: Dispatch<SetStateAction<string | undefined>>;
};

const TraitsSelectors = forwardRef<HTMLDivElement, TraitsSelectorsProps>(
  (
    {
      activeBtn,
      openBrowse,
      onOpenBrowse,
      onSelectSearchMethod,
      onFetchSearchResults,
      searchInput,
      setSearchInput,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const metadataTree = useRecoilValue(parsedMetadataTreeSelector);

    const styles = {
      root: 'TraitsSelectors',
      autoComplete: 'TraitsSelectors__autoComplete',
      segmentSearchBtnGroup: 'TraitsSelectors__segmentSearchBtnGroup',
      segmentSearchBtn: 'TraitsSelectors__segmentSearchBtn',
      activeSegment: 'TraitsSelectors__segmentSearchBtn-active',
      searchInput: 'TraitsSelectors__searchInput',
    };

    const browseRef = useClickOutside({
      toggler: () => onOpenBrowse((prev) => !prev),
      state: openBrowse,
      refToAllowClick: ref,
    });

    const handleSearch = (e: FormEvent<HTMLInputElement>) => {
      const element = e.currentTarget as HTMLInputElement;
      setSearchInput(element.value);
      onOpenBrowse(false);

      if (!element.value) {
        onFetchSearchResults([]);
      } else if (element.value.length > 0) {
        const searchResults = metadataTree
          .map((treeItem) => {
            return (
              treeItem.title !== null &&
              treeItem.items.filter((item) => {
                if (
                  item.title !== null &&
                  item.title
                    .toString()
                    .toLowerCase()
                    .includes(element.value.toLowerCase())
                ) {
                  return item;
                } else {
                  return null;
                }
              })
            );
          })
          .filter((results) => results && results.length > 0);

        onSelectSearchMethod(searchResults.length > 0 ? 'search' : undefined);

        onFetchSearchResults(searchResults);
      }
    };

    return (
      <div className={styles.root}>
        <SearchInputField
          placeholder={t('segmentsModal.searchPlaceholder')}
          onInput={(e) => handleSearch(e)}
          classes={(current) => ({
            ...current,
            root: `${current.root} ${styles.searchInput}
            ${activeBtn === 'search' && styles.activeSegment}`,
          })}
          onClick={() => onSelectSearchMethod('search')}
          noClearIcon
          value={searchInput}
        />
        <div className={styles.segmentSearchBtnGroup}>
          <div ref={browseRef.ref}>
            <Button
              classes={(current) => ({
                ...current,
                root: `${current.root} ${styles.segmentSearchBtn} ${
                  activeBtn === 'browse' && styles.activeSegment
                }`,
              })}
              onClick={(e) => {
                e.stopPropagation();
                onSelectSearchMethod('browse');
                setSearchInput('');
                onFetchSearchResults([]);
                onOpenBrowse(!openBrowse);
              }}
              styleVariant="quaternary"
            >
              <FormattedMessage id="segmentsModal.browse" />
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

export default TraitsSelectors;
