import React, { FunctionComponent, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import cx from 'classnames';
import { CustomLinkSanitizer } from './CustomLinkSanitizer';
import { useSetRecoilState } from 'recoil';
import { emailIntroEditInProgressAtom } from 'store/state/campaignState';

Quill.register(CustomLinkSanitizer, true);

var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

var SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['14px', '16px', '18px', '20px', '24px'];
Quill.register(SizeStyle, true);

export type RichTextEditorProps = {
  richTextEditorValue: string | undefined;
  setEditorHtml?: (html: string) => void;
  readOnly: boolean;
};

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
  richTextEditorValue,
  setEditorHtml,
  readOnly,
}) => {
  const setEditInProgress = useSetRecoilState(emailIntroEditInProgressAtom);
  const [theme] = useState(readOnly ? 'bubble' : 'snow');
  const modules = {
    toolbar: [
      [{ size: SizeStyle.whitelist }],
      ['bold', 'italic', 'underline'],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      [{ list: 'bullet' }],
      ['link'],
    ],
  };

  const handleChange = (html: string) => {
    setEditorHtml?.(html);
  };

  return (
    <div className="RichTextEditor-container" id="richTextEditorContainer">
      <ReactQuill
        className={cx('RichTextEditor', {
          'RichTextEditor-readOnly': readOnly,
        })}
        bounds="#richTextEditorContainer"
        theme={theme}
        onChange={handleChange}
        value={richTextEditorValue}
        modules={modules}
        readOnly={readOnly}
        onFocus={() => setEditInProgress(true)}
        onBlur={() => setEditInProgress(false)}
      />
    </div>
  );
};

export default RichTextEditor;
