import {
  APP_URL,
  FACEBOOK_CLIENT_ID,
  FACEBOOK_LOGIN_URL,
  GOOGLE_CLIENT_ID,
  HARVEST_CRUSADE_BACKEND_URL,
  HARVEST_GENERATE_BACKEND_URL,
  OAUTH2_API_URL,
  OKTA_BASE_URL,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
} from './config';

export type EnvironmentId =
  | 'local'
  | 'usw2-dev'
  | 'usw2-stg'
  | 'apne1'
  | 'usw2'
  | 'euc1';

export type Environment = {
  id: EnvironmentId;
  oktaBaseUrl: string;
  oktaIssuer: string;
  oktaClientId: string;
  backendGenerateUrl: string;
  backendCrusadeUrl: string;
  facebookClientId: string;
  googleClientId: string;
  oAuth2ApiUrl: string;
};

const localEnv: Environment = {
  id: 'local',
  oktaBaseUrl: 'https://external-dev.sso.plume.com',
  oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
  oktaClientId: '0oa15glvqezXd8EHR0h8',
  backendGenerateUrl: 'https://engage-dev-usw2-external.data.plume.tech',
  backendCrusadeUrl: 'https://engage-dev-usw2-external.data.plume.tech',
  oAuth2ApiUrl: 'https://engage-dev-usw2-external.data.plume.tech',
  facebookClientId: '544405653795030',
  googleClientId:
    '109642344058-6342cgk3507chd5s1ehjif50ddeemcs9.apps.googleusercontent.com',
};

const environments: Environment[] = [
  localEnv,
  {
    id: 'usw2-dev',
    oktaBaseUrl: 'https://external-dev.sso.plume.com',
    oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
    oktaClientId: '0oa15glvqezXd8EHR0h8',
    backendGenerateUrl: 'https://engage-dev-usw2-external.data.plume.tech',
    backendCrusadeUrl: 'https://engage-dev-usw2-external.data.plume.tech',
    oAuth2ApiUrl: 'https://engage-dev-usw2-external.data.plume.tech',
    facebookClientId: '544405653795030',
    googleClientId:
      '109642344058-6342cgk3507chd5s1ehjif50ddeemcs9.apps.googleusercontent.com',
  },
  {
    id: 'usw2-stg',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvr126gULRNQqp357',
    backendGenerateUrl: 'https://engage-stg-usw2-external.data.plume.tech',
    backendCrusadeUrl: 'https://engage-stg-usw2-external.data.plume.tech',
    oAuth2ApiUrl: 'https://engage-stg-usw2-external.data.plume.tech',
    facebookClientId: '544405653795030',
    googleClientId:
      '109642344058-6342cgk3507chd5s1ehjif50ddeemcs9.apps.googleusercontent.com',
  },
  {
    id: 'apne1',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvr0vakLW29wi3357',
    backendGenerateUrl: 'https://engage-prod-apne1-external.data.plumenet.io',
    backendCrusadeUrl: 'https://engage-prod-apne1-external.data.plumenet.io',
    oAuth2ApiUrl: 'https://engage-prod-apne1-external.data.plumenet.io',
    facebookClientId: '3061755800803102',
    googleClientId:
      '257724844173-76k4uu34o3jdf7cn694jjkmjmqvo0n16.apps.googleusercontent.com',
  },
  {
    id: 'usw2',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvr3jpqhV2GjKt357',
    backendGenerateUrl: 'https://engage-prod-usw2-external.data.plumenet.io',
    backendCrusadeUrl: 'https://engage-prod-usw2-external.data.plumenet.io',
    oAuth2ApiUrl: 'https://engage-prod-usw2-external.data.plumenet.io',
    facebookClientId: '3061755800803102',
    googleClientId:
      '257724844173-76k4uu34o3jdf7cn694jjkmjmqvo0n16.apps.googleusercontent.com',
  },
  {
    id: 'euc1',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvr6khoU8ALxYZ357',
    backendGenerateUrl: 'https://engage-prod-euc1-external.data.plumenet.io',
    backendCrusadeUrl: 'https://engage-prod-euc1-external.data.plumenet.io',
    oAuth2ApiUrl: 'https://engage-prod-euc1-external.data.plumenet.io',
    facebookClientId: '689308375369622',
    googleClientId:
      '66725452170-je29r33dnc511sbk7h8di83v4ik5qe57.apps.googleusercontent.com',
  },
];

export const getEnvironment = (): Environment => {
  const hostnameBits = window.location.hostname.split('.');

  if (hostnameBits.length > 3) {
    const envFromUrl = hostnameBits[hostnameBits.length - 4];
    const env = environments.find((e) => e.id === envFromUrl);
    return env || localEnv;
  } else {
    return localEnv;
  }
};

const getEnvProp = (propName: keyof Environment, fallbackValue: string) => {
  const env = getEnvironment();
  return env[propName] || fallbackValue;
};

export const getOktaClientId = () => {
  return getEnvProp('oktaClientId', OKTA_CLIENT_ID);
};

export const getOktaBaseUrl = () => {
  return getEnvProp('oktaBaseUrl', OKTA_BASE_URL);
};

export const getOktaIssuer = () => {
  return getEnvProp('oktaIssuer', OKTA_ISSUER);
};

export const getBackendGenerateUrl = () => {
  return getEnvProp('backendGenerateUrl', HARVEST_GENERATE_BACKEND_URL);
};

export const getBackendCrusadeUrl = () => {
  return getEnvProp('backendCrusadeUrl', HARVEST_CRUSADE_BACKEND_URL);
};

export const getFacebookClientId = () => {
  return getEnvProp('facebookClientId', FACEBOOK_CLIENT_ID);
};

export const getGoogleClientId = () => {
  return getEnvProp('googleClientId', GOOGLE_CLIENT_ID);
};

export const getOAuth2ApiUrl = () => {
  return getEnvProp('oAuth2ApiUrl', OAUTH2_API_URL);
};

export const getFacebookLoginUrl = () => {
  return FACEBOOK_LOGIN_URL;
};

export const getIntegrationsRedirectUri = () => {
  const appUrl = APP_URL;
  return `${appUrl}/integrations`;
};
