import { Icons, Tooltip } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

type CardTitleProps = {
  cardType: string;
};

export const CardTitle: FunctionComponent<CardTitleProps> = ({ cardType }) => {
  return (
    <div className="SegmentsModal__CardTitleContainer">
      <div className="SegmentsModal__CardTitle">
        {i18next.t(`segmentsModal.${cardType}`)}
      </div>
      <Tooltip
        label={i18next.t('segmentsModal.includeSearchTooltip')}
        position="below"
        alignment="center"
        overrideMaxCharacters={true}
      >
        <Icons.InfoIcon width={20} />
      </Tooltip>
    </div>
  );
};
