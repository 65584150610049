import classNames from 'classnames';
import { Box, Icons, Spinner } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'utils/components/FormattedMessage';

export type SyncTestResultProps = {
  status: string;
  mode?: string;
  testType?: 'fetch' | 'load';
  syncTestResult: {
    result: 'success' | 'error' | string;
    message?: string;
    loadingMessage?: string;
  };
};

/**
 * The sync test will be run on button click in CreateSyncModal
 * Will need to update this component when we know what the shape of response
 *  from API looks like
 */

const SyncTestResult: FunctionComponent<SyncTestResultProps> = ({
  status,
  mode,
  testType,
  syncTestResult,
}) => {
  const { t } = useTranslation();

  const renderPendingResult = () => {
    return (
      <div className="CreateSyncModal__testResult">
        <Spinner size="small" visible={true} />
        <div className="CreateSyncModal__testResultLabel">
          {mode === 'channel' ? (
            <div>
              <FormattedMessage id="settings.channel.verifyService" />
            </div>
          ) : (
            <div className="CreateSyncModal__loadingMessageLabel">
              {syncTestResult?.loadingMessage ? (
                syncTestResult?.loadingMessage
              ) : (
                <>
                  {testType === 'fetch'
                    ? t('syncModal.fetchingRecord')
                    : t('syncModal.loadingRecords')}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderError = () => {
    return (
      <div className="CreateSyncModal__testResult-error">
        <div className="CreateSyncModal__testResult">
          <Icons.CrossIcon width={16} />
          <div className="CreateSyncModal__testResultLabel CreateSyncModal__testResultLabel-error">
            {mode === 'channel' ? (
              <div>
                <FormattedMessage id="settings.channel.verificationFailed" />
              </div>
            ) : (
              <div>
                {testType === 'fetch'
                  ? t('syncModal.fetchingRecord')
                  : t('syncModal.loadingRecords')}
              </div>
            )}
          </div>
        </div>
        <Box
          classes={(current) => ({
            ...current,
            root: `${current.root} CreateSyncModal__errorMsg`,
          })}
        >
          {syncTestResult?.message}
        </Box>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div className="CreateSyncModal__testResult">
        <Icons.CheckIcon width={16} />
        <div
          className={classNames('CreateSyncModal__testResultLabel', {
            'CreateSyncModal__testResultLabel-success': syncTestResult?.result,
          })}
        >
          {mode === 'channel' ? (
            <div>
              {syncTestResult?.message ? (
                syncTestResult?.message
              ) : (
                <FormattedMessage id="settings.channel.verifiedSuccessfully" />
              )}
            </div>
          ) : (
            <div>
              {syncTestResult?.message ? (
                syncTestResult?.message
              ) : (
                <>
                  {testType === 'fetch' ? (
                    <FormattedMessage id="syncModal.fetchingRecord" />
                  ) : (
                    <FormattedMessage id="settings.channel.verifiedSuccessfully" />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      {status === 'loading'
        ? renderPendingResult()
        : syncTestResult.result === 'error'
        ? renderError()
        : renderSuccess()}
    </div>
  );
};

export default SyncTestResult;
