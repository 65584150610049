import React, { FunctionComponent, useEffect } from 'react';
import './root.scss';
import { Route, Switch, useLocation } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { LOGIN_SCREEN_ROUTE, Routes } from './config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginUrls } from './urls';
import LoginContainer from './features/login/containers/LoginContainer';
import ForAuthenticated from './ForAuthenticated';
import { useRedirectToRoute } from './utils/hooks/useRedirectToRoute';
import { DependencyContainer } from './DependencyContainer';
import LoginCallbackError from './features/login/components/LoginCallbackError';
import AppLayout from './layouts/AppLayout';
import ConfigurationContainer from './features/configuration/containers/ConfigurationContainer';
import CreateAudience from './features/generate/audience/components/createAudience/CreateAudience';
import CrusadeCampaignsContainer from './features/crusade/containers/CrusadeCampaignsContainer';
import SyncsContainer from './features/syncs/containers/SyncsContainer';
import AudienceLayout from './features/generate/audience/containers/AudienceLayout';
import { getOktaClientId, getOktaIssuer } from './environment';
import SegmentDataPreview from 'components/SegmentDataPreview/SegmentDataPreview';
import IntegrationsContainer from './features/integrations/containers/IntegrationsContainer';
import useIntegrations from './features/integrations/hooks/useIntegrations';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import {
  CustomErrorProtocol,
  ErrorOAuthCancelled,
} from './features/integrations/errors';
import { ConfigurationEnum } from 'features/crusade/types';
import CrusadeCampaignCardsContainer from 'features/crusade/containers/CrusadeCampaignCardsContainer';
import TemplateListContainer from 'features/crusade/components/templates/TemplateListContainer';
import OverviewContainer from 'features/overview/containers/OverviewContainer';
import NpsOverview from 'features/overview/components/NpsOverview/NpsOverview';

const redirectUri = LoginUrls.getRedirectUriForOkta();
const oktaAuth = new OktaAuth({
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri,
  postLogoutRedirectUri: redirectUri,
  scopes: ['openid', 'email', 'plume', 'harvest'],
  responseType: 'id_token',
  tokenManager: {
    autoRenew: true,
  },
});
const { loginService } = new DependencyContainer();
const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
  const targetRoute = loginService.getTargetRoute();
  if (targetRoute) {
    window.location.href = targetRoute;
    loginService.removeTargetRoute();
    return;
  }
  const url = originalUri
    ? toRelativeUrl(originalUri, window.location.origin)
    : Routes.Index;
  window.location.href = url;
};

const AppContainer: FunctionComponent = () => {
  const { closeAllModals } = useGlobalModalContext();

  const usePageViews = () => {
    let location = useLocation();

    useEffect(() => {
      closeAllModals();
    }, [location]);
  };

  // Close all modals on route change
  usePageViews();

  const { pathname } = useLocation();
  const { cacheStrategyData, cleanup } = useIntegrations({
    automaticallyStartFlow: false,
    onError: (error: CustomErrorProtocol) => {
      if (
        error instanceof ErrorOAuthCancelled &&
        pathname !== Routes.LoginCallback
      ) {
        cleanup();
        redirectToRoute(Routes.Settings);
      }
    },
  });
  const redirectToRoute = useRedirectToRoute();

  const onAuthRequired = () => {
    if (pathname === LOGIN_SCREEN_ROUTE) {
      return;
    }
    loginService.setTargetRoute(pathname);
    redirectToRoute(LOGIN_SCREEN_ROUTE);
  };

  useEffect(() => {
    cacheStrategyData();
  }, [window.location.href]);

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="AppContainer">
        <Switch>
          <Route path={Routes.Login} component={LoginContainer} />
          <Route path={Routes.LoginCallback}>
            <LoginCallback errorComponent={LoginCallbackError} />
          </Route>
          <SecureRoute path={Routes.NpsOverview}>
            <ForAuthenticated>
              <AppLayout>
                <NpsOverview />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Overview}>
            <ForAuthenticated>
              <AppLayout>
                <OverviewContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={Routes.CrusadeCampaigns}>
            <ForAuthenticated>
              <AppLayout>
                <CrusadeCampaignsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Settings}>
            <ForAuthenticated>
              <AppLayout>
                <ConfigurationContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Integrations}>
            <ForAuthenticated>
              <AppLayout>
                <IntegrationsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute
            exact
            path={[Routes.CreateAudience, Routes.EditAudience]}
          >
            <ForAuthenticated>
              <AppLayout>
                <CreateAudience />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={[Routes.Index, Routes.GenerateSpecificTab]}>
            <ForAuthenticated>
              <AppLayout>
                <AudienceLayout />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Connections}>
            <ForAuthenticated>
              <AppLayout>
                <SyncsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.GenerateDataPreview}>
            <ForAuthenticated>
              <AppLayout>
                <SegmentDataPreview />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.FromTemplateCampaign}>
            <ForAuthenticated>
              <AppLayout>
                <TemplateListContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={[Routes.CreateCampaign, Routes.EditCampaign]}>
            <ForAuthenticated>
              <AppLayout>
                <CrusadeCampaignCardsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={[Routes.Index, Routes.Generate]}>
            <ForAuthenticated>
              <AppLayout>
                <AudienceLayout />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
        </Switch>
      </div>
    </Security>
  );
};

export default AppContainer;
