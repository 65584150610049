import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Button,
  Divider,
  Dropdown,
  DropdownSelectableItem,
  IconButton,
  Icons,
  InputField,
  PendingContent,
  Spinner,
  StaticCard,
  Switch,
} from 'plume-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  editingCampaignAtom,
  editingCampaignEmailData,
  emailIntroEditInProgressAtom,
  formUpdated,
} from '../../../store/state/campaignState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { campaignEmailConfigAtom } from 'store/state/configurationState';
import { generateKey } from 'utils/helpers';
import {
  CampaignDynamicContentType,
  CampaignFormikFields,
  CampaignGenericFormField,
  CampaignMessageCardValues,
  ConfigurationEnum,
  DisplayNameAddressCardType,
} from '../types';
import { useCampaignEmailConfiguration } from 'features/configuration/hooks/useCampaignEmailConfiguration';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { Routes } from 'config';
import Iframe from '../util/Iframe';

const CampaignMessageCard: FunctionComponent<CampaignGenericFormField<
  CampaignMessageCardValues
>> = ({ form, field }) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = useState(false);
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const [emailBody, setEmailBody] = useState(editingCampaign?.emailBody || '');
  const [showHTML, setShowHTML] = useState(false);
  const [dynamicContent] = useState<
    CampaignDynamicContentType[] | null | undefined
  >(editingCampaign?.dynamicContent);
  const { loading, error } = useCampaignEmailConfiguration();
  const campaignEmailConfig = useRecoilValue(campaignEmailConfigAtom);
  const { showModal } = useGlobalModalContext();
  const redirectToRoute = useRedirectToRoute();
  const emailData = useRecoilValue(editingCampaignEmailData);
  const emailIntroEditInProgress = useRecoilValue(emailIntroEditInProgressAtom);
  const setFormFieldsUpdated = useSetRecoilState(formUpdated);
  useEffect(() => {
    setEmailBody(emailData.emailBody);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_MESSAGE_CARD, {
      ...field.value,
      emailBody: emailData.emailBody,
    });
  }, [emailData]);

  useEffect(() => {
    if (!editingCampaign) {
      setInEditMode(true);
    }
  }, [editingCampaign]);

  const handleCancelEdit = () => {
    setInEditMode(false);
  };

  const handleEmailPreviewAndTest = () => {
    showModal(
      MODAL_TYPES.PREVIEW_AND_TEST_MODAL,
      { mode: 'emailPreview' },
      MODAL_TYPES.PREVIEW_AND_TEST_MODAL,
    );
  };

  const cardActions = () => {
    if (!editingCampaign) {
      return undefined;
    } else if (!inEditMode) {
      return [
        <IconButton
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__editIcon`,
          })}
          onClick={() => setInEditMode(true)}
        >
          <Icons.EditIcon />
        </IconButton>,
      ];
    }
  };

  const handleEmailBody = (html: string) => {
    setEmailBody(html);
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_MESSAGE_CARD, {
      ...field.value,
      emailBody: html,
    });
  };

  const handleMessageFromClick = (address: DisplayNameAddressCardType) => {
    const fromAddress = `${address.fromDisplayName}<${address.localPart}@${address.emailDomain}>`;
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_MESSAGE_CARD, {
      ...field.value,
      from: fromAddress,
    });
  };
  const handleDynamicContentToggle = (content: CampaignDynamicContentType) => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_MESSAGE_CARD, {
      ...field.value,
      dynamicContent: field.value.dynamicContent?.map((item) => {
        if (item.id === content.id) {
          return { ...item, value: !item.value };
        }
        return item;
      }),
    });
  };

  const renderHTMLBtn = () => {
    if (inEditMode) {
      return showHTML ? (
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__emailHTMLBtn`,
          })}
          styleVariant="tertiary-grey"
          icon={<Icons.CodeBlockIcon />}
          onClick={() => setShowHTML(false)}
        >
          {t('crusade.campaigns.hideHTML')}
        </Button>
      ) : (
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__emailHTMLBtn`,
          })}
          styleVariant="tertiary-grey"
          icon={<Icons.CodeBlockIcon />}
          onClick={() => setShowHTML(true)}
        >
          {t('crusade.campaigns.showHTML')}
        </Button>
      );
    }
  };

  const renderCardContent = () => {
    if (inEditMode && showHTML) {
      return (
        <div className="EditCard__emailPreview-container">
          <textarea
            id="editor"
            name="customHtml"
            className="EditCard__textArea"
            value={emailBody}
            onChange={(e) => handleEmailBody(e.target.value)}
            placeholder={t('crusade.campaigns.htmlPlaceholder')}
          />
          <p className="EditCard__emailPreview-hint">
            <FormattedMessage id="crusade.campaigns.emailHTMLHint" />
          </p>
        </div>
      );
    } else {
      return (
        emailData && (
          <div className="EditCard__emailPreviewDiv">
            <PendingContent loading={emailIntroEditInProgress} loader={Spinner}>
              <Iframe
                scrolling="yes"
                height="280"
                html={emailData.emailBodyPreview}
                title={emailData.campaignId}
              />
            </PendingContent>
          </div>
        )
      );
    }
  };

  return (
    <div className="EditCard">
      <StaticCard
        title={t('crusade.campaigns.messages')}
        actions={cardActions()}
        classes={(current) => ({
          ...current,
          title: `${current.title} EditCard__cardHeader-title`,
        })}
      >
        <>
          {!inEditMode && (
            <div className="EditCard__campaignSettingsBtnContainer">
              <div className="EditCard__campaignSettingsPrompt">
                <FormattedMessage id="crusade.campaigns.messageCard.campaignSettingsPrompt" />
              </div>
              <Button
                styleVariant="tertiary"
                classes={(current) => ({
                  ...current,
                  root: `${current.root} EditCard__campaignSettingsBtn`,
                })}
                onClick={() => redirectToRoute(Routes.Settings)}
              >
                <FormattedMessage id="crusade.campaigns.messageCard.campaignSettingsBtn" />
              </Button>
            </div>
          )}
          <div className="EditCard__fromDropdownWrapper">
            <p className="EditCard__label">{`${t(
              'crusade.campaigns.emailFrom',
            )} :`}</p>
            {inEditMode ? (
              <div className="EditCard__campaignMessageCard-fromDropdown">
                <PendingContent loading={loading} isError={Boolean(error)}>
                  <Dropdown
                    listPosition="left"
                    label={
                      field.value?.from
                        ? field.value?.from
                        : t('crusade.campaigns.emailFrom')
                    }
                    closeOnItemClick={true}
                  >
                    {campaignEmailConfig?.displayNameAddresses?.map(
                      (address) => (
                        <DropdownSelectableItem
                          key={generateKey()}
                          selected={
                            field.value?.from === address.fromDisplayName
                          }
                          onClick={() => handleMessageFromClick(address)}
                        >
                          {`${address.fromDisplayName}<${address.localPart}@${address.emailDomain}>`}
                        </DropdownSelectableItem>
                      ),
                    )}
                  </Dropdown>
                </PendingContent>
              </div>
            ) : (
              <p className="EditCard__campaignNonEditCardValue">
                {field.value.from}
              </p>
            )}
          </div>
          <div className="EditCard__wrapper">
            <p className="EditCard__label">
              <FormattedMessage id="crusade.campaigns.emailSubject" />:
            </p>
            {inEditMode ? (
              <div className="EditCard__inputWrapper">
                <InputField
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} EditCard__campaignInfoCardInput`,
                  })}
                  onInput={(e) => {
                    setFormFieldsUpdated(true);

                    form.setFieldValue(
                      CampaignFormikFields.CAMPAIGN_MESSAGE_CARD,
                      {
                        ...field.value,
                        subject: (e.target as HTMLInputElement).value || '',
                      },
                    );
                  }}
                  value={field.value.subject}
                />
              </div>
            ) : (
              <p className="EditCard__campaignNonEditCardValue">
                {field.value.subject}
              </p>
            )}
          </div>
          {dynamicContent?.length && (
            <div className="EditCard__wrapper">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.metricsDisplayed" />:
              </p>
              {inEditMode ? (
                <div className="EditCard__metricsDisplaydynamicFieldsContainer">
                  {dynamicContent?.length &&
                    field.value.dynamicContent?.map((content) => {
                      return (
                        <div key={content.id} className="EditCard__row">
                          <p className="EditCard__label">{content.label}</p>
                          <Switch
                            selected={content.value}
                            onToggle={() => handleDynamicContentToggle(content)}
                            title={''}
                          />
                          <p className="EditCard__selectedSwitch">
                            {content.value
                              ? t('crusade.campaigns.enabled')
                              : t('crusade.campaigns.disabled')}
                          </p>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <div className="EditCard__campaignNonEditCardValue">
                  {dynamicContent?.length &&
                    dynamicContent.map((content) => {
                      return content.value ? (
                        <p key={content.id}>{content.label}</p>
                      ) : null;
                    })}
                </div>
              )}
            </div>
          )}
          <div className="EditCard__emailBtnContainer">
            <Button
              classes={(current) => ({
                ...current,
                root: `${current.root} EditCard__emailPreviewAndTestBtn`,
              })}
              styleVariant="tertiary"
              onClick={handleEmailPreviewAndTest}
              icon={<Icons.SearchIcon />}
            >
              {t('crusade.campaigns.emailPreviewAndTest')}
            </Button>
            {renderHTMLBtn()}
          </div>
          {renderCardContent()}
        </>
      </StaticCard>
    </div>
  );
};

export default CampaignMessageCard;
