import { CampaignStatus, CampaignType } from 'features/crusade/types';
import { ChartOptions } from 'plume-ui';
import {
  EmailChartHeroMetricEnum,
  EmailChartHeroMetricType,
  OverviewChartColorOptions,
  PushChartHeroMetricEnum,
  PushChartHeroMetricType,
} from './types';
import i18next from 'i18next';
import format from 'date-fns/format';

export const emailCampaignGroups = [
  {
    color: OverviewChartColorOptions.COLOR_WARM_X_500,
    key: EmailChartHeroMetricEnum.SEGMENT_SIZE,
    label: 'Segment size',
    stroke: OverviewChartColorOptions.COLOR_WARM_X_500,
    type: ChartOptions.ChartType.LINE,
  },
  {
    color: OverviewChartColorOptions.FREEZE_500,
    key: EmailChartHeroMetricEnum.CLICKS,
    label: 'Clicks',
    stroke: OverviewChartColorOptions.FREEZE_500,
    type: ChartOptions.ChartType.BAR,
  },
  {
    color: '#41459D',
    key: EmailChartHeroMetricEnum.OPENS,
    label: 'Opened emails',
    stroke: '#41459D',
    type: ChartOptions.ChartType.BAR,
  },

  {
    color: OverviewChartColorOptions.WARM_500,
    key: EmailChartHeroMetricEnum.BOUNCES,
    label: 'Bounces',
    stroke: OverviewChartColorOptions.WARM_500,
    type: ChartOptions.ChartType.BAR,
  },
  {
    color: OverviewChartColorOptions.COLD_500,
    key: EmailChartHeroMetricEnum.UNSUBSCRIBES,
    label: 'Unsubscribes',
    stroke: OverviewChartColorOptions.COLD_500,
    type: ChartOptions.ChartType.BAR,
  },
];

export const pushCampaignGroups = [
  {
    color: OverviewChartColorOptions.COLOR_WARM_X_500,
    key: PushChartHeroMetricEnum.SEGMENT_SIZE,
    label: 'Segment size',
    stroke: OverviewChartColorOptions.COLOR_WARM_X_500,
    type: ChartOptions.ChartType.LINE,
  },
  {
    color: '#41459D',
    key: PushChartHeroMetricEnum.OPENS,
    label: 'Opens',
    stroke: '#41459D',
    type: ChartOptions.ChartType.BAR,
  },
  {
    color: OverviewChartColorOptions.WARM_500,
    key: PushChartHeroMetricEnum.BOUNCES,
    label: 'Bounces',
    stroke: OverviewChartColorOptions.WARM_500,
    type: ChartOptions.ChartType.BAR,
  },
];

export const mockCampaignListApiResponse = [
  {
    campaignName: 'Campaign1: Campaign1Campaign1Campaign1Campaign1Campaign1',
    campaignId: 'campaign_1',
    campaignType: 'email' as CampaignType,
    updatedAt: '2023-03-01 21:28:47.476836',
    status: 'Active' as CampaignStatus,
    nps: false,
  },
  {
    campaignName: 'Campaign 2',
    campaignId: 'campaign_2',
    campaignType: 'pushNotification' as CampaignType,
    updatedAt: '2023-03-01 21:28:47.476836',
    status: 'Paused' as CampaignStatus,
    nps: false,
  },
  {
    campaignName: 'Campaign 3',
    campaignId: 'campaign_3',
    campaignType: 'email' as CampaignType,
    updatedAt: '2023-03-01 21:28:47.476836',
    status: 'Completed' as CampaignStatus,
    nps: false,
  },
  {
    campaignName: 'Campaign 4',
    campaignId: 'campaign_4',
    campaignType: 'pushNotification' as CampaignType,
    updatedAt: '2023-03-01 21:28:47.476836',
    status: 'Active' as CampaignStatus,
    nps: false,
  },
  {
    campaignName: 'NPS Survey 1',
    campaignId: 'campaign_5',
    campaignType: 'email' as CampaignType,
    updatedAt: '2023-03-01 21:28:47.476836',
    status: 'Completed' as CampaignStatus,
    nps: true,
  },
];

const mockEmail30DayDailyStats = () => {
  let response = [];
  for (let i = 0; i < 30; i++) {
    response.push({
      dt: format(new Date(Date.now() - i * 24 * 60 * 60 * 1000), 'yyyy-MM-dd'),
      sent: 250 - i * 2,
      segmentSize: 250 + i * 2,
      opens: 100 - i * 2,
      delivered: 30 - i,
      clicks: 0,
      bounces: 10,
      unsubscribes: 10,
    });
  }
  return response;
};

const mockEmail90DayDailyStats = () => {
  let response = [];
  for (let i = 0; i < 90; i++) {
    response.push({
      dt: format(new Date(Date.now() - i * 24 * 60 * 60 * 1000), 'yyyy-MM-dd'),
      sent: 250 - i * 2,
      segmentSize: 250 + i * 2,
      opens: 100 - i * 2,
      delivered: 135 - i * 2,
      clicks: 0,
      bounces: 10,
      unsubscribes: 10,
    });
  }
  return response;
};

export const mock90DayEmail = {
  type: 'email' as CampaignType,
  heroMetrics: {
    emailsSent: 250,
    delivered: 235,
    segmentSize: 250,
    opens: 195,
    clicks: 195,
    bounces: 15,
    unsubscribes: 10,
  } as EmailChartHeroMetricType,
  dailyStats: mockEmail90DayDailyStats(),
};

export const mock30DayEmail = {
  type: 'email' as CampaignType,
  heroMetrics: {
    emailsSent: 250,
    delivered: 235,
    segmentSize: 250,
    opens: 195,
    clicks: 195,
    bounces: 15,
    unsubscribes: 10,
  } as EmailChartHeroMetricType,
  dailyStats: mockEmail30DayDailyStats(),
};

export const mockEmailCampaignResponse = {
  type: 'email' as CampaignType,
  heroMetrics: {
    emailsSent: 250,
    delivered: 235,
    segmentSize: 250,
    opens: 195,
    clicks: 195,
    bounces: 15,
    unsubscribes: 10,
  } as EmailChartHeroMetricType,
  dailyStats: [
    {
      dt: '2023-04-12',
      sent: 250,
      delivered: 135,
      segmentSize: 250,
      opens: 100,
      clicks: 0,
      bounces: 10,
      unsubscribes: 10,
    },
    {
      dt: '2023-04-13',
      sent: 200,
      delivered: 100,
      segmentSize: 225,
      opens: 20,
      clicks: 60,
      bounces: 10,
      unsubscribes: 10,
    },
    {
      dt: '2023-04-14',
      sent: 150,
      delivered: 100,
      segmentSize: null,
      opens: 35,
      clicks: 50,
      bounces: 10,
      unsubscribes: 20,
    },
    {
      dt: '2023-04-15',
      sent: 100,
      delivered: 80,
      segmentSize: 100,
      opens: 15,
      clicks: 40,
      bounces: 10,
      unsubscribes: 20,
    },
    // {
    //   dt: '2023-04-12',
    //   sent: 80,
    //   delivered: 60,
    //   segmentSize: 100,
    //   opens: 10,
    //   clicks: 30,
    //   bounces: 10,
    //   unsubscribes: 30,
    // },
    // {
    //   dt: '2023-04-13',
    //   sent: 50,
    //   delivered: 30,
    //   segmentSize: 90,
    //   opens: 5,
    //   clicks: 20,
    //   bounces: 10,
    //   unsubscribes: 30,
    // },
    {
      dt: '2023-04-18',
      sent: 20,
      delivered: 10,
      segmentSize: 80,
      opens: 5,
      clicks: 10,
      bounces: 10,
      unsubscribes: 5,
    },
  ],
};

export const mockPushCampaignResponse = {
  type: 'pushNotification' as CampaignType,
  heroMetrics: {
    messagesSent: 30500,
    segmentSize: 300,
    opens: 170,
    bounces: 50,
  } as PushChartHeroMetricType,
  dailyStats: [
    {
      dt: '2023-04-08',
      segmentSize: 300,
      opens: 170,
      bounces: 50,
    },
    // {
    //   dt: '2023-04-09',
    //   messagesSent: 300,
    //   delivered: 200,
    //   segmentSize: 300,
    //   opens: 170,
    //   bounces: 50,
    // },
    {
      dt: '2023-04-10',
      segmentSize: 300,
      opens: 170,
      bounces: 50,
    },
    {
      dt: '2023-04-11',
      segmentSize: 300,
      opens: 170,
      bounces: 50,
    },
    // {
    //   dt: '2023-04-12',
    //   messagesSent: 300,
    //   delivered: 200,
    //   segmentSize: 300,
    //   opens: 170,
    //   bounces: 50,
    // },
    // {
    //   dt: '2023-04-13',
    //   messagesSent: 300,
    //   delivered: 200,
    //   segmentSize: 250,
    //   opens: 170,
    //   bounces: 50,
    // },
    {
      dt: '2023-04-14',
      segmentSize: 200,
      opens: 170,
      bounces: 50,
    },
  ],
};

export const defaultHeroMetrics = (campaignType: CampaignType) => {
  return campaignType === CampaignType.EMAIL
    ? {
        sent: 0,
        delivered: 0,
        segmentSize: 0,
        opens: 0,
        clicks: 0,
        bounces: 0,
        unsubscribes: 0,
      }
    : {
        sent: 0,
        segmentSize: 0,
        opens: 0,
        bounces: 0,
      };
};

export const mockEmailCampaignResponseWithNull = {
  type: 'email' as CampaignType,
  heroMetrics: {
    emailsSent: 250,
    delivered: 235,
    segmentSize: 250,
    opens: 195,
    clicks: 195,
    bounces: 15,
    unsubscribes: 10,
  } as EmailChartHeroMetricType,
  dailyStats: [
    {
      dt: '2023-04-13',
      sent: 250,
      delivered: 135,
      segmentSize: 250,
      opens: 100,
      clicks: 0,
      bounces: 10,
      unsubscribes: 10,
    },
    {
      dt: '2023-04-14',
      sent: 200,
      delivered: 135,
      segmentSize: 225,
      opens: 20,
      clicks: 60,
      bounces: 10,
      unsubscribes: 10,
    },
    {
      dt: '2023-04-15',
      sent: 150,
      delivered: 135,
      segmentSize: null,
      opens: 35,
      clicks: 50,
      bounces: 10,
      unsubscribes: 20,
    },
    {
      dt: '2023-04-16',
      sent: 100,
      delivered: 80,
      segmentSize: 100,
      opens: 15,
      clicks: 40,
      bounces: 10,
      unsubscribes: 20,
    },
    {
      dt: '2023-04-17',
      sent: 80,
      delivered: 60,
      segmentSize: 100,
      opens: 10,
      clicks: 30,
      bounces: 10,
      unsubscribes: 30,
    },
    {
      dt: '2023-04-18',
      sent: 50,
      delivered: 135,
      segmentSize: 90,
      opens: 5,
      clicks: 20,
      bounces: 10,
      unsubscribes: 30,
    },
    {
      dt: '2023-04-19',
      sent: 20,
      delivered: 10,
      segmentSize: 80,
      opens: 5,
      clicks: 10,
      bounces: 10,
      unsubscribes: 5,
    },
  ],
};
