import React, { FunctionComponent, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, notify, PendingContent, Spinner } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import useIntegrations, {
  getStrategy,
  setStrategy,
} from '../hooks/useIntegrations';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { Routes } from '../../../config';
import { DependencyContainer } from '../../../DependencyContainer';
import { IntegrationStrategy } from '../../../persistent-state/persistent-state';
import { AvailableIntegrationTypes } from '../../configuration/types';
import { useSetRecoilState } from 'recoil';

import IntegrationsErrorRenderer from '../components/IntegrationsErrorRenderer';
import { IntegrationErrorAction } from '../types';
import { PendingContentStyles } from 'plume-ui/dist/components/PendingContent/PendingContent';
import {
  CustomErrorProtocol,
  ErrorMissingAccessToken,
  ErrorRequestTimedOut,
} from '../errors';
import { ConfigurationEnum } from 'features/crusade/types';
import {
  adAccountsResponseAtom,
  integrationErrorAtom,
} from 'features/configuration/integrationsState';

const { integrationsService } = new DependencyContainer();

const mapServiceStrategyToAccountType: Record<
  AvailableIntegrationTypes,
  'getGoogleAdAccounts' | 'getFacebookAdAccounts'
> = {
  [AvailableIntegrationTypes.Google]: 'getGoogleAdAccounts',
  [AvailableIntegrationTypes.Facebook]: 'getFacebookAdAccounts',
};

const addClassesToPendingContent = (current: PendingContentStyles) => ({
  ...current,
  loader: `${current.loader} IntegrationsContainer__loader`,
});

const IntegrationsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const [error, setError] = useState<any>();
  const [integrationType, setIntegrationType] = useState<
    AvailableIntegrationTypes
  >();
  const [loading, setLoading] = useState(true);
  const setAdAccounts = useSetRecoilState(adAccountsResponseAtom);
  const setIntegrationError = useSetRecoilState(integrationErrorAtom);

  const onError = (error: CustomErrorProtocol) => {
    if (error instanceof ErrorMissingAccessToken) {
      cleanup();
      redirectToRoute(Routes.Settings);
    }
  };

  const handleReAuthProcess = async (
    strategy: IntegrationStrategy,
    partnerId: string,
    accessToken: string,
  ) => {
    const { accountId, channelId, integrationType } = strategy;

    if (!channelId) {
      throw new Error('Channel id is missing');
    }
    if (!accessToken) {
      throw new Error('AccessToken is missing');
    }
    await integrationsService.reAuthChannel(channelId, partnerId, accessToken);
  };

  const onRetrievedAccessToken = useCallback(
    async (
      accessToken: string,
      expirationTime: string,
      integrationType: AvailableIntegrationTypes,
      partnerId: string,
    ) => {
      setLoading(true);
      setIntegrationType(integrationType);
      try {
        const strategy = getStrategy();
        if (!strategy) {
          redirectToRoute(Routes.Index);
          return;
        }
        const isReAuthProcess = Boolean(
          strategy.channelId && strategy.accountId,
        );

        if (isReAuthProcess) {
          try {
            await handleReAuthProcess(strategy, partnerId, accessToken);

            notify({
              title: t('success'),
              body: t('settings.channel.accountAuthorized'),
              type: 'success',
            });
            redirectToRoute(Routes.Settings);

            return;
          } catch (error) {
            setError(error);
            return;
          }
        }
        const accountStrategyForGettingAdAccounts =
          mapServiceStrategyToAccountType[integrationType];
        const adAccounts = await integrationsService[
          accountStrategyForGettingAdAccounts
        ](accessToken, partnerId, isReAuthProcess);

        if (!adAccounts) {
          redirectToRoute(Routes.Index);
          return;
        }

        setAdAccounts(adAccounts);
        const updatedStrategy = {
          ...getStrategy(),
          cache: {
            ...getStrategy()?.cache,
            accessToken,
          },
        };
        setStrategy(updatedStrategy as IntegrationStrategy);
        redirectToRoute(Routes.Settings);
      } catch (error) {
        setError(error);
        setIntegrationError(error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const { cleanup } = useIntegrations({
    automaticallyStartFlow: true,
    onRetrievedAccessToken,
    onError,
  });

  const actions: IntegrationErrorAction[] = [
    {
      labelId: 'back',
      handler: () => {
        cleanup();
        setTimeout(() => {
          redirectToRoute(Routes.Settings);
        }, 400);
      },
    },
    {
      labelId: 'retry',
      handler: () => {
        window.location.reload();
      },
      onlyFor: [ErrorRequestTimedOut],
    },
  ];

  if (!integrationType) {
    return null;
  }
  return (
    <div className="IntegrationsContainer p-xl">
      <Helmet>
        <title>{t('plume')}</title>
      </Helmet>
      <Grid>
        <GridItem colSpan="12">
          <PendingContent
            classes={addClassesToPendingContent}
            loading={loading}
            loader={Spinner}
            transparent
          >
            <IntegrationsErrorRenderer
              integrationType={integrationType}
              actions={actions}
              error={error}
            />
          </PendingContent>
        </GridItem>
      </Grid>
    </div>
  );
};

export default IntegrationsContainer;
