import React, { FunctionComponent } from 'react';
import { IntegrationErrorAction } from '../../../integrations/types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Button } from 'plume-ui';
import { Maybe } from '../../../../types';
import { CustomErrorProtocol } from '../../errors';

export type ErrorActionsRendererProps = {
  actions: Maybe<IntegrationErrorAction[]>;
  error: CustomErrorProtocol;
};

export const ErrorActionsRenderer: FunctionComponent<ErrorActionsRendererProps> = ({
  actions,
  error,
}) => {
  const visibleActions =
    actions?.filter((a) => a.onlyFor?.includes(error)) || [];
  return (
    <>
      {Boolean(visibleActions.length) && (
        <div className="IntegrationsContainer__error-actions">
          {visibleActions.map(
            (action: IntegrationErrorAction, index: number) => (
              <Button key={index} onClick={action.handler}>
                <FormattedMessage id={action.labelId} />
              </Button>
            ),
          )}
        </div>
      )}
    </>
  );
};

export default ErrorActionsRenderer;
