import { DependencyContainer } from 'DependencyContainer';
import { AxiosError } from 'axios';
import { Routes } from 'config';
import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { Form, Formik } from 'formik';
import { MixPanelEvents } from 'mixPanelEvents';
import { Button, Icons, PendingContent, notify } from 'plume-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  campaignIsFromTemplate,
  campaignLaunchComplete,
  campaignObjectivesAtom,
  campaignsForTableSelector,
  editingCampaignAtom,
  emailIntroEditInProgressAtom,
  findSingleCampaign,
  formUpdated,
} from 'store/state/campaignState';
import { brandingCompanyNameAtom } from 'store/state/configurationState';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import CrusadeCampaignForm from '../components/CrusadeCampaignForm';
import { useCampaignEmailData } from '../hooks/useCampaignEmailData';
import useCampaignForm from '../hooks/useCampaignForm';
import { useCampaignObjectives } from '../hooks/useCampaignObjectives';
import { useCrusadeJourneysAndCampaigns } from '../hooks/useCrusadeJourneysAndCampaigns';
import {
  CampaignDtoType,
  CampaignStatus,
  CampaignType,
  CrusadeCampaign,
} from '../types';
import {
  defaultCampaign,
  prepareCampaignDto,
} from '../util/campaignFormikHelpers';

export const CAMPAIGN_IN_LOCAL_STORAGE = 'newCampaign';

const CrusadeCampaignCardsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const { runFetch } = useCrusadeJourneysAndCampaigns();

  const { runFetch: fetchObjectives } = useCampaignObjectives();
  const allObjectives = useRecoilValue(campaignObjectivesAtom);

  const redirectToRoute = useRedirectToRoute();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [launchInProgress, setLaunchInProgress] = useState(false);
  const { campaignId } = useParams<{ campaignId: string }>();
  const editingCampaignSelector = useRecoilValue(
    findSingleCampaign({ id: campaignId }),
  );
  const [editingCampaign, setEditingCampaign] = useRecoilState(
    editingCampaignAtom,
  );
  const {
    getCampaignInitialValues,
    deliveryInputsValid,
    getFormValidationState,
  } = useCampaignForm();
  const companyName = useRecoilValue(brandingCompanyNameAtom);
  const partnerId = useRecoilValue(partnerIdAtom);
  const { crusadeJourneyService } = new DependencyContainer();
  const trackEvent = useTrackEvent();
  const setLaunchComplete = useSetRecoilState(campaignLaunchComplete);
  const setEditInProgress = useSetRecoilState(emailIntroEditInProgressAtom);
  const { runFetch: fetchEmailData } = useCampaignEmailData(
    editingCampaign?.campaignId,
    editingCampaign?.campaignType as CampaignType,
  );
  const [newCampaignCreated, setNewCampaignCreated] = useState(false);
  const campaignsInStore = useRecoilValue(campaignsForTableSelector);
  const fromTemplate = useRecoilValue(campaignIsFromTemplate);
  const [formFieldsUpdated, setFormFieldsUpdated] = useRecoilState(formUpdated);

  useEffect(() => {
    localStorage.removeItem(CAMPAIGN_IN_LOCAL_STORAGE);
  }, []);

  useEffect(() => {
    let newCampaign: CrusadeCampaign | undefined;

    if (newCampaignCreated) {
      const localStorageCampaign = JSON.parse(
        localStorage.getItem(CAMPAIGN_IN_LOCAL_STORAGE)!,
      );

      newCampaign = campaignsInStore.find(
        (campaign) => campaign.campaignId === localStorageCampaign.campaignId,
      );
    }

    setEditingCampaign(newCampaign || editingCampaignSelector);
  }, [editingCampaignSelector, newCampaignCreated, campaignsInStore]);

  const handleSave = async (values: CampaignDtoType) => {
    const dto = prepareCampaignDto(values as any);

    let campaign;
    try {
      if (editingCampaign) {
        if (partnerId) {
          if (editingCampaign.campaignType === 'pushNotification') {
            setSaveInProgress(true);

            campaign = await crusadeJourneyService.editCrusadeCampaign(
              partnerId,
              editingCampaign.campaignId!,
              dto,
            );

            setLaunchComplete(true);

            notify({
              id: 'campaignEdited',
              title: t('crusade.campaigns.campaignSaved'),
              body: '',
              type: 'success',
              showDismiss: false,
              mustDismiss: false,
            });
            trackEvent({
              eventName: MixPanelEvents.CAMPAIGN_SAVE_SUCCESS,
              additionalContent: {
                CAMPAIGN_ID: editingCampaign.campaignId,
                CAMPAIGN_TYPE: 'pushNotification',
              },
            });
          } else {
            setSaveInProgress(true);

            campaign = await crusadeJourneyService.editCrusadeCampaign(
              partnerId,
              dto.campaignId!,
              dto,
            );

            setLaunchComplete(true);

            notify({
              id: 'campaignEdited',
              title: t('crusade.campaigns.campaignSaved'),
              body: '',
              type: 'success',
              showDismiss: false,
              mustDismiss: false,
            });
            trackEvent({
              eventName: MixPanelEvents.CAMPAIGN_SAVE_SUCCESS,
              additionalContent: {
                CAMPAIGN_ID: editingCampaign.campaignId,
                CAMPAIGN_TYPE: 'email',
              },
            });

            fetchEmailData();
          }
        }
      } else {
        // new push notification campaign
        if (partnerId) {
          setSaveInProgress(true);

          campaign = await crusadeJourneyService.createCrusadePushCampaign(
            partnerId,
            dto,
          );

          setLaunchComplete(true);

          localStorage.setItem(
            CAMPAIGN_IN_LOCAL_STORAGE,
            JSON.stringify(campaign.data),
          );
          runFetch();
          setNewCampaignCreated(true);

          notify({
            id: 'campaignSaved',
            title: t('crusade.campaigns.campaignSaved'),
            body: '',
            type: 'success',
            showDismiss: false,
            mustDismiss: false,
          });
          trackEvent({
            eventName: MixPanelEvents.CAMPAIGN_SAVE_SUCCESS,
            additionalContent: {
              CAMPAIGN_TYPE: 'pushNotification',
            },
          });
        }
      }
      await fetchObjectives();
      await runFetch();
      setSaveInProgress(false);
      setEditInProgress(false);
      setFormFieldsUpdated(false);
      return campaign.data;
    } catch (error) {
      setSaveInProgress(false);
      setEditInProgress(false);

      const mappedError = error as AxiosError;

      if (mappedError.response?.data.message) {
        notify({
          title: mappedError.response?.data.message,
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_SAVE_FAILURE,
          additionalContent: {
            CAMPAIGN_TYPE: 'pushNotification',
            ERROR_MESSAGE: mappedError.response?.data.message,
          },
        });
      } else {
        notify({
          title: t('crusade.campaigns.campaignSaveFailed'),
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_SAVE_FAILURE,
          additionalContent: {
            CAMPAIGN_TYPE: 'pushNotification',
            ERROR_MESSAGE: 'unknown',
          },
        });
      }
    }
  };

  const handleLaunchOrPause = async (values: CampaignDtoType) => {
    const failureToastTitle = (campaign: any): string => {
      if (!campaign) {
        return t('somethingWentWrong');
      }

      if (campaign.status === CampaignStatus.Active) {
        return t('crusade.campaigns.campaignPauseFailed');
      } else if (
        [CampaignStatus.Draft, CampaignStatus.Paused].includes(campaign.status)
      ) {
        return t('crusade.campaigns.campaignLaunchFailed');
      } else {
        return t('somethingWentWrong');
      }
    };

    if (editingCampaign) {
      let campaign = editingCampaign;
      // We want to save edits but avoid duplicate calls to the save API
      if (
        editingCampaign.status !== CampaignStatus.Active &&
        formFieldsUpdated
      ) {
        campaign = await handleSave(values);
      }

      if (campaign) {
        setLaunchInProgress(true);
        const changeCampaignStatus = async () => {
          return await crusadeJourneyService.launchCrusadePushNotification(
            partnerId,
            editingCampaign.campaignId,
            campaign.status === CampaignStatus.Active
              ? CampaignStatus.Paused
              : CampaignStatus.Active,
          );
        };
        changeCampaignStatus()
          .then((response: any) => {
            if (response) {
              const successToastTitle = (): string => {
                if (editingCampaign.status === CampaignStatus.Active) {
                  return t('crusade.campaigns.campaignPaused');
                } else if (
                  [CampaignStatus.Draft, CampaignStatus.Paused].includes(
                    editingCampaign.status,
                  )
                ) {
                  return t('crusade.campaigns.campaignLaunched');
                } else {
                  return t('success');
                }
              };

              setLaunchComplete(true);
              runFetch();

              notify({
                id: 'campaignLaunched',
                title: successToastTitle(),
                body: '',
                type: 'success',
                showDismiss: false,
                mustDismiss: false,
              });

              trackEvent({
                eventName: MixPanelEvents.CAMPAIGN_LAUNCH_SUCCESS,
                additionalContent: {
                  CAMPAIGN_ID: campaign.campaignId,
                },
              });
              setLaunchInProgress(false);
              if (
                [CampaignStatus.Draft, CampaignStatus.Paused].includes(
                  campaign.status,
                )
              ) {
                redirectToRoute(Routes.CrusadeCampaigns);
              }
            }
          })
          .catch((error) => {
            const mappedError = error as AxiosError;

            if (
              mappedError.response?.data.message &&
              typeof mappedError.response?.data.message !== 'object'
            ) {
              notify({
                title: failureToastTitle(campaign),
                body: mappedError.response?.data.message,
                type: 'error',
                showDismiss: false,
                mustDismiss: false,
              });
              trackEvent({
                eventName: MixPanelEvents.CAMPAIGN_LAUNCH_FAILURE,
                additionalContent: {
                  CAMPAIGN_ID: editingCampaign.campaignId,
                  CAMPAIGN_TYPE: editingCampaign.campaignType,
                  ERROR_MESSAGE: mappedError.response?.data?.message
                    ? mappedError.response?.data.message
                    : 'unknown',
                },
              });
            } else {
              notify({
                title: failureToastTitle(campaign),
                body: '',
                type: 'error',
                showDismiss: false,
                mustDismiss: false,
              });
              trackEvent({
                eventName: MixPanelEvents.CAMPAIGN_SAVE_FAILURE,
                additionalContent: {
                  CAMPAIGN_ID: editingCampaign.campaignId,
                  CAMPAIGN_TYPE: editingCampaign.campaignType,
                  ERROR_MESSAGE: 'no campaign',
                },
              });
            }
            setLaunchInProgress(false);
          });
        setEditingCampaign(defaultCampaign(companyName));
      } else {
        notify({
          title: failureToastTitle(campaign),
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_LAUNCH_FAILURE,
          additionalContent: {
            CAMPAIGN_ID: editingCampaign.campaignId,
            CAMPAIGN_TYPE: editingCampaign.campaignType,
            ERROR_MESSAGE: 'no campaign',
          },
        });
        setLaunchInProgress(false);
      }
      await runFetch();
    } else {
      // new push notification campaign
      let campaign: CrusadeCampaign;

      const localStorageCampaign = localStorage.getItem(
        CAMPAIGN_IN_LOCAL_STORAGE,
      );

      if (localStorageCampaign) {
        campaign = JSON.parse(localStorageCampaign);
      } else {
        campaign = await handleSave(values);
      }

      if (campaign) {
        setLaunchInProgress(true);
        const launchCampaign = async () => {
          return await crusadeJourneyService.launchCrusadePushNotification(
            partnerId,
            campaign.campaignId!,
            CampaignStatus.Active,
          );
        };

        launchCampaign()
          .then((response: any) => {
            if (response) {
              setLaunchComplete(true);

              notify({
                id: 'campaignLaunched',
                title: t('crusade.campaigns.campaignLaunched'),
                body: '',
                type: 'success',
                showDismiss: false,
                mustDismiss: false,
              });

              setLaunchInProgress(false);

              trackEvent({
                eventName: MixPanelEvents.CAMPAIGN_LAUNCH_SUCCESS,
                additionalContent: {
                  CAMPAIGN_ID: response.campaignId,
                },
              });
              setEditingCampaign(defaultCampaign(companyName));
              redirectToRoute(Routes.CrusadeCampaigns);
            }
          })
          .catch((error) => {
            const mappedError = error as AxiosError;
            notify({
              title: failureToastTitle(campaign),
              body: mappedError.response?.data.message,
              type: 'error',
              showDismiss: false,
              mustDismiss: false,
            });
            trackEvent({
              eventName: MixPanelEvents.CAMPAIGN_LAUNCH_FAILURE,
              additionalContent: {
                ERROR_MESSAGE: mappedError.response?.data?.message
                  ? mappedError.response?.data.message
                  : 'unknown',
              },
            });
            setLaunchInProgress(false);
          });
        await runFetch();
      } else {
        notify({
          title: failureToastTitle(campaign),
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_LAUNCH_FAILURE,
          additionalContent: {
            ERROR_MESSAGE: 'no campaign',
          },
        });
        setLaunchInProgress(false);
      }
    }
  };

  const handleBack = () => {
    localStorage.removeItem(CAMPAIGN_IN_LOCAL_STORAGE);
    setEditingCampaign(defaultCampaign(companyName));
    redirectToRoute(Routes.CrusadeCampaigns);
  };

  const renderLaunchOrPause = () => {
    if (editingCampaign && editingCampaign.status === CampaignStatus.Active) {
      return t('crusade.campaigns.pauseCampaign');
    } else {
      return t('crusade.campaigns.launchCampaign');
    }
  };

  const getCampaignType = () => {
    return editingCampaign
      ? (editingCampaign.campaignType as CampaignType)
      : CampaignType.PUSH_NOTIFICATION;
  };

  const renderHeader = () => {
    if (fromTemplate) {
      return (
        <div className="CrusadeCampaignCardsContainer__heading">
          {t('crusade.campaigns.createCampaignFromTemplate')}
        </div>
      );
    } else if (editingCampaign) {
      return (
        <div className="CrusadeCampaignCardsContainer__heading">
          {t('crusade.campaigns.editCampaign')}
        </div>
      );
    } else {
      return (
        <div className="CrusadeCampaignCardsContainer__heading">
          {t('crusade.campaigns.createNewCampaign')}
        </div>
      );
    }
  };

  const renderSaveButton = () => {
    return fromTemplate || !editingCampaign ? t('saveDraft') : t('save');
  };

  return (
    <div className="CrusadeCampaignCardsContainer">
      <PendingContent
        loading={
          launchInProgress ||
          saveInProgress ||
          (!!campaignId && !editingCampaignSelector?.campaignId)
        }
      >
        <Formik
          initialValues={getCampaignInitialValues()}
          enableReinitialize
          onSubmit={handleSave}
        >
          {({ values }) => (
            <Form>
              <div className="CrusadeCampaignCardsContainer__headerBtnsContainer">
                <div className="CrusadeCampaignCardsContainer__backBtnContainer">
                  <Button
                    styleVariant="tertiary-grey"
                    icon={<Icons.ArrowLeftIcon />}
                    onClick={handleBack}
                  >
                    {t('back')}
                  </Button>
                </div>

                {renderHeader()}
                <div className="CrusadeCampaignCardsContainer__actionBtnsContainer">
                  <Button
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} CrusadeCampaignCardsContainer__actionBtnsContainer__saveBtn`,
                    })}
                    styleVariant="secondary"
                    type="submit"
                    disabled={
                      !values.campaignInformationCard.campaignName ||
                      !deliveryInputsValid(values) ||
                      saveInProgress
                    }
                  >
                    {renderSaveButton()}
                  </Button>
                  <Button
                    styleVariant="superprimary"
                    disabled={
                      !!getFormValidationState(values).subSectionsObj.length ||
                      launchInProgress ||
                      saveInProgress
                    }
                    onClick={() => handleLaunchOrPause(values)}
                    tooltip={{
                      position: 'below',
                      ...getFormValidationState(values),
                    }}
                    notifications={
                      getFormValidationState(values).subSectionsObj.length ||
                      undefined
                    }
                  >
                    {renderLaunchOrPause()}
                  </Button>
                </div>
              </div>
              <CrusadeCampaignForm campaignType={getCampaignType()} />
            </Form>
          )}
        </Formik>
      </PendingContent>
    </div>
  );
};

export default CrusadeCampaignCardsContainer;
