import {
  CampaignHistory,
  NpsResponseType,
  NpsTimeRangeEnum,
  OverviewTimeRange,
  OverviewTimeRangeEnum,
  SegmentHistory,
} from 'features/overview/types';
import { atom } from 'recoil';

export const segmentOverviewGlobalTimeRangeAtom = atom<OverviewTimeRange>({
  key: 'segmentOverviewGlobalTimeRangeAtom',
  default: {
    label: OverviewTimeRangeEnum.SEVEN_DAYS,
    value: 7,
  },
});

export const campaignOverviewGlobalTimeRangeAtom = atom<OverviewTimeRange>({
  key: 'campaignOverviewGlobalTimeRangeAtom',
  default: {
    label: OverviewTimeRangeEnum.SEVEN_DAYS,
    value: 7,
  },
});

export const npsOverviewGlobalTimeRangeAtom = atom<OverviewTimeRange>({
  key: 'npsOverviewGlobalTimeRangeAtom',
  default: {
    label: NpsTimeRangeEnum.THIRTY_DAYS,
    value: 30,
  },
});

export const segmentHistoryAtom = atom<SegmentHistory[]>({
  key: 'segmentHistoryAtom',
  default: [],
});

export const campaignHistoryAtom = atom<CampaignHistory[]>({
  key: 'campaignHistoryAtom',
  default: [],
});

export const campaignHistoryCounterAtom = atom<number>({
  key: 'campaignHistoryCounterAtom',
  default: 2,
});

export const fetchedChartDataCampaignIds = atom<string[]>({
  key: 'fetchedChartDataCampaignIds',
  default: [],
});

export const nps30DayHistoryAtom = atom<NpsResponseType>({
  key: 'nps30DayHistoryAtom',
  default: {} as NpsResponseType,
});

export const npsAllTimeHistoryAtom = atom<NpsResponseType>({
  key: 'npsAllTimeHistoryAtom',
  default: {} as NpsResponseType,
});
