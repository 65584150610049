import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { campaignConfigEmailDomainListAtom } from 'store/state/configurationState';

type UseCampaignConfigEmailDomainListValues = {
  loading: boolean;
  error: any;
};

const { configurationService } = new DependencyContainer();

export const useCampaignConfigEmailDomainList = (): UseCampaignConfigEmailDomainListValues => {
  const setCampaignConfigEmailDomainList = useSetRecoilState(
    campaignConfigEmailDomainListAtom,
  );
  const partnerId = useRecoilValue(partnerIdAtom);

  const [loading, , error] = useFetchData(
    async () => {
      return await configurationService.getCampaignConfigEmailDomainList(
        partnerId,
      );
    },
    (response) => {
      setCampaignConfigEmailDomainList(response.data);
    },
    [partnerId],
  );

  return {
    loading,
    error,
  };
};
