import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../../../config';
import { TrackingUser } from '../types';
import { MixPanelEvents } from '../../../mixPanelEvents';

export class MixpanelService {
  private enabled: boolean = false;
  private static mixPanelInstance: MixpanelService;

  public static getInstance() {
    return this.mixPanelInstance || (this.mixPanelInstance = new this());
  }

  private constructor() {
    mixpanel.init(MIXPANEL_ID, { persistence: 'localStorage' });
    this.enabled = true;
  }

  public storeEvent(id: MixPanelEvents, action: any = {}): void {
    if (this.enabled) {
      mixpanel.track(id, action);
    }
  }

  public setUser(user: TrackingUser): void {
    if (this.enabled) {
      mixpanel.identify(user.emailAddress);
      mixpanel.people.set({
        $email: user.emailAddress,
        name: user.emailAddress,
        'Cloud ID': user.id,
        'Cloud Role': user.role,
      });
      mixpanel.register({
        CLOUD_EMAIL: user.emailAddress,
        CLOUD_ID: user.id,
        CLOUD_ROLE: user.role,
      });
    }
  }

  public setRole(role: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_ROLE: role });
    }
  }

  public setEnvironment(environment: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_ENV: environment });
    }
  }

  public setPartnerId(partnerId: string): void {
    if (this.enabled) {
      mixpanel.register({
        CLOUD_PARTNER: partnerId,
      });
    }
  }

  public setLocation(locationId: string): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_LOCATION: locationId });
    }
  }

  public clearCustomer(): void {
    if (this.enabled) {
      mixpanel.register({ CLOUD_CUSTOMER: null, CLOUD_LOCATION: null });
    }
  }

  public clearUserAndCustomerDetails(): void {
    if (this.enabled) {
      mixpanel.reset();
    }
  }

  public getDistinctId(): string {
    return mixpanel.get_distinct_id();
  }
}
