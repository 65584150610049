import React, { FunctionComponent, useContext } from 'react';
import { AddChannelModalContext } from './AddChannelModalContext';
import {
  AddChannelModalSubTabs,
  AddChannelModalTabs,
  AvailableIntegrationTypes,
  AvailableModalActionViews,
} from '../../types';
import { Button } from 'plume-ui';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import GoogleSignIn from '../../../../assets/png/btn_google_signin.png';

export const AddChannelModalFooter: FunctionComponent = () => {
  const context = useContext(AddChannelModalContext);
  const selectedAdAccountId = context.getSelectedAccountId();

  const renderFooterForTest = () => {
    return (
      <>
        <div className="AddChannelModal__backButton">
          <Button
            styleVariant="tertiary-grey"
            onClick={() =>
              context.handleSteps(
                AvailableModalActionViews.BackToConfigureAccount,
              )
            }
          >
            <FormattedMessage id="back" />
          </Button>
        </div>
        <Button
          styleVariant="superprimary"
          onClick={() => context.handleFinish()}
        >
          <FormattedMessage id="finish" />
        </Button>
      </>
    );
  };

  const renderConnectButton = () => {
    return context.selectedAccountType === AvailableIntegrationTypes.Google ? (
      <img
        className="AddChannelModal__googleSignin-btn"
        src={GoogleSignIn}
        alt="sign-in button"
        onClick={() =>
          context.handleSteps(AvailableModalActionViews.ConnectToConfigure)
        }
      />
    ) : (
      <Button
        styleVariant="superprimary"
        onClick={() =>
          context.handleSteps(AvailableModalActionViews.ConnectToConfigure)
        }
      >
        <FormattedMessage id="connect" />
      </Button>
    );
  };

  const renderFooterForConfigure = () => {
    const nextButtonCopyId = context.isRequestInProgress ? 'loading' : 'next';
    return (
      <>
        {context.activeSubTabName === AddChannelModalSubTabs.SelectAccount ? (
          <>
            <div className="AddChannelModal__backButton">
              <Button
                styleVariant="tertiary-grey"
                onClick={() =>
                  context.handleSteps(AvailableModalActionViews.BackToConfigure)
                }
              >
                <FormattedMessage id="back" />
              </Button>
            </div>
            <Button
              styleVariant="superprimary"
              disabled={!selectedAdAccountId}
              onClick={() =>
                context.handleSteps(AvailableModalActionViews.GoToTest)
              }
            >
              <FormattedMessage id={nextButtonCopyId} />
            </Button>
          </>
        ) : (
          <>
            <div className="AddChannelModal__backButton">
              <Button
                styleVariant="tertiary-grey"
                onClick={() =>
                  context.handleSteps(AvailableModalActionViews.BackToChoose)
                }
              >
                <FormattedMessage id="back" />
              </Button>
            </div>
            {renderConnectButton()}
          </>
        )}
      </>
    );
  };

  const renderer =
    context.activeTabName === AddChannelModalTabs.Configure
      ? renderFooterForConfigure()
      : context.activeTabName === AddChannelModalTabs.Test
      ? renderFooterForTest()
      : () => null;

  return <>{renderer}</>;
};

export default AddChannelModalFooter;
