import React, { FunctionComponent, useContext } from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Logo } from 'plume-ui';
import { AvailableIntegrationTypes } from '../../types';
import { AddChannelModalContext } from './AddChannelModalContext';
import {
  mapSelectedIntegrationToConfigureSubtitle,
  mapSelectedIntegrationToConfigureTitle,
  mapSelectedIntegrationToIcon,
} from './AddChannelConfigure';

const AddChannelIntroduction: FunctionComponent = () => {
  const context = useContext(AddChannelModalContext);
  const Icon =
    mapSelectedIntegrationToIcon[
      context.selectedAccountType as AvailableIntegrationTypes
    ] || React.Fragment;
  return (
    <div>
      <p className="AddChannelModal__adsconfigureTitle">
        <FormattedMessage
          id={
            mapSelectedIntegrationToConfigureTitle[
              context.selectedAccountType as AvailableIntegrationTypes
            ]
          }
        />
      </p>
      <p className="AddChannelModal__adsconfigureSubtitle">
        <FormattedMessage
          id={
            mapSelectedIntegrationToConfigureSubtitle[
              context.selectedAccountType as AvailableIntegrationTypes
            ]
          }
        />
      </p>
      <div className="AddChannelModal__iconWrapper">
        <Logo />
        <div className="AddChannelModal__dashedBorder"></div>
        <Icon className="icon" />
      </div>
    </div>
  );
};

export default AddChannelIntroduction;
