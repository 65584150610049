import React, { FunctionComponent } from 'react';
import { Icons } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'utils/components/FormattedMessage';

const PlaceholderChart: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="PlaceholderChart">
      <Icons.PlaceHolderGraphIcon className="PlaceholderChart__icon" />
      <div className="PlaceholderChart__header">
        <FormattedMessage id="noResultsChartPlaceholderHeader" />
      </div>
      <div className="PlaceholderChart__subheader">
        <FormattedMessage id="noResultsChartPlaceholderSubheader" />
      </div>
    </div>
  );
};

export default PlaceholderChart;
