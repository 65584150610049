import { Introspect } from 'store/state/introspect';
import { DependencyContainer } from '../../../DependencyContainer';

export default class IntrospectService {
  constructor(private readonly factory: DependencyContainer) {}

  async getIntrospect(): Promise<Introspect> {
    return await this.factory.introspectClient.fetchIntrospect();
  }
}
