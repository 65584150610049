import { FormikValues } from 'formik';
import { useRecoilValue } from 'recoil';
import { editingCampaignAtom } from 'store/state/campaignState';
import { brandingCompanyNameAtom } from 'store/state/configurationState';
import {
  CampaignStatus,
  CampaignType,
  DeliveryRecurrenceEnum,
  EndRecurrenceConditionEnum,
  FormValidationState,
  FormValidationSubState,
} from '../types';
import {
  getCampaignDefault,
  prepareFormData,
} from '../util/campaignFormikHelpers';
import {
  formatErrors,
  getCardErrors,
  isAudienceCardValid,
  isEmailMessageCardValid,
  isInformationCardValid,
  isPushMessageCardValid,
} from '../util/campaignValidationHelpers';
import {
  isValidDateFormat,
  isValidTimeFormat,
  startInputIsAfterCurrentTime,
} from '../util/crusadeHelpers';
import i18next from 'i18next';

export type UseCampaignFormValues = {
  getCampaignInitialValues: () => any;
  deliveryInputsValid: (values: any) => boolean;
  getFormValidationState: (values: FormikValues) => FormValidationState;
};

const useCampaignForm = (): UseCampaignFormValues => {
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const brandingCompanyName = useRecoilValue(brandingCompanyNameAtom);
  const companyName =
    brandingCompanyName !== undefined || brandingCompanyName === ''
      ? brandingCompanyName
      : undefined;
  const defaults = getCampaignDefault(companyName);

  const getCampaignInitialValues = () => {
    if (editingCampaign) {
      const preparedCampaign = prepareFormData(editingCampaign, defaults);
      return preparedCampaign;
    } else {
      return defaults;
    }
  };

  const deliveryInputsValid = (values: FormikValues): boolean => {
    const startDate = values.campaignDeliveryCard.delivery.startDate;
    const startTime = values.campaignDeliveryCard.delivery.startTime;
    const endTime = values.campaignDeliveryCard.delivery.endTime;
    const endRecurrenceCondition =
      values.campaignDeliveryCard.delivery.endRecurrence?.condition;
    const endRecurrenceDate =
      values.campaignDeliveryCard.delivery.endRecurrence?.endDate;
    const frequency = values.campaignDeliveryCard.delivery.frequency;
    const weekly = values.campaignDeliveryCard.delivery.weekly;

    const validationType: DeliveryRecurrenceEnum = values.campaignDeliveryCard
      .delivery.recurrence as DeliveryRecurrenceEnum;

    let deliveryCardValid: boolean = false;
    let endingParamsValid: boolean = false;

    let startInputValid: boolean;
    if (values.campaignInformationCard.status === CampaignStatus.Active) {
      startInputValid =
        isValidDateFormat(startDate) && isValidTimeFormat(startTime);
    } else {
      startInputValid =
        isValidDateFormat(startDate) &&
        isValidTimeFormat(startTime) &&
        startInputIsAfterCurrentTime(startDate, startTime);
    }

    const frequencyValid =
      frequency !== undefined && frequency >= 1 && frequency <= 30;

    // validating the delivery.endRecurrence object
    if (endRecurrenceCondition === EndRecurrenceConditionEnum.NEVER_ENDS) {
      endingParamsValid = true;
    } else if (
      endRecurrenceCondition === EndRecurrenceConditionEnum.ON_SPECIFIC_DATE &&
      endRecurrenceDate !== undefined &&
      startDate < endRecurrenceDate
    ) {
      endingParamsValid = true;
    }

    if (
      values.campaignDeliveryCard.immediately &&
      !values.campaignDeliveryCard.warmup
    ) {
      deliveryCardValid = true;
    } else if (values.campaignDeliveryCard.warmup === true) {
      const endDateValid =
        isValidDateFormat(values.campaignDeliveryCard.delivery.endDate) &&
        values.campaignDeliveryCard.delivery.startDate <
          values.campaignDeliveryCard.delivery.endDate;
      const ipWarmupDatesValid =
        startInputValid && endDateValid && isValidTimeFormat(endTime);

      deliveryCardValid =
        values.campaignDeliveryCard.immediately || ipWarmupDatesValid;
    } else if (validationType === DeliveryRecurrenceEnum.ONCE) {
      const frequencyValid = frequency !== undefined && frequency === 1;
      deliveryCardValid = startInputValid && frequencyValid;
    } else if (validationType === DeliveryRecurrenceEnum.DAILY) {
      deliveryCardValid =
        startInputValid && frequencyValid && endingParamsValid;
    } else if (validationType === DeliveryRecurrenceEnum.WEEKLY) {
      const weeklyValid = weekly !== undefined && weekly.length > 0;

      deliveryCardValid =
        startInputValid && frequencyValid && weeklyValid && endingParamsValid;
    } else if (validationType === DeliveryRecurrenceEnum.MONTHLY) {
      deliveryCardValid =
        startInputValid && frequencyValid && endingParamsValid;
    }

    return deliveryCardValid;
  };

  const getFormValidationState = (values: FormikValues) => {
    const campaignType = values.campaignInformationCard.campaignType;
    const campaignStatus = values.campaignInformationCard.status;
    const formState = {
      heading: i18next.t('crusade.campaigns.launchIsDisabledDueTo'),
      subSectionsObj: [] as Array<FormValidationSubState>,
    };

    if (campaignStatus === CampaignStatus.Completed) {
      formState.subSectionsObj.push({
        subheading: i18next.t('crusade.campaigns.campaign'),
        paragraph: i18next.t('crusade.campaigns.campaignHasAlreadyEnded'),
      });

      return formState;
    }

    const campaignInfoError = getCardErrors(
      'Campaign info',
      isInformationCardValid(values.campaignInformationCard.campaignName),
    );

    const audienceError = getCardErrors(
      'Audience',
      isAudienceCardValid(
        values.campaignAudienceCard.audienceSegments[0]?.segmentId,
        values.campaignAudienceCard.audienceSize,
      ),
    );

    let messageError;
    if (campaignType === CampaignType.PUSH_NOTIFICATION) {
      messageError = getCardErrors('Message', isPushMessageCardValid(values));
    } else {
      messageError = getCardErrors('Message', isEmailMessageCardValid(values));
    }

    formState.subSectionsObj = formatErrors([
      ...campaignInfoError,
      ...audienceError,
      ...messageError,
    ]);

    if (!deliveryInputsValid(values)) {
      formState.subSectionsObj.push({
        subheading: i18next.t('crusade.campaigns.deliveryCard.deliveryError'),
        paragraph: i18next.t('crusade.campaigns.deliveryCard.pleaseSeeError'),
      });
    }

    return formState;
  };

  return {
    getCampaignInitialValues,
    deliveryInputsValid,
    getFormValidationState,
  };
};

export default useCampaignForm;
