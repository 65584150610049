import i18n from 'i18next';
import i18next from 'i18next';
import format from 'date-fns/format';
import { enUS } from 'date-fns/locale';
import { fr } from 'date-fns/locale';
import { frCA } from 'date-fns/locale';
import { ja } from 'date-fns/locale';
import { pt } from 'date-fns/locale';

export const LOCALES: Record<string, Locale> = {
  'en-US': enUS,
  fr: fr,
  'fr-FR': fr,
  'fr-CA': frCA,
  ja: ja,
  pt: pt,
  'pt-PT': pt,
};

export const formatGraphDateLabel = (date: Date) => {
  const language = i18n.language;
  return format(date, 'MMM d', {
    locale: LOCALES[language],
  });
};

export const formatGraphMonthLabel = (date: Date) => {
  const language = i18n.language;
  return format(date, 'MMM', {
    locale: LOCALES[language],
  });
};

export const convertToDateObject = (dateString: string) => {
  const year = parseInt(dateString.split('-')[0]);
  const month = parseInt(dateString.split('-')[1]) - 1;
  const date = parseInt(dateString.split('-')[2]);

  return new Date(year, month, date);
};

export const getDayOfWeek = (date: Date) => {
  const days = [
    i18next.t('daysOfWeek.sunday'),
    i18next.t('daysOfWeek.monday'),
    i18next.t('daysOfWeek.tuesday'),
    i18next.t('daysOfWeek.wednesday'),
    i18next.t('daysOfWeek.thursday'),
    i18next.t('daysOfWeek.friday'),
    i18next.t('daysOfWeek.saturday'),
  ];

  return days[date.getDay()];
};
