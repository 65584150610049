import { AxiosRequestConfig, Method } from 'axios';
import { DependencyContainer } from './DependencyContainer';
import { HttpClient, HttpClientProtocol } from './HttpClient';
import { getOAuth2ApiUrl } from './environment';

export class HttpOAuth2Client extends HttpClient implements HttpClientProtocol {
  constructor(protected readonly factory: DependencyContainer) {
    super(factory);
  }

  protected async getAxiosRequestConfig(
    method: Method,
    endpoint: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> {
    const currentConfig = await super.getAxiosRequestConfig(
      method,
      endpoint,
      configOverwrite,
    );
    return Promise.resolve({
      ...currentConfig,
      baseURL: getOAuth2ApiUrl(),
    });
  }
}
