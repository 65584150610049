import { AxiosResponse } from 'axios';
import { DependencyContainer } from 'DependencyContainer';
import { HttpClient } from 'HttpClient';
import { CrusadeApiUrls, HarvestApiUrls } from 'urls';
import { SegmentHistoryParams } from '../types';

export default class OverviewClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getSegmentGrowthHistory(
    partnerId: string,
    segmentHistoryParams: SegmentHistoryParams,
  ): Promise<any> {
    const clientRes = await this.post<AxiosResponse<any>>(
      HarvestApiUrls.getSegmentGrowthHistory(partnerId),
      segmentHistoryParams,
    );

    return clientRes;
  }

  async getCampaignGrowthHistory(partnerId: string): Promise<any> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getCampaignGrowthHistory(partnerId),
    );
  }

  async getIndividualCampaignHistory(
    partnerId: string,
    campaignId: string,
    daysAgo: number,
  ): Promise<any> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getIndividualCampaignHistory(
        partnerId,
        campaignId,
        daysAgo,
      ),
    );
  }

  async getNps30DayHistory(
    partnerId: string,
    campaignId: string,
  ): Promise<any> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getNps30DayHistory(partnerId, campaignId),
    );
  }

  async getNpsAllTimeHistory(
    partnerId: string,
    campaignId: string,
  ): Promise<any> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getNpsAllTimeHistory(partnerId, campaignId),
    );
  }
}
