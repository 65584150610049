import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './utils/i18n';

console.log(`UI Version: ${process.env.REACT_APP_VERSION}`);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
