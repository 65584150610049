import { atom } from 'recoil';
import { Maybe } from 'types';

export type Introspect = {
  role: string;
  partnerId?: string;
  harvestGranularSuiteAccess: string[];
  name: string;
};

export const introspectAtom = atom<Maybe<Introspect>>({
  key: 'introspectAtom',
  default: undefined,
});
