import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, Heading, Icons, Tabs, Tooltip } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import AudienceList from '../components/audienceList/AudienceList';
import { Tab } from 'plume-ui/dist/components/Tabs/Tabs';
import GenerateSyncs from '../components/GenerateSyncs';
import { useHistory, useParams } from 'react-router-dom';
import { ConfigurationEnum } from 'features/crusade/types';

const AudienceLayout: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const configuration = useParams<{ activeTab: ConfigurationEnum }>();
  const history = useHistory();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Audience,
      },
    });
  }, []);

  const tabs: Tab[] = [
    {
      label: 'Segments',
      id: ConfigurationEnum.SEGMENTS,
      content: <AudienceList />,
    },
    { label: 'Syncs', id: ConfigurationEnum.SYNCS, content: <GenerateSyncs /> },
  ];

  const handleSelectTab = (tab: Tab) => {
    if (tab.id === 'segments') {
      history.push(`/segments`);
    } else {
      history.push(`/segments/${ConfigurationEnum.SYNCS}`);
    }
  };

  return (
    <div className="AudienceContainer p-xl">
      <Helmet>
        <title>{t('plume')}</title>
      </Helmet>
      <Heading
        // NEW RELIC MONITORING PLEASE DO NOT DELETE THIS ID
        testId="Generate__heading"
        level={1}
        medium
        size="xxxxl"
        classes={(current) => ({
          ...current,
          root: `${current.root} AudienceContainer__title`,
        })}
      >
        {t('generate.title')}
      </Heading>
      <Tabs
        active={configuration.activeTab === ConfigurationEnum.SYNCS ? 1 : 0}
        tabType="flatTabs"
        options={tabs}
        onSelectTab={handleSelectTab}
        classes={(current) => ({
          ...current,
          root: `${current.root} AudienceContainer__tabs`,
        })}
      />
    </div>
  );
};

export default AudienceLayout;
