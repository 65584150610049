import { GENERATE_DATE_FORMAT } from 'config';
import i18next from 'i18next';
import moment from 'moment';
import {
  HeroStats,
  CrusadeCampaign,
  CrusadeJourneys,
  CrusadeCampaignPushStatisticsResponse,
} from '../types';

export const campaignExists = (
  parsedData: CrusadeCampaign[],
  campaignId: string,
) => {
  const foundCampaign = parsedData.find((c) => c.campaignId === campaignId);
  return Boolean(foundCampaign);
};

export const makeCamelCase = (string: string) => {
  let camelCase = '';
  string.split(' ').forEach((word, idx) => {
    if (word !== '') {
      if (idx === 0) {
        const parsed =
          word.split('').splice(0, 1)[0].toLowerCase() +
          word.split('').splice(1).join('');
        camelCase = camelCase.concat(parsed);
      } else {
        const parsed =
          word.split('').splice(0, 1)[0].toUpperCase() +
          word.split('').splice(1).join('');
        camelCase = camelCase.concat(parsed);
      }
    }
  });

  return camelCase;
};

export const upOrDown = (value: number): string => {
  return value >= 0 ? 'up' : 'down';
};

export const findJourneyByIdOrName = (
  journeysInStore: CrusadeJourneys[],
  param: string,
): CrusadeJourneys => {
  let foundJourney: CrusadeJourneys | undefined;

  foundJourney = journeysInStore.find(
    (journey) => journey.journeyName === param || journey.journeyId === param,
  );

  return foundJourney?.journeyId || foundJourney?.journeyName
    ? foundJourney
    : ({} as CrusadeJourneys);
};

export const isValidDateFormat = (date: string) => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  return date !== undefined && date.match(dateRegex) !== null;
};

export const isValidTimeFormat = (time: string) => {
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  return time !== undefined && time.match(timeRegex) !== null;
};

export const convertToDateObject = (
  dateString: string,
  timeString: string,
  inputTimezone: 'local' | 'utc',
) => {
  const year = parseInt(dateString.split('-')[0]);
  const month = parseInt(dateString.split('-')[1]) - 1;
  const date = parseInt(dateString.split('-')[2]);

  const hours = parseInt(timeString.split(':')[0]);
  const minutes = parseInt(timeString.split(':')[1]);

  return inputTimezone === 'local'
    ? new Date(year, month, date, hours, minutes)
    : new Date(Date.UTC(year, month, date, hours, minutes));
};

export const startInputIsAfterCurrentTime = (
  startDate: string,
  startTime: string,
) => {
  const currentDateTime = new Date();

  const combinedStartDateTime = convertToDateObject(
    startDate,
    startTime,
    'local',
  );

  return combinedStartDateTime >= currentDateTime;
};

export const convertDateToTimezone = (
  date: string,
  time: string,
  endTimezone: 'local' | 'utc',
): { date: string; time: string } => {
  if (!date || !time) return { date: '', time: '' };
  const inputTimezone = endTimezone === 'local' ? 'utc' : 'local';
  const combinedDateTime = convertToDateObject(date, time, inputTimezone);

  let convertedDateTime;

  if (endTimezone === 'local') {
    convertedDateTime = moment
      .utc(combinedDateTime)
      .local()
      .format('YYYY-MM-DD HH:mm');
  } else {
    convertedDateTime = moment.utc(combinedDateTime).format('YYYY-MM-DD HH:mm');
  }

  const convertedDate = convertedDateTime.split(' ')[0];
  const convertedTime = convertedDateTime.split(' ')[1];

  return {
    date: convertedDate,
    time: convertedTime,
  };
};

const HTTPS = 'https';
const PLUME = 'plume';
const PLUME_SMALL_BUSINESS = 'plumeSmallBusiness';

export const isValidRedirectUrl = (input: string) => {
  if (!input) {
    return false;
  } else if (input && !input.includes('://')) {
    return false;
  } else {
    const protocol = input.split('://')[0];

    if ([HTTPS, PLUME, PLUME_SMALL_BUSINESS].includes(protocol)) {
      return true;
    } else {
      return false;
    }
  }
};

export const parseUpdatedByValue = (value: string) => {
  if (value.includes('@')) {
    return '';
  }
  const firstInitial = value.split(' ')[0].split('')[0];
  const lastInitial = value.split(' ')[1].split('')[0];
  return `${firstInitial}${lastInitial}`;
};

export const formatDateForTable = (date: string | undefined) => {
  return moment.utc(date).local().format(GENERATE_DATE_FORMAT);
};
