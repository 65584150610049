import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { nps30DayHistoryAtom } from 'store/state/overviewState';

type UseNps30DayHistoryValues = {
  loading: boolean;
  error: any;
};

const { overviewService } = new DependencyContainer();

export const useNps30DayHistory = (
  campaignId: string,
): UseNps30DayHistoryValues => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const set30DayHistory = useSetRecoilState(nps30DayHistoryAtom);

  const [loading, , error] = useFetchData(
    async () => {
      return await overviewService.getNps30DayHistory(partnerId, campaignId);
    },
    (history) => {
      set30DayHistory(history.data);
    },
    [partnerId, campaignId],
  );

  return {
    loading,
    error,
  };
};
