import React from 'react';
import wifiSvg from '../../../assets/svg/wifi.svg';
import bluetoothSvg from '../../../assets/svg/bluetooth.svg';
import flightSvg from '../../../assets/svg/flight.svg';
import locationSvg from '../../../assets/svg/location.svg';
import volumeSvg from '../../../assets/svg/volume.svg';
import dataSvg from '../../../assets/svg/data.svg';

export const wifiIcon = () => {
  return <img src={wifiSvg} alt="data icon" height={45} />;
};

export const bluetoothIcon = () => {
  return <img src={bluetoothSvg} alt="data icon" height={45} />;
};

export const flightIcon = () => {
  return <img src={flightSvg} alt="data icon" height={45} />;
};

export const locationIcon = () => {
  return <img src={locationSvg} alt="data icon" height={45} />;
};

export const volumeIcon = () => {
  return <img src={volumeSvg} alt="data icon" height={45} />;
};

export const dataIcon = () => {
  return <img src={dataSvg} alt="data icon" height={45} />;
};
