import React, { FunctionComponent } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { Button, GridItem, PendingContent, SearchInputField } from 'plume-ui';
import { Routes } from 'config';

import { syncsSearchAtom } from 'store/state/syncsState';
import { useSyncs } from '../../../syncs/hooks/useSyncs';
import SyncsTable from './SyncsTable/SyncsTable';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';

const GenerateSyncs: FunctionComponent = () => {
  const { t } = useTranslation();
  const { loading, error } = useSyncs();
  const [syncsSearch, setSyncsSearch] = useRecoilState(syncsSearchAtom);
  const redirectToRoute = useRedirectToRoute();

  const handleSearch = (searchParam: string | undefined) => {
    const searchString = searchParam === undefined ? '' : searchParam;

    setSyncsSearch(searchString);
  };

  return (
    <PendingContent loading={loading} isError={Boolean(error)}>
      <GridItem>
        <div className="GenerateSyncs__header">
          <div className="GenerateSyncs__search">
            <SearchInputField
              classes={(current) => ({
                ...current,
                root: `${current.root} GenerateSyncs__search-input`,
              })}
              name="search"
              placeholder="Search syncs"
              value={syncsSearch}
              onInput={(e) => handleSearch(e.currentTarget.value)}
              rounded
            />
          </div>
          <Button
            classes={(current) => ({
              ...current,
              root: `${current.root} GenerateSyncs__add-btn`,
            })}
            styleVariant="superprimary"
            onClick={() => redirectToRoute(Routes.CreateAudience)}
          >
            {t('generate.enterWorkspace')}
          </Button>
        </div>
        <SyncsTable />
      </GridItem>
    </PendingContent>
  );
};

export default GenerateSyncs;
