import { AxiosError } from 'axios';
import { Routes } from 'config';
import { DependencyContainer } from 'DependencyContainer';
import { Button, notify, PendingContent } from 'plume-ui';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  campaignIsFromTemplate,
  editingCampaignAtom,
} from 'store/state/campaignState';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { ReactComponent as TemplatePreviewNA } from '../../../../assets/svg/templatePreviewNA.svg';
import { CampaignTemplate } from '../../types';

export type TemplatePreviewCardProps = {
  template: CampaignTemplate;
};

const TemplatePreviewCard: FunctionComponent<TemplatePreviewCardProps> = ({
  template,
}) => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const partnerId = useRecoilValue(partnerIdAtom);
  const { campaignTemplateService } = new DependencyContainer();
  const setEditingCampaign = useSetRecoilState(editingCampaignAtom);
  const setCampaignIsFromTemplate = useSetRecoilState(campaignIsFromTemplate);
  const [createDraftInProgress, setCreateDraftInProgress] = useState(false);

  const renderThumbnail = () => {
    return template.thumbnail ? (
      <img
        src={template.thumbnail}
        alt="thumbnail"
        className="TemplatePreviewCard__thumbnail"
      />
    ) : (
      <TemplatePreviewNA className="TemplatePreviewCard__thumbnail" />
    );
  };

  const handleDraftCampaign = async () => {
    try {
      setCreateDraftInProgress(true);

      const newDraft = await campaignTemplateService.createDraftCampaignFromTemplate(
        partnerId,
        template.templateId,
      );

      setEditingCampaign(newDraft.data);
      setCampaignIsFromTemplate(true);

      setCreateDraftInProgress(false);

      redirectToRoute(Routes.EditCampaign, {
        campaignId: newDraft.data.campaignId,
      });
    } catch (error) {
      const mappedError = error as AxiosError;

      if (mappedError.response?.data.message) {
        notify({
          title: mappedError.response?.data.message,
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
      } else {
        notify({
          title: t('crusade.campaigns.campaignSaveFailed'),
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
      }
    }
    setCreateDraftInProgress(false);
  };

  return (
    <PendingContent loading={createDraftInProgress}>
      <div className="TemplatePreviewCard">
        <div className="TemplatePreviewCard__copyContainer">
          <div className="TemplatePreviewCard__name">
            {template.templateName}
          </div>
          <div className="TemplatePreviewCard__description">
            {template.templateDescription}
          </div>
        </div>
        {renderThumbnail()}
        <div className="TemplatePreviewCard__btnContainer">
          <Button
            classes={(current) => ({
              ...current,
              root: `${current.root} TemplatePreviewCard__ctaBtn`,
            })}
            styleVariant="primary"
            onClick={() => handleDraftCampaign()}
          >
            {t('crusade.templates.draftCampaign')}
          </Button>
        </div>
      </div>
    </PendingContent>
  );
};

export default TemplatePreviewCard;
