import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Button,
  GridItem,
  InputField,
  PendingContent,
  SearchInputField,
  Space,
  Spinner,
} from 'plume-ui';
import React, { ChangeEvent, FunctionComponent, useEffect } from 'react';
import { channelsAtom } from 'store/state/channelState';
import { generateKey } from '../../../utils/helpers';
import ConnectionListItem from './ConnectionListItem/ConnectionListItem';
import { useChannels } from '../../configuration/hooks/useChannels';
import useIntegrations, {
  getStrategy,
} from '../../integrations/hooks/useIntegrations';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useRecoilValue } from 'recoil';
import { AvailableIntegrationTypes, Channel } from '../../configuration/types';
import { useTranslation } from 'react-i18next';

const mapChannelTypeToIntegrationType: Record<
  string,
  AvailableIntegrationTypes
> = {
  facebookAds: AvailableIntegrationTypes.Facebook,
  googleAds: AvailableIntegrationTypes.Google,
};

const Connections: FunctionComponent = () => {
  const { showModal } = useGlobalModalContext();
  const { t } = useTranslation();
  const channels = useRecoilValue(channelsAtom);
  const { loading, error, runFetch } = useChannels();
  const {
    isIntegrationFinished,
    beginAuthorization,
    cleanup,
  } = useIntegrations({
    automaticallyStartFlow: false,
  });

  const [search, setSearch] = React.useState('');

  const onAuthorize = (channel: Channel) => {
    if (!channel.channelId) {
      return;
    }
    const integrationType =
      mapChannelTypeToIntegrationType[channel.channelType];
    beginAuthorization(integrationType, channel.channelId, channel.adAccountId);
  };

  useEffect(() => {
    if (!isIntegrationFinished()) {
      return;
    }

    const strategy = getStrategy();
    const isReAuthProcess = Boolean(strategy?.channelId && strategy.accountId);

    if (isReAuthProcess) {
      cleanup();
      return;
    }

    showModal(MODAL_TYPES.ADD_CHANNEL_MODAL, {}, MODAL_TYPES.ADD_CHANNEL_MODAL);
  }, [isIntegrationFinished]);

  return (
    <GridItem colSpan="12">
      <Space size="l" />
      <div className="ConnectionsContainer__add">
        <SearchInputField
          onInput={(e) =>
            setSearch((e as ChangeEvent<HTMLInputElement>).target.value)
          }
          rounded
          placeholder={t('connections.searchConnections')}
          classes={(current) => ({
            ...current,
            root: `${current.root} ConnectionsContainer__search`,
          })}
        />
        <Button
          styleVariant="superprimary"
          onClick={() =>
            showModal(
              MODAL_TYPES.ADD_CHANNEL_MODAL,
              {},
              MODAL_TYPES.ADD_CHANNEL_MODAL,
            )
          }
        >
          <FormattedMessage id="settings.channel.addChannel" />
        </Button>
      </div>
      <Space size="l"></Space>
      <div className="ConnectionsContainer__channels">
        {channels
          ?.filter(
            (channel) =>
              channel.channelName
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              channel.channelDescription
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              channel.adAccountId
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
              channel.channelType?.toLowerCase().includes(search.toLowerCase()),
          )
          .map((channel, id) => (
            <PendingContent
              classes={(current) => ({
                ...current,
                root: `${current.root} ConnectionsContainer__pendingContent`,
              })}
              loading={loading}
              isError={Boolean(error)}
              loader={Spinner}
              key={generateKey()}
            >
              <ConnectionListItem
                channel={channel}
                onAuthorize={() => onAuthorize(channel)}
                resetChannelData={runFetch}
                id={id}
              />
            </PendingContent>
          ))}
      </div>
    </GridItem>
  );
};

export default Connections;
