const formatValue = (val: number): string => {
  if (val > 1000000) {
    return (val / 1000000).toFixed(1) + 'm';
  }
  if (val < 1000000 && val > 100000) {
    return Math.round(val / 1000) + 'k';
  }
  if (val < 100000 && val > 1000) {
    return (val / 1000).toFixed(1) + 'k';
  }
  return `${val}`;
};

export default formatValue;

/*

    These functions are a by product of my original implementation.
    We may want to roll up/down the tree so there commented out in case of future use
    */
/*

 const findChildrenId = (aud:any): any => {
   aud.forEach((seg: any) => {
     if (!seg.childrenId) {
       seg['childrenId'] = [];
     }
     aud.forEach((child: any) => {
       if (seg.id === child.parent) {
         if (seg.childrenId.indexOf(child.id) < 0) {
           seg.childrenId.push(child.id);
         }
       }
     });
   });
   return aud;
 };


 let geoLeft:number=0;

 const layout = (tree:any) => {
   let geoLeft:number=0;



   if (!tree.position) { tree.position= {}; }
   if (!tree.position.top) { tree.position.top=0; }
   if (!tree.position.left) { tree.position.left=0; }

   tree.children.forEach((child:any) => {
     console.log('child => ',child.id, child.position);

     layout(child);
     child.position.top=tree.position.top+flowGeo.nodeH+flowGeo.nodePad;
     child.position.left=tree.position.left+geoLeft;
     geoLeft += flowGeo.nodeW + flowGeo.nodePad;
   });


   return tree;
 };

 const audArrayToTree = (aud:any,tree:any) => {
   tree.children=[];
   tree.childrenId.forEach((child:any) => {
     tree.children.push(aud[idToIndex(aud,child)]);
     audArrayToTree(aud,aud[idToIndex(aud,child)]);
   });
   return tree;
 };

 const treeToAudArray = (tree:any):any => {
   const audArray:any=[];
   treeToAud(tree,audArray);
   return audArray;
 }

 const treeToAud = ( tree:any, audArray:any ):any =>
 {
   audArray.push(tree);
   tree.children.forEach((child:any) => {
     treeToAud(child,audArray);
   });
   return audArray;
 };


 const audToTree = (aud:any) => {
   console.log('audToTree: ',aud);
   let tree=aud[0];

   if (!aud[0].position) { aud[0].position={} }

   aud[0].position={ top: flowGeo.nodePad, left: flowGeo.originCenter };

   tree=audArrayToTree(aud,tree);
   tree=layout(tree);

   return tree;
 };


 */

/*

 const TreeNodeComponent = ({ data }: any) => {
   const [flip, setFlip] = useState(false);

   const flipCard = () => {
     setFlip(!flip);
   };

   if (flip === true) {
     return (
       <div className="treeNode">
         <Handle
           type="target"
           position={Position.Top}
           style={{ borderRadius: 100 }}
         />
         <div>
           <div className="nodeHeader">
             <Tooltip
               alignment="left"
               label="Flip"
               position="above"
               style={{
                 opacity: null,
               }}
             >
               <Icons.SignOutIcon
                 width={16}
                 onClick={() => flipCard()}
                 style={{ cursor: 'pointer' }}
               />
             </Tooltip>
             <Icons.InfoIcon width={16} />
             <Icons.BehaviorIcon width={16} />
           </div>
           <div className="nodeTitle">FLIPPED</div>
         </div>
         <Handle
           type="source"
           position={Position.Bottom}
           id="a"
           style={{ top: '50%%', borderRadius: 0 }}
         />
       </div>
     );
   }

   return (
     <div className="treeNode">
       <Handle
         type="target"
         position={Position.Top}
         style={{ borderRadius: 100 }}
       />
       <div>
         <div className="nodeHeader">
           <Tooltip
             alignment="left"
             label="Flip"
             position="above"
             style={{
               opacity: null,
             }}
           >
             <SignOutIcon
               width={16}
               onClick={() => flipCard()}
               style={{ cursor: 'pointer' }}
             />
           </Tooltip>
           <Icons.InfoIcon width={16} />
           <Icons.BehaviorIcon width={16} />
         </div>
         <div className="nodeTitle">{data.text}</div>

         <div className="nodeFooter">
           <Tooltip
             alignment="left"
             label={`${data.perc}%`}
             position="above"
             style={{
               opacity: null,
             }}
           >
             <PercentageRing percentValue={data.perc} svgSize={16} />
           </Tooltip>
           {data.value}
         </div>
       </div>
       <Handle
         type="source"
         position={Position.Bottom}
         id="a"
         style={{ top: '50%%', borderRadius: 0 }}
       />
     </div>
   );
 };
 */
