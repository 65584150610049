import { AxiosResponse } from 'axios';
import { DependencyContainer } from 'DependencyContainer';
import { HarvestApiUrls } from 'urls';
import { HttpClient } from '../../../HttpClient';
import {
  EditChannelDescriptionParameter,
  EditChannelDescriptionResponse,
  ChannelsResponse,
  DeleteChannelResponse,
} from '../types';

export default class ChannelsClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getChannels(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<ChannelsResponse>>(
      HarvestApiUrls.getChannels(partnerId),
    );
  }

  async editChannelDescription(
    partnerId: string,
    channelId: string,
    parameters: EditChannelDescriptionParameter,
  ): Promise<any> {
    return this.patch<AxiosResponse<EditChannelDescriptionResponse>>(
      HarvestApiUrls.editChannelDescription(partnerId, channelId),
      parameters,
    );
  }

  async deleteChannel(partnerId: string, channelId: string): Promise<any> {
    return this.delete<AxiosResponse<DeleteChannelResponse>>(
      HarvestApiUrls.deleteChannel(partnerId, channelId),
    );
  }
}
