import {
  Button,
  Divider,
  Heading,
  IconButton,
  Icons,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  Space,
  Tooltip,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from '../../utils/helpers';
import CriteriaCollapseCard, {
  CardTypes,
  CriteriaCollectionType,
} from '../CriteriaCollapseCard/CriteriaCollapseCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { CreateAudienceContext } from '../../features/generate/audience/contexts/CreateAudienceContext';
import { useTraits } from '../../features/generate/audience/hooks/useTraits';
import { DependencyContainer } from '../../DependencyContainer';

import {
  getSegmentTreeByIdSelector,
  syncedChannelsSelector,
} from 'store/state/audienceFlowState';
import { partnerIdAtom } from 'store/state/appState';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import {
  selectedSegmentAtom,
  activeNodeAtom,
} from 'store/state/audienceFlowState';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { useAudience } from 'features/generate/audience/hooks/useAudience';
import FormattedMessage from 'utils/components/FormattedMessage';
import { setTimeout } from 'timers';
import {
  criteriaMultiviewMetadataAtom,
  segmentAudienceSizeAtom,
} from 'store/state/metadataTreeState';
import { SegmentsFooterModal } from './SegmentsFooterModal';
import { SegmentDto } from './segment.dto';
import { SegmentDtoProtocol, SegmentModeTypes } from './types';
import { omit } from '../../utils/omit';
import { Maybe, Nullable } from '../../types';
import { SegmentType } from 'features/generate/audience/types';
import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from 'mixPanelEvents';
import { AxiosError } from 'axios';
import { CardTitle } from './CardTitle';
import { UseDynamicTraits } from 'features/generate/audience/hooks/useDynamicTraits';

/**
 * criteriaCards: an array of the card types, used for rendering the cards
 * at most it will have 2 arrays, one for the include group of cards, and another
 *  for the exclude group of cards
 *
 * An example of criteriaCards:
 * [[includeSearch, narrowIncludeSearch], [excludeSearch, narrowExcludeSearch]]
 */

const defaultCriteriaCollection: CriteriaCollectionType = {
  excludeFilters: [],
  excludeNarrowFilters: [],
  includeFilters: [],
  includeNarrowFilters: [],
};

type SegmentsModalProps = {
  id: string;
  mode: SegmentModeTypes;
  segmentId: string;
  parentSegmentId?: string;
  closeEvent: () => void;
};

export const SegmentsModal: FunctionComponent<SegmentsModalProps> = (props) => {
  const { t } = useTranslation();
  const { activeSegmentType } = useContext(CreateAudienceContext); // TODO I'm unsure this is needed now
  const [segmentName, setSegmentName] = useState<string>('');
  const [inputsegmentName, setInputSegmentName] = useState<string>();
  const [criteriaCards, setCriteriaCards] = useState<CardTypes[][]>([]);
  const [showIncludeBtn, setShowIncludeBtn] = useState(false);
  const [showExcludeBtn, setShowExcludeBtn] = useState(true);
  const partnerId = useRecoilValue(partnerIdAtom);
  const trackEvent = useTrackEvent();

  const [selectedSegment, setSelectedSegment] = useRecoilState(
    selectedSegmentAtom,
  );

  const segmentSyncedChannels = useRecoilValue(
    syncedChannelsSelector({ id: selectedSegment?.segmentId || '' }),
  );

  const setActiveNode = useSetRecoilState(activeNodeAtom);
  const editSegmentTree = useRecoilValue(
    getSegmentTreeByIdSelector(selectedSegment?.segmentId || ''),
  );

  const [criteriaCollection, setCriteriaCollection] = useState<
    CriteriaCollectionType
  >({ ...defaultCriteriaCollection });
  const { showModal, hideModal, switchModal } = useGlobalModalContext();
  const [initialDto, setInitialDto] = useState<SegmentDtoProtocol>();
  // Set Criteria Cards that have already been created
  useEffect(() => {
    const totalIncludeFilters =
      editSegmentTree?.includeFilters || props.mode === SegmentModeTypes.NEW
        ? ['includeSearch' as CardTypes]
        : [];

    const totalExcludeFilters =
      editSegmentTree?.excludeFilters &&
      editSegmentTree?.excludeFilters?.length > 0
        ? ['excludeSearch' as CardTypes]
        : [];

    if (editSegmentTree && editSegmentTree?.includeNarrowFilters?.length > 0) {
      const narrowCount = editSegmentTree?.includeNarrowFilters?.length;
      const narrowFilters = Array(narrowCount).fill('narrowIncludeSearch');
      totalIncludeFilters.push(...narrowFilters);
    }
    if (editSegmentTree && editSegmentTree?.excludeNarrowFilters?.length > 0) {
      const excludeCount = editSegmentTree?.excludeNarrowFilters?.length;
      const excludeFilters = Array(excludeCount).fill('narrowExcludeSearch');
      totalExcludeFilters.push(...excludeFilters);
    }
    setCriteriaCards([totalIncludeFilters, totalExcludeFilters]);
  }, [editSegmentTree]);

  const isIncludeOrExcludeFilterEmpty = useMemo(() => {
    return (
      (criteriaCards[0]?.includes('includeSearch' as CardTypes) &&
        criteriaCollection.includeFilters.length < 1) ||
      (!criteriaCards[0]?.includes('includeSearch' as CardTypes) &&
        criteriaCards[1]?.includes('excludeSearch' as CardTypes) &&
        criteriaCollection.excludeFilters.length < 1) ||
      (criteriaCards[0]?.length < 1 && criteriaCards[1]?.length < 1)
    );
  }, [criteriaCollection, criteriaCards]);

  // ------ Deactivating node ------- //
  useEffect(() => {
    return () => {
      setActiveNode('');
    };
  }, []);

  useEffect(() => {
    if (props.mode === SegmentModeTypes.EDIT) {
      if (editSegmentTree) {
        criteriaCollection.excludeFilters = editSegmentTree.excludeFilters;
        criteriaCollection.excludeNarrowFilters =
          editSegmentTree.excludeNarrowFilters;
        criteriaCollection.includeFilters = editSegmentTree.includeFilters;
        criteriaCollection.includeNarrowFilters =
          editSegmentTree.includeNarrowFilters;
        setCriteriaCollection({ ...criteriaCollection });

        setSegmentName(editSegmentTree.segmentName);
        setInputSegmentName(editSegmentTree.segmentName);
        setInitialDto(getSegmentDto());
      }
    } else {
      if (props.mode === SegmentModeTypes.NEW) {
        setInitialDto(new SegmentDto());
      }
    }
  }, [editSegmentTree, segmentName]);

  useEffect(() => {
    if (editSegmentTree === undefined && criteriaCards[0]) {
      setCriteriaMultiviewMetadata(true);
    }
  }, [editSegmentTree, criteriaCards]);

  // Here we are ensuring the criteriaCards are updated with the current criteriaCollection
  // The final condition ensures that when a filter is removed, the corresponding card is removed (no empty cards)
  useEffect(() => {
    if (
      criteriaCards[0]?.length > 1 &&
      criteriaCollection.includeFilters.length === 0
    ) {
      const newIncludeCards = ['includeSearch' as CardTypes];

      const updatedArray = [newIncludeCards, criteriaCards[1] || []];
      setCriteriaCards(updatedArray);
    } else if (
      criteriaCards[1]?.length > 1 &&
      criteriaCollection.excludeFilters.length === 0
    ) {
      const newExcludeCards = ['excludeSearch' as CardTypes];
      const updatedArray = [criteriaCards[0] || [], newExcludeCards];

      setCriteriaCards(updatedArray);
    } else if (criteriaCards[0]?.length > 0 || criteriaCards[1]?.length > 0) {
      let includeCards = ['includeSearch' as CardTypes];
      let excludeCards = ['excludeSearch' as CardTypes];
      let updatedArray: CardTypes[][] = [];

      if (
        criteriaCollection.includeFilters.length > 0 &&
        criteriaCards[0]?.length > 0
      ) {
        const updatedIncludeNarrowCards: CardTypes[] = Array(
          criteriaCollection.includeNarrowFilters.length,
        ).fill('narrowIncludeSearch');

        includeCards.push(...updatedIncludeNarrowCards);
        updatedArray = [[...includeCards], excludeCards || []];
      }

      if (
        criteriaCollection.excludeFilters.length > 0 &&
        criteriaCards[1]?.length > 0
      ) {
        const updatedExcludeNarrowCards = Array(
          criteriaCollection.excludeNarrowFilters.length,
        ).fill('narrowExcludeSearch');

        excludeCards = [...excludeCards, ...updatedExcludeNarrowCards];
        updatedArray = [includeCards || [], [...excludeCards]];
      }

      if (criteriaCollection.includeFilters.length === 0) {
        updatedArray = [[], excludeCards || []];
      }
      if (criteriaCollection.excludeFilters.length === 0) {
        updatedArray = [includeCards || [], []];
        setShowExcludeBtn(true);
      }

      setCriteriaCards(updatedArray);
    }
  }, [criteriaCollection]);

  const [includeNarrowCardCount, setIncludeNarrowCardCount] = useState(0);
  const [excludeNarrowCardCount, setExcludeNarrowCardCount] = useState(0);
  const { loading, error } = useTraits();
  const setAudienceSize = useSetRecoilState(segmentAudienceSizeAtom);
  const [savingSegment, setSavingSegment] = useState(false);

  const getSegmentDto = (
    parentSegmentIds?: Nullable<string>[],
  ): SegmentDtoProtocol => {
    const ids: Maybe<string[]> = parentSegmentIds
      ? (parentSegmentIds
          ?.map((i) => i?.toString())
          .filter((i) => i) as string[])
      : undefined;

    let name;
    if (inputsegmentName) {
      name = inputsegmentName;
    } else if (inputsegmentName === undefined || inputsegmentName === '') {
      name = '';
    } else {
      name = segmentName;
    }

    return new SegmentDto({
      segmentName: name,
      parentSegmentIds: ids || [props.parentSegmentId],
      ...criteriaCollection,
    });
  };

  const clearModalState = () => {
    setCriteriaCards([['includeSearch']]);
    setShowIncludeBtn(false);
    setShowExcludeBtn(true);
    setCriteriaCollection({ ...defaultCriteriaCollection });
    setSelectedSegment(undefined);
    setAudienceSize(undefined);
  };

  useEffect(() => {
    const includeNarrowCardCount = criteriaCards
      .flat()
      .filter((card) => card === 'narrowIncludeSearch').length;
    const excludeNarrowCardCount = criteriaCards
      .flat()
      .filter((card) => card === 'narrowExcludeSearch').length;

    setIncludeNarrowCardCount(includeNarrowCardCount);
    setExcludeNarrowCardCount(excludeNarrowCardCount);
  }, [criteriaCards]);

  const scrollContainer = {
    marginRight: '2px',
    height: '628px',
    padding: '32px',
  };

  const { runFetch: updateSegements } = useAudience(false);

  const handleCloseModal = async () => {
    clearModalState();
    updateSegements();
    if (props.closeEvent) {
      props.closeEvent();
    }
    hideModal(MODAL_TYPES.SEGMENTS_MODAL);
  };

  const hasModalUnsavedChanges = (): boolean => {
    if (!initialDto) {
      return false;
    }
    const obsoleteDtoFieldName = 'parentSegmentIds';
    const isStillLoading = loading;
    const currentDto = getSegmentDto();
    const currentDtoToCompare = omit(currentDto, obsoleteDtoFieldName);
    const initialDtoToCompare = omit(initialDto, obsoleteDtoFieldName);
    const hasChanges =
      JSON.stringify(currentDtoToCompare) !==
      JSON.stringify(initialDtoToCompare);

    if (isStillLoading && !hasChanges) {
      return false;
    }
    return hasChanges;
  };

  const handleExplicitCancelModal = () => {
    if (hasModalUnsavedChanges()) {
      showModal(
        MODAL_TYPES.CONFIRMATION_MODAL,
        {
          title: t('unsavedChangesModal.title'),
          body: t('unsavedChangesModal.subtitle'),
          onConfirm: () => {
            setTimeout(() => {
              handleCloseModal();
            }, 50);
          },
          confirmButtonLabel: t('unsavedChangesModal.proceed'),
          isOpen: true,
        },
        MODAL_TYPES.CONFIRMATION_MODAL,
      );
    } else {
      handleCloseModal();
    }
  };

  const { audienceService } = new DependencyContainer();

  const segmentIdForDynamicMetadata = useMemo(() => {
    return props.mode === SegmentModeTypes.NEW
      ? props.parentSegmentId
      : editSegmentTree?.segmentId;
  }, [editSegmentTree, props.parentSegmentId]);

  const isNewNode = props.mode === SegmentModeTypes.NEW;

  const {
    popSize,
    queryOutput,
    loading: popSizeLoading,
    runFetch,
  } = UseDynamicTraits({
    segmentIdForDynamicMetadata: segmentIdForDynamicMetadata!,
    criteriaCollection,
    isNewNode: isNewNode,
    runOnInit: false,
    deps: [segmentIdForDynamicMetadata],
  });

  const getSegmentSize = () => {
    const segmentIdForDynamicMetadata =
      props.mode === SegmentModeTypes.NEW
        ? props.parentSegmentId
        : editSegmentTree?.segmentId;

    if (!partnerId || !segmentIdForDynamicMetadata || !hasModalUnsavedChanges) {
      return;
    }

    runFetch();
  };

  /**
   * Save a new segment
   */
  const handleSave = async () => {
    try {
      const dto = getSegmentDto();
      setSavingSegment(true);
      await audienceService.addSegment(partnerId, dto);

      notify({
        title: t('success'),
        body: t('segmentsModal.segmentAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_CREATE_SUCCESS,
        additionalContent: {
          SEGMENT_TYPE: 'standard',
          SEGMENT_NAME: segmentName,
        },
      });
      handleCloseModal();
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_CREATE_FAILURE,
        additionalContent: {
          SEGMENT_TYPE: 'standard',
          SEGMENT_NAME: segmentName,
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    } finally {
      setSavingSegment(false);
    }
  };

  const isSegmentNameEmpty = useMemo(() => {
    if (props.mode === SegmentModeTypes.EDIT) {
      return (
        (segmentName && !inputsegmentName) ||
        (segmentName && inputsegmentName && !inputsegmentName?.length)
      );
    } else {
      return !inputsegmentName?.length;
    }
  }, [inputsegmentName, segmentName]);

  /**
   * Update a segment
   */
  const handleUpdate = async () => {
    try {
      const dto = getSegmentDto(editSegmentTree?.parentSegmentIds || undefined);
      setSavingSegment(true);
      await audienceService.updateSegment(
        partnerId,
        dto,
        editSegmentTree!.segmentId,
      );
      setSavingSegment(false);

      notify({
        title: t('success'),
        body: t('segmentsModal.segmentUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_EDIT_SUCCESS,
        additionalContent: {
          SEGMENT_ID: editSegmentTree!.segmentId,
          SEGMENT_NAME: segmentName,
          SEGMENT_TYPE: 'standard',
        },
      });
      handleCloseModal();
    } catch (error) {
      setSavingSegment(false);
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_EDIT_FAILURE,
        additionalContent: {
          SEGMENT_ID: editSegmentTree!.segmentId,
          SEGMENT_NAME: segmentName,
          SEGMENT_TYPE: 'standard',
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    }
  };

  /**
   *
   * Handle Delete Segment
   */

  const handleDeleteSegment = async () => {
    try {
      await audienceService.deleteSegment(
        partnerId,
        editSegmentTree!.segmentId,
      );
      notify({
        title: t('success'),
        body: t('segmentsModal.segmentDeleted'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_DELETE_SUCCESS,
        additionalContent: {
          SEGMENT_ID: editSegmentTree!.segmentId,
          SEGMENT_NAME: segmentName,
          SEGMENT_TYPE: 'standard',
        },
      });
      handleCloseModal();
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      const mappedError = error as AxiosError;
      trackEvent({
        eventName: MixPanelEvents.SEGMENT_DELETE_FAILURE,
        additionalContent: {
          SEGMENT_ID: editSegmentTree!.segmentId,
          SEGMENT_NAME: segmentName,
          SEGMENT_TYPE: 'standard',
          ERROR_MESSAGE: mappedError.response?.data.error,
        },
      });
    }
  };

  // 1. The Include card always renders at the top of the list of cards
  // 2. The Include btn will appear if the card has been deleted
  const handleAddIncludeOrExclude = (cardType: CardTypes) => {
    const currentCriteriaCards = [...criteriaCards];
    if (cardType === 'includeSearch') {
      setShowIncludeBtn(false);
      currentCriteriaCards[0].push('includeSearch');
    } else {
      setShowExcludeBtn(false);
      if (currentCriteriaCards[1]) {
        currentCriteriaCards[1].push(cardType);
      } else {
        currentCriteriaCards.push([cardType]);
      }
    }

    setCriteriaCards(currentCriteriaCards);
  };

  /**
   * 1. Differentiate between a nested and top level Narrow card
   * nested -> When Include and Exclude have already been defined, and we need to insert
   *           a narrow card into the Include set of cards
   * topLevel -> Adding a Narrow card to Include when there is no Exclude
   *           OR
   *           Adding a narrow card to Exclude
   * 2. Prevent user from adding more than 15 cards, since the indentation on the AND divider
   *    will make it run off the modal
   */
  const handleAddNarrow = (level: 'topLevel' | 'nested', groupIdx?: number) => {
    const currentCriteriaCards = [...criteriaCards];
    const includeNarrowCardCount = criteriaCards
      .flat()
      .filter((card) => card === 'narrowIncludeSearch').length;
    const excludeNarrowCardCount = criteriaCards
      .flat()
      .filter((card) => card === 'narrowExcludeSearch').length;

    if (
      groupIdx !== undefined &&
      level === 'nested' &&
      includeNarrowCardCount < 15
    ) {
      currentCriteriaCards[groupIdx].push('narrowIncludeSearch');
    } else if (
      criteriaCollection['includeFilters'].length > 0 &&
      !criteriaCards[1]?.length &&
      includeNarrowCardCount < 15
    ) {
      currentCriteriaCards[0].push('narrowIncludeSearch');
    } else if (
      criteriaCollection['excludeFilters'].length > 0 &&
      excludeNarrowCardCount < 15
    ) {
      currentCriteriaCards[1].push('narrowExcludeSearch');
    }

    setCriteriaCards(currentCriteriaCards);
  };

  const handleRemoveCard = (
    cardType: CardTypes,
    groupIdx: number,
    cardIdx: number,
  ) => {
    const currentCriteriaCards = [...criteriaCards];
    const currentCriteriaCollection = JSON.parse(
      JSON.stringify(criteriaCollection),
    );

    if (cardType === 'includeSearch') {
      setShowIncludeBtn(true);
      currentCriteriaCards.splice(groupIdx, 1, []);
      currentCriteriaCollection.includeFilters = [];
      currentCriteriaCollection.includeNarrowFilters = [];
    } else if (cardType === 'excludeSearch') {
      setShowExcludeBtn(true);
      currentCriteriaCards.splice(groupIdx, 1, []);
      currentCriteriaCollection.excludeFilters = [];
      currentCriteriaCollection.excludeNarrowFilters = [];
    } else {
      currentCriteriaCards[groupIdx].splice(cardIdx, 1);

      if (
        cardType === 'narrowIncludeSearch' &&
        currentCriteriaCollection.includeNarrowFilters[cardIdx - 1]
      ) {
        currentCriteriaCollection.includeNarrowFilters.splice(cardIdx - 1, 1);
      } else if (
        cardType === 'narrowExcludeSearch' &&
        currentCriteriaCollection.excludeNarrowFilters[cardIdx - 1]
      ) {
        currentCriteriaCollection.excludeNarrowFilters.splice(cardIdx - 1, 1);
      }
    }

    setCriteriaCards(currentCriteriaCards);
    setCriteriaCollection(currentCriteriaCollection);
  };

  const handleShowSyncModal = () => {
    if (hasModalUnsavedChanges()) {
      showModal(
        MODAL_TYPES.CONFIRMATION_MODAL,
        {
          title: t('unsavedChangesModal.title'),
          body: t('unsavedChangesModal.subtitle'),
          onConfirm: () => {
            setTimeout(() => {
              switchModal(
                MODAL_TYPES.SEGMENTS_MODAL,
                MODAL_TYPES.SYNC_PREFERENCES_MODAL,
                {
                  readOnly: false,
                  activeSegmentName: selectedSegment?.segmentName || '',
                },
                MODAL_TYPES.SYNC_PREFERENCES_MODAL,
              );
            }, 50);
          },
          confirmButtonLabel: t('unsavedChangesModal.proceed'),
        },
        MODAL_TYPES.CONFIRMATION_MODAL,
      );
    } else {
      switchModal(
        MODAL_TYPES.SEGMENTS_MODAL,
        MODAL_TYPES.SYNC_PREFERENCES_MODAL,
        {
          readOnly: false,
          activeSegmentName: selectedSegment?.segmentName || '',
        },
        MODAL_TYPES.SYNC_PREFERENCES_MODAL,
      );
    }
  };
  const setCriteriaMultiviewMetadata = useSetRecoilState(
    criteriaMultiviewMetadataAtom,
  );
  const showNarrowIncludeBtn = (cardType: CardTypes) => {
    /**
     * First criteria
     * 1. Include Filter has a criteria
     * 2. There are no narrowIncludeSearch or narrowExcludeSearch cards (since we don't want duplicate buttons)
     * 3. cardType !== excludeSearch because that case is handled on line 757
     *
     * Second criteria
     * 1. If the cardType is narrowIncludeSearch, we want to show the btn because it has to appear above the divider and
     *    won't be captured by line 757
     */
    if (
      criteriaCollection['includeFilters'].length > 0 &&
      !criteriaCards[0]?.includes('narrowIncludeSearch') &&
      !criteriaCards[1]?.includes('narrowExcludeSearch') &&
      cardType !== 'excludeSearch'
    ) {
      return true;
    }

    if (cardType === 'narrowIncludeSearch') {
      return true;
    }

    return false;
  };

  /**
   * Exclude card: Needs to have a horizontal divider above it
   * If an exclude group of cards have been added and this is the last card in the
   *  Include group, it needs to have a 'Narrow futher' button below it
   * Otherwise just render the card
   */
  const renderCriteriaCards = () => {
    return criteriaCards.map((group, groupIdx) => {
      return group.map((cardType: CardTypes, cardIdx: number) => {
        if (cardType === 'excludeSearch') {
          return (
            <div key={generateKey()}>
              <Divider
                classes={(current) => ({
                  ...current,
                  root: `${current.root} SegmentsModal__divider`,
                })}
                orientation="horizontal"
              />
              <CriteriaCollapseCard
                title={<CardTitle cardType={cardType} />}
                open
                cardType={cardType as CardTypes}
                actionsForHeader={[
                  <IconButton
                    small
                    onClick={() =>
                      handleRemoveCard(cardType as CardTypes, groupIdx, cardIdx)
                    }
                  >
                    <Icons.TrashIcon width={24} color="#8F9397" />
                  </IconButton>,
                ]}
                narrowLevel={null}
                parentIdx={groupIdx}
                treeLoading={loading}
                treeLoadingError={error}
                criteriaCollection={criteriaCollection}
                onUpdateCriteriaCollection={setCriteriaCollection}
              />
            </div>
          );
        } else if (
          criteriaCards[1]?.length > 0 &&
          groupIdx === 0 &&
          cardIdx === group.length - 1
        ) {
          return (
            <div key={generateKey()}>
              <CriteriaCollapseCard
                title={<CardTitle cardType={cardType} />}
                open
                cardType={cardType as CardTypes}
                actionsForHeader={[
                  <IconButton
                    small
                    onClick={() =>
                      handleRemoveCard(cardType as CardTypes, groupIdx, cardIdx)
                    }
                  >
                    <Icons.TrashIcon width={24} color="#8F9397" />
                  </IconButton>,
                ]}
                narrowLevel={
                  ['narrowIncludeSearch', 'narrowExcludeSearch'].includes(
                    cardType,
                  )
                    ? cardIdx
                    : null
                }
                parentIdx={groupIdx}
                treeLoading={loading}
                treeLoadingError={error}
                criteriaCollection={criteriaCollection}
                onUpdateCriteriaCollection={setCriteriaCollection}
              />
              {criteriaCollection['includeFilters'].length > 0 && (
                <div className="SegmentsModal__filterBtnsContainer">
                  <Button
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} SegmentsModal__filterBtn SegmentsModal__filterBtn-nestedNarrow`,
                    })}
                    styleVariant="action"
                    icon={<Icons.FilterIcon />}
                    onClick={() => handleAddNarrow('nested', groupIdx)}
                    disabled={includeNarrowCardCount >= 15}
                  >
                    <FormattedMessage id="segmentsModal.narrow" />
                  </Button>
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div key={generateKey()}>
              <CriteriaCollapseCard
                title={<CardTitle cardType={cardType} />}
                open
                cardType={cardType as CardTypes}
                actionsForHeader={[
                  <IconButton
                    small
                    onClick={() =>
                      handleRemoveCard(cardType as CardTypes, groupIdx, cardIdx)
                    }
                  >
                    <Icons.TrashIcon width={24} color="#8F9397" />
                  </IconButton>,
                ]}
                narrowLevel={
                  ['narrowIncludeSearch', 'narrowExcludeSearch'].includes(
                    cardType,
                  )
                    ? cardIdx
                    : null
                }
                parentIdx={groupIdx}
                treeLoading={loading}
                treeLoadingError={error}
                criteriaCollection={criteriaCollection}
                onUpdateCriteriaCollection={setCriteriaCollection}
              />
              <div className="SegmentsModal__filterBtnsContainer">
                {showExcludeBtn &&
                  criteriaCollection['includeFilters'].length > 0 &&
                  !criteriaCards.flat().includes('excludeSearch') &&
                  cardIdx === group.length - 1 && (
                    <Button
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} SegmentsModal__filterBtn SegmentsModal__filterBtn-exclude`,
                      })}
                      styleVariant="action"
                      icon={<Icons.CircleBlockIcon />}
                      onClick={() => handleAddIncludeOrExclude('excludeSearch')}
                    >
                      <FormattedMessage id="segmentsModal.exclude" />
                    </Button>
                  )}
                {cardIdx === group.length - 1 &&
                  showNarrowIncludeBtn(cardType) && (
                    <Button
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} SegmentsModal__filterBtn SegmentsModal__filterBtn-nestedNarrow`,
                      })}
                      styleVariant="action"
                      icon={<Icons.FilterIcon />}
                      onClick={() => handleAddNarrow('nested', groupIdx)}
                      disabled={includeNarrowCardCount >= 15}
                    >
                      <FormattedMessage id="segmentsModal.narrow" />
                    </Button>
                  )}
              </div>
            </div>
          );
        }
      });
    });
  };

  const renderChannelIcons = () => {
    const icons = [];
    if (segmentSyncedChannels.includes('facebookAds')) {
      icons.push(<Icons.FacebookIcon width={24} key={generateKey()} />);
    }
    if (segmentSyncedChannels.includes('googleAds')) {
      icons.push(<Icons.GoogleIcon width={24} key={generateKey()} />);
    }
    return (
      <div className="SegmentsModal__syncPreferences-channelIcons">{icons}</div>
    );
  };

  return (
    <>
      <Modal
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} SegmentsModal`,
        })}
        isOpen
        onRequestClose={handleExplicitCancelModal}
      >
        <ModalHeader
          title={
            props.mode === SegmentModeTypes.NEW
              ? t('segmentsModal.newSegment')
              : t('segmentsModal.editSegment')
          }
        />
        <ModalBody
          classes={(current) => ({
            ...current,
            root: `${current.root} SegmentsModal__body`,
          })}
        >
          {props.mode === SegmentModeTypes.EDIT && (
            <>
              {segmentSyncedChannels?.length > 0 && (
                <>
                  <div className="SegmentsModal__syncPreferences">
                    {renderChannelIcons()}
                    <Button
                      styleVariant="tertiary"
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} SegmentsModal__syncPreferences-viewSyncsBtn`,
                      })}
                      onClick={() => handleShowSyncModal()}
                    >
                      <FormattedMessage id="segmentsModal.viewSyncs" />
                    </Button>
                  </div>
                  <Space size="l" />
                </>
              )}
            </>
          )}

          {editSegmentTree?.segmentType === SegmentType.EverythingElse && (
            <div className="SegmentsModal__everythingElse">
              <FormattedMessage id="segmentsModal.everythingElse" />
            </div>
          )}

          <div className="SegmentsModal__nameBlock">
            <div className="SegmentsModal__sectionLabel">
              <FormattedMessage id="segmentsModal.name" />
            </div>
            <div
              className={`SegmentsModal__nameInputContainer
                  ${
                    editSegmentTree?.segmentType === SegmentType.EverythingElse
                      ? 'disabled'
                      : ''
                  }`}
            >
              <InputField
                classes={(current) => ({
                  ...current,
                  root: `${current.root} SegmentsModal__nameInput`,
                })}
                placeholder={t('segmentsModal.namePlaceholder')}
                onInput={(e) =>
                  setInputSegmentName((e.target as HTMLInputElement).value)
                }
                value={editSegmentTree ? editSegmentTree.segmentName : ''}
                disabled={
                  editSegmentTree?.segmentType === SegmentType.EverythingElse
                }
                noClearIcon={
                  editSegmentTree?.segmentType === SegmentType.EverythingElse
                }
              />
            </div>
          </div>
          {editSegmentTree?.segmentType ===
          SegmentType.EverythingElse ? null : (
            <>
              <div className="SegmentsModal__criteriaBlock">
                <div className="SegmentsModal__sectionLabel">
                  <FormattedMessage id="segmentsModal.segmentCriteria" />
                </div>
                {renderCriteriaCards()}
              </div>
              {(showIncludeBtn ||
                criteriaCollection['excludeFilters'].length > 0) && (
                <div className="SegmentsModal__filterBtnsContainer">
                  {showIncludeBtn && (
                    <Button
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} SegmentsModal__filterBtn SegmentsModal__filterBtn-include`,
                      })}
                      styleVariant="action"
                      icon={<Icons.IncludeIcon />}
                      onClick={() => handleAddIncludeOrExclude('includeSearch')}
                    >
                      <FormattedMessage id="segmentsModal.include" />
                    </Button>
                  )}
                  {criteriaCollection['excludeFilters'].length > 0 && (
                    <Button
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} SegmentsModal__filterBtn SegmentsModal__filterBtn-narrow`,
                      })}
                      styleVariant="action"
                      icon={<Icons.FilterIcon />}
                      onClick={() => handleAddNarrow('topLevel')}
                      disabled={excludeNarrowCardCount >= 15}
                    >
                      <FormattedMessage id="segmentsModal.narrow" />
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
          <Space size="l" />
          <Heading
            size="l"
            classes={(current) => ({
              ...current,
              root: `${current.root} SegmentsModal__segmentOutput`,
            })}
          >
            Segment output
          </Heading>
          <Space size="s" />
          <div className="SegmentsModal__segmentOutputMessage">
            <FormattedMessage id="segmentsModal.segmentOutputMessage" />
          </div>
          <Space
            size="m"
            classes={(current) => ({
              ...current,
              root: `${current.root} SegmentsModal__outputSpace`,
            })}
          />

          <Button
            onClick={getSegmentSize}
            disabled={popSizeLoading}
            classes={(current) => ({
              ...current,
              root: `${current.root} SegmentsModal__outputButton`,
            })}
          >
            {t('segmentsModal.generateOutput')}
          </Button>

          <div className="SegmentsModal__outputDivider">
            {(popSize >= 0 || popSizeLoading) && <Divider />}
          </div>

          {popSizeLoading ? (
            <div className="SegmentsModal__generating">
              <span className="loader"></span>
              <FormattedMessage id="segmentsModal.generatingOutput" />
            </div>
          ) : (
            <>
              {popSize >= 0 && (
                <>
                  <div className="SegmentsModal__totalSegmentSizeGroup">
                    <div>
                      <FormattedMessage id="segmentsModal.totalSegmentSize" />
                    </div>
                    <div>{popSize}</div>
                  </div>
                  <div className="SegmentsModal__outputGroup">
                    <div>
                      <FormattedMessage id="segmentsModal.output" />
                    </div>
                    <div>{queryOutput}</div>
                  </div>
                </>
              )}
            </>
          )}

          {props.mode === SegmentModeTypes.EDIT &&
            (editSegmentTree?.segmentType
              ? editSegmentTree?.segmentType
              : activeSegmentType) !== SegmentType.EverythingElse && (
              <Button
                classes={(current) => ({
                  ...current,
                  root: `${current.root} SegmentsModal__deleteBtn`,
                })}
                styleVariant="navigation"
                icon={<Icons.TrashIcon />}
                onClick={() =>
                  showModal(
                    MODAL_TYPES.CONFIRMATION_MODAL,
                    {
                      title: t('segmentsModal.confirmDeleteTitle'),
                      body: t('segmentsModal.confirmDeleteBody'),
                      confirmButtonLabel: t('delete'),
                      onConfirm: () => handleDeleteSegment(),
                      isOpen: true,
                    },
                    MODAL_TYPES.CONFIRMATION_MODAL,
                  )
                }
              >
                <FormattedMessage id="segmentsModal.deleteSegment" />
              </Button>
            )}
          <Space size="l" />
        </ModalBody>
        <ModalFooter>
          <SegmentsFooterModal
            handleExplicitCancelModal={handleExplicitCancelModal}
            selectedSegment={selectedSegment}
            handleUpdate={handleUpdate}
            handleSave={handleSave}
            saveDisabled={
              isSegmentNameEmpty ||
              isIncludeOrExcludeFilterEmpty ||
              savingSegment
            }
            selectedCriteriaCollection={criteriaCollection}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
