import { DependencyContainer } from 'DependencyContainer';
import { Maybe } from 'types';
import { GenerateAddSyncPayload, GenerateUpdateSyncPayload } from '../types';

export default class SyncService {
  constructor(private readonly factory: DependencyContainer) {}

  async getSyncs(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.syncsClient.getSyncs(partnerId);
  }

  async addSync(
    partnerId: Maybe<string>,
    segmentId: string,
    sync: GenerateAddSyncPayload[],
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.syncsClient.addSync(partnerId, segmentId, sync);
  }

  async updateSync(
    partnerId: Maybe<string>,
    segmentId: string,
    syncId: string,
    sync: GenerateUpdateSyncPayload,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.syncsClient.updateSync(
      partnerId,
      segmentId,
      syncId,
      sync,
    );
  }

  async deleteSync(
    partnerId: Maybe<string>,
    segmentNodeId: string,
    syncId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.syncsClient.deleteSync(
      partnerId,
      segmentNodeId,
      syncId,
    );
  }
}
