import PlaceholderChart from 'components/PlaceholderChart/PlaceholderChart';
import { CampaignType } from 'features/crusade/types';
import { ComposedChart, Spinner } from 'plume-ui';
import React, { FunctionComponent, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  campaignOverviewGlobalTimeRangeAtom,
  fetchedChartDataCampaignIds,
} from 'store/state/overviewState';
import {
  createComposedChartEmailData,
  createComposedChartPushData,
} from '../../CampaignHelpers';
import {
  defaultHeroMetrics,
  emailCampaignGroups,
  pushCampaignGroups,
} from '../../CampaignOverviewFixture';
import { useCampaignChartData } from '../../hooks/useCampaignChartData';
import {
  CampaignHistory,
  EmailCampaignStatType,
  OverviewTimeRangeEnum,
  PushCampaignStatType,
} from '../../types';
import CampaignOverviewCardHeader from './CampaignOverviewCardHeader';
import CampaignOverviewChartLegend from './CampaignOverviewChartLegend';

export type CampaignOverviewCardProps = {
  campaign: CampaignHistory;
  index: number;
};

const CampaignOverviewCard: FunctionComponent<CampaignOverviewCardProps> = ({
  campaign,
  index,
}) => {
  const timeRange = useRecoilValue(campaignOverviewGlobalTimeRangeAtom);
  const { campaignData } = useCampaignChartData(campaign.campaignId, index);
  const fetchedCampaignIds = useRecoilValue(fetchedChartDataCampaignIds);

  const tickFormatterFunctions = {
    [OverviewTimeRangeEnum.SEVEN_DAYS]: undefined,
    [OverviewTimeRangeEnum.THIRTY_DAYS]: (
      value: any,
      index: number,
    ): string => {
      if (index % 9 === 0) return `${value}`;
      return '';
    },
    [OverviewTimeRangeEnum.NINETY_DAYS]: (
      value: any,
      index: number,
    ): string => {
      if (index % 25 === 0) return `${value}`;
      return '';
    },
  };

  const heroMetrics = useMemo(() => {
    return campaignData?.heroMetrics
      ? campaignData.heroMetrics
      : defaultHeroMetrics;
  }, [campaignData]);

  const chartData = useMemo(() => {
    if (
      !campaignData ||
      !campaignData.dailyStats ||
      !campaignData.dailyStats.length
    ) {
      return undefined;
    } else if (campaignData && campaignData.dailyStats.length > 0) {
      return campaign.campaignType === CampaignType.EMAIL
        ? createComposedChartEmailData(
            campaignData.dailyStats as EmailCampaignStatType[],
            timeRange.value,
            campaign.campaignType,
          )
        : createComposedChartPushData(
            campaignData.dailyStats as PushCampaignStatType[],
            timeRange.value,
            campaign.campaignType,
          );
    } else {
      return [];
    }
  }, [campaignData, timeRange.value]);

  const campaignGroups =
    campaign.campaignType === CampaignType.EMAIL
      ? emailCampaignGroups
      : pushCampaignGroups;

  return (
    <div className="CampaignOverview__card">
      <CampaignOverviewCardHeader campaign={campaign} />
      <CampaignOverviewChartLegend
        campaign={campaign}
        campaignHeroMetrics={heroMetrics}
      />
      {!chartData && fetchedCampaignIds.includes(campaign.campaignId) ? (
        <div className="CampaignOverview__placeholderChart">
          <PlaceholderChart />
        </div>
      ) : !chartData?.length ||
        !fetchedCampaignIds.includes(campaign.campaignId) ? (
        <div className="CampaignOverview__card-spinner">
          <Spinner visible />
        </div>
      ) : (
        <div className="CampaignOverview__card-chart">
          <ComposedChart
            classes={(current) => ({
              ...current,
              root: `${current.root} CampaignOverview__card__lineChart`,
            })}
            data={chartData}
            groups={campaignGroups}
            curveType="monotone"
            stacked
            showDots={false}
            config={{
              showPercentageOnTooltip: false,
              showPercentageInGroup: false,
              xAxisLabelAngle: 0,
              showTooltipTitleForNumerical: true,
            }}
            tickFormatter={
              tickFormatterFunctions[timeRange.label as OverviewTimeRangeEnum]
            }
          />
        </div>
      )}
    </div>
  );
};

export default CampaignOverviewCard;
