import { NotificationPreview } from 'features/crusade/types';
import i18next from 'i18next';

export const NotificationPreviewDropdown = (): Array<NotificationPreview> => {
  return [
    {
      title: i18next.t('crusade.campaigns.device'),
      label: i18next.t('crusade.campaigns.iPhoneLabel'),
      items: [
        {
          title: i18next.t('crusade.campaigns.iPhoneLabel'),
        },
        {
          title: i18next.t('crusade.campaigns.androidLabel'),
        },
      ],
    },
    {
      title: i18next.t('crusade.campaigns.notificationState'),
      label: i18next.t('crusade.campaigns.lockScreenLabel'),
      items: [
        {
          title: i18next.t('crusade.campaigns.lockScreenLabel'),
        },
        {
          title: i18next.t('crusade.campaigns.expandedScreenLabel'),
        },
        {
          title: i18next.t('crusade.campaigns.activeScreenLabel'),
        },
      ],
    },
  ];
};
