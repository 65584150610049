import { CampaignStatus, CampaignType } from 'features/crusade/types';
import { number } from 'yup';

export enum OverviewEnum {
  SEGMENT_OVERVIEW = 'segmentsOverview',
  CAMPAIGN_OVERVIEW = 'campaignsOverview',
}

export enum OverviewTimeRangeEnum {
  SEVEN_DAYS = 'Last 7 days',
  THIRTY_DAYS = 'Last 30 days',
  NINETY_DAYS = 'Last 90 days',
}

export enum NpsTimeRangeEnum {
  THIRTY_DAYS = 'Last 30 days',
  ALL_TIME = 'All time',
}

export type OverviewTimeRange = {
  label: string;
  value: number;
};

export enum OverviewChartColorOptions {
  COLOR_WARM_X_500 = '#b17395',
  HOT_500 = '#656CFF',
  HOT_500_20 = 'rgba(101, 108, 255, .33)',
  WARM_500 = '#FFC500',
  WARM_500_20 = 'rgba(255, 197, 0, .33)',
  GOOD_500 = '#17E3AE',
  GOOD_500_20 = 'rgba(23, 227, 174, .33)',
  SORE_500 = '#FA1478',
  SORE_500_20 = 'rgba(250, 20, 120, .33)',
  COLD_500 = '#0FDCFF',
  FREEZE_500 = '#803BD2',
  SORE_X_500 = '#F78673',
  GOOD_X_500 = '#72A5A5',
  FREEZE_X_500 = '#737BBD',
  STILL_400 = '#8F9397',
}

export type SegmentStatType = {
  dt: string;
  value: number;
};

export type SegmentHistory = {
  segmentName: string;
  segmentId: string;
  segmentStats: SegmentStatType[];
};

export type SegmentHistoryParams = {
  daysAgo: number;
  segments?: string[];
};

export type SegmentGroupType = {
  segmentId: string;
  segmentName: string;
};

export type EmailCampaignStatType = {
  dt: string;
  segmentSize: number;
  opens: number;
  clicks: number;
  bounces: number;
  unsubscribes: number;
};

export type PushCampaignStatType = {
  dt: string;
  segmentSize: number;
  opens: number;
  bounces: number;
};

export enum EmailChartHeroMetricEnum {
  EMAILS_SENT = 'emailsSent',
  DELIVERED = 'delivered',
  SEGMENT_SIZE = 'segmentSize',
  OPENS = 'opens',
  CLICKS = 'clicks',
  BOUNCES = 'bounces',
  UNSUBSCRIBES = 'unsubscribes',
}

export enum PushChartHeroMetricEnum {
  MESSAGES_SENT = 'messagesSent',
  SEGMENT_SIZE = 'segmentSize',
  OPENS = 'opens',
  BOUNCES = 'bounces',
}

export type EmailChartHeroMetricType = {
  emailsSent: number;
  delivered: number;
  segmentSize: number;
  opens: number;
  clicks: number;
  bounces: number;
  unsubscribes: number;
};

export type PushChartHeroMetricType = {
  messagesSent: number;
  segmentSize: number;
  opens: number;
  bounces: number;
};

export type CampaignHistory = {
  campaignName: string;
  campaignId: string;
  campaignType: CampaignType;
  updatedAt: string;
  status: CampaignStatus;
  nps?: boolean;
};

export type CampaignHistoryChartDataType = {
  campaignId?: string;
  type: CampaignType;
  heroMetrics: EmailChartHeroMetricType | PushChartHeroMetricType;
  dailyStats: EmailCampaignStatType[] | PushCampaignStatType[];
};

export type NpsHeroMetricType = {
  npsScore: number;
  detractors: number;
  promoters: number;
  passives: number;
};

export type NpsResponseType = {
  campaignName: string;
  campaignId: string;
  heroMetric: NpsHeroMetricType;
  scoresOverTime: NpsScoresOverTimeType[];
};

export type NpsScoresOverTimeType = {
  dt: string;
  npsScore: number;
  detractors: number;
  promoters: number;
  passives: number;
};

export enum NpsEnum {
  NPS_SCORE = 'npsScore',
  DETRACTORS = 'detractors',
  PROMOTERS = 'promoters',
  PASSIVES = 'passives',
}

export type BarChartDataItem = {
  name: string;
  values: number[];
};

export type AreaChartDataItem = {
  npsScore: number;
  xAxisLabel: string;
};
