import { DependencyContainer } from '../../../DependencyContainer';
import { CreateJourney } from '../types';

export default class AddJourneyService {
  constructor(private readonly factory: DependencyContainer) {}

  async createJourney(
    partnerId: string,
    parameter: CreateJourney,
  ): Promise<any> {
    return await this.factory.createJourneyClient.createJourney(
      partnerId,
      parameter,
    );
  }
}
