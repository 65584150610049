import { DependencyContainer } from 'DependencyContainer';
import { useFormikContext } from 'formik';
import {
  Dropdown,
  DropdownSelectableItem,
  IconButton,
  Icons,
  PendingContent,
  Spinner,
  StaticCard,
} from 'plume-ui';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  audienceListMonitorsSelector,
  audienceListSegmentsSelector,
  audienceListSelector,
  editingCampaignAtom,
  formUpdated,
} from 'store/state/campaignState';
import FormattedMessage from 'utils/components/FormattedMessage';
import { CampaignAudienceCardDropdown } from 'utils/fixtures/CampaignAudienceCard.fixture';
import { useTargetAudience } from '../hooks/useTargetAudience';
import {
  AudienceSegmentType,
  CampaignAudienceCardValues,
  CampaignDtoType,
  CampaignFormikFields,
  CampaignGenericFormField,
  SegmentOrigin,
  SendToUsersType,
} from '../types';

const CampaignAudienceCard: FunctionComponent<CampaignGenericFormField<
  CampaignAudienceCardValues
>> = ({ form, field }) => {
  const { values } = useFormikContext<CampaignDtoType>();
  const { t } = useTranslation();
  const dropdownData = CampaignAudienceCardDropdown();
  const [inEditMode, setInEditMode] = useState(false);
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const partnerId = useRecoilValue(partnerIdAtom);
  const { campaignAudienceService } = new DependencyContainer();
  const [audienceDataLoading, setAudienceDataLoading] = useState(false);
  const setFormFieldsUpdated = useSetRecoilState(formUpdated);

  useEffect(() => {
    if (!editingCampaign) {
      setInEditMode(true);
    }
  }, [editingCampaign]);

  // call the preCalculateGlobalFilters API for audience size and summary
  //   if all required fields are selected
  useMemo(() => {
    const fetchAudienceResults = async () => {
      setAudienceDataLoading(true);
      try {
        const result = await campaignAudienceService.preCalculateGlobalFilters(
          partnerId,
          {
            campaignType: values?.campaignInformationCard.campaignType,
            audienceSegments: field.value.audienceSegments,
            sendToTheseUsers: field.value.sendToTheseUsers,
          },
        );

        form.setFieldValue(CampaignFormikFields.CAMPAIGN_AUDIENCE_CARD, {
          ...field.value,
          audienceSummary: result.data.audienceSummary,
          audienceSize: result.data.audienceSize,
        });
      } catch (error) {
        form.setFieldValue(CampaignFormikFields.CAMPAIGN_AUDIENCE_CARD, {
          ...field.value,
          audienceSummary: t('NA'),
          audienceSize: t('NA'),
        });
      }
      setAudienceDataLoading(false);
    };

    if (
      field.value.audienceSegments[0]?.segmentId !== '' &&
      field.value.sendToTheseUsers !== undefined
    ) {
      fetchAudienceResults();
    }
  }, [
    field.value.audienceSegments[0]?.segmentId,
    field.value.sendToTheseUsers,
  ]);

  const { targetAudienceLoading, targetAudienceError } = useTargetAudience();
  const targetAudienceList = useRecoilValue(audienceListSelector);
  const targetAudienceSegmentList = useRecoilValue(
    audienceListSegmentsSelector,
  );
  const targetAudienceMonitorList = useRecoilValue(
    audienceListMonitorsSelector,
  );

  const sendToUsersList = dropdownData?.find(
    (item: any) => item.title === 'crusade.campaigns.audience.sendToUser',
  );

  const onTargetAudienceSelect = (
    segment: AudienceSegmentType | undefined,
  ): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_AUDIENCE_CARD, {
      ...field.value,
      audienceSegments: [segment],
    });
  };

  const onSendToUsersSelect = (value: SendToUsersType): void => {
    setFormFieldsUpdated(true);
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_AUDIENCE_CARD, {
      ...field.value,
      sendToTheseUsers: value,
    });
  };

  const cardActions = () => {
    if (!editingCampaign) {
      return undefined;
    } else if (!inEditMode) {
      return [
        <IconButton
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__editIcon`,
          })}
          onClick={() => setInEditMode(true)}
        >
          <Icons.EditIcon />
        </IconButton>,
      ];
    }
  };

  const findSegmentName = (segmentId: string): string => {
    const audience = targetAudienceList.find(
      (audience) => audience.segmentId === segmentId,
    );

    return audience
      ? audience.segmentName
      : t('crusade.campaigns.audience.targetAudienceLabel');
  };

  const audienceSegment = useMemo(
    () =>
      targetAudienceList.find(
        (aud) => aud.segmentId === field.value?.audienceSegments[0]?.segmentId,
      ),
    [targetAudienceList, field.value?.audienceSegments],
  );

  return (
    <div className="EditCard">
      <StaticCard
        title={<FormattedMessage id="crusade.campaigns.audience.title" />}
        actions={cardActions()}
        classes={(current) => ({
          ...current,
          root: `${current.root} EditCard__root`,
          title: `${current.title} EditCard__cardHeader-title`,
        })}
      >
        {inEditMode ? (
          <>
            <div className="AudienceCard__wrapper">
              <p className="EditCard__label AudienceCard__target-label">
                <FormattedMessage id="crusade.campaigns.audience.target" /> :
              </p>
              <div className="AudienceCard__dropdownWrapper">
                <PendingContent
                  loading={targetAudienceLoading || audienceDataLoading}
                  loader={Spinner}
                  isError={Boolean(targetAudienceError)}
                >
                  <div className="AudienceCard__dropdownWrapper_target_audience">
                    <div className="AudienceCard__dropdown">
                      <p className="AudienceCard__dropdown-label">
                        <FormattedMessage id="crusade.campaigns.audience.segmentsAudienceLabel" />
                      </p>
                      <Dropdown
                        classes={(current) => ({
                          ...current,
                          list: `${current.list} AudienceCard__targetAudienceDropdown-list`,
                        })}
                        listPosition="left"
                        label={
                          audienceSegment?.segmentId !== '' &&
                          audienceSegment?.segmentOrigin ===
                            SegmentOrigin.Generate ? (
                            findSegmentName(audienceSegment.segmentId)
                          ) : (
                            <FormattedMessage id="crusade.campaigns.audience.targetAudienceLabel" />
                          )
                        }
                        openInPortal={true}
                        closeOnItemClick
                        disabled={audienceDataLoading}
                        noItemsWarning={t('noResults')}
                      >
                        {targetAudienceSegmentList?.map((item, index) => {
                          return (
                            <DropdownSelectableItem
                              classes={(current) => ({
                                ...current,
                                root: `${current.root} AudienceCard__targetAudienceDropdown-listItem`,
                              })}
                              key={index}
                              selected={
                                field.value?.audienceSegments[0]?.segmentId ===
                                item.segmentId
                              }
                              onClick={() => onTargetAudienceSelect(item)}
                            >
                              {item.segmentName}
                            </DropdownSelectableItem>
                          );
                        })}
                      </Dropdown>
                    </div>
                    <div className="AudienceCard__dropdown">
                      <p className="AudienceCard__dropdown-label">
                        <FormattedMessage id="crusade.campaigns.audience.monitorsAudienceLabel" />
                      </p>
                      <Dropdown
                        classes={(current) => ({
                          ...current,
                          list: `${current.list} AudienceCard__targetAudienceDropdown-list`,
                        })}
                        listPosition="left"
                        label={
                          audienceSegment?.segmentId !== '' &&
                          audienceSegment?.segmentOrigin ===
                            SegmentOrigin.Signal ? (
                            findSegmentName(audienceSegment.segmentId)
                          ) : (
                            <FormattedMessage id="crusade.campaigns.audience.targetAudienceLabel" />
                          )
                        }
                        openInPortal={true}
                        closeOnItemClick
                        disabled={audienceDataLoading}
                        noItemsWarning={t('noResults')}
                      >
                        {targetAudienceMonitorList?.map((item, index) => {
                          return (
                            <DropdownSelectableItem
                              classes={(current) => ({
                                ...current,
                                root: `${current.root} AudienceCard__targetAudienceDropdown-listItem`,
                              })}
                              key={index}
                              selected={
                                field.value?.audienceSegments[0]?.segmentId ===
                                item.segmentId
                              }
                              onClick={() => onTargetAudienceSelect(item)}
                            >
                              {item.segmentName}
                            </DropdownSelectableItem>
                          );
                        })}
                      </Dropdown>
                    </div>
                    {!field.value.audienceSegments[0]?.segmentId && (
                      <p className="AudienceCard__targetAudienceDropdown-hintMsg">
                        <FormattedMessage id="fieldRequired" />
                      </p>
                    )}
                  </div>
                </PendingContent>
              </div>
            </div>
            <PendingContent
              loading={audienceDataLoading}
              loader={Spinner}
              classes={(current) => ({
                ...current,
                root: `${current.root} AudienceCard__pending-content`,
              })}
            >
              <div className="AudienceCard__wrapper">
                <p className="AudienceCard__label">{`${t(
                  'crusade.campaigns.audience.summary',
                )} :`}</p>
                <div className="AudienceCard__dropdownWrapper">
                  <p className="AudienceCard__value_label">
                    {field.value.audienceSummary}
                  </p>
                </div>
              </div>
              <div className="AudienceCard__wrapper">
                <p className="AudienceCard__label">{`${t(
                  'crusade.campaigns.audience.size',
                )} :`}</p>
                <div className="AudienceCard__dropdownWrapper">
                  <p className="AudienceCard__value_label">
                    {field.value.audienceSize}
                  </p>
                </div>
              </div>
            </PendingContent>
            <div className="AudienceCard__wrapper">
              <p className="AudienceCard__label">{`${t(
                'crusade.campaigns.audience.sendToUser',
              )} :`}</p>
              <div className="AudienceCard__dropdownWrapper">
                <div className="AudienceCard__dropdownWrapper_user">
                  <Dropdown
                    listPosition="left"
                    openInPortal={true}
                    label={
                      field.value?.sendToTheseUsers ||
                      t(`${sendToUsersList?.label}`)
                    }
                    closeOnItemClick
                    expandDirection="auto"
                    disabled={audienceDataLoading}
                  >
                    {sendToUsersList?.items?.map((item, index) => (
                      <DropdownSelectableItem
                        key={index}
                        selected={field.value?.sendToTheseUsers === item.title}
                        onClick={() =>
                          onSendToUsersSelect(item.title as SendToUsersType)
                        }
                      >
                        {item.title}
                      </DropdownSelectableItem>
                    ))}
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="EditCard__wrapper">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.audience.target" /> :
              </p>
              <p className="EditCard__campaignNonEditCardValue">
                {field.value?.audienceSegments[0]?.segmentId !== '' ? (
                  findSegmentName(field.value?.audienceSegments[0]?.segmentId)
                ) : (
                  <FormattedMessage id="none" />
                )}
              </p>
            </div>
            <div className="AudienceCard__wrapper">
              <p className="EditCard__label">
                <FormattedMessage id="crusade.campaigns.audience.size" /> :
              </p>
              <p className="EditCard__campaignNonEditCardValue">
                {field.value.audienceSize !== '' ? (
                  field.value.audienceSize
                ) : (
                  <FormattedMessage id="none" />
                )}
              </p>
            </div>
          </>
        )}
      </StaticCard>
    </div>
  );
};
export default CampaignAudienceCard;
