import { DependencyContainer } from '../../../DependencyContainer';

import { AvailableIntegrationTypes } from '../../configuration/types';
import IntegrationsClient from './IntegrationsClient';
import {
  ErrorGeneric,
  ErrorNonApprovedDeveloperToken,
  ErrorNotValidAdsUser,
  ErrorPermissionsNotGranted,
  ErrorRequestTimedOut,
  IntegrationErrorDetails,
} from '../errors';
import {
  AdAccountResponseItem,
  AdConnectedAccountResponse,
  TestChannelResponse,
} from 'features/configuration/integrationsState';

export default class IntegrationsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getFacebookAdAccounts(
    accessToken: string,
    partnerId: string,
    isReAuthProcess: boolean = false,
  ): Promise<AdAccountResponseItem[]> {
    try {
      const response = await this.factory.integrationsClient.exchangeFacebookToken(
        accessToken,
        partnerId,
      );
      return response.data;
    } catch (error) {
      return await this.parseAdAccountsError(error);
    }
  }

  async getGoogleAdAccounts(
    accessToken: string,
    partnerId: string,
    isReAuthProcess: boolean = false,
  ): Promise<AdAccountResponseItem[]> {
    try {
      let accounts;
      if (!isReAuthProcess) {
        const response = await this.factory.integrationsClient.exchangeGoogleToken(
          accessToken,
          partnerId,
        );
        accounts = response.data;
      } else {
        // get accounts
        accounts = await this.getAdAccounts(
          AvailableIntegrationTypes.Google,
          partnerId,
        );
      }

      const hasErrors =
        accounts.find((a: AdAccountResponseItem) => a.error_message) !==
        undefined;
      if (hasErrors) {
        throw new ErrorNonApprovedDeveloperToken();
      }
      return accounts;
    } catch (error) {
      return await this.parseAdAccountsError(error);
    }
  }

  protected async parseAdAccountsError(error: any): Promise<any> {
    const errorResponseDetail = error?.response?.data?.detail;
    const isErrorNonApprovedDeveloperToken =
      error instanceof ErrorNonApprovedDeveloperToken;
    if (!isErrorNonApprovedDeveloperToken && error?.response?.status === 504) {
      throw new ErrorRequestTimedOut();
    } else if (
      !isErrorNonApprovedDeveloperToken &&
      error?.response?.status === 422 &&
      errorResponseDetail[0]?.msg
    ) {
      throw new ErrorGeneric(errorResponseDetail[0].msg);
    }
    switch (errorResponseDetail) {
      case IntegrationErrorDetails.RequiredPermissionsNotGranted:
        throw new ErrorPermissionsNotGranted();
      case IntegrationErrorDetails.NotValidAdsUser:
        throw new ErrorNotValidAdsUser();
      default:
        if (error instanceof ErrorNonApprovedDeveloperToken) {
          throw error;
        }
        throw new ErrorGeneric(errorResponseDetail);
    }
  }

  async connectAccount(
    integrationType: AvailableIntegrationTypes,
    accountId: string,
    partnerId: string,
  ): Promise<AdConnectedAccountResponse> {
    const mapClientMethodToAccountType: Record<
      AvailableIntegrationTypes,
      keyof IntegrationsClient
    > = {
      [AvailableIntegrationTypes.Facebook]: 'connectFacebookAccount',
      [AvailableIntegrationTypes.Google]: 'connectGoogleAccount',
    };
    const strategy = mapClientMethodToAccountType[integrationType];
    // @ts-ignore
    const response = await this.factory.integrationsClient[strategy](
      accountId,
      partnerId,
    );
    return response.data;
  }

  async testChannel(
    integrationType: AvailableIntegrationTypes,
    channelId: string,
    partnerId: string,
  ): Promise<TestChannelResponse> {
    const mapClientMethodToAccountType: Record<
      AvailableIntegrationTypes,
      keyof IntegrationsClient
    > = {
      [AvailableIntegrationTypes.Facebook]: 'testFacebookChannel',
      [AvailableIntegrationTypes.Google]: 'testGoogleChannel',
    };
    const strategy = mapClientMethodToAccountType[integrationType];
    // @ts-ignore
    const response = await this.factory.integrationsClient[strategy](
      channelId,
      partnerId,
    );
    return response.data;
  }

  async reAuthChannel(
    channelId: string,
    partnerId: string,
    code: string,
  ): Promise<any> {
    try {
      const response = await this.factory.integrationsClient.reAuthChannel(
        code,
        channelId,
        partnerId,
      );
      return response.data;
    } catch (error) {
      return await this.parseAdAccountsError(error);
    }
  }

  async getAdAccounts(
    accountType: AvailableIntegrationTypes,
    partnerId: string,
  ): Promise<any> {
    const response = await this.factory.integrationsClient.getAdAccounts(
      accountType,
      partnerId,
    );
    return response.data;
  }
}
