import {
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Panel,
  PanelFooter,
  notify,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import FormattedMessage from 'utils/components/FormattedMessage';
import useIntegrations from '../../../integrations/hooks/useIntegrations';
import cx from 'classnames';
import {
  AvailableIntegration,
  AddChannelModalTabs,
  AddChannelModalSubTabs,
  AvailableModalActionViews,
} from '../../types';
import {
  AddChannelModalContext,
  AddChannelModalContextValues,
} from './AddChannelModalContext';
import {
  availableIntegrations,
  scrollContainer,
  tabBarTitleIds,
} from '../../config';
import AddChannelConfigure from './AddChannelConfigure';
import AddChannelChoose from './AddChannelChoose';
import AddChannelTest from './AddChannelTest';
import AddChannelModalFooter from './AddChannelModalFooter';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { introspectAtom } from '../../../../store/state/introspect';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useChannels } from '../../hooks/useChannels';
import { AxiosError } from 'axios';
import { PanelStyles } from 'plume-ui/dist/components/Panel/Panel';
import {
  integrationErrorAtom,
  integrationCacheAtom,
  AdConnectedAccountResponse,
} from 'features/configuration/integrationsState';

const mapActiveTabNameToView: Record<AddChannelModalTabs, FunctionComponent> = {
  [AddChannelModalTabs.Configure]: AddChannelConfigure,
  [AddChannelModalTabs.Choose]: AddChannelChoose,
  [AddChannelModalTabs.Test]: AddChannelTest,
};

const { integrationsService } = new DependencyContainer();

export const AddChannelModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    beginAuthorization,
    isIntegrationFinished,
    cleanup,
  } = useIntegrations({
    automaticallyStartFlow: false,
  });
  const { runFetch } = useChannels(true);
  const { hideModal } = useGlobalModalContext();
  const [activeTabName, setActiveTabName] = useState<AddChannelModalTabs>(
    AddChannelModalTabs.Choose,
  );
  const [activeSubTabName, setActiveSubTabName] = useState<
    AddChannelModalSubTabs
  >();
  const [selectedIntegration, setSelectedIntegration] = useState<
    AvailableIntegration
  >();
  const isCurrentIntegrationFinished = isIntegrationFinished();
  const introspect = useRecoilValue(introspectAtom);
  const setIntegrationError = useSetRecoilState(integrationErrorAtom);
  const [selectedAccountId, setSelectedAccountId] = useState<string>();
  const integrationCacheAtomValue = useRecoilValue(integrationCacheAtom);
  const [adConnectedAccount, setAdConnectedAccount] = useState<
    AdConnectedAccountResponse
  >();
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(
    false,
  );

  const handleCloseModal = async () => {
    hideModal(MODAL_TYPES.ADD_CHANNEL_MODAL);
    setIntegrationError(undefined);
  };

  const renderTabBar = () => {
    return Object.keys(tabBarTitleIds).map((tabId: string, index: number) => {
      const titleId = tabBarTitleIds[tabId as AddChannelModalTabs];
      const classes = cx('AddChannelModal__tabContainer', {
        AddChannelModal__activeTab: activeTabName === tabId,
      });
      return (
        <div key={tabId} className={classes}>
          <p className="AddChannelModal__tabIndex">{index + 1}</p>
          <p>
            <FormattedMessage id={titleId} />
          </p>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!integrationCacheAtomValue) {
      return;
    }
    const integration = availableIntegrations.find(
      (i) => i.id === integrationCacheAtomValue.integrationType,
    );
    if (!integration) {
      return;
    }
    setSelectedIntegration(integration);
  }, [integrationCacheAtomValue]);

  const renderTabContent = (): ReactElement => {
    const View: FunctionComponent =
      mapActiveTabNameToView[activeTabName] || React.Fragment;
    return <View />;
  };

  useEffect(() => {
    if (!isCurrentIntegrationFinished) {
      return;
    }
    setActiveTabName(AddChannelModalTabs.Configure);
    setActiveSubTabName(AddChannelModalSubTabs.SelectAccount);
    cleanup(true);
  }, [isCurrentIntegrationFinished]);

  const handleIntegrationChange = (integration: AvailableIntegration) => {
    setSelectedIntegration(integration);
    setActiveTabName(AddChannelModalTabs.Configure);
  };

  const onSelectAccount = (adAccountId: string) => {
    setSelectedAccountId(adAccountId);
  };

  const getSelectedAccountId = () => {
    return selectedAccountId;
  };

  const getInitialContextValues = (): AddChannelModalContextValues => {
    return {
      selectedAccountType: selectedIntegration?.id,
      activeTabName,
      setActiveTabName,
      activeSubTabName,
      handleIntegrationChange,
      handleSteps,
      handleFinish,
      onSelectAccount,
      getSelectedAccountId,
      adConnectedAccount,
      isRequestInProgress,
      setIsRequestInProgress,
    };
  };

  const onConnectAccount = async (): Promise<AdConnectedAccountResponse> => {
    const selectedAdAccountId = getSelectedAccountId();
    if (
      !introspect ||
      !introspect.partnerId ||
      !selectedIntegration?.id ||
      !selectedAdAccountId
    ) {
      return Promise.reject();
    }
    try {
      setIsRequestInProgress(true);
      const response = await integrationsService.connectAccount(
        selectedIntegration.id,
        selectedAdAccountId,
        introspect.partnerId,
      );
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    } finally {
      setIsRequestInProgress(false);
    }
  };

  const handleSteps = async (flag: AvailableModalActionViews) => {
    switch (flag) {
      case AvailableModalActionViews.BackToChoose:
        setActiveTabName(AddChannelModalTabs.Choose);
        break;
      case AvailableModalActionViews.ConnectToConfigure:
        const accountType = selectedIntegration?.id;
        if (!accountType) {
          return;
        }
        beginAuthorization(accountType);
        break;
      case AvailableModalActionViews.BackToConfigure:
        setActiveSubTabName(undefined);
        break;
      case AvailableModalActionViews.GoToTest:
        try {
          const connectedAccount = await onConnectAccount();
          setAdConnectedAccount(connectedAccount);
          setActiveTabName(AddChannelModalTabs.Test);
        } catch (error) {
          const mappedError = error as AxiosError;

          if (mappedError.response) {
            const messageId =
              mappedError?.response === undefined ||
              mappedError?.response?.status === 504
                ? 'settings.errors.requestTimedOut.description'
                : 'somethingWentWrong';
            setAdConnectedAccount(undefined);
            notify({
              title: t('error'),
              body: t(messageId),
              type: 'error',
            });
          } else {
            notify({
              title: t('error'),
              body: '',
              type: 'error',
            });
          }
        }
        break;
      case AvailableModalActionViews.BackToConfigureAccount:
        setActiveTabName(AddChannelModalTabs.Configure);
        setActiveSubTabName(AddChannelModalSubTabs.SelectAccount);
        break;
    }
  };

  const handleFinish = async () => {
    hideModal(MODAL_TYPES.ADD_CHANNEL_MODAL);
    await runFetch(true);
    notify({
      title: t('settings.channel.successNotification'),
      body: t(''),
      type: 'success',
    });
  };

  return (
    <AddChannelModalContext.Provider value={getInitialContextValues()}>
      <Panel
        title={t('settings.channel.addAChannel')}
        classes={(current: PanelStyles) => ({
          ...current,
          root: `${current.root} AddChannelModal`,
        })}
        open
        setOpen={() => handleCloseModal()}
      >
        <div className="AddChannelModal__body">
          <div className="AddChannelModal__tabWrapper">{renderTabBar()}</div>
          <div className="AddChannelModal__tabContent">
            {renderTabContent()}
          </div>
        </div>
        <PanelFooter>
          <AddChannelModalFooter />
        </PanelFooter>
      </Panel>
    </AddChannelModalContext.Provider>
  );
};
