import { DependencyContainer } from 'DependencyContainer';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  dataPreviewAtom,
  dataPreviewLoadingStatusAtom,
} from 'store/state/dataPreviewState';
import { DataPreview } from 'types/date-preivew.type';
import { useFetchData } from './useFetchData';

type UseDataPreviewValues = {
  dataPreview: DataPreview[];
  loading: boolean;
  error: any;
};

const { segmentsPreviewService } = new DependencyContainer();

export const useDataPreview = (): UseDataPreviewValues => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const segmentId = useParams<{ segmentId: string }>();
  const [dataPreview, setDataPreview] = useRecoilState(dataPreviewAtom);
  const [
    dataPreviewLoadingStatus,
    setDataPreviewLoadingStatus,
  ] = useRecoilState(dataPreviewLoadingStatusAtom);

  const [loading, , error] = useFetchData<DataPreview[], any>(
    async () => {
      return await segmentsPreviewService.getSegmentsPreview(
        partnerId,
        segmentId.segmentId,
      );
    },
    (dataPreview: any) => {
      setDataPreviewLoadingStatus('loaded');
      return setDataPreview(dataPreview.data);
    },
  );

  useEffect(() => {
    if (loading) {
      setDataPreviewLoadingStatus('loading');
    }
    if (error) {
      setDataPreviewLoadingStatus('error');
    }
  }, [loading, error]);

  return {
    dataPreview,
    loading,
    error,
  };
};
