import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import {
  crusadeApiVersionAtom,
  generateApiVersionAtom,
} from 'store/state/apiVersionState';
import { DependencyContainer } from 'DependencyContainer';

const { crusadeJourneyService, audienceService } = new DependencyContainer();

export const useApiVersion = () => {
  const [generateApiVersion, setGenerateApiVersion] = useRecoilState(
    generateApiVersionAtom,
  );
  const [crusadeApiVersion, setCrusadeApiVersion] = useRecoilState(
    crusadeApiVersionAtom,
  );

  const getGenerateVersion = async () => {
    const version = await audienceService.getVersion();

    if (version) {
      console.log(`Generate API version: ${version}`);
      setGenerateApiVersion(version);
    }
  };

  const getCrusadeVersion = async () => {
    const version = await crusadeJourneyService.getVersion();

    if (version) {
      console.log(`Nurture API version: ${version}`);
      setCrusadeApiVersion(version);
    }
  };

  useEffect(() => {
    if (!generateApiVersion) {
      getGenerateVersion();
    }
    if (!crusadeApiVersion) {
      getCrusadeVersion();
    }
  }, []);
};
