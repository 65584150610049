import { Routes } from 'config';
import { useProduct } from 'features/login/hooks/useProduct';
import { HarvestProduct } from 'features/login/types';
import { Tabs } from 'plume-ui';
import { Tab } from 'plume-ui/dist/components/Tabs/Tabs';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { OverviewEnum } from '../types';
import CampaignOverview from './CampaignOverview/CampaignOverview';
import SegmentOverview from './SegmentOverview/SegmentOverview';

const OverviewContent = () => {
  const { hasProduct } = useProduct();
  const redirectToRoute = useRedirectToRoute();
  const overviewParam = useParams<{ activeOverview: OverviewEnum }>();

  const segmentsOverview = hasProduct(HarvestProduct.Generate)
    ? [
        {
          id: 'segmentsOverview',
          label: 'Segments',
          content: <SegmentOverview />,
          visible: true,
        },
      ]
    : [];

  const campaignsOverview = hasProduct(HarvestProduct.Crusade)
    ? [
        {
          id: 'campaignsOverview',
          label: 'Campaigns',
          content: <CampaignOverview />,
          visible: true,
        },
      ]
    : [];

  const tabs = [...campaignsOverview, ...segmentsOverview];

  const handleSelectTab = (tab: Tab) => {
    const activeOverview =
      tab.id === OverviewEnum.SEGMENT_OVERVIEW
        ? OverviewEnum.SEGMENT_OVERVIEW
        : OverviewEnum.CAMPAIGN_OVERVIEW;

    redirectToRoute(Routes.Overview, { activeOverview });
  };
  return (
    <div className="OverviewContent">
      <Tabs
        options={tabs}
        tabType="flatTabs"
        active={
          overviewParam.activeOverview === OverviewEnum.SEGMENT_OVERVIEW ? 1 : 0
        }
        onSelectTab={handleSelectTab}
        classes={(current) => ({
          ...current,
          root: `${current.root} OverviewContent__tabs`,
        })}
        fullWidth
      />
    </div>
  );
};

export default OverviewContent;
