import { useRecoilState } from 'recoil';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useFetchData } from '../../../../hooks/useFetchData';
import { metadataTreeAtom } from '../../../../store/state/metadataTreeState';
import { MetadataTree } from '../types';

type UseTraitValues = {
  metadataTree: MetadataTree[];
  loading: boolean;
  error: any;
};

const { traitsService } = new DependencyContainer();

export const useTraits = (): UseTraitValues => {
  const [metadataTree, setMetadataTree] = useRecoilState(metadataTreeAtom);

  const [loading, , error] = useFetchData(
    async () => {
      return await traitsService.getMetadataTree();
    },
    (metadataTree) => {
      return setMetadataTree(metadataTree.data);
    },
  );

  return {
    metadataTree,
    loading,
    error,
  };
};
