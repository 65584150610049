import { DEFAULT_PAGE_SIZE } from 'config';
import { LoadingStatus } from 'features/generate/audience/types';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector } from 'recoil';
import {
  DataPreview,
  SortableDataPreviewFieldNames,
} from 'types/date-preivew.type';

export const dataPreviewAtom = atom<DataPreview[]>({
  key: 'usersAtom',
  default: [],
});

export const dataPreviewSelectedFilterAtom = atom<string>({
  key: 'dataPreviewSelectedFilterAtom',
  default: '',
});

export const dataPreviewSelectedPageAtom = atom<number>({
  key: 'dataPreviewSelectedPageAtom',
  default: 0,
});

export const dataPreviewSortAtom = atom<FieldSort | null>({
  key: 'dataPreviewSortAtom',
  default: null,
});

export const dataPreviewLoadingStatusAtom = atom<LoadingStatus>({
  key: 'dataPreviewLoadingStatusAtom',
  default: 'loading',
});

export const sortedDataPreview = selector<DataPreview[]>({
  key: 'sortedDataPreview',
  get: ({ get }) => {
    const dataPreview = get(dataPreviewAtom);
    const sort = get(dataPreviewSortAtom);
    if (sort) {
      return sortDataPreview([...dataPreview], sort);
    }
    return dataPreview;
  },
});

const sortDataPreview = (
  dataPreview: DataPreview[],
  sort: FieldSort,
): DataPreview[] => {
  const fieldName = sort.fieldName as SortableDataPreviewFieldNames;
  return dataPreview.sort(
    (a, b) => a[fieldName]!.localeCompare(b[fieldName]!) * sort.direction,
  );
};

const filteredDataPreview = selector<DataPreview[]>({
  key: 'filteredDataPreview',
  get: ({ get }) => {
    let dataPreview = get(sortedDataPreview);
    const filter = get(dataPreviewSelectedFilterAtom);

    if (filter) {
      dataPreview = dataPreview.filter((preview) =>
        preview.accountId
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()),
      );
    }

    return dataPreview;
  },
});

export const currentDataPreviewPage = selector<DataPreview[]>({
  key: 'currentDataPreviewPage',
  get: ({ get }) => {
    let dataPreview = get(filteredDataPreview);
    const selectedPage = get(dataPreviewSelectedPageAtom);

    return dataPreview.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const filteredDataPreviewCount = selector<number>({
  key: 'filteredDataPreviewCount',
  get: ({ get }) => {
    return get(filteredDataPreview).length;
  },
});
