export const MODAL_TYPES = {
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  SEGMENTS_MODAL: 'SEGMENTS_MODAL',
  SPLIT_SEGMENTS_MODAL: 'SPLIT_SEGMENTS_MODAL',
  SYNC_PREFERENCES_MODAL: 'SYNC_PREFERENCES_MODAL',
  CREATE_SYNC_MODAL: 'CREATE_SYNC_MODAL',
  CAMPAIGN_MODAL: 'CAMPAIGN_MODAL',
  PREVIEW_AND_TEST_MODAL: 'PREVIEW_AND_TEST_MODAL',
  DELETE_MODAL: 'DELETE_MODAL',
  DESCRIPTION_MODAL: 'DESCRIPTION_MODAL',
  TAGS_MODAL: 'TAGS_MODAL',
  PATTERN_STRING_MODAL: 'PATTERN_STRING_MODAL',
  ADD_CHANNEL_MODAL: 'ADD_CHANNEL_MODAL',
  CREATE_CAMPAIGN_MODAL: 'CREATE_CAMPAIGN_MODAL',
};
