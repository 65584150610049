import { AxiosResponse } from 'axios';
import { HttpClient } from 'HttpClient';
import { Introspect } from 'store/state/introspect';
import { HarvestApiUrls } from 'urls';

export default class IntrospectClient extends HttpClient {
  async fetchIntrospect(): Promise<Introspect> {
    const response = await this.get<AxiosResponse<Introspect>>(
      HarvestApiUrls.introspect(),
    );
    return response.data;
  }
}
