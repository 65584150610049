import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { APP_URL, LOGIN_SCREEN_ROUTE } from '../../../config';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { activeAccountAtom } from '../../../store/state/loginState';
import { useGetCurrentRoute } from '../../../utils/hooks/useGetCurrentRoute';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixpanelService } from '../../trackingAnalytics/services/mixPanel.service';
import { TrackingUser } from '../../trackingAnalytics/types';

type UseLoginWithOktaValues = {
  login: () => void;
  isAuthenticated: boolean;
};

const useLoginWithOkta = (): UseLoginWithOktaValues => {
  const { authState, oktaAuth } = useOktaAuth();
  const trackEvent = useTrackEvent();
  const location = useLocation();
  const setActiveAccount = useSetRecoilState(activeAccountAtom);
  const getCurrentRoute = useGetCurrentRoute();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [sessionAvailable, setSessionAvailable] = useState(false);

  useEffect(() => {
    if (location.state?.error) {
      oktaAuth.closeSession();
    } else {
      checkSessionAndStoreToken();
    }
  }, [location.state?.error]);

  useEffect(() => {
    const isAuthenticatedOnOkta =
      Boolean(authState?.isAuthenticated) && sessionAvailable;
    setIsAuthenticated(isAuthenticatedOnOkta);
  }, [authState, sessionAvailable]);

  useEffect(() => {
    if (sessionAvailable && authState?.isAuthenticated) {
      setSessionAvailable(false);
    }
  }, [authState, sessionAvailable]);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setActiveAccount(undefined);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setActiveAccount(info);
          const trackingUser: TrackingUser = {
            id: info.email,
            emailAddress: info.email,
          };
          MixpanelService.getInstance().setUser(trackingUser);
          trackEvent({
            eventName: MixPanelEvents.AUTHENTICATION_SUCCESS,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  const invalidateSession = async () => {
    if (getCurrentRoute() !== LOGIN_SCREEN_ROUTE) {
      oktaAuth.closeSession().then(() => {
        oktaAuth.tokenManager.clear();
        window.location.reload();
      });
    }
  };

  const login = async () => {
    await oktaAuth.signInWithRedirect({ originalUri: APP_URL });
  };

  const retrieveAndSetUserTokens = async () => {
    const tokenResponse = await oktaAuth.token.getWithoutPrompt({
      responseType: 'id_token',
    });

    let tokens = tokenResponse.tokens;
    await oktaAuth.tokenManager.setTokens(tokens);
    setSessionAvailable(true);
  };

  const sessionStrategies: Record<string, (params: any) => void> = {
    ACTIVE: retrieveAndSetUserTokens,
    INACTIVE: invalidateSession,
  };

  const checkSessionAndStoreToken = async () => {
    try {
      const session = await oktaAuth.session.get();
      const sessionStrategy: (params: any) => void = sessionStrategies[
        session?.status
      ]
        ? sessionStrategies[session?.status]
        : () => {};
      await sessionStrategy(session);
    } catch (_) {
      invalidateSession();
    }
  };

  return { login, isAuthenticated };
};

export default useLoginWithOkta;
