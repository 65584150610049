import { SegmentDtoProtocol } from './types';

export class SegmentDto implements SegmentDtoProtocol {
  segmentName = '';
  parentSegmentIds = [];
  includeFilters = [];
  includeNarrowFilters = [];
  excludeFilters = [];
  excludeNarrowFilters = [];

  constructor(override?: Partial<SegmentDtoProtocol>) {
    if (override) {
      Object.assign(this, override);
    }
  }
}
