import { Maybe } from 'types';
import { DependencyContainer } from '../../../DependencyContainer';
import { EditChannelDescriptionParameter } from '../types';

export default class ChannelsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getChannels(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.channelsClient.getChannels(partnerId);
  }

  async editChannelDescription(
    partnerId: Maybe<string>,
    channelId: string,
    parameters: EditChannelDescriptionParameter,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.channelsClient.editChannelDescription(
      partnerId,
      channelId,
      parameters,
    );
  }

  async deleteChannel(
    partnerId: Maybe<string>,
    channelId: string,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.channelsClient.deleteChannel(
      partnerId,
      channelId,
    );
  }
}
