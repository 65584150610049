import { AxiosResponse } from 'axios';

import { DependencyContainer } from '../../../DependencyContainer';
import { HttpClient } from '../../../HttpClient';
import { CrusadeApiUrls } from '../../../urls';
import { CampaignObjective } from '../types';

export default class CampaignObjectiveClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getObjectivesForPartner(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<CampaignObjective[]>>(
      CrusadeApiUrls.getCampaignObjectives(partnerId),
    );
  }

  async createObjective(
    partnerId: string,
    objective: Omit<CampaignObjective, 'id'>,
  ): Promise<any> {
    return this.post<AxiosResponse<CampaignObjective>>(
      CrusadeApiUrls.createCampaignObjective(partnerId),
      objective,
    );
  }

  async updateObjective(
    partnerId: string,
    objective: CampaignObjective,
  ): Promise<any> {
    return this.put<AxiosResponse<CampaignObjective>>(
      CrusadeApiUrls.updateCampaignObjective(partnerId, objective.id),
      objective,
    );
  }
}
