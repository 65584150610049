import React, { useContext, useState } from 'react';
import { Icons, PercentageRing, Tooltip, Badge } from 'plume-ui';
import { Handle, Position } from 'reactflow';

import formatValue from './AudienceUtils';
import { useTranslation } from 'react-i18next';
import { CreateAudienceContext } from '../../contexts/CreateAudienceContext';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { Routes } from 'config';
import { useSetRecoilState, useRecoilState } from 'recoil';
import {
  segmentForPreviewAtom,
  selectedSegmentAtom,
  activeSyncSegmentAtom,
  activeNodeAtom,
} from 'store/state/audienceFlowState';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { SegmentType } from '../../types';
import FormattedMessage from 'utils/components/FormattedMessage';
import {
  dataPreviewSelectedFilterAtom,
  dataPreviewSelectedPageAtom,
} from 'store/state/dataPreviewState';

const TreeNodeComponent = ({ data }: any) => {
  const redirectToRoute = useRedirectToRoute();
  const [flip, setFlip] = useState(false);
  const { setActiveSegmentType } = useContext(CreateAudienceContext);
  const { t } = useTranslation();
  const setSegmentForPreview = useSetRecoilState(segmentForPreviewAtom);
  const setIsSyncSegment = useSetRecoilState(activeSyncSegmentAtom);
  const [activeNode, setActiveNode] = useRecoilState(activeNodeAtom);

  const setDataPreivewSelectedPage = useSetRecoilState(
    dataPreviewSelectedPageAtom,
  );
  const setDataPreivewSelectedFilter = useSetRecoilState(
    dataPreviewSelectedFilterAtom,
  );

  const flipCard = () => {
    setFlip(!flip);
  };
  const setSelectedSegment = useSetRecoilState(selectedSegmentAtom);

  const tooltipSubSections = [
    { subheading: 'Population size', paragraph: data.value },
    {
      subheading: 'Targeting criteria',
      paragraph: data?.logicalExpression,
    },
    {
      subheading: 'Sync channel',
      paragraph:
        data?.syncCount > 0
          ? data.syncChannelNameList
          : t('treeNode.missingLabel'),
    },
    {
      subheading: 'Active campaigns',
      paragraph: data?.activeCampaignCount,
    },
  ];

  const handleSegmentPreview = (e: React.MouseEvent, segment: any) => {
    e.stopPropagation();
    setDataPreivewSelectedFilter('');
    setDataPreivewSelectedPage(0);
    setSegmentForPreview({ segmentId: segment.id, segmentName: segment.text });
    redirectToRoute(Routes.GenerateDataPreview, { segmentId: segment.id });
  };

  const { showModal } = useGlobalModalContext();

  const [showIconMenu, setShowIconMenu] = useState<boolean>(false);

  const handleIconMenu = () => {
    setShowIconMenu(!showIconMenu);
  };

  // NB: Icons in the footer will be uncommented once actions/user stories are added for them
  // FES-1141 -> Add icons but keep hidden

  if (flip === true) {
    return (
      <div className="TreeNode">
        <Handle
          type="target"
          position={Position.Top}
          style={{ borderRadius: 100 }}
        />
        <div>
          <div className="TreeNode__nodeTitle">{t('treeNode.flipped')}</div>
        </div>
        <div className="TreeNode__nodeFooter">
          <Tooltip
            alignment="center"
            label="Delete"
            position="above"
            liveMeasure={false}
            style={{
              opacity: null,
            }}
          >
            <Icons.ExportIcon
              width={16}
              className="TreeNode__cardIcon"
              onClick={data.deleteNode}
            />
          </Tooltip>

          <Tooltip
            alignment="center"
            label="Flip"
            position="above"
            liveMeasure={false}
            style={{
              opacity: null,
            }}
          >
            <Icons.InfoIcon
              width={16}
              onClick={() => flipCard()}
              className="TreeNode__cardIcon"
            />
          </Tooltip>
        </div>

        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          style={{ top: '50%%', borderRadius: 0 }}
        />
      </div>
    );
  }

  const displayPlus = (data: any) => {
    if (
      ![SegmentType.SplitEverythingElse, SegmentType.Split].includes(data.type)
    ) {
      return (
        <div
          className="TreeNode__nodeAddDiv"
          onClick={() => {
            showModal(
              MODAL_TYPES.SEGMENTS_MODAL,
              {
                mode: 'new',
                parentSegmentId: data.id,
                closeEvent: handleIconMenu,
              },
              MODAL_TYPES.SEGMENTS_MODAL,
            );
          }}
        >
          <Tooltip
            alignment="center"
            label={t('treeNode.newSegmentLabel')}
            position="below"
            liveMeasure={false}
            style={{
              opacity: null,
            }}
          >
            <Icons.AddIcon width={24} className="TreeNode__cardAddIcon" />
          </Tooltip>
        </div>
      );
    }
    return;
  };

  const renderCampaignCount = (
    active: number,
    paused: number,
    draft: number,
  ) => {
    if (active > 0) return active;
    if (paused > 0) return paused;
    if (draft > 0) return draft;
    return 0;
  };

  const renderCampaignNumericBadgeStatus = (
    active: number,
    paused: number,
    draft: number,
  ) => {
    if (active > 0) return 'active';
    if (paused > 0) return 'paused';
    if (draft > 0) return 'draft';
    return 'active';
  };

  const renderCampaignToolip = (
    active: number,
    paused: number,
    draft: number,
  ) => {
    return (
      'crusade.campaigns.status.' +
      renderCampaignNumericBadgeStatus(active, paused, draft)
    );
  };

  const handleSyncIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsSyncSegment(true);
    setActiveNode(data.id);
    setSelectedSegment({
      segmentName: data.text,
      segmentId: data.id,
    });
    showModal(
      MODAL_TYPES.SYNC_PREFERENCES_MODAL,
      {
        readOnly: false,
        activeSegmentName: data.text,
      },
      MODAL_TYPES.SYNC_PREFERENCES_MODAL,
    );
  };

  return (
    <div
      className={`TreeNode ${activeNode === data.id ? 'TreeNode__active' : ''}`}
    >
      <Handle
        type="target"
        position={Position.Top}
        style={{ borderRadius: 100 }}
      />
      <div
        className="TreeNode__content"
        onClick={() => {
          setActiveNode(data.id);
          setSelectedSegment({ segmentName: data.text, segmentId: data.id });
          setActiveSegmentType(data.type);
          if (
            [SegmentType.SplitEverythingElse, SegmentType.Split].includes(
              data.type,
            )
          ) {
            showModal(
              MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
              {
                mode: 'edit',
                id: data.id,
                segmentName: data.text,
                totalUser: data.value,
                parentId: data.parentId,
                closeEvent: handleIconMenu,
              },
              MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
            );
          } else {
            showModal(
              MODAL_TYPES.SEGMENTS_MODAL,
              {
                mode: 'edit',
                segmentId: data.id,
              },
              MODAL_TYPES.SEGMENTS_MODAL,
            );
          }
        }}
      >
        <div className="TreeNode__nodeHeader">
          <Tooltip
            alignment="center"
            label={t('treeNode.dataPreviewLabel')}
            position="above"
            liveMeasure={false}
            style={{
              opacity: null,
            }}
          >
            <Icons.DataPreviewIcon
              width={16}
              className="TreeNode__cardIcon"
              onClick={(e) => {
                handleSegmentPreview(e, data);
              }}
            />
          </Tooltip>
          <div className="TreeNode__percentageRing">
            <PercentageRing
              label={formatValue(data.value)}
              percentValue={data.perc}
            />
          </div>
          <Tooltip
            alignment="center"
            label={t('treeNode.syncSegment')}
            position="above"
            liveMeasure={false}
            style={{
              opacity: null,
            }}
          >
            <Icons.SyncIcon
              width={16}
              className="TreeNode__cardIcon"
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                handleSyncIconClick(e);
              }}
            />
          </Tooltip>
        </div>
        <div className="TreeNode__nodeTitleWrapper">
          <Tooltip
            alignment="center"
            label={data.text}
            position="right"
            liveMeasure={false}
            subSectionsObj={tooltipSubSections}
            style={{
              opacity: null,
            }}
          >
            <div className="TreeNode__nodeTitle">{data.text}</div>
          </Tooltip>
        </div>
        <div className="TreeNode__nodeFooter">
          {renderCampaignCount(
            data.activeCampaignCount,
            data.pausedCampaignCount,
            data.draftCampaignCount,
          ) === 0 ? (
            <Tooltip
              alignment="center"
              label={t('treeNode.campaignCount', {
                count: 0,
                type: t('crusade.campaigns.status.active').toLowerCase(),
              })}
              position="below"
              liveMeasure={false}
              style={{
                opacity: null,
              }}
            >
              <Icons.NoEntryIcon
                width={16}
                className="TreeNode__cardNoEntryIcon"
              />
            </Tooltip>
          ) : (
            <Tooltip
              alignment="center"
              label={t('treeNode.campaignCount', {
                count: renderCampaignCount(
                  data.activeCampaignCount,
                  data.pausedCampaignCount,
                  data.draftCampaignCount,
                ),
                type: t(
                  renderCampaignToolip(
                    data.activeCampaignCount,
                    data.pausedCampaignCount,
                    data.draftCampaignCount,
                  ),
                ).toLowerCase(),
              })}
              position="below"
              liveMeasure={false}
              style={{
                opacity: null,
              }}
            >
              <Badge
                badgeContent={renderCampaignCount(
                  data.activeCampaignCount,
                  data.pausedCampaignCount,
                  data.draftCampaignCount,
                ).toString()}
                size="mini"
                numericBadgeStatus={renderCampaignNumericBadgeStatus(
                  data.activeCampaignCount,
                  data.pausedCampaignCount,
                  data.draftCampaignCount,
                )}
              />
            </Tooltip>
          )}

          <div className="TreeNode__FooterLabel">
            <p>
              {[SegmentType.SplitEverythingElse, SegmentType.Split].includes(
                data.type,
              ) && <FormattedMessage id="treeNode.splitLabel" />}
            </p>
          </div>
          {false && (
            <Tooltip
              alignment="center"
              label={t('treeNode.crusadeLabel')}
              position="below"
              liveMeasure={false}
              style={{
                opacity: null,
              }}
            >
              <Icons.CrusadeIcon
                width={16}
                className="TreeNode__cardIcon"
                onClick={(event) => {
                  redirectToRoute(Routes.CrusadeCampaigns);
                  event.stopPropagation();
                }}
              />
            </Tooltip>
          )}
          {![SegmentType.SplitEverythingElse, SegmentType.Split].includes(
            data.type,
          ) && (
            <Tooltip
              alignment="center"
              label={t('treeNode.splitSegmentLabel')}
              position="below"
              liveMeasure={false}
              style={{
                opacity: null,
              }}
            >
              <Icons.SplitIcon
                width={16}
                className="TreeNode__cardIcon"
                onClick={(e) => {
                  e.stopPropagation();
                  showModal(
                    MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
                    {
                      mode: data.hasSplitChildren ? 'edit' : 'new',
                      parentId: data.id,
                      id: data.id,
                      segmentName: data.text,
                      totalUser: data.value,
                      closeEvent: handleIconMenu,
                    },
                    MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
                  );
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {/*
        clicking on the Add used to call data.addNode, and added a node
        we need to add this back in when connect the API and save/add node
      */}
      {displayPlus(data)}
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        className="TreeNode__hideFlowHandle"
      />
    </div>
  );
};

export default TreeNodeComponent;
