import { DependencyContainer } from 'DependencyContainer';
import { Button, GridItem, notify, PendingContent, Space } from 'plume-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  brandingCompanyNameAtom,
  brandingLogoAtom,
  brandingMobileApplicationNameAtom,
  brandingPrimaryColorAtom,
  brandingProductNameAtom,
  campaignConfigurationPayloadSelector,
  campaignEmailConfigAtom,
  customFooterAtom,
  displayNameAddressAtom,
  replyToAddressAtom,
} from 'store/state/configurationState';
import BrandingCard from '../components/BrandingCard';
import FooterCard from '../components/FooterCard';
import NameAddressCard from '../components/NameAddressCard';
import ReplyToAddressCard from '../components/ReplyToAddressCard';
import { useCampaignEmailConfiguration } from '../hooks/useCampaignEmailConfiguration';
import { MixPanelEvents } from 'mixPanelEvents';
import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { AxiosError } from 'axios';

const CampaignSetting: FunctionComponent = () => {
  const { t } = useTranslation();
  const { configurationService } = new DependencyContainer();
  const partnerId = useRecoilValue(partnerIdAtom);
  const { loading, error, runFetch } = useCampaignEmailConfiguration();
  const campaignConfiguration = useRecoilValue(campaignEmailConfigAtom);
  const [replyToAddressList, setReplyToAddressList] = useRecoilState(
    replyToAddressAtom,
  );
  const [displayNameAddressList, setDisplayNameAddressList] = useRecoilState(
    displayNameAddressAtom,
  );
  const [brandingLogoList, setBrandingLogoList] = useRecoilState(
    brandingLogoAtom,
  );
  const setBrandingPrimaryColor = useSetRecoilState(brandingPrimaryColorAtom);
  const setBrandingCompanyName = useSetRecoilState(brandingCompanyNameAtom);
  const setBrandingMobileAppName = useSetRecoilState(
    brandingMobileApplicationNameAtom,
  );
  const setBrandingProductName = useSetRecoilState(brandingProductNameAtom);
  const setCustomFooter = useSetRecoilState(customFooterAtom);
  const configurationPayload = useRecoilValue(
    campaignConfigurationPayloadSelector,
  );
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    if (
      campaignConfiguration.customFooter &&
      campaignConfiguration.customFooter.customFooterContent !== null
    ) {
      setCustomFooter(campaignConfiguration.customFooter);
    }

    if (campaignConfiguration.displayNameAddresses) {
      setDisplayNameAddressList(campaignConfiguration.displayNameAddresses);
    }

    if (campaignConfiguration.replyToAddresses) {
      setReplyToAddressList(campaignConfiguration.replyToAddresses);
    }

    if (
      campaignConfiguration.branding &&
      campaignConfiguration.branding.logos
    ) {
      setBrandingLogoList(campaignConfiguration.branding.logos);
    }

    if (campaignConfiguration.branding) {
      setBrandingPrimaryColor(campaignConfiguration.branding.colorPrimary);
    }

    if (
      campaignConfiguration.branding &&
      campaignConfiguration.branding.companyName
    ) {
      setBrandingCompanyName(campaignConfiguration.branding.companyName);
    }

    if (
      campaignConfiguration.branding &&
      campaignConfiguration.branding.mobileAppName
    ) {
      setBrandingMobileAppName(campaignConfiguration.branding.mobileAppName);
    }

    if (
      campaignConfiguration.branding &&
      campaignConfiguration.branding.productName
    ) {
      setBrandingProductName(campaignConfiguration.branding.productName);
    }
  }, [campaignConfiguration]);

  useEffect(() => {
    let displayNameAddressesValid = false;
    let replyToAddressesValid = false;
    let logoListValid = false;

    if (displayNameAddressList) {
      displayNameAddressesValid = displayNameAddressList.every(
        (displayName) => {
          return (
            displayName.fromDisplayName !== '' &&
            displayName.fromDisplayName !== null &&
            displayName.localPart !== '' &&
            displayName.localPart !== null &&
            !displayName.localPart.includes(' ') &&
            displayName.emailDomain !== '' &&
            displayName.emailDomain !== undefined &&
            displayName.emailIpPool !== '' &&
            displayName.emailIpPool !== undefined
          );
        },
      );
    }

    if (replyToAddressList) {
      replyToAddressesValid = replyToAddressList.every((address) => {
        return (
          address.fromDisplayName !== '' && address.fromDisplayName !== null
        );
      });
    }

    if (brandingLogoList) {
      logoListValid = brandingLogoList.every((logo) => {
        return logo.url !== '' && logo.url !== null;
      });
    }

    setFormValid(
      displayNameAddressesValid && replyToAddressesValid && logoListValid,
    );
  }, [displayNameAddressList, replyToAddressList, brandingLogoList]);

  const handleSave = async () => {
    try {
      setSaveInProgress(true);
      console.log('config payload', configurationPayload);
      await configurationService.editCampaignEmailConfiguration(
        partnerId,
        configurationPayload,
      );

      notify({
        id: 'configurationSaved',
        title: t('settings.campaignSetting.configurationSaved'),
        body: '',
        type: 'success',
        showDismiss: false,
        mustDismiss: false,
      });
      trackEvent({
        eventName: MixPanelEvents.EMAIL_CONFIG_SAVE_SUCCESS,
        additionalContent: {},
      });
      runFetch();
      setSaveInProgress(false);
    } catch (error) {
      setSaveInProgress(false);

      const mappedError = error as AxiosError;

      if (mappedError.response?.data.message) {
        notify({
          title: mappedError.response?.data.message,
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_SAVE_FAILURE,
          additionalContent: {
            ERROR_MESSAGE: mappedError.response?.data.message,
          },
        });
      } else {
        notify({
          title: t('settings.campaignSetting.configurationSaveFailed'),
          body: '',
          type: 'error',
          showDismiss: false,
          mustDismiss: false,
        });
        trackEvent({
          eventName: MixPanelEvents.CAMPAIGN_SAVE_FAILURE,
          additionalContent: {
            ERROR_MESSAGE: 'unknown',
          },
        });
      }
    }
  };

  return (
    <GridItem colSpan="12">
      <div className="CampaignSettingContainer__save">
        <Button
          styleVariant="superprimary"
          onClick={handleSave}
          disabled={saveInProgress || !formValid}
        >
          {t('settings.campaignSetting.saveChanges')}
        </Button>
      </div>
      <Space size="l"></Space>
      <PendingContent loading={loading || saveInProgress} isError={error}>
        <BrandingCard />
        <NameAddressCard />
        <ReplyToAddressCard />
        <FooterCard />
      </PendingContent>
      <Space size="l"></Space>
      <div className="CampaignSettingContainer__save">
        <Button
          styleVariant="superprimary"
          onClick={handleSave}
          disabled={saveInProgress || !formValid}
        >
          {t('settings.campaignSetting.saveChanges')}
        </Button>
      </div>
    </GridItem>
  );
};

export default CampaignSetting;
