import useCampaignStatusMapping from 'features/crusade/hooks/useCampaignStatusMapping';
import { CampaignStatus, CampaignType } from 'features/crusade/types';
import { Button, Icons, Status, Tooltip } from 'plume-ui';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { CampaignHistory } from '../../types';
import CampaignOverviewCardHeaderTitle from './CampaignOverviewCardHeaderTitle';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import { Routes } from 'config';

export type CampaignOverviewCardHeaderProps = {
  campaign: CampaignHistory;
};

const CampaignOverviewCardHeader: FunctionComponent<CampaignOverviewCardHeaderProps> = ({
  campaign,
}) => {
  const { t } = useTranslation();
  const { getCampaignStatusColorCode } = useCampaignStatusMapping();
  const redirectToRoute = useRedirectToRoute();

  const campaignIcon =
    campaign.campaignType === CampaignType.EMAIL ? (
      <Icons.EmailIcon
        width={24}
        className="CampaignOverview__cardHeader-emailIcon"
      />
    ) : (
      <Icons.AppNotificationIcon
        width={24}
        className="CampaignOverview__cardHeader-pushIcon"
      />
    );

  const getStatusTooltipLabel = () => {
    let status;

    switch (campaign.status) {
      case CampaignStatus.Active:
        status = t('overview.campaigns.active');
        break;
      case CampaignStatus.Paused:
        status = t('overview.campaigns.paused');
        break;
      case CampaignStatus.Completed:
        status = t('overview.campaigns.ended');
        break;
      default:
        return '';
    }

    return t('overview.campaigns.campaignStatusIs', { status });
  };

  const handleNpsClick = () => {
    redirectToRoute(Routes.NpsOverview, { campaignId: campaign.campaignId });
  };

  return (
    <div className="CampaignOverview__cardHeader">
      <div className="CampaignOverview__cardHeader__statusAndName">
        {campaign.status && (
          <Tooltip label={getStatusTooltipLabel()}>
            <Status
              classes={(current) => ({
                ...current,
                root: `${current.root} CampaignOverview__cardHeader-status`,
              })}
              color={getCampaignStatusColorCode(
                campaign.status as CampaignStatus,
              )}
            />
          </Tooltip>
        )}
        {campaignIcon}
        <CampaignOverviewCardHeaderTitle
          campaignName={campaign.campaignName}
          showRightButton={campaign.nps ?? false}
        />
      </div>
      {campaign.nps && (
        <Button
          styleVariant="tertiary"
          classes={(current) => ({
            ...current,
            root: `${current.root} CampaignOverview__cardHeader__resultsBtn`,
          })}
          onClick={handleNpsClick}
        >
          {t('overview.campaigns.viewResults')}
        </Button>
      )}
    </div>
  );
};

export default CampaignOverviewCardHeader;
