export enum MixPanelEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  LOGOUT = 'LOGOUT',
  SCREEN = 'SCREEN',
  SEGMENT_CREATE_SUCCESS = 'SEGMENT_CREATE_SUCCESS',
  SEGMENT_CREATE_FAILURE = 'SEGMENT_CREATE_FAILURE',
  SEGMENT_EDIT_SUCCESS = 'SEGMENT_EDIT_SUCCESS',
  SEGMENT_EDIT_FAILURE = 'SEGMENT_EDIT_FAILURE',
  SEGMENT_DELETE_SUCCESS = 'SEGMENT_DELETE_SUCCESS',
  SEGMENT_DELETE_FAILURE = 'SEGMENT_DELETE_FAILURE',
  CAMPAIGN_LAUNCH_SUCCESS = 'CAMPAIGN_LAUNCH_SUCCESS',
  CAMPAIGN_LAUNCH_FAILURE = 'CAMPAIGN_LAUNCH_FAILURE',
  CAMPAIGN_SAVE_SUCCESS = 'CAMPAIGN_SAVE_SUCCESS',
  CAMPAIGN_SAVE_FAILURE = 'CAMPAIGN_SAVE_FAILURE',
  EMAIL_CONFIG_SAVE_SUCCESS = 'EMAIL_CONFIGURATION_SAVE_SUCCESS',
  EMAIL_CONFIG_SAVE_FAILURE = 'EMAIL_CONFIG_SAVE_FAILURE',
}
