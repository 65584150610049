export enum Operator {
  EQUAL = '=',
  AT_LEAST = '>=',
  AT_MOST = '<=',
  MORE_THAN = '>',
  LESS_THAN = '<',
  NOT_EQUAL = '!=',
  BETWEEN = 'between',
  NOW = 'now',
  ANY_TIME_PAST = 'anytimeInPast',
  DAYS_AGO = 'daysAgo',
  EACH_OF_LAST = 'withinLastDays',
  NEXT = 'withinNextDays',
  BETWEEN_DAYS = 'betweenDays',
  BETWEEN_DATES = 'betweenDates',
  IN = 'in',
}

export const OperatorLabel: Record<string, string> = {
  '=': 'Equal',
  '>=': 'At least',
  '<=': 'At most',
  '>': 'More than',
  '<': 'Less than',
  '!=': 'Not equal',
  between: 'Between',
  now: 'Now',
  anytimeInPast: 'Anytime in the past',
  daysAgo: 'Days ago',
  withinLastDays: 'Each of last',
  withinNextDays: 'Next',
  betweenDays: 'Between',
  betweenDates: 'Choose dates',
};
