import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import NpsOverviewHeader from './NpsOverviewHeader';
import {
  AreaChartDataItem,
  NpsScoresOverTimeType,
  BarChartDataItem,
  NpsTimeRangeEnum,
  NpsResponseType,
} from '../../types';
import TimeRangeDropdown from './../TimeRangeDropdown';
import { AreaChart, BarChart, Grid, GridItem, PendingContent } from 'plume-ui';
import {
  npsAreaChartGroups,
  npsBarChartGroups,
} from '../../NpsOverviewFixture';
import { getNpsAreaChartData, getNpsBarChartData } from '../../NpsHelpers';
import NpsOverviewChartLegend from './NpsOverviewChartLegend';
import {
  nps30DayHistoryAtom,
  npsAllTimeHistoryAtom,
  npsOverviewGlobalTimeRangeAtom,
} from 'store/state/overviewState';
import { useRecoilState, useRecoilValue } from 'recoil';
import BarChartCustomTooltip from './BarChartCustomTooltip';
import { useTranslation } from 'react-i18next';
import AreaChartCustomTooltip from './AreaChartCustomTooltip';
import { useNps30DayHistory } from 'features/overview/hooks/useNps30DayHistory';
import { useParams } from 'react-router-dom';
import PlaceholderChart from 'components/PlaceholderChart/PlaceholderChart';
import { useNpsAllTimeHistory } from 'features/overview/hooks/useNpsAllTimeHistory';

const NpsOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useRecoilState(
    npsOverviewGlobalTimeRangeAtom,
  );
  const { campaignId } = useParams<{ campaignId: string }>();
  const { loading: loading30Day, error: error30Day } = useNps30DayHistory(
    campaignId,
  );
  const { loading: loadingAllTime, error: errorAllTime } = useNpsAllTimeHistory(
    campaignId,
  );
  const [nps30DayHistory, setNps30DayHistory] = useRecoilState(
    nps30DayHistoryAtom,
  );
  const [npsAllTimeHistory, setNpsAllTimeHistory] = useRecoilState(
    npsAllTimeHistoryAtom,
  );
  const [chartData30Day, setChartData30Day] = useState<BarChartDataItem[]>([]);
  const [chartDataAllTime, setChartDataAllTime] = useState<AreaChartDataItem[]>(
    [],
  );

  useEffect(() => {
    return () => {
      setNps30DayHistory({} as NpsResponseType);
      setNpsAllTimeHistory({} as NpsResponseType);
      setTimeRange({
        label: NpsTimeRangeEnum.THIRTY_DAYS,
        value: 30,
      });
    };
  }, []);

  useEffect(() => {
    if (timeRange.label === NpsTimeRangeEnum.THIRTY_DAYS) {
      if (nps30DayHistory?.scoresOverTime?.length) {
        const parsedData = getNpsBarChartData(nps30DayHistory.scoresOverTime);
        setChartData30Day(parsedData);
      }
    } else {
      if (npsAllTimeHistory?.scoresOverTime?.length) {
        const parsedData = getNpsAreaChartData(
          npsAllTimeHistory.scoresOverTime,
        );
        setChartDataAllTime(parsedData);
      }
    }
  }, [nps30DayHistory, npsAllTimeHistory, timeRange]);

  const chartDataReady = useMemo(() => {
    return timeRange.label === NpsTimeRangeEnum.THIRTY_DAYS
      ? chartData30Day &&
          chartData30Day.length &&
          nps30DayHistory.heroMetric &&
          nps30DayHistory.scoresOverTime
      : chartDataAllTime &&
          chartDataAllTime.length &&
          npsAllTimeHistory.heroMetric &&
          npsAllTimeHistory.scoresOverTime;
  }, [
    nps30DayHistory,
    npsAllTimeHistory,
    timeRange,
    chartData30Day,
    chartDataAllTime,
  ]);

  const tickFormatter = (value: any) => {
    return Math.floor(value * 100).toString();
  };

  const dynamicYAxisDomain = (scoresOverTime: NpsScoresOverTimeType[]) => {
    const nps = scoresOverTime.map((score) => score.npsScore);
    const min = (Math.min(...nps) - 10) / 100;
    const max = (Math.max(...nps) + 10) / 100;

    return [Number(min), Number(max)];
  };

  return (
    <div className="NpsOverview">
      <Grid>
        <GridItem colSpan="12" tabletColSpan="6">
          <NpsOverviewHeader
            campaignName={
              timeRange.label === NpsTimeRangeEnum.THIRTY_DAYS
                ? nps30DayHistory.campaignName
                : npsAllTimeHistory.campaignName
            }
          />
        </GridItem>
        <GridItem colSpan="12" tabletColSpan="6">
          <div className="NpsOverview__container">
            <TimeRangeDropdown
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              nps
            />
            <PendingContent
              isError={!!error30Day || !!errorAllTime}
              loading={loading30Day || loadingAllTime}
            >
              {chartDataReady ? (
                <div className="NpsOverview__chart">
                  <NpsOverviewChartLegend
                    heroMetrics={
                      timeRange.label === NpsTimeRangeEnum.THIRTY_DAYS
                        ? nps30DayHistory.heroMetric
                        : npsAllTimeHistory.heroMetric
                    }
                  />
                  {timeRange.label === NpsTimeRangeEnum.THIRTY_DAYS ? (
                    <BarChart
                      data={chartData30Day as BarChartDataItem[]}
                      groups={npsBarChartGroups}
                      domainY={[-100, 100]}
                      stacked={true}
                      roundEveryCell={false}
                      legendPosition="none"
                      customTooltip={
                        <BarChartCustomTooltip
                          data={nps30DayHistory.scoresOverTime}
                        />
                      }
                      yAxisLabel={t('overview.nps.npsScore')}
                      config={{
                        showPositiveNegative: true,
                        positiveNegativeColors: {
                          positive: '#17E3AE',
                          negative: '#FA1478',
                        },
                      }}
                    />
                  ) : (
                    <AreaChart
                      data={chartDataAllTime as AreaChartDataItem[]}
                      groups={npsAreaChartGroups}
                      curveType="linear"
                      statusVariant="standard"
                      legendPosition="none"
                      yAxisLabel={t('overview.nps.npsScore')}
                      showPercentageValue
                      domainY={dynamicYAxisDomain(
                        npsAllTimeHistory.scoresOverTime,
                      )}
                      yAxisAllowDataOverflow
                      tickFormatter={tickFormatter}
                      customTooltip={
                        <AreaChartCustomTooltip
                          data={npsAllTimeHistory.scoresOverTime}
                        />
                      }
                    />
                  )}
                </div>
              ) : (
                <PlaceholderChart />
              )}
            </PendingContent>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default NpsOverview;
