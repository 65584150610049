import { AxiosResponse } from 'axios';
import { DependencyContainer } from 'DependencyContainer';
import { HttpClient } from 'HttpClient';
import { HarvestApiUrls } from 'urls';
import { GenerateAddSyncPayload, GenerateUpdateSyncPayload } from '../types';

export default class SyncClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getSyncs(partnerId: string): Promise<AxiosResponse<any>> {
    return await this.get<AxiosResponse<any>>(
      HarvestApiUrls.getSyncs(partnerId),
    );
  }

  async addSync(
    partnerId: string,
    segmentId: string,
    sync: GenerateAddSyncPayload[],
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      HarvestApiUrls.addSync(partnerId, segmentId),
      sync,
    );
  }

  async updateSync(
    partnerId: string,
    segmentId: string,
    syncId: string,
    sync: GenerateUpdateSyncPayload,
  ): Promise<AxiosResponse<any>> {
    return await this.patch<AxiosResponse<any>>(
      HarvestApiUrls.updateSync(partnerId, segmentId, syncId),
      sync,
    );
  }

  async deleteSync(
    partnerId: string,
    segmentNodeId: string,
    syncId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.delete<AxiosResponse<any>>(
      HarvestApiUrls.deleteSync(partnerId, segmentNodeId, syncId),
    );
  }
}
