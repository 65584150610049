import React, { FunctionComponent, useMemo } from 'react';
import { Button } from 'plume-ui';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { useRecoilValue } from 'recoil';
import { CriteriaCollectionType } from 'components/CriteriaCollapseCard/CriteriaCollapseCard';
import { Operator } from 'components/PatternMatcher/OperatorEnum';
import { PatternColumnValue } from 'features/generate/audience/types';
import { SelectedSegmentAtom } from '../../store/state/audienceFlowState';

export type SegmentsFooterModalProps = {
  handleExplicitCancelModal: () => void;
  selectedSegment: SelectedSegmentAtom;
  handleUpdate: () => void;
  handleSave: () => void;
  saveDisabled?: boolean;
  selectedCriteriaCollection: CriteriaCollectionType;
};

export const SegmentsFooterModal: FunctionComponent<SegmentsFooterModalProps> = ({
  handleExplicitCancelModal,
  saveDisabled = false,
  handleUpdate,
  selectedSegment,
  handleSave,
  selectedCriteriaCollection,
}) => {
  const selectedCriteriaHasValidValues = useMemo(() => {
    const includeFiltersValid =
      selectedCriteriaCollection.includeFilters.length === 0
        ? true
        : selectedCriteriaCollection.includeFilters.every(
            (criteria) =>
              criteria.operator === Operator.NOW ||
              criteria.columnValue !== PatternColumnValue.SELECT_VALUE,
          );
    let excludeFiltersValid =
      selectedCriteriaCollection.excludeFilters.length === 0
        ? true
        : selectedCriteriaCollection.excludeFilters.every(
            (criteria) =>
              criteria.operator === Operator.NOW ||
              criteria.columnValue !== PatternColumnValue.SELECT_VALUE,
          );
    const includeNarrowFiltersValid =
      selectedCriteriaCollection.includeNarrowFilters.length === 0
        ? true
        : selectedCriteriaCollection.includeNarrowFilters
            .map((narrowFilterArray) => {
              return narrowFilterArray.every(
                (criteria) =>
                  criteria.operator === Operator.NOW ||
                  criteria.columnValue !== PatternColumnValue.SELECT_VALUE,
              );
            })
            .every((condition) => condition);
    let excludeNarrowFiltersValid =
      selectedCriteriaCollection.excludeNarrowFilters.length === 0
        ? true
        : selectedCriteriaCollection.excludeNarrowFilters
            .map((narrowFilterArray) => {
              return narrowFilterArray.every(
                (criteria) =>
                  criteria.operator === Operator.NOW ||
                  criteria.columnValue !== PatternColumnValue.SELECT_VALUE,
              );
            })
            .every((condition) => condition);

    return (
      includeFiltersValid &&
      excludeFiltersValid &&
      includeNarrowFiltersValid &&
      excludeNarrowFiltersValid
    );
  }, [selectedCriteriaCollection]);

  return (
    <>
      <Button styleVariant="tertiary-grey" onClick={handleExplicitCancelModal}>
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        styleVariant="superprimary"
        onClick={selectedSegment?.segmentId ? handleUpdate : handleSave}
        disabled={saveDisabled || !selectedCriteriaHasValidValues}
      >
        <FormattedMessage id="save" />
      </Button>
    </>
  );
};
