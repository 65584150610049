import {
  createAudienceFlow,
  createAudienceFlows,
} from '../../../../utils/fixtures/Audience.fixture';
import { DependencyContainer } from '../../../../DependencyContainer';
import {
  AudienceFlowResponse,
  GenerateVersionResponse,
  SegmentSplitNodeDto,
  SegmentSplitNodeResponse,
  SegmentTreeApiResponse,
} from '../types';
import { HarvestApiUrls } from '../../../../urls';
import { AxiosResponse } from 'axios';
import { HttpClient } from '../../../../HttpClient';

export default class AudienceClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getAudienceFlows(
    page: number = 0,
    limit: number = 10,
    query?: string,
  ): Promise<AudienceFlowResponse> {
    const predefined1 = createAudienceFlow({
      segmentName: 'Predefined 1',
    });
    const predefined2 = createAudienceFlow({
      segmentName: 'Predefined Something New',
    });
    let total = [predefined1, predefined2, ...createAudienceFlows(23)];
    if (query) {
      total = total.filter((flow) =>
        flow.segmentName.toLowerCase().includes(query.toLowerCase()),
      );
    }
    const flows = total.slice(page * limit, (page + 1) * limit);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: flows,
          total: total.length,
        });
      }, 500);
    });
  }

  async getSegmentDataTree(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<SegmentTreeApiResponse>>(
      HarvestApiUrls.getAudienceTree(partnerId),
    );
  }

  async addSegment(
    partnerId: string,
    segment: any,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      HarvestApiUrls.addSegmentNode(partnerId),
      segment,
    );
  }

  async updateSegment(
    partnerId: string,
    segment: any,
    segmentId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.patch<AxiosResponse<any>>(
      HarvestApiUrls.updateSegmentNode(partnerId, segmentId),
      segment,
    );
  }

  async deleteSegment(
    partnerId: string,
    segmentId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.delete<AxiosResponse<any>>(
      HarvestApiUrls.deleteSegmentNode(partnerId, segmentId),
    );
  }

  async getSplitNode(
    partnerId: string,
    segmentId: string,
  ): Promise<SegmentSplitNodeResponse> {
    return this.get<AxiosResponse<any>>(
      HarvestApiUrls.getSplitNode(partnerId, segmentId),
    );
  }

  async addSplitNode(
    partnerId: string,
    segmentNodeId: string,
    dto: SegmentSplitNodeDto,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      HarvestApiUrls.addSplitNode(partnerId, segmentNodeId),
      dto,
    );
  }

  async updateSplitNode(
    partnerId: string,
    segmentNodeId: string,
    dto: SegmentSplitNodeDto,
  ): Promise<AxiosResponse<any>> {
    return await this.patch<AxiosResponse<any>>(
      HarvestApiUrls.addSplitNode(partnerId, segmentNodeId),
      dto,
    );
  }

  async deleteSplitNode(
    partnerId: string,
    segmentNodeId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.delete<AxiosResponse<any>>(
      HarvestApiUrls.deleteSplitNode(partnerId, segmentNodeId),
    );
  }

  async getApiVersion(): Promise<AxiosResponse<GenerateVersionResponse>> {
    return await this.get<AxiosResponse<GenerateVersionResponse>>(
      HarvestApiUrls.getApiVersionUrl(),
    );
  }
}
