import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { ConfirmationModal } from 'plume-ui';
import React, { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type DeleteModalProps = {
  deletingItem: string;
  onConfirm: () => void;
  isOpen: boolean;
};

export const DeleteModal: FunctionComponent<DeleteModalProps> = ({
  deletingItem,
  isOpen,
  ...props
}) => {
  const { t } = useTranslation();

  const { hideModal } = useGlobalModalContext();

  const onConfirm = () => {
    props.onConfirm();
    hideModal(MODAL_TYPES.DELETE_MODAL);
  };

  const header = useMemo(() => {
    switch (deletingItem) {
      case 'connection':
        return t('settings.connection.deleteConnection');
      case 'campaign':
        return t('crusade.campaigns.table.deleteCampaign');
      default:
        return t('delete');
    }
  }, [deletingItem]);

  return (
    <>
      <ConfirmationModal
        title={header}
        isOpen={isOpen}
        onConfirm={() => onConfirm()}
        onRequestClose={() => hideModal(MODAL_TYPES.DELETE_MODAL)}
        confirmTitle={t('delete')}
      >
        {t('thisActionCannotBeUndone')}
      </ConfirmationModal>
    </>
  );
};
