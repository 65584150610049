import { DependencyContainer } from 'DependencyContainer';
import { CampaignConfigurationPayloadType } from 'features/crusade/types';
import { Maybe } from 'types';

export default class ConfigurationService {
  constructor(private readonly factory: DependencyContainer) {}

  async getCampaignConfigEmailDomainList(
    partnerId: Maybe<string>,
  ): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.configurationClient.getCampaignConfigEmailDomainList(
      partnerId,
    );
  }

  async getCampaignEmailConfiguration(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.configurationClient.getCampaignEmailConfiguration(
      partnerId,
    );
  }

  async editCampaignEmailConfiguration(
    partnerId: Maybe<string>,
    configuration: CampaignConfigurationPayloadType,
  ): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.configurationClient.editCampaignEmailConfiguration(
      partnerId,
      configuration,
    );
  }
}
