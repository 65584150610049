import { createContext, Dispatch, SetStateAction } from 'react';

export enum Mode {
  ReadOnly,
  Update,
  Insert,
}

export type CreateAudienceContextValues = {
  showHistory: boolean;
  setShowHistory: Dispatch<SetStateAction<boolean>>;
  showTagsModal: boolean;
  setShowTagsModal: Dispatch<SetStateAction<boolean>>;
  showExportOptions: boolean;
  setShowExportOptions: Dispatch<SetStateAction<boolean>>;
  activeSegmentId: string;
  setActiveSegmentId: Dispatch<SetStateAction<string>>;
  activeSegmentType: string;
  setActiveSegmentType: Dispatch<SetStateAction<string>>;
  editSegmentId: string;
  setEditSegmentId: Dispatch<SetStateAction<string>>;
  setIsDisabledSave: Dispatch<SetStateAction<boolean>>;
  isDisabledSave: boolean;
};

export const CreateAudienceContext = createContext<CreateAudienceContextValues>(
  {
    showHistory: false,
    setShowHistory: () => {},
    showTagsModal: false,
    setShowTagsModal: () => {},
    showExportOptions: false,
    setShowExportOptions: () => {},
    activeSegmentId: '',
    setActiveSegmentId: () => {},
    setActiveSegmentType: () => {},
    activeSegmentType: '',
    editSegmentId: '',
    setEditSegmentId: () => {},
    setIsDisabledSave: () => {},
    isDisabledSave: false,
  },
);

CreateAudienceContext.displayName = 'CreateAudienceContext';
