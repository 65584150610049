import { Maybe } from 'types';
import { DependencyContainer } from '../../../DependencyContainer';

export default class CampaignTemplateService {
  constructor(private readonly factory: DependencyContainer) {}

  async getCampaignTemplates(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.campaignTemplateClient.getCampaignTemplates(
      partnerId,
    );
  }

  async createDraftCampaignFromTemplate(
    partnerId: Maybe<string>,
    templateId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.campaignTemplateClient.createDraftCampaignFromTemplate(
      partnerId,
      templateId,
    );
  }
}
