import PlaceholderChart from 'components/PlaceholderChart/PlaceholderChart';
import { useAudience } from 'features/generate/audience/hooks/useAudience';
import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from 'features/trackingAnalytics/types';
import { MixPanelEvents } from 'mixPanelEvents';
import { Grid, GridItem, Heading, LineChart, PendingContent } from 'plume-ui';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { firstTenSegmentsSelector } from 'store/state/audienceFlowState';
import {
  segmentHistoryAtom,
  segmentOverviewGlobalTimeRangeAtom,
} from 'store/state/overviewState';
import { useSegmentHistory } from '../../hooks/useSegmentHistory';
import {
  convertSegmentDataToLineChartGroups,
  createLineChartData,
} from '../../SegmentHelpers';
import TimeRangeDropdown from '../TimeRangeDropdown';
import { useTranslation } from 'react-i18next';

const SegmentOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const [timeRange, setTimeRange] = useRecoilState(
    segmentOverviewGlobalTimeRangeAtom,
  );

  const {
    loading: segmentDataLoading,
    error: segmentDataError,
  } = useAudience();

  const firstTenSegments = useRecoilValue(firstTenSegmentsSelector);
  const {
    loading: loadingSegmentHistory,
    error: errorSegmentHistory,
    runFetchSegmentHistory,
  } = useSegmentHistory(timeRange.value, firstTenSegments);
  const segmentHistory = useRecoilValue(segmentHistoryAtom);

  const chartGroups = convertSegmentDataToLineChartGroups(
    firstTenSegments || [],
  );
  const [parsedData, setParsedData] = useState<any>(undefined);

  useEffect(() => {
    if (segmentHistory && segmentHistory.length) {
      const parsedData = createLineChartData(segmentHistory, firstTenSegments);
      setParsedData(parsedData);
    }
  }, [segmentHistory]);

  useEffect(() => {
    runFetchSegmentHistory({
      numOfDays: timeRange.value,
      segments: firstTenSegments,
    });
  }, [timeRange, firstTenSegments]);

  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.SegmentOverview,
      },
    });
  }, []);

  return (
    <div className="SegmentOverview__Grid">
      <Grid>
        <GridItem colSpan="12" tabletColSpan="6">
          <TimeRangeDropdown
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            nps={false}
          />
        </GridItem>
        <GridItem colSpan="12" tabletColSpan="6">
          <div className="SegmentOverview">
            <Heading
              medium
              size="m"
              classes={(current) => ({
                ...current,
                root: `${current.root} SegmentOverview__heading`,
              })}
            >
              {t('overview.mySegments')}
            </Heading>
            <PendingContent
              loading={
                segmentDataLoading || loadingSegmentHistory || !firstTenSegments
              }
              isError={
                Boolean(segmentDataError) || Boolean(errorSegmentHistory)
              }
            >
              {parsedData && parsedData.length ? (
                <LineChart
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} SegmentOverview__lineChart`,
                    legend: `${current.legend} SegmentOverview__lineChart-legend`,
                    legendItem: `${current.legendItem} SegmentOverview__lineChart-legendItem`,
                    tooltipGroupsLabel: `${current.tooltipGroupsLabel} SegmentOverview__tooltipGroupsLabel`,
                  })}
                  curveType="linear"
                  data={parsedData}
                  groups={chartGroups}
                  legendPosition="top"
                  domainY={[0, 'auto']}
                  statusVariant="legend"
                  showLastElementOnXAxis={timeRange.value === 7}
                  showFirstElementOnXAxis={timeRange.value === 7}
                />
              ) : (
                <PlaceholderChart />
              )}
            </PendingContent>
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default SegmentOverview;
