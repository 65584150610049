import i18next from 'i18next';
import {
  CampaignDtoType,
  CampaignPushNotificationCardValues,
  CampaignStatus,
  CampaignType,
  CampaignTypes,
  CrusadeCampaign,
  EndRecurrenceConditionTypes,
  EndRecurrenceType,
  PushCampaignMessageOnClickEnum,
} from '../types';
import { convertDateToTimezone } from './crusadeHelpers';

export const defaultDeliverySettings = {
  recurrence: 'once',
  endDate: '',
  startDate: '',
  endTime: '',
  startTime: '',
  weekly: [],
  frequency: 1,
  endRecurrence: {
    condition: 'neverEnds' as EndRecurrenceConditionTypes,
    endDate: '',
  },
};

const defaultAudienceSegments = [{ segmentId: '', segmentName: '' }];

export const getCampaignDefault = (
  companyNameInStore: string | undefined,
): CampaignDtoType => {
  return {
    campaignInformationCard: {
      campaignName: '',
      description: '',
      journeyId: '',
      campaignType: CampaignType.PUSH_NOTIFICATION,
      status: 'Draft' as CampaignStatus,
      campaignId: '',
      objectives: null,
    },

    campaignAudienceCard: {
      audienceSegments: defaultAudienceSegments,
      audienceSize: '0',
      audienceSummary: '',
      sendToTheseUsers: 'Users who are subscribed or opted in',
    },

    campaignPushNotificationCard: {
      title: companyNameInStore,
      messageBody: '',
      onClickBehavior: {
        action: PushCampaignMessageOnClickEnum.OPEN_APP,
      },
    },

    campaignMessageCard: {
      from: '',
      subject: '',
      emailBody: '',
      emailPreheaderOverwrite: '',
      dynamicContent: [],
    },

    campaignDeliveryCard: {
      delivery: defaultDeliverySettings,
      immediately: true,
      warmup: false,
    },
  };
};

// How we populate form with existing campaign data (in Edit mode)
export const prepareFormData = (
  editingCampaign: CrusadeCampaign,
  formDto: any,
): CampaignDtoType => {
  let localStartDateTime;
  let localEndDateTime;
  if (editingCampaign.delivery) {
    localStartDateTime = convertDateToTimezone(
      editingCampaign.delivery?.startDate,
      editingCampaign.delivery?.startTime,
      'local',
    );

    localEndDateTime = convertDateToTimezone(
      editingCampaign.delivery?.endDate,
      editingCampaign.delivery?.endTime,
      'local',
    );
  }

  return {
    ...formDto,
    campaignInformationCard: {
      ...formDto.campaignInformationCard,
      campaignName: editingCampaign.campaignName,
      description: editingCampaign.description,
      journeyId: editingCampaign.journeyId,
      campaignType: editingCampaign.campaignType as CampaignTypes,
      status: editingCampaign.status,
      campaignId: editingCampaign.campaignId,
      objectives: editingCampaign.objectives,
    },

    campaignAudienceCard: {
      ...formDto.campaignAudienceCard,
      audienceSegments: editingCampaign.audienceSegments
        ? editingCampaign.audienceSegments
        : defaultAudienceSegments,
      audienceSize: editingCampaign.audienceSize || 0,
      audienceSummary: editingCampaign.audienceSummary,
      sendToTheseUsers: editingCampaign?.sendToTheseUsers,
    },

    campaignPushNotificationCard: {
      ...formDto.campaignPushNotificationCard,
      title: editingCampaign.message?.title || '',
      messageBody:
        (editingCampaign.message as CampaignPushNotificationCardValues)
          ?.messageBody || '',
      onClickBehavior: {
        action: editingCampaign.message?.onClickBehavior?.action || 'openApp',
        redirectURL:
          editingCampaign?.message?.onClickBehavior?.redirectURL || '',
      },
    },

    campaignMessageCard: {
      ...formDto.campaignMessageCard,
      from: editingCampaign.from || '',
      subject: editingCampaign.subject || '',
      emailBody: editingCampaign.emailBody || '',
      emailPreheaderOverwrite: editingCampaign.emailPreheaderOverwrite || '',
      dynamicContent: editingCampaign.dynamicContent || [],
    },

    campaignDeliveryCard: {
      ...formDto.campaignDeliveryCard,
      delivery: editingCampaign.delivery
        ? {
            recurrence: editingCampaign.delivery?.recurrence || 'once',
            endDate: localEndDateTime?.date || '',
            startDate: localStartDateTime?.date || '',
            endTime: localEndDateTime?.time || '',
            startTime: localStartDateTime?.time || '',
            frequency: editingCampaign.delivery?.frequency || 1,
            endRecurrence: (editingCampaign.delivery
              ?.endRecurrence as EndRecurrenceType) || {
              condition: 'neverEnds',
              endDate: '',
            },
            weekly: editingCampaign.delivery.weekly || [],
          }
        : defaultDeliverySettings,
      immediately:
        editingCampaign.immediately === undefined
          ? true
          : editingCampaign.immediately,
      warmup: editingCampaign.warmup,
    },
  };
};

export const prepareCampaignDto = (formData: any) => {
  let utcEndInput;

  const campaignInfo = {
    ...formData.campaignInformationCard,
    campaignDescription: formData.campaignInformationCard.description,
  };

  const campaignObjectives = formData.campaignInformationCard
    .objectives as string[];
  if (campaignObjectives) {
    campaignInfo.objectiveNames = campaignObjectives;
  }

  delete campaignInfo.objectives;
  delete campaignInfo.description;

  const utcStartInput = convertDateToTimezone(
    formData.campaignDeliveryCard.delivery.startDate,
    formData.campaignDeliveryCard.delivery.startTime,
    'utc',
  );

  if (
    formData.campaignDeliveryCard.delivery?.endDate &&
    formData.campaignDeliveryCard.delivery?.endTime
  ) {
    utcEndInput = convertDateToTimezone(
      formData.campaignDeliveryCard.delivery.endDate,
      formData.campaignDeliveryCard.delivery.endTime,
      'utc',
    );
  }

  formData.campaignDeliveryCard.delivery.startDate = utcStartInput.date;
  formData.campaignDeliveryCard.delivery.startTime = utcStartInput.time;

  if (utcEndInput) {
    formData.campaignDeliveryCard.delivery.endDate = utcEndInput.date;
    formData.campaignDeliveryCard.delivery.endTime = utcEndInput.time;
  }

  const campaignAudience = {
    ...JSON.parse(JSON.stringify(formData.campaignAudienceCard)),
    audienceSize:
      formData.campaignAudienceCard.audienceSize === i18next.t('NA')
        ? 0
        : formData.campaignAudienceCard.audienceSize,
    audienceSummary:
      formData.campaignAudienceCard.audienceSummary === i18next.t('NA')
        ? ''
        : formData.campaignAudienceCard.audienceSummary,
  };

  return {
    ...campaignInfo,
    ...campaignAudience,
    message: {
      ...JSON.parse(JSON.stringify(formData.campaignPushNotificationCard)),
    },
    ...formData.campaignMessageCard,
    ...JSON.parse(JSON.stringify(formData.campaignDeliveryCard)),
  };
};

export const defaultCampaign = (
  companyNameInStore: string,
): CrusadeCampaign => {
  return {
    campaignId: '',
    campaignType: 'pushNotification',
    campaignName: '',
    description: '',
    journeyId: '',
    tags: [],
    audienceSegments: [{ segmentId: '', segmentName: '' }],
    audienceSummary: '',
    audienceSize: '0',
    message: {
      title: companyNameInStore,
      messageBody: '',
      onClickBehavior: {
        action: 'openApp',
      },
    },
    delivery: {
      recurrence: 'once',
      endDate: '',
      startDate: '',
      endTime: '',
      startTime: '',
      weekly: [],
      frequency: 1,
      endRecurrence: {
        condition: 'neverEnds',
        endDate: '',
      },
    },
    immediately: true,
    warmup: false,
    sendToTheseUsers: 'Users who are subscribed or opted in',
    status: 'Draft' as CampaignStatus,
    from: '',
    subject: '',
    dynamicContent: [{ id: '', type: '', label: '', value: false }],
  };
};
