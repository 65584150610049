import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { campaignTemplatesAtom } from 'store/state/campaignState';

type UseCampaignTemplatesValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { campaignTemplateService } = new DependencyContainer();

export const useCampaignTemplates = (): UseCampaignTemplatesValues => {
  const setCampaignTemplates = useSetRecoilState(campaignTemplatesAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const [loading, , error, runFetch] = useFetchData(
    async () => {
      return await campaignTemplateService.getCampaignTemplates(partnerId);
    },
    (response) => setCampaignTemplates(response.data),
    [partnerId],
    () => partnerId === undefined,
  );

  return {
    loading,
    error,
    runFetch,
  };
};
