import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { CreateAudienceContext } from '../../contexts/CreateAudienceContext';
import {
  activeSyncSegmentAtom,
  getSegmentTreeByIdSelector,
  segmentTreeSelector,
  selectedSegmentAtom,
} from '../../../../../store/state/audienceFlowState';
import { useAudience } from '../../hooks/useAudience';
import AudienceFlow from '../audienceFlow/AudienceFlow';
import { useRecoilValue } from 'recoil';
import { ReactFlowProvider } from 'reactflow';
import { AudienceRef } from '../audienceFlow/AudienceFlow.types';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { SegmentType } from '../../types';

const CreateAudience: FunctionComponent = () => {
  const isSyncSegment = useRecoilValue(activeSyncSegmentAtom);
  const audienceFlowRef = useRef<AudienceRef>(null);

  const { showModal } = useGlobalModalContext();

  /**
   * Populate segments tree with data from backend
   */
  useAudience();

  /**
   * Get segments tree from recoil state
   */
  const treeState = useRecoilValue(segmentTreeSelector);

  /**
   * Create a copy of the segments tree
   */
  const treedata = useMemo(() => JSON.parse(JSON.stringify(treeState)), [
    treeState,
  ]);

  /**
   * Selected segment
   */
  const selectedSegment = useRecoilValue(selectedSegmentAtom);
  const editSegmentDetails = useRecoilValue(
    getSegmentTreeByIdSelector(selectedSegment?.segmentId || ''),
  );

  useEffect(() => {
    if (treedata && selectedSegment?.segmentId && !isSyncSegment) {
      audienceFlowRef.current?.flyToSegment(selectedSegment.segmentId);

      if (
        editSegmentDetails?.segmentType &&
        [SegmentType.SplitEverythingElse, SegmentType.Split].includes(
          editSegmentDetails?.segmentType as SegmentType,
        )
      ) {
        showModal(
          MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
          {
            mode: 'edit',
            id: editSegmentDetails.segmentId,
            segmentName: editSegmentDetails.segmentName,
            totalUser: editSegmentDetails.rowCount,
            parentId: editSegmentDetails.parentSegmentIds![0] || null,
            closeEvent: () => {},
          },
          MODAL_TYPES.SPLIT_SEGMENTS_MODAL,
        );
      } else {
        showModal(
          MODAL_TYPES.SEGMENTS_MODAL,
          { mode: 'edit', segmentId: selectedSegment.segmentId },
          MODAL_TYPES.SEGMENTS_MODAL,
        );
      }
    }
  }, [selectedSegment?.segmentId]);

  // TODO: work out how to know its a new segment
  // useEffect(() => {
  //   if (true)
  //     audienceFlowRef.current?.flyToSegment(selectedSegment.segmentId);
  // }, []);

  return (
    <ReactFlowProvider>
      <div className="CreateAudience">
        <div className="CreateAudience__content">
          <div className="CreateAudience__audienceMap">
            {treedata && treedata!.length > 0 && (
              <AudienceFlow data={treedata} ref={audienceFlowRef} />
            )}
          </div>
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default CreateAudience;
