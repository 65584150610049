import { CardTypes } from './CriteriaCollapseCard';

// Helpers for Card
export const findFilterCategory = (cardType: CardTypes, parentIdx: number) => {
  if (cardType === 'includeSearch') {
    return 'includeFilters';
  } else if (cardType === 'excludeSearch') {
    return 'excludeFilters';
  } else {
    if (parentIdx === 0) {
      return 'includeNarrowFilters';
    } else {
      return 'excludeNarrowFilters';
    }
  }
};
