import { atom, selector, selectorFamily } from 'recoil';
import { Maybe } from 'types';
import { DependencyContainer } from '../../DependencyContainer';
import {
  AudienceSizeResponse,
  MetadataTree,
  ParsedMetadataTreeType,
} from '../../features/generate/audience/types';

export const metadataTreeAtom = atom<MetadataTree[]>({
  key: 'metadataTreeAtom',
  default: [],
});

export const segmentAudienceSizeAtom = atom<Maybe<AudienceSizeResponse>>({
  key: 'segmentAudienceSizeAtom',
  default: undefined,
});

export const metadataAllowedValuesAtom = atom<string[]>({
  key: 'metadataAllowedValuesAtom',
  default: [],
});

export const criteriaMultiviewMetadataAtom = atom<boolean>({
  key: 'criteriaMultiviewMetadataAtom',
  default: true,
});

export const parsedMetadataTreeSelector = selector<ParsedMetadataTreeType[]>({
  key: 'parsedMetadataTreeSelector',
  get: ({ get }) => {
    const { traitsService } = new DependencyContainer();
    const metadataTree = get(metadataTreeAtom);
    const segmentSizes = get(segmentAudienceSizeAtom);

    const parsedTreeWithCategories = traitsService.mapTraitData(
      metadataTree,
      segmentSizes,
    );

    return parsedTreeWithCategories;
  },
});

export const getMetadataByColumnNameSelector = selectorFamily<
  MetadataTree | null,
  string
>({
  key: 'getMetadataByColumnNameSelector',
  get: (columnName: string) => ({ get }) => {
    const metadataTree = get(metadataTreeAtom);
    return metadataTree.find((node) => node.columnName === columnName) ?? null;
  },
});

export const getSegmentAudienceSizeSelector = selector({
  key: 'getSegmentAudienceSizeSelector',
  get: ({ get }): any | null | undefined => {
    const segmentSizes = get(segmentAudienceSizeAtom);

    if (!segmentSizes) {
      return null;
    }

    return segmentSizes;
  },
});
