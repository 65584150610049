import { useTrackEvent } from 'features/trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from 'features/trackingAnalytics/types';
import { MixPanelEvents } from 'mixPanelEvents';
import { Grid, GridItem, PendingContent } from 'plume-ui';
import React, { FunctionComponent, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  campaignHistoryAtom,
  campaignHistoryCounterAtom,
  campaignOverviewGlobalTimeRangeAtom,
  fetchedChartDataCampaignIds,
} from 'store/state/overviewState';
import { useCampaignHistory } from '../../hooks/useCampaignHistory';
import TimeRangeDropdown from './../TimeRangeDropdown';
import CampaignOverviewCard from './CampaignOverviewCard';

const CampaignOverview: FunctionComponent = () => {
  const { loading, error } = useCampaignHistory();
  const campaignHistory = useRecoilValue(campaignHistoryAtom);
  const [timeRange, setTimeRange] = useRecoilState(
    campaignOverviewGlobalTimeRangeAtom,
  );
  const [counter, setCounter] = useRecoilState(campaignHistoryCounterAtom);
  const [fetchedCampaignIds, setFetchedCampaignIds] = useRecoilState(
    fetchedChartDataCampaignIds,
  );

  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.CampaignOverview,
      },
    });
  }, []);

  useEffect(() => {
    if (
      fetchedCampaignIds.length - 1 === counter &&
      campaignHistory.length > counter
    ) {
      setCounter((counter) => counter + 3);
    }
  }, [campaignHistory, counter, fetchedCampaignIds]);

  useEffect(() => {
    setCounter(2);
    setFetchedCampaignIds([]);
  }, [timeRange]);

  return (
    <div>
      <TimeRangeDropdown
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        nps={false}
      />
      <div className="CampaignOverview">
        <PendingContent loading={loading} isError={Boolean(error)}>
          <Grid>
            {campaignHistory.map((campaign, index) => (
              <GridItem key={campaign.campaignId}>
                <CampaignOverviewCard
                  campaign={campaign}
                  key={campaign.campaignId}
                  index={index}
                />
              </GridItem>
            ))}
          </Grid>
        </PendingContent>
      </div>
    </div>
  );
};

export default CampaignOverview;
