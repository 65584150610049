import { FormikValues } from 'formik';
import { numberInputValid } from 'utils/helpers';
import { GlobalFilterEnum } from '../components/GlobalFilterEnum';
import {
  CardErrorType,
  FormValidationSubState,
  PushCampaignMessageOnClickEnum,
} from '../types';
import { isValidRedirectUrl } from './crusadeHelpers';
import { lowerCase } from 'lodash';
import i18next from 'i18next';

export const isInformationCardValid = (campaignName: string) => {
  return {
    campaignName: campaignName !== '' && campaignName !== undefined,
  };
};

export const isAudienceCardValid = (
  audienceSegmentId: string,
  audienceSize: number,
) => {
  return {
    targetAudience: audienceSegmentId !== '',
    audienceSize: audienceSize > 0,
  };
};

export const isPushMessageCardValid = (values: FormikValues) => {
  const pushMessageTitle = values.campaignPushNotificationCard.title;
  const pushMessageBody = values.campaignPushNotificationCard.messageBody;

  let messageTitleValid =
    pushMessageTitle !== '' && pushMessageTitle !== undefined;
  let messageBodyValid =
    pushMessageBody !== '' && pushMessageBody !== undefined;
  let messageOnClickValid: boolean;

  if (
    values.campaignPushNotificationCard.onClickBehavior.action ===
    PushCampaignMessageOnClickEnum.REDIRECT_TO_WEB_URL
  ) {
    messageOnClickValid = isValidRedirectUrl(
      values.campaignPushNotificationCard.onClickBehavior.redirectURL,
    );
  } else {
    messageOnClickValid = true;
  }

  return {
    messageTitle: messageTitleValid,
    messageBody: messageBodyValid,
    messageOnClickBehavior: messageOnClickValid,
  };
};

export const isEmailMessageCardValid = (values: FormikValues) => {
  const messageFrom = values.campaignMessageCard.from;
  const messageSubject = values.campaignMessageCard.subject;
  let messageFromValid = messageFrom !== '' && messageFrom !== undefined;
  let messageSubjectValid =
    messageSubject !== '' && messageSubject !== undefined;

  return {
    messageFrom: messageFromValid,
    messageSubject: messageSubjectValid,
  };
};

export const getCardErrors = (card: string, cardErrorObj: CardErrorType) => {
  let errors = [] as Array<FormValidationSubState>;
  for (const [key, value] of Object.entries(cardErrorObj)) {
    let result = {} as FormValidationSubState;
    if (!value) {
      result.subheading = `${card} ${i18next.t('crusade.campaigns.error')}`;
      result.paragraph = `${i18next.t(
        'crusade.campaigns.pleaseSee',
      )} ${lowerCase(key)}`;
      errors.push(result);
    }
  }

  return errors;
};

export const formatErrors = (error: FormValidationSubState[]) => {
  let result = [];
  if (error.length && error[0].subheading) {
    result.push(...error);
  }
  return result;
};
