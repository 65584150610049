import {
  convertToDateObject,
  formatGraphMonthLabel,
} from 'features/overview/ChartDateHelpers';
import { NpsScoresOverTimeType } from 'features/overview/types';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';

export type AreaChartCustomTooltipProps = {
  data: NpsScoresOverTimeType[];
} & TooltipProps<ValueType, NameType>;

const AreaChartCustomTooltip: FunctionComponent<AreaChartCustomTooltipProps> = ({
  data,
  active,
  payload,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {data.map((el: NpsScoresOverTimeType, idx: number) => {
        const formattedDate = formatGraphMonthLabel(convertToDateObject(el.dt));
        const year = el.dt.split('-')[0];
        if (
          active &&
          payload &&
          payload[0].payload.xAxisLabel === formattedDate
        ) {
          return (
            <div
              className="NpsOverview__customTooltip-areaChart"
              key={`${el.dt}-${idx}`}
            >
              <div className="NpsOverview__customTooltip-date">
                {`${formattedDate} ${year}`}
              </div>
              <div className="NpsOverview__customTooltip-areaChartNps">
                <div className="NpsOverview__customTooltip-areaChartNps-group">
                  <div className="NpsOverview__customTooltip-areaChartNps-indicator" />
                  <div>{t('overview.nps.npsScore')}</div>
                </div>
                <div className="NpsOverview__customTooltip-areaChartNps-value">
                  {el.npsScore}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-promoters">
                <div>{t('overview.nps.promoters')}</div>
                <div className="NpsOverview__customTooltip-value">
                  {el.promoters}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-detractors">
                <div>{t('overview.nps.detractors')}</div>
                <div className="NpsOverview__customTooltip-value">
                  {el.detractors}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-passives">
                <div>{t('overview.nps.passives')}</div>
                <div className="NpsOverview__customTooltip-value">
                  {el.passives}
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default AreaChartCustomTooltip;
