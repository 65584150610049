import { Tags } from 'features/crusade/types';

export const TagsList = (): Array<Tags> => {
  return [
    {
      id: 854,
      value: 'Tag 1',
    },
    {
      id: 214,
      value: 'Tag 2',
    },
    {
      id: 451,
      value: 'Tag 3',
    },
    {
      id: 365,
      value: 'Tag 4',
    },
    {
      id: 125,
      value: 'Tag 5',
    },
    {
      id: 521,
      value: 'Tag 6',
    },
    {
      id: 512,
      value: 'Tag 7',
    },
    {
      id: 845,
      value: 'Tag 8',
    },
    {
      id: 745,
      value: 'Tag 9',
    },
  ];
};
