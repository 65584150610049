import { DependencyContainer } from '../../../DependencyContainer';

import { AxiosResponse } from 'axios';
import { CrusadeApiUrls } from '../../../urls';
import { HttpClient } from '../../../HttpClient';
import {
  TargetAudienceResponse,
  PreCalculateGlobalFiltersParams,
} from '../types';

export default class CampaignAudienceClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getTargetAudience(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<TargetAudienceResponse>>(
      CrusadeApiUrls.getTargetAudience(partnerId),
    );
  }

  async preCalculateGlobalFilters(
    partnerId: string,
    audienceParams: PreCalculateGlobalFiltersParams,
  ): Promise<any> {
    return this.post<AxiosResponse<any>>(
      CrusadeApiUrls.preCalculateGlobalFilters(partnerId),
      audienceParams,
    );
  }
}
