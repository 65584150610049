import {
  formatGraphDateLabel,
  formatGraphMonthLabel,
  convertToDateObject,
} from './ChartDateHelpers';
import {
  AreaChartDataItem,
  BarChartDataItem,
  NpsScoresOverTimeType,
} from './types';

export const getNpsAreaChartData = (
  scoresOverTime: NpsScoresOverTimeType[],
): AreaChartDataItem[] => {
  let scores = [...scoresOverTime];

  const currentMonth = new Date().getMonth();
  const firstEntryMonth = convertToDateObject(scoresOverTime[0].dt).getMonth();

  if (currentMonth !== firstEntryMonth) {
    scores = scores.sort((a, b) => {
      const aDate = convertToDateObject(a.dt);
      const bDate = convertToDateObject(b.dt);
      return aDate > bDate ? 1 : -1;
    });
  }

  return scores.map((scores) => {
    const statDateAsDateObject = convertToDateObject(scores.dt);
    return {
      npsScore: scores.npsScore / 100,
      xAxisLabel: formatGraphMonthLabel(statDateAsDateObject),
    };
  });
};

export const getNpsBarChartData = (
  scoresOverTime: NpsScoresOverTimeType[],
): BarChartDataItem[] => {
  let scores = [...scoresOverTime];

  if (convertToDateObject(scoresOverTime[0].dt) !== new Date()) {
    scores = scores.sort((a, b) => {
      const aDate = convertToDateObject(a.dt);
      const bDate = convertToDateObject(b.dt);
      return aDate > bDate ? 1 : -1;
    });
  }

  return scores.map((score) => {
    const statDateAsDateObject = convertToDateObject(score.dt);
    return {
      name: formatGraphDateLabel(statDateAsDateObject),
      values: [score.npsScore],
    };
  });
};
