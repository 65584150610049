import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { generateKey } from '../../utils/helpers';
import { getMetadataByColumnNameSelector } from '../../store/state/metadataTreeState';
import { useRecoilValue } from 'recoil';
import { Operator } from 'components/PatternMatcher/OperatorEnum';
import { MetadataTree } from 'features/generate/audience/types';
import { SelectValue } from 'features/generate/audience/consts';
import { Space } from 'plume-ui';

export type CriteriaCollapseCardPartialContentProps = {
  selection: any;
};

const CriteriaCollapseCardPartialContent: FunctionComponent<CriteriaCollapseCardPartialContentProps> = ({
  selection,
}) => {
  const { t } = useTranslation();
  const metaData = useRecoilValue(
    getMetadataByColumnNameSelector(selection.columnName),
  );
  const isPatternString = (metaData: MetadataTree): boolean => {
    if (
      metaData &&
      metaData.pattern === 'string' &&
      metaData.allowedValues.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderCriteria = () => {
    switch (selection.operator) {
      case Operator.BETWEEN_DATES:
        return (
          <span>
            {' '}
            ({selection.columnValue.startDate} - {selection.columnValue.endDate}
            )
          </span>
        );

      case Operator.BETWEEN:
        return (
          <span>
            {' '}
            ({selection.columnValue.start} - {selection.columnValue.end})
          </span>
        );

      case Operator.BETWEEN_DAYS:
        const startLabel =
          selection.columnValue.startDays < 0
            ? t('patternMatcher.next')
            : t('patternMatcher.past');
        const endLabel =
          selection.columnValue.endDays < 0
            ? t('patternMatcher.next')
            : t('patternMatcher.past');
        return (
          <span>
            {' '}
            ({startLabel} {Math.abs(selection.columnValue.startDays)} -{' '}
            {endLabel} {Math.abs(selection.columnValue.endDays)})
          </span>
        );

      case Operator.EQUAL:
      case Operator.AT_LEAST:
      case Operator.AT_MOST:
      case Operator.MORE_THAN:
      case Operator.LESS_THAN:
      case Operator.NOT_EQUAL:
      case Operator.ANY_TIME_PAST:
      case Operator.DAYS_AGO:
      case Operator.NEXT:
      case Operator.EACH_OF_LAST:
        return <span> {selection.columnValue}</span>;

      case Operator.IN:
        return `${selection.columnValue.length} ${t('segmentsModal.selected')}`;
      default:
        return (
          <span>
            {isPatternString(metaData!)
              ? `${selection.columnValue.length} ${t('segmentsModal.selected')}`
              : selection.columnValue}
          </span>
        );
    }
  };

  return (
    <div key={generateKey()}>
      <div className="CriteriaCollapseCard__partialContent">
        {!metaData?.allowedValues.length ? (
          <>
            <div className="CriteriaCollapseCard__partialContent-title">
              {metaData?.attribute || 'MISSING'}
            </div>
            {selection.columnValue !== SelectValue && (
              <div className="CriteriaCollapseCard__partialContent-value">
                {isPatternString(metaData!) ? '' : selection.operator}
                {renderCriteria()}
              </div>
            )}
            {/* <p className="CriteriaCollapseCard__partialContent-popSizeCount">
              {selection.rowCount}
            </p> */}
          </>
        ) : (
          <>
            <div className="CriteriaCollapseCard__partialContent-title">
              {metaData.attribute}
            </div>
            <div className="CriteriaCollapseCard__partialContent-value">
              {selection.columnValue}
            </div>
            {/* <div className="CriteriaCollapseCard__partialContent-popSizeCount">
              {selection.info || metaData.allowedValues.length
                ? selection.rowCount
                : ''}
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default CriteriaCollapseCardPartialContent;
