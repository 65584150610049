import {
  Icons,
  MultipleViewCard,
  PendingContent,
  Space,
  Spinner,
  TreeFilter,
  TreeFilterGroup,
  TreeFilterItem,
} from 'plume-ui';
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { findFilterCategory } from './helpers';
import TraitsSelectors from '../TraitsSelectors/TraitsSelectors';
import { AndDivider } from '../AndDivider/AndDivider';
import { useRecoilState, useRecoilValue } from 'recoil';
import cx from 'classnames';
import SelectedCriteriaBreadcrumb from '../SelectedCriteriaBreadcrumb/SelectedCriteriaBreadcrumb';
import CriteriaCollapseCardPartialContent from './CriteriaCollapseCardPartialContent';
import { generateKey } from '../../utils/helpers';
import {
  criteriaMultiviewMetadataAtom,
  parsedMetadataTreeSelector,
} from '../../store/state/metadataTreeState';
import { FilterEntity } from '../../features/generate/audience/types';
import PatternMatcher from 'components/PatternMatcher/PatternMatcher';
import {
  Operator,
  OperatorLabel,
} from 'components/PatternMatcher/OperatorEnum';
import FormattedMessage from '../../utils/components/FormattedMessage';

/**
 *
 * selectedCriteriaCollection is the query built by selected filters, and how we will pass the query
 *  to the backend
 *
 * An example of selectedCriteriaCollection:
 * {
 *  excludeFilters: [excludeFilters],
 *  excludeNarrowFilters: [[excludeNarrowFilters], [excludeNarrowFilters]],
 *  includeFilters: [includeFilters],
 *  includeNarrowFilters: [[includeNarrowFilters, includeNarrowFilters], [includeNarrowFilters]]
 * }
 *
 * Any time we delete or update this collection:
 * 1. if it is an exclude or include filter, we can just update those arrays directly
 * 2. if it is a narrow filter, we need to find the nested array within the includeNarrowFilter or
 *    excludeNarrowFilter arrays first
 *    To do this we use the narrowLevel prop which we get from mapping over the cards in SegmentModal
 *    narrowLevel indicates it is a nested narrow filter
 *    levels start from 1 (so need to always subtract 1 to get the index)
 */

export type SearchMethodTypes = 'search' | 'browse' | undefined;

export type CardTypes =
  | 'includeSearch'
  | 'narrowIncludeSearch'
  | 'narrowExcludeSearch'
  | 'excludeSearch';

// These map to the 3 levels of the tree
// firstLevel: category, secondLevel: displayProperty, thirdLevel: value
export type SelectedCriteriaType = {
  category: string;
  displayProperty: string;
  value: string;
  info: string;
  customization?: boolean;
  columnName?: string;
};

export type PatternSelection = {
  columnName: string;
  columnValue: string | number | BetweenDaysType | BetweenType;
  patternType: Operator;
  rangeValue: number;
  operator?: string;
};

export type BetweenDaysType = {
  startDays: number;
  endDays: number;
};

export type BetweenType = {
  start: number;
  end: number;
};

export type CriteriaCollectionType = {
  includeFilters: FilterEntity[];
  includeNarrowFilters: FilterEntity[][];
  excludeFilters: FilterEntity[];
  excludeNarrowFilters: FilterEntity[][];
};

export type CriteriaCollapseCardProps = {
  title: string | JSX.Element | React.ReactNode;
  open: boolean;
  actionsForHeader?: ReactNode[];
  cardType: CardTypes;
  narrowLevel: number | null;
  parentIdx: number;
  treeLoading: boolean;
  treeLoadingError: any;
  criteriaCollection: any;
  onUpdateCriteriaCollection: any;
};

export const CriteriaCollapseCard: FunctionComponent<CriteriaCollapseCardProps> = ({
  title,
  actionsForHeader,
  cardType,
  narrowLevel,
  parentIdx,
  treeLoading,
  treeLoadingError,
  criteriaCollection,
  onUpdateCriteriaCollection,
}) => {
  const { t } = useTranslation();
  const [openBrowse, setOpenBrowse] = useState(false);
  const [, setTreeLabels] = useState<{ [index: string]: string }>({});
  const [isSelectingCriteria, setIsSelectingCriteria] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState<FilterEntity>({
    columnName: '',
    columnValue: '',
    operator: '=',
  });
  const [numberOfSelections, setNumberOfSelections] = useState(0);
  const [searchMethod, setSearchMethod] = useState<SearchMethodTypes>(
    undefined,
  );
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchInput, setSearchInput] = useState<string>();
  const listRef = useRef<HTMLDivElement>(null);
  const treeByCategory = useRecoilValue(parsedMetadataTreeSelector);
  const [showPatternMatcherIdx, setShowPatternMatcherIdx] = useState<
    number | null
  >(-1);
  const [
    showPatternMatcherNestedIdx,
    setShowPatternMatcherNestedIdx,
  ] = useState<number | null>(-1);

  const [
    criteriaMultiviewMetadata,
    setCriteriaMultiviewMetadata,
  ] = useRecoilState(criteriaMultiviewMetadataAtom);

  useEffect(() => {
    const cardFilter = findFilterCategory(cardType, parentIdx);
    let updatedNumberOfSelections;

    if (['includeFilters', 'excludeFilters'].includes(cardFilter)) {
      updatedNumberOfSelections = criteriaCollection[cardFilter].length;
    } else {
      if (narrowLevel) {
        updatedNumberOfSelections =
          criteriaCollection[cardFilter][narrowLevel - 1] !== undefined
            ? criteriaCollection[cardFilter][narrowLevel - 1].length
            : 0;
      }
    }

    setNumberOfSelections(updatedNumberOfSelections);
  }, [criteriaCollection]);

  useEffect(() => {
    const labels: { [index: string]: string } = {};
    treeByCategory.forEach((category): void => {
      category.items.forEach((item) => {
        labels[item.title] = category.title;
      });
    });

    setTreeLabels(labels);
  }, [treeByCategory]);

  useEffect(() => {
    if (!openBrowse && !searchResults.length) {
      setSearchMethod(undefined);
    }
  }, [openBrowse, searchResults.length]);

  const renderNumberOfSelections = () => {
    if (numberOfSelections === 0) {
      return `${numberOfSelections} ${t('segmentsModal.selectionsMade')}`;
    } else {
      return `${numberOfSelections} ${t('segmentsModal.selected')}`;
    }
  };

  function handleDeleteGroup(
    criteria: SelectedCriteriaType,
    criteriaIdx: number,
  ) {
    const cardFilter = findFilterCategory(cardType, parentIdx);
    const currentSelectedCriteriaFilter = [...criteriaCollection[cardFilter]];
    let updatedSelection;

    if (['includeFilters', 'excludeFilters'].includes(cardFilter)) {
      updatedSelection = currentSelectedCriteriaFilter.filter(
        (currentCriteria) => {
          return currentCriteria !== criteria ? currentCriteria : false;
        },
      );

      // if updatedSelection still has filters in the array, then we are not deleting the last of the parent filter
      // therefore we should keep the existing includeNarrowFilter || excludeNarrowFilter
      // if updatedSelection is now empty, we are removing the final parent filter, so we need to clear out all
      // children. Otherwise it remains in the criteriaCollection and affects the dynamic metadata of the segment.
      onUpdateCriteriaCollection({
        ...criteriaCollection,
        [cardFilter]: updatedSelection,
        includeNarrowFilters: updatedSelection.length
          ? criteriaCollection.includeNarrowFilters
          : [],
        excludeNarrowFilters: updatedSelection.length
          ? criteriaCollection.excludeNarrowFilters
          : [],
      });
    } else {
      if (narrowLevel) {
        currentSelectedCriteriaFilter[narrowLevel - 1].splice(criteriaIdx, 1);

        onUpdateCriteriaCollection({
          ...criteriaCollection,
          [cardFilter]: currentSelectedCriteriaFilter,
        });
      }
    }
  }

  const savePatternSelection = (
    patternSelection: PatternSelection,
    cardFilter: string,
    index: number,
  ) => {
    let columnPatternValue:
      | string
      | {
          [index: string]: number;
        }
      | BetweenType
      | BetweenDaysType = '';
    setShowPatternMatcherIdx(null);
    if (patternSelection.patternType === 'between') {
      columnPatternValue = patternSelection.columnValue as BetweenType;
    } else if (patternSelection.patternType === 'betweenDays') {
      columnPatternValue = patternSelection.columnValue as BetweenDaysType;
    } else {
      columnPatternValue = patternSelection.columnValue as string;
    }

    if (['includeFilters', 'excludeFilters'].includes(cardFilter)) {
      onUpdateCriteriaCollection({
        ...criteriaCollection,
        [cardFilter]: criteriaCollection[cardFilter].map(
          (criteria: { pattern: any }, idx: number) => {
            if (idx === index) {
              return {
                ...criteria,
                columnValue: columnPatternValue,
                operator: patternSelection.patternType,
              };
            }
            delete criteria.pattern;
            return criteria;
          },
        ),
      });
    } else {
      onUpdateCriteriaCollection({
        ...criteriaCollection,
        [cardFilter]: criteriaCollection[cardFilter].map(
          (criteria: any, criteriaIdx: number) => {
            return criteria.map((nestedCriteria: any, idx: number) => {
              if (criteriaIdx === narrowLevel! - 1 && idx === index) {
                delete nestedCriteria.pattern;

                return {
                  ...nestedCriteria,
                  columnValue: columnPatternValue,
                  operator: patternSelection.patternType,
                };
              } else {
                return nestedCriteria;
              }
            });
          },
        ),
      });
    }

    setSelectedCriteria({
      ...selectedCriteria,
      operator: OperatorLabel[patternSelection.patternType] as string,
      columnValue: columnPatternValue as string,
    });
  };

  function handleEdit(criteriaIndex: number, cardFilterArrayIndex?: number) {
    if (cardFilterArrayIndex !== undefined) {
      setShowPatternMatcherIdx(cardFilterArrayIndex);
      setShowPatternMatcherNestedIdx(criteriaIndex);
    } else {
      setShowPatternMatcherIdx(criteriaIndex);
      setShowPatternMatcherNestedIdx(null);
    }
  }

  const renderSelectedCriteria = () => {
    const cardFilter = findFilterCategory(cardType, parentIdx);

    if (['includeFilters', 'excludeFilters'].includes(cardFilter)) {
      return (
        <>
          {criteriaCollection &&
            criteriaCollection[cardFilter].map(
              (criteria: any, index: number) => {
                return (
                  <div
                    className={`${
                      showPatternMatcherIdx === index ? 'active' : ''
                    }`}
                    key={generateKey()}
                  >
                    <div key={generateKey()}>
                      <SelectedCriteriaBreadcrumb
                        key={generateKey()}
                        criteria={criteria}
                        handleDeleteGroup={handleDeleteGroup}
                        handleEdit={handleEdit}
                        patternMode={showPatternMatcherIdx === index}
                        criteriaIndex={index}
                      />
                      {showPatternMatcherIdx !== null &&
                        showPatternMatcherIdx === index && (
                          <>
                            <PatternMatcher
                              key={generateKey()}
                              criteria={criteria}
                              onSave={(patternSelection) => {
                                savePatternSelection(
                                  patternSelection,
                                  cardFilter,
                                  index,
                                );
                              }}
                              onCancel={() => {
                                setShowPatternMatcherIdx(null);
                              }}
                              cardFilter={cardFilter}
                            />
                          </>
                        )}
                      <Space size="m" />
                    </div>
                  </div>
                );
              },
            )}
        </>
      );
    } else {
      return (
        <div>
          {criteriaCollection &&
            narrowLevel &&
            criteriaCollection[cardFilter].map(
              (cardFilterArray: any, cardFilterArrayIdx: number) => {
                return cardFilterArray.map(
                  (nestedCriteria: any, nestedIndex: number) => {
                    if (cardFilterArrayIdx === narrowLevel - 1) {
                      return (
                        <div key={generateKey()}>
                          <SelectedCriteriaBreadcrumb
                            key={generateKey()}
                            criteria={nestedCriteria}
                            handleDeleteGroup={handleDeleteGroup}
                            handleEdit={handleEdit}
                            patternMode={showPatternMatcherIdx === nestedIndex}
                            criteriaIndex={nestedIndex}
                            cardFilterArrayIndex={cardFilterArrayIdx}
                          />
                          {nestedCriteria.cardFilter === cardFilter &&
                            showPatternMatcherNestedIdx === nestedIndex &&
                            showPatternMatcherIdx === cardFilterArrayIdx && (
                              <>
                                <PatternMatcher
                                  key={generateKey()}
                                  cardFilter={cardFilter}
                                  criteria={nestedCriteria}
                                  onSave={(patternSelection) =>
                                    savePatternSelection(
                                      patternSelection,
                                      cardFilter,
                                      nestedIndex,
                                    )
                                  }
                                  onCancel={() =>
                                    setShowPatternMatcherIdx(null)
                                  }
                                />
                              </>
                            )}
                        </div>
                      );
                    } else {
                      return undefined;
                    }
                  },
                );
              },
            )}
        </div>
      );
    }
  };

  const renderListLabel = (): ReactNode | null => {
    if (openBrowse) {
      return (
        <div className="CriteriaCollapseCard__listLabel">
          <FormattedMessage id="segmentsModal.browseSearch" />
        </div>
      );
    }

    if (searchInput && searchInput?.length > 0) {
      return (
        <div className="CriteriaCollapseCard__listLabel">
          {searchResults.flat(Infinity).length}{' '}
          <FormattedMessage id="segmentsModal.inputSearch" />
        </div>
      );
    }
  };

  function toggleTreeItemChecked(treeItem: any) {
    const cardFilter = findFilterCategory(cardType, parentIdx);
    const currentSelectedCriteria = { ...selectedCriteria };
    currentSelectedCriteria.columnValue = treeItem.label;
    currentSelectedCriteria.rowCount = parseInt(treeItem.info);
    // adding cardFilter lets us render the correct PatternMatcher for the correct nested narrow
    currentSelectedCriteria.cardFilter = cardFilter;
    setSelectedCriteria(currentSelectedCriteria);

    if (isSelectingCriteria) {
      const currentSelectedCriteriaFilter = [...criteriaCollection[cardFilter]];
      if (['includeFilters', 'excludeFilters'].includes(cardFilter)) {
        currentSelectedCriteriaFilter.push(currentSelectedCriteria);
      } else {
        if (narrowLevel) {
          if (currentSelectedCriteriaFilter[narrowLevel - 1] !== undefined) {
            let currentNarrowFilter = [
              ...currentSelectedCriteriaFilter[narrowLevel - 1],
            ];
            currentNarrowFilter = [
              ...currentNarrowFilter,
              currentSelectedCriteria,
            ];
            currentSelectedCriteriaFilter[
              narrowLevel - 1
            ] = currentNarrowFilter;
          } else {
            currentSelectedCriteriaFilter.push([currentSelectedCriteria]);
          }
        }
      }
      onUpdateCriteriaCollection({
        ...criteriaCollection,
        [cardFilter]: currentSelectedCriteriaFilter,
      });
    }

    setIsSelectingCriteria(false);

    setOpenBrowse(false);
    setSearchResults([]);
    setSearchInput('');
    setCriteriaMultiviewMetadata(true);
  }

  function handleGroupClick(node: any) {
    let currentSelectedCriteria: FilterEntity;
    currentSelectedCriteria = { ...selectedCriteria };
    currentSelectedCriteria.columnName = node.columnName;
    setSelectedCriteria(currentSelectedCriteria);

    setIsSelectingCriteria(true);
  }

  function renderTreeFilterItems(node: any, index: number) {
    if (node.type === 'group') {
      return (
        <TreeFilterGroup
          key={index}
          title={node.title}
          startExpanded={node.expanded}
          onClick={() => handleGroupClick(node)}
          showSelectIndicator={false}
        >
          {node.items.map(renderTreeFilterItems)}
        </TreeFilterGroup>
      );
    } else if (node.type === 'item') {
      return (
        <TreeFilterItem
          key={index}
          label={node.label}
          info=""
          isChecked={node.checked}
          onClick={() => toggleTreeItemChecked(node)}
        />
      );
    }
    return false;
  }

  const renderLeftIcon = () => {
    if (['narrowIncludeSearch', 'narrowExcludeSearch'].includes(cardType)) {
      return <Icons.FilterIcon width={24} color="#DEE0E2" />;
    } else if (cardType === 'excludeSearch') {
      return <Icons.CircleBlockIcon width={24} color="#FFFFFF" />;
    } else {
      return <Icons.IncludeIcon width={24} color="#FFFFFF" />;
    }
  };

  const renderPartialContent = () => {
    const cardFilter = findFilterCategory(cardType, parentIdx);

    if (criteriaCollection[cardFilter].length > 0) {
      return criteriaCollection[cardFilter].map(
        (selection: any, selectionIdx: number) => {
          if (Array.isArray(selection)) {
            return selection.map((nestedSelection) => {
              if (narrowLevel && selectionIdx === narrowLevel - 1) {
                return (
                  <div key={generateKey()}>
                    <CriteriaCollapseCardPartialContent
                      selection={nestedSelection}
                    />
                  </div>
                );
              } else {
                return undefined;
              }
            });
          } else {
            return (
              <div key={generateKey()}>
                <CriteriaCollapseCardPartialContent selection={selection} />
              </div>
            );
          }
        },
      );
    } else {
      return <FormattedMessage id="noneSelected" />;
    }
  };

  const renderFullContent = () => {
    return (
      <>
        <div>
          <div className="CriteriaCollapseCard__numberOfSelections">
            {renderNumberOfSelections()}
          </div>
          {renderSelectedCriteria()}
        </div>
        <TraitsSelectors
          ref={listRef}
          activeBtn={searchMethod}
          openBrowse={openBrowse}
          onOpenBrowse={setOpenBrowse}
          onSelectSearchMethod={setSearchMethod}
          onFetchSearchResults={setSearchResults}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        {renderListLabel()}
        {searchResults.length > 0 && (
          <div>
            <TreeFilter
              classes={(current) => ({
                ...current,
                root: `${current.root} CriteriaCollapseCard__segmentSearchList`,
              })}
            >
              {searchResults.flat().map(renderTreeFilterItems)}
            </TreeFilter>
          </div>
        )}
        {openBrowse && (
          <div ref={listRef}>
            <TreeFilter
              classes={(current) => ({
                ...current,
                root: `${current.root} CriteriaCollapseCard__segmentSearchList`,
              })}
            >
              {treeByCategory.map(renderTreeFilterItems)}
            </TreeFilter>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <PendingContent
        loading={treeLoading}
        isError={Boolean(treeLoadingError)}
        loader={Spinner}
      >
        {['narrowIncludeSearch', 'narrowExcludeSearch'].includes(cardType) && (
          <AndDivider narrowLevel={narrowLevel && narrowLevel} />
        )}
        <MultipleViewCard
          classes={(current) => ({
            ...current,
            root: `${current.root} ${cx('CriteriaCollapseCard', {
              [`CriteriaCollapseCard__${cardType}`]: cardType,
            })}`,
            fullContent: `${current.fullContent} CriteriaCollapseCard__fullContent`,
          })}
          title={title}
          open={criteriaMultiviewMetadata}
          hideOnOpen={true}
          actions={actionsForHeader && actionsForHeader}
          iconRight={<Icons.EditIcon width={24} color="#999EFF" />}
          iconLeft={renderLeftIcon()}
          fullContent={renderFullContent()}
          partialContent={renderPartialContent()}
          closeText={t('segmentsModal.close')}
          activeHeader
        />
      </PendingContent>
    </>
  );
};

export default CriteriaCollapseCard;
