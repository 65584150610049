import { Maybe } from 'types';
import { DependencyContainer } from '../../../DependencyContainer';
import { PreCalculateGlobalFiltersParams } from '../types';

export default class CampaignAudienceService {
  constructor(private readonly factory: DependencyContainer) {}

  async getTargetAudience(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.campaignAudienceClient.getTargetAudience(
      partnerId,
    );
  }

  async preCalculateGlobalFilters(
    partnerId: Maybe<string>,
    audienceParams: PreCalculateGlobalFiltersParams,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.campaignAudienceClient.preCalculateGlobalFilters(
      partnerId,
      audienceParams,
    );
  }
}
