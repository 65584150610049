import { Routes } from 'config';
import { Heading, Icons, Tabs, Tooltip } from 'plume-ui';
import { Tab } from 'plume-ui/dist/components/Tabs/Tabs';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { campaignTabNavigationIndex } from 'store/state/campaignState';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';

const CampaignTabNavigationHeader: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const [activeTab, setActiveTab] = useRecoilState(campaignTabNavigationIndex);

  const tabs = [
    {
      id: 'campaignTable',
      label: t('crusade.campaigns.myCampaigns'),
    },
    {
      id: 'templateList',
      label: t('crusade.campaigns.templates'),
    },
  ];

  const handleTabSelection = (tabIndex: number) => {
    switch (tabIndex) {
      case 0:
        setActiveTab(tabIndex);
        redirectToRoute(Routes.CrusadeCampaigns);
        break;
      case 1:
        setActiveTab(tabIndex);
        redirectToRoute(Routes.FromTemplateCampaign);
        break;
      default:
        redirectToRoute(Routes.CrusadeCampaigns);
    }
  };

  return (
    <div className="CrusadeCampaignsContainer__headerTabsContainer">
      <Heading // NEW RELIC MONITORING PLEASE DO NOT DELETE THIS ID
        testId="Nurture__heading"
        level={1}
        medium
        size="xxxxl"
        classes={(current) => ({
          ...current,
          root: `${current.root} CrusadeCampaignsContainer__title`,
        })}
      >
        {t('crusade.title')}
      </Heading>
      <Tabs
        classes={(current) => ({
          ...current,
          root: `${current.root} CrusadeCampaignsContainer__tabs`,
          toggler: `${current.toggler} CrusadeCampaignsContainer__tabs-toggler`,
        })}
        options={tabs}
        tabType="flatTabs"
        active={activeTab}
        onSelectTab={(_tab: Tab, idx: number) => handleTabSelection(idx)}
        fullWidth
      />
    </div>
  );
};

export default CampaignTabNavigationHeader;
