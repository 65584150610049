import { DependencyContainer } from 'DependencyContainer';
import { HttpClient } from 'HttpClient';
import { HarvestApiUrls } from 'urls';
import { AxiosResponse } from 'axios';
import { SegmentDataPreviewResponse } from '../types';
export default class SegmentsPreviewClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getSegmentsPreview(partnerId: string, segmentId: string): Promise<any> {
    return this.get<AxiosResponse<SegmentDataPreviewResponse>>(
      HarvestApiUrls.getSegmentsPreview(partnerId, segmentId),
    );
  }
}
