import { HarvestConfirmationModal } from 'components/HarvestConfirmationModal/HarvestConfirmationModal';
import { CreateSyncModal } from 'components/CreateSyncModal/CreateSyncModal';
import { SegmentsModal } from 'components/SegmentsModal/SegmentsModal';
import { SplitSegmentsModal } from 'components/SplitSegmentsModal/SplitSegmentsModal';
import { SyncPreferencesModal } from 'components/SyncPreferencesModal/SyncPreferencesModal';
import { DeleteModal } from 'features/configuration/components/DeleteModal';
import { DescriptionModal } from 'features/configuration/components/DescriptionModal';
import { PreviewAndTestModal } from 'features/crusade/components/PreviewAndTestModal';
import { TagsModal } from 'features/generate/audience/components/createAudience/TagsModal';
import { MODAL_TYPES } from './ModalTypes';
import { PatternStringModal } from 'components/PatternStringModal/PatternStringModal';
import { AddChannelModal } from 'features/configuration/components/AddChannelModal/AddChannelModal';
import { TemplateModal } from 'features/crusade/components/templates/TemplateModal';

export const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CONFIRMATION_MODAL]: HarvestConfirmationModal,
  [MODAL_TYPES.SEGMENTS_MODAL]: SegmentsModal,
  [MODAL_TYPES.SPLIT_SEGMENTS_MODAL]: SplitSegmentsModal,
  [MODAL_TYPES.SYNC_PREFERENCES_MODAL]: SyncPreferencesModal,
  [MODAL_TYPES.CREATE_SYNC_MODAL]: CreateSyncModal,
  [MODAL_TYPES.PREVIEW_AND_TEST_MODAL]: PreviewAndTestModal,
  [MODAL_TYPES.DELETE_MODAL]: DeleteModal,
  [MODAL_TYPES.DESCRIPTION_MODAL]: DescriptionModal,
  [MODAL_TYPES.TAGS_MODAL]: TagsModal,
  [MODAL_TYPES.PATTERN_STRING_MODAL]: PatternStringModal,
  [MODAL_TYPES.ADD_CHANNEL_MODAL]: AddChannelModal,
  [MODAL_TYPES.CREATE_CAMPAIGN_MODAL]: TemplateModal,
};
