import Quill from 'quill';
const Link = Quill.import('formats/link');

/**
 * We use Braze liquid variables to expose the unsubscribe/subscribe feature to users
 * They all begin with '{{${', so rather than hardcode each variable, we have decided to use this to bypass url sanitization
 * (liquid variables already have https in it, so we need to prevent an additional https being prefixed)
 */
const BRAZE_LIQUID_VARIABLE_PREFIX = '{{${';

Link.PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];
export class CustomLinkSanitizer extends Link {
  static sanitize(url: string) {
    if (url.startsWith(BRAZE_LIQUID_VARIABLE_PREFIX)) return url;

    const sanitizedUrl = super.sanitize(url);

    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function (
      protocol: string,
    ) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    return `https://${sanitizedUrl}`;
  }
}
