import { DependencyContainer } from 'DependencyContainer';
import { Maybe } from 'types';

export default class SegmentsPreviewService {
  constructor(private readonly factory: DependencyContainer) {}

  async getSegmentsPreview(
    partnerId: Maybe<string>,
    segmentId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.segmentsPreviewClient.getSegmentsPreview(
      partnerId,
      segmentId,
    );
  }
}
