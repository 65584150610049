import React, { FunctionComponent, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, Heading, Icons, Tooltip } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import GenerateSyncs from '../../generate/audience/components/GenerateSyncs';
import Connections from '../components/Connections';

const CrusadeCampaignsContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Syncs,
      },
    });
  }, []);

  return (
    <div className="SyncsContainer p-xl">
      <Helmet>
        <title>{t('connections.title')}</title>
      </Helmet>
      <Heading
        level={1}
        medium
        size="xxxxl"
        classes={(current) => ({
          ...current,
          root: `${current.root} SyncsContainer__title`,
        })}
      >
        {t('connections.title')}
      </Heading>
      <Connections />
    </div>
  );
};

export default CrusadeCampaignsContainer;
