import axios, { AxiosError } from 'axios';
import SyncTestResult from 'components/CreateSyncModal/SyncTestResult';
import { DependencyContainer } from 'DependencyContainer';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import moment from 'moment';
import { Button, Heading, Icons, notify, Section, Space } from 'plume-ui';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import FormattedMessage from 'utils/components/FormattedMessage';
import {
  AvailableIntegrationTypes,
  Channel,
} from '../../../configuration/types';
import { TestChannelResponse } from '../../../configuration/integrationsState';

export type ConnectionListItemProps = {
  channel: Channel;
  onAuthorize: () => void;
  resetChannelData: () => void;
  id: number;
};

const mapChannelTypeToIntegrationType: Record<
  string,
  AvailableIntegrationTypes
> = {
  facebookAds: AvailableIntegrationTypes.Facebook,
  googleAds: AvailableIntegrationTypes.Google,
};

const mapTestStatuses = {
  success: 'success',
  failure: 'error',
  error: 'error',
};

const { integrationsService, loginService } = new DependencyContainer();

const ConnectionListItem: FunctionComponent<ConnectionListItemProps> = ({
  channel,
  onAuthorize,
  resetChannelData,
  id,
}) => {
  const { t } = useTranslation();
  const { channelsService } = new DependencyContainer();
  const [isOpenTest, setIsOpenTest] = useState(false);
  const [testResultsLoading, setTestResultsLoading] = useState<boolean>(false);
  const [testResultsError, setTestResultsError] = useState<any>();
  const { showModal } = useGlobalModalContext();
  const partnerId = useRecoilValue(partnerIdAtom);
  const [testResult, setTestResult] = useState<TestChannelResponse>();

  const renderChannelTypeIcon = () => {
    return channel?.channelType === 'facebookAds' ? (
      <Icons.FacebookIcon width={24} />
    ) : (
      <Icons.GoogleIcon width={24} />
    );
  };

  const handleEditClick = () => {
    showModal(
      MODAL_TYPES.DESCRIPTION_MODAL,
      { channel: channel, isOpen: true },
      MODAL_TYPES.DESCRIPTION_MODAL,
    );
  };

  const getTestResultStatus = () => {
    if ((!testResultsError && !testResult?.status) || testResultsLoading) {
      return 'loading';
    }
    if (testResultsError) {
      return 'error';
    }
    return mapTestStatuses[testResult!.status];
  };

  const isErrorRequestTimedOut = () => {
    return (
      (testResultsError && testResultsError?.response === undefined) ||
      testResultsError?.response?.status === 504
    );
  };

  const getTestResultMessage = () => {
    if (isErrorRequestTimedOut()) {
      return t('settings.errors.requestTimedOut.descriptionForTesting');
    }
    return testResult?.error_message;
  };

  const handleDeleteClick = async () => {
    try {
      const response = await channelsService.deleteChannel(
        partnerId,
        channel.channelId,
      );

      if (response.data) {
        resetChannelData();
        notify({
          title: t('settings.channel.deleteChannelNotification'),
          body: t(''),
          type: 'success',
        });
      }
    } catch (error) {
      const mappedError = error as AxiosError;

      if (mappedError.response?.data.error) {
        notify({
          title: t('error'),
          body: mappedError.response?.data.error,
          type: 'error',
        });
      } else {
        notify({
          title: t('error'),
          body: t('somethingWentWrong'),
          type: 'error',
        });
      }
    }
  };

  const onTestClick = async (channel: Channel) => {
    if (!partnerId) {
      return;
    }
    const integrationType =
      mapChannelTypeToIntegrationType[channel.channelType];
    setIsOpenTest(true);
    setTestResultsLoading(true);
    try {
      const result = await integrationsService.testChannel(
        integrationType,
        channel.channelId,
        partnerId,
      );
      setTestResult(result);
      setTestResultsError(undefined);
    } catch (error) {
      setTestResultsError(error);
      if (axios.isAxiosError(error)) {
        setTestResult({
          status: 'error',
          error_message:
            error?.response?.data?.detail || t('somethingWentWrong'),
        });
      }
    } finally {
      setTestResultsLoading(false);
    }
  };

  return (
    <Section
      classes={(current) => ({
        ...current,
        root: `${current.root} ConnectionListItem`,
      })}
      id={`Generate__channel-${id}`}
    >
      <div className="ConnectionListItem__title">
        {renderChannelTypeIcon()}
        <Heading size="m">{channel?.channelName}</Heading>
        <div className="ConnectionListItem__action-buttons">
          <Button
            styleVariant="secondary"
            icon={<Icons.SpeedTestIcon width={24} />}
            onClick={() => onTestClick(channel)}
          >
            <FormattedMessage id="settings.channel.test" />
          </Button>
          <Button
            styleVariant="secondary"
            icon={<Icons.PersonLockIcon width={24} />}
            onClick={onAuthorize}
          >
            <FormattedMessage id={'settings.channel.reauthorize'} />
          </Button>
          <Button
            styleVariant="secondary"
            icon={<Icons.TrashIcon width={16} />}
            onClick={() =>
              showModal(
                MODAL_TYPES.DELETE_MODAL,
                {
                  deletingItem: 'connection',
                  onConfirm: handleDeleteClick,
                  isOpen: true,
                },
                MODAL_TYPES.DELETE_MODAL,
              )
            }
          >
            <FormattedMessage id="settings.channel.delete" />
          </Button>
        </div>
      </div>
      <table className="ConnectionListItem__table">
        <tbody>
          <tr>
            <td className="ConnectionListItem__row-label">
              <FormattedMessage id="settings.labels.addAccountId" />
            </td>
            <td>{channel?.adAccountId}</td>
          </tr>
          <tr>
            <td className="ConnectionListItem__row-label">
              <FormattedMessage id="settings.labels.connected" />
            </td>
            <td>
              {moment(channel?.createdAt).format('YYYY-MM-DD')} by{' '}
              {channel?.createdBy}
            </td>
          </tr>
          {channel?.channelDescription ? (
            <tr>
              <td className="ConnectionListItem__description-label">
                {t('settings.labels.description')}
                <Icons.EditIcon
                  width={16}
                  color="#999EFF"
                  onClick={handleEditClick}
                />
              </td>
              <td className="ConnectionListItem__description">
                {channel?.channelDescription}
              </td>
            </tr>
          ) : (
            <tr>
              <td>
                <Button
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} ConnectionListItem__addDescriptionBtn`,
                  })}
                  icon={<Icons.EditIcon width={16} />}
                  styleVariant="tertiary"
                  onClick={handleEditClick}
                >
                  <FormattedMessage id="settings.channel.addDescription" />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isOpenTest && (
        <div className="ConnectionListItem__TestComponent">
          <div className="ConnectionListItem__TestComponentTitle">
            <FormattedMessage id="settings.channel.testConnection" />
          </div>
          <SyncTestResult
            status={getTestResultStatus()}
            mode="channel"
            syncTestResult={{
              result: getTestResultStatus(),
              message: getTestResultMessage(),
            }}
          />
        </div>
      )}
    </Section>
  );
};

export default ConnectionListItem;
