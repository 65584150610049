import { AxiosResponse } from 'axios';
import { DependencyContainer } from 'DependencyContainer';
import { CampaignConfigurationPayloadType } from 'features/crusade/types';
import { HttpClient } from 'HttpClient';
import { CrusadeApiUrls } from 'urls';

export default class ConfigurationClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getCampaignConfigEmailDomainList(
    partnerId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getCampaignConfigEmailDomainList(partnerId),
    );
  }

  async getCampaignEmailConfiguration(
    partnerId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.get<AxiosResponse<any>>(
      CrusadeApiUrls.getCampaignEmailConfiguration(partnerId),
    );
  }

  async editCampaignEmailConfiguration(
    partnerId: string,
    configuration: CampaignConfigurationPayloadType,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      CrusadeApiUrls.editCampaignEmailConfiguration(partnerId),
      configuration,
    );
  }
}
