import {
  EmailChartHeroMetricEnum,
  NpsEnum,
  NpsTimeRangeEnum,
  OverviewChartColorOptions,
  OverviewTimeRangeEnum,
  PushChartHeroMetricEnum,
} from './types';

export const timeRangeOptions = [
  {
    label: OverviewTimeRangeEnum.SEVEN_DAYS,
    value: 7,
  },
  {
    label: OverviewTimeRangeEnum.THIRTY_DAYS,
    value: 30,
  },
  {
    label: OverviewTimeRangeEnum.NINETY_DAYS,
    value: 90,
  },
];

export const npsTimeRangeOptions = [
  {
    label: OverviewTimeRangeEnum.THIRTY_DAYS,
    value: 30,
  },
  {
    label: NpsTimeRangeEnum.ALL_TIME,
    value: 0,
  },
];

export const lineChartColorOptions = [
  OverviewChartColorOptions.COLOR_WARM_X_500,
  OverviewChartColorOptions.HOT_500,
  OverviewChartColorOptions.WARM_500,
  OverviewChartColorOptions.GOOD_500,
  OverviewChartColorOptions.SORE_500,
  OverviewChartColorOptions.COLD_500,
  OverviewChartColorOptions.FREEZE_500,
  OverviewChartColorOptions.SORE_X_500,
  OverviewChartColorOptions.GOOD_X_500,
  OverviewChartColorOptions.FREEZE_X_500,
];

export const emailCampaignHeroMetrics: string[] = [
  EmailChartHeroMetricEnum.EMAILS_SENT,
  EmailChartHeroMetricEnum.DELIVERED,
  EmailChartHeroMetricEnum.SEGMENT_SIZE,
  EmailChartHeroMetricEnum.OPENS,
  EmailChartHeroMetricEnum.CLICKS,
  EmailChartHeroMetricEnum.BOUNCES,
  EmailChartHeroMetricEnum.UNSUBSCRIBES,
];

export const pushCampaignHeroMetrics: string[] = [
  PushChartHeroMetricEnum.MESSAGES_SENT,
  PushChartHeroMetricEnum.SEGMENT_SIZE,
  PushChartHeroMetricEnum.OPENS,
  PushChartHeroMetricEnum.BOUNCES,
];

export const npsChartLegendOptions: string[] = [
  NpsEnum.DETRACTORS,
  NpsEnum.PROMOTERS,
  NpsEnum.PASSIVES,
];
