import { FunctionComponent } from 'enzyme';
import { useSyncs } from 'features/syncs/hooks/useSyncs';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalHeader,
  Panel,
  PendingContent,
  Space,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { syncsByChannelSelector, syncSelector } from 'store/state/syncsState';
import SyncCard from '../SyncCard/SyncCard';
import classNames from 'classnames';
import {
  activeNodeAtom,
  activeSyncSegmentAtom,
  selectedSegmentAtom,
} from 'store/state/audienceFlowState';
import { GenerateSync } from '../../features/syncs/types';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { PanelStyles } from 'plume-ui/dist/components/Panel/Panel';

const scrollContainer = {
  marginRight: '2px',
  maxHeight: '628px',
  padding: '32px',
};

export type SyncPreferencesModalProps = {
  readOnly: boolean;
  syncId?: string;
  activeSegmentName?: string;
};

export const SyncPreferencesModal: FunctionComponent<SyncPreferencesModalProps> = ({
  readOnly,
  syncId,
  activeSegmentName,
}) => {
  const { t } = useTranslation();
  const { hideModal, showModal } = useGlobalModalContext();
  const { loading, error } = useSyncs();
  const syncsByChannel = useRecoilValue(syncsByChannelSelector);
  const setActiveNode = useSetRecoilState(activeNodeAtom);
  const [selectedSegment, setSelectedSegment] = useRecoilState(
    selectedSegmentAtom,
  );

  const setIsSyncSegment = useSetRecoilState(activeSyncSegmentAtom);
  const { runFetch } = useSyncs();
  const singleSync = useRecoilValue(syncSelector(syncId));
  const closeSyncPreferencesModal = () => {
    hideModal(MODAL_TYPES.SYNC_PREFERENCES_MODAL);
    setSelectedSegment(undefined);
    setIsSyncSegment(false);
  };

  useEffect(() => {
    return () => {
      setActiveNode('');
    };
  }, []);

  const syncsForSelectedSegment = useMemo(() => {
    const syncs: GenerateSync[] = [];

    Object.keys(syncsByChannel).forEach((channelId) => {
      syncs.push(
        ...syncsByChannel[channelId].filter(
          (sync) => sync.segmentId === selectedSegment?.segmentId,
        ),
      );
    });

    return syncs;
  }, [selectedSegment, syncsByChannel]);

  return (
    <Panel
      classes={(current: PanelStyles) => ({
        ...current,
        root: `${current.root} ${classNames('SyncPreferencesModal', {
          'SyncPreferencesModal-readOnly': readOnly,
        })}`,
      })}
      open
      setOpen={() => closeSyncPreferencesModal()}
      title={t('syncModal.syncPreferences')}
    >
      <div className="SyncPreferencesModal__body">
        <PendingContent loading={loading} isError={Boolean(error)}>
          <Heading size="xl">{activeSegmentName}</Heading>
          <Space size="m" />
          {readOnly && singleSync && singleSync?.channel ? (
            <SyncCard
              readOnly={readOnly}
              title={singleSync.channel.channelName}
              state={singleSync.state}
              status={singleSync.status}
              syncedTo={singleSync.channel.channelType}
              rowsAdded={singleSync.rowsAddedInLatestSync}
              rowsDeleted={singleSync.rowsDeletedInLatestSync}
              rowsUpdated={singleSync.rowsCurrentlySynced}
              syncedToDate={singleSync.latestSyncExecutedAt}
              segmentId={singleSync.segmentId}
              syncId={singleSync.syncId}
              segmentName={singleSync.segmentName}
              refreshSyncData={runFetch}
            />
          ) : (
            syncsForSelectedSegment.length > 0 &&
            syncsForSelectedSegment.map((sync: GenerateSync) => (
              <div key={sync.id}>
                <SyncCard
                  readOnly={readOnly}
                  title={sync.channel.channelName}
                  state={sync.state}
                  status={sync.status}
                  syncedTo={sync.channel.channelType}
                  rowsAdded={sync.rowsAddedInLatestSync}
                  rowsDeleted={sync.rowsDeletedInLatestSync}
                  rowsUpdated={sync.rowsCurrentlySynced}
                  syncedToDate={sync.latestSyncExecutedAt}
                  segmentId={sync.segmentId}
                  syncId={sync.syncId}
                  segmentName={sync.segmentName}
                  refreshSyncData={runFetch}
                />
                <Space size="m" />
              </div>
            ))
          )}
          {!syncsForSelectedSegment ||
            (syncsForSelectedSegment?.length === 0 && (
              <p>
                <FormattedMessage id="syncCard.noSyncs" />
              </p>
            ))}
          {!readOnly && (
            <Button
              styleVariant="superprimary"
              onClick={() =>
                showModal(
                  MODAL_TYPES.CREATE_SYNC_MODAL,
                  {},
                  MODAL_TYPES.CREATE_SYNC_MODAL,
                )
              }
            >
              <FormattedMessage id="syncCard.newSync" />
            </Button>
          )}
        </PendingContent>
      </div>
    </Panel>
  );
};
