export type LoginCallbackErrorProps = {
  error: Error;
};

export type AuthError = {
  name: string;
  errorSummary: string;
  errorCode: string;
};

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  idToken: string;
  expiresAt: number;
};

export enum HarvestRole {
  Admin = 'admin',
  PartnerAdmin = 'partnerAdmin',
}

export enum HarvestProduct {
  Crusade = 'crusade',
  Generate = 'generate',
}

export type LoginContainerAuthErrorProps = AuthError;
