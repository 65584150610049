import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownSelectableItem,
  IconButton,
  Icons,
  InputField,
  PendingContent,
  Spinner,
  StaticCard,
  Tooltip,
} from 'plume-ui';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  campaignObjectivesAtom,
  editingCampaignAtom,
  formUpdated,
  journeysAtom,
  unclassifiedJourneyId,
} from '../../../store/state/campaignState';
import { generateKey } from '../../../utils/helpers';
import { useJourney } from '../hooks/useJourney';
import {
  CampaignFormikFields,
  CampaignGenericFormField,
  CampaignInformationCardValues,
  CampaignJourney,
} from '../types';
import { findJourneyByIdOrName, makeCamelCase } from '../util/crusadeHelpers';

export enum DefaultJourney {
  VALUE_DISCOVERY = 'Value Discovery',
  UNCLASSIFIED = 'Unclassified',
}

const CampaignInfoCard: FunctionComponent<CampaignGenericFormField<
  CampaignInformationCardValues
>> = ({ form, field }) => {
  const { t } = useTranslation();
  const [inEditMode, setInEditMode] = useState(false);
  const editingCampaign = useRecoilValue(editingCampaignAtom);
  const { loading, error } = useJourney();
  const journeysInStore = useRecoilValue(journeysAtom);
  const defaultUnclassifiedJourneyId = useRecoilValue(unclassifiedJourneyId);
  const setFormFieldsUpdated = useSetRecoilState(formUpdated);

  const campaignObjectives = useRecoilValue(campaignObjectivesAtom);
  const [createNewObjective, setCreateNewObjective] = useState(false);

  useEffect(() => {
    form.setFieldValue(CampaignFormikFields.CAMPAIGN_INFORMATION_CARD, {
      ...field.value,
      journeyId: defaultUnclassifiedJourneyId,
    });
  }, [defaultUnclassifiedJourneyId]);

  useEffect(() => {
    setCreateNewObjective(false);
    if (!editingCampaign) {
      setInEditMode(true);
    }
  }, [editingCampaign]);

  const channel = useMemo(() => {
    if (editingCampaign) {
      return makeCamelCase(editingCampaign?.campaignType);
    }
  }, [editingCampaign?.campaignType]);

  const userJourneys = useMemo(() => {
    const allJourneys = [
      {
        journeyName: t('crusade.campaigns.journey.unclassified'),
        journeyId: findJourneyByIdOrName(
          journeysInStore,
          CampaignJourney.UNCLASSIFIED,
        ).journeyId,
      },
      {
        journeyName: t('crusade.campaigns.journey.valueDiscovery'),
        journeyId: findJourneyByIdOrName(
          journeysInStore,
          CampaignJourney.VALUE_DISCOVERY,
        ).journeyId,
      },
    ];

    journeysInStore.forEach((journey) => {
      if (
        ![
          t('crusade.campaigns.journey.valueDiscovery'),
          t('crusade.campaigns.journey.unclassified'),
        ].includes(journey.journeyName)
      ) {
        allJourneys.push({
          journeyName: journey.journeyName,
          journeyId: journey.journeyId,
        });
      }
    });

    return allJourneys;
  }, [editingCampaign, journeysInStore]);

  const campaignNameHintMessage = !field.value.campaignName
    ? {
        status: 'hint' as InputMessageStatus,
        message: t('fieldRequired'),
      }
    : {};

  const cardActions = () => {
    if (!editingCampaign) {
      return undefined;
    } else if (!inEditMode) {
      return [
        <IconButton
          classes={(current) => ({
            ...current,
            root: `${current.root} EditCard__editIcon`,
          })}
          onClick={() => setInEditMode(true)}
        >
          <Icons.EditIcon />
        </IconButton>,
      ];
    }
  };

  const renderJourney = () => {
    return editingCampaign ? (
      <>
        <p className="EditCard__campaignNonEditCardValue-withTooltip">
          {editingCampaign?.journeyName}
        </p>
        {/* TODO: update defaults to value discovery and UNCLASSIFIED.
          need new tooltip copy for Unclassified journey */}
        {editingCampaign?.journeyName &&
          [
            DefaultJourney.UNCLASSIFIED,
            DefaultJourney.VALUE_DISCOVERY,
          ].includes(editingCampaign?.journeyName as DefaultJourney) && (
            <Tooltip
              alignment="left"
              label={t(
                `crusade.campaigns.tooltips.${makeCamelCase(
                  editingCampaign?.journeyName,
                )}`,
              )}
              position="right"
              overrideMaxCharacters
            >
              <Icons.InfoIcon width={16} />
            </Tooltip>
          )}
      </>
    ) : (
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        loader={Spinner}
      >
        <div className="EditCard__campaignInfoDropdown">
          <Dropdown
            classes={(current) => ({
              ...current,
              root: `${current.root} EditCard__campaignInfoDropdown-dropdown`,
            })}
            openInPortal={true}
            label={
              field.value.journeyId
                ? findJourneyByIdOrName(journeysInStore, field.value.journeyId)
                    .journeyName
                : userJourneys[0].journeyName
            }
            closeOnItemClick={true}
          >
            {userJourneys.map((journey) => {
              return (
                <DropdownItem
                  key={generateKey()}
                  onClick={() => {
                    setFormFieldsUpdated(true);
                    form.setFieldValue(
                      CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                      {
                        ...field.value,
                        journeyId: journey.journeyId,
                      },
                    );
                  }}
                >
                  {journey.journeyName}
                </DropdownItem>
              );
            })}
          </Dropdown>
        </div>
      </PendingContent>
    );
  };

  const renderObjectiveSelect = () => {
    if (createNewObjective) {
      return (
        <div className="EditCard__newObjectiveInput">
          <InputField
            placeholder={t('crusade.campaigns.objective.typeObjective')}
            onInput={(e) => {
              setFormFieldsUpdated(true);
              form.setFieldValue(
                CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                {
                  ...field.value,
                  objectives: [(e.target as HTMLInputElement).value],
                },
              );
            }}
          />
          <Button
            styleVariant="small-pill"
            onClick={() => {
              setCreateNewObjective(false);
              form.setFieldValue(
                CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                {
                  ...field.value,
                  objectives: editingCampaign?.objectives || [],
                },
              );
            }}
            icon={<Icons.CrossIcon />}
          ></Button>
        </div>
      );
    }
    return (
      <Dropdown
        selected={field.value.objectives as any}
        openInPortal
        label={renderObjectiveLabel()}
      >
        <DropdownItem
          icon={<Icons.PlusIcon />}
          iconPosition="right"
          onClick={() => {
            setCreateNewObjective(true);
          }}
        >
          {t('crusade.campaigns.objective.createNew')}
        </DropdownItem>
        {campaignObjectives.map((objective) => {
          return (
            <DropdownSelectableItem
              key={`objective-${objective.id}`}
              selected={field.value.objectives?.includes(objective.content)}
              onClick={() => {
                setFormFieldsUpdated(true);
                if (!field.value.objectives?.includes(objective.content))
                  form.setFieldValue(
                    CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                    {
                      ...field.value,
                      objectives: [
                        ...(field.value.objectives || []),
                        objective.content,
                      ],
                    },
                  );
                else {
                  form.setFieldValue(
                    CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                    {
                      ...field.value,
                      objectives: field.value.objectives?.filter(
                        (obj) => obj !== objective.content,
                      ),
                    },
                  );
                }
              }}
            >
              {objective.content}
            </DropdownSelectableItem>
          );
        })}
      </Dropdown>
    );
  };

  const renderObjectiveLabel = () => {
    const objectives = field.value.objectives || [];
    if (!Boolean(objectives.length)) {
      if (!inEditMode) return t('none');
      return t('crusade.campaigns.objective.selectObjective');
    }
    return `${objectives[0]} ${
      objectives.length > 1 ? `(+${objectives.length - 1})` : ''
    }`;
  };

  return (
    <StaticCard
      title={t('crusade.campaigns.campaignInfo')}
      actions={cardActions()}
      classes={(current) => ({
        ...current,
        title: `${current.title} EditCard__cardHeader-title`,
      })}
    >
      <>
        <div className="EditCard__wrapper">
          <p className="EditCard__label">{`${t(
            'crusade.campaigns.campaignName',
          )} :`}</p>
          {inEditMode ? (
            <div className="EditCard__inputWrapper">
              <InputField
                classes={(current) => ({
                  ...current,
                  root: `${current.root} EditCard__campaignInfoCardInput`,
                })}
                onInput={(e) => {
                  setFormFieldsUpdated(true);
                  form.setFieldValue(
                    CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                    {
                      ...field.value,
                      campaignName: (e.target as HTMLInputElement).value || '',
                    },
                  );
                }}
                value={field.value.campaignName}
                messages={[campaignNameHintMessage]}
                placeholder={t(
                  'crusade.campaigns.channel.campaignNamePlaceholder',
                )}
              />
            </div>
          ) : (
            <p className="EditCard__campaignNonEditCardValue">
              {field.value.campaignName}
            </p>
          )}
        </div>
        <div className="EditCard__wrapper">
          <p className="EditCard__label">{`${t('description')} :`}</p>
          {inEditMode ? (
            <div className="EditCard__inputWrapper">
              <InputField
                classes={(current) => ({
                  ...current,
                  root: `${current.root} EditCard__campaignInfoCardInput`,
                })}
                onInput={(e) => {
                  setFormFieldsUpdated(true);
                  form.setFieldValue(
                    CampaignFormikFields.CAMPAIGN_INFORMATION_CARD,
                    {
                      ...field.value,
                      description: (e.target as HTMLInputElement).value || '',
                    },
                  );
                }}
                value={field.value.description}
                placeholder={t(
                  'crusade.campaigns.channel.descriptionPlaceholder',
                )}
              />
            </div>
          ) : (
            <p className="EditCard__campaignNonEditCardValue">
              {field.value.description}
            </p>
          )}
        </div>
        <div className="EditCard__wrapper">
          <p className="EditCard__label">{`${t(
            'crusade.campaigns.journey.title',
          )} :`}</p>
          <div className="EditCard__valueContainer">{renderJourney()}</div>
        </div>
        <div className="EditCard__wrapper">
          <p className="EditCard__label">{`${t(
            'crusade.campaigns.objective.title',
          )} :`}</p>
          <div className="EditCard__valueContainer">
            {!inEditMode && (
              <p className="EditCard__campaignNonEditCardValue">
                {renderObjectiveLabel()}
              </p>
            )}
            {inEditMode && renderObjectiveSelect()}
          </div>
        </div>
        <div className="EditCard__wrapper">
          <p className="EditCard__label">{`${t(
            'crusade.campaigns.channel.title',
          )} :`}</p>
          <p className="EditCard__campaignNonEditCardValue">
            {editingCampaign
              ? t(`crusade.campaigns.channel.${channel}`)
              : t('crusade.campaigns.channel.pushNotification')}
          </p>
        </div>
      </>
    </StaticCard>
  );
};

export default CampaignInfoCard;
