import React, { FunctionComponent } from 'react';
import { IntegrationErrorComponentProps } from '../../../integrations/types';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import ErrorActionsRenderer from './ErrorActionsRenderer';

export type ErrorGenericComponentProps = IntegrationErrorComponentProps & {
  body?: string;
};

export const ErrorGenericComponent: FunctionComponent<ErrorGenericComponentProps> = ({
  actions,
  body,
}) => {
  return (
    <div className="IntegrationsContainer__error">
      <h1>
        <FormattedMessage id="error" />
      </h1>
      <p>{!body ? <FormattedMessage id="somethingWentWrong" /> : body}</p>
      <ErrorActionsRenderer actions={actions} error={Error} />
    </div>
  );
};

export default ErrorGenericComponent;
