import { IntegrationStrategy } from '../../../persistent-state/persistent-state';
import { useCallback, useEffect } from 'react';
import { ThirdPartyUrls } from '../../../urls';
import { facebookPermissionScopes, googlePermissionScopes } from '../config';
import { Maybe } from '../../../types';
import { AvailableIntegrationTypes } from '../../configuration/types';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getIntegrationsRedirectUri } from '../../../environment';
import { introspectAtom } from '../../../store/state/introspect';
import {
  CustomErrorProtocol,
  ErrorInvalidOAuthUrl,
  ErrorMissingAccessToken,
  ErrorMissingStrategy,
  ErrorOAuthCancelled,
} from '../errors';
import { retrieveAccessTokenFromUrl } from '../helpers';
import { integrationCacheAtom } from 'features/configuration/integrationsState';

export type UseIntegrationsParams = {
  onRetrievedAccessToken?: (
    accessToken: string,
    expirationTime: string,
    integrationType: AvailableIntegrationTypes,
    partnerId: string,
  ) => void;
  onError?: (error: CustomErrorProtocol) => void;
  automaticallyStartFlow?: boolean;
  deps?: any[];
};

export type UseIntegrationsProtocol = {
  beginAuthorization: (
    integrationType: AvailableIntegrationTypes,
    channelId?: string,
    accountId?: string,
  ) => void;
  processImplicitFlow: () => void;
  cacheStrategyData: () => void;
  finishIntegration: () => void;
  isIntegrationFinished: () => boolean;
  cleanup: (replicateToRecoil?: boolean) => void;
  getAccessToken: () => any;
};

const LOCAL_STORAGE_KEY_NAME = 'integrationStrategy';

export const getStrategy = (): Maybe<IntegrationStrategy> => {
  const lsString = window.localStorage.getItem(LOCAL_STORAGE_KEY_NAME);
  if (!lsString) {
    return undefined;
  }
  try {
    const parsed = JSON.parse(lsString || '{}');
    return parsed;
  } catch (error) {
    return undefined;
  }
};

export const setStrategy = (strategy: Maybe<IntegrationStrategy>) => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY_NAME, JSON.stringify(strategy));
};
const redirectUri = getIntegrationsRedirectUri();
const mapAuthorizationUrlToAccountType: Record<
  AvailableIntegrationTypes,
  string
> = {
  [AvailableIntegrationTypes.Facebook]: ThirdPartyUrls.getFacebookAuthorizationUrl(
    redirectUri,
    facebookPermissionScopes,
  ),
  [AvailableIntegrationTypes.Google]: ThirdPartyUrls.getGoogleAuthorizationUrl(
    redirectUri,
    googlePermissionScopes,
  ),
};

const useIntegrations = ({
  onRetrievedAccessToken,
  automaticallyStartFlow = true,
  onError,
}: UseIntegrationsParams): UseIntegrationsProtocol => {
  const strategy = getStrategy();
  const introspect = useRecoilValue(introspectAtom);
  const setIntegrationCacheAtom = useSetRecoilState(integrationCacheAtom);

  const beginAuthorization = (
    integrationType: AvailableIntegrationTypes,
    channelId?: string,
    accountId?: string,
  ) => {
    setStrategy({
      integrationType,
      channelId,
      accountId,
    });
    const url = mapAuthorizationUrlToAccountType[integrationType];
    if (!url) {
      onError?.(new ErrorInvalidOAuthUrl());
      return;
    }
    window.location.href = url;
  };

  const cacheStrategyData = () => {
    const strategy = getStrategy();
    const searchParams = new URLSearchParams(
      window.location.href.split('?')[1],
    );
    if (searchParams.has('error')) {
      onError?.(new ErrorOAuthCancelled());
      return;
    }
    if (!strategy) {
      onError?.(new ErrorMissingStrategy());
      return;
    }
    const accessToken = retrieveAccessTokenFromUrl(strategy.integrationType);

    if (accessToken == null) {
      onError?.(new ErrorMissingAccessToken());
      return;
    }

    const newStrategy = {
      integrationType: strategy.integrationType,
      channelId: strategy.channelId,
      accountId: strategy.accountId,
      cache: {
        accessToken,
        expirationTime: new Date().toString(),
      },
    };
    setStrategy(newStrategy);
  };

  const getAccessToken = () => {
    const strategy = getStrategy();
    return strategy?.cache?.accessToken;
  };

  const processImplicitFlow = useCallback(async () => {
    const strategy = getStrategy();
    if (!strategy || !strategy.cache || !introspect?.partnerId) {
      return;
    }
    const { accessToken, expirationTime } = strategy.cache;
    if (accessToken && expirationTime) {
      await onRetrievedAccessToken?.(
        accessToken,
        expirationTime,
        strategy.integrationType,
        introspect.partnerId,
      );
    }
  }, [introspect?.partnerId]);

  const isIntegrationFinished = () => {
    const strategy = getStrategy();
    return Boolean(strategy);
  };

  const finishIntegration = () => {
    setStrategy(undefined);
  };

  const cleanup = (replicateToRecoil: boolean = false) => {
    const current = getStrategy();
    localStorage.removeItem(LOCAL_STORAGE_KEY_NAME);
    if (replicateToRecoil && current) {
      setIntegrationCacheAtom(current);
    }
  };

  useEffect(() => {
    if (!strategy || !introspect?.partnerId) {
      return;
    }
    automaticallyStartFlow && processImplicitFlow();
  }, [introspect?.partnerId]);

  return {
    beginAuthorization,
    processImplicitFlow,
    finishIntegration,
    isIntegrationFinished,
    cacheStrategyData,
    cleanup,
    getAccessToken,
  };
};
export default useIntegrations;
