import React, {
  FunctionComponent,
  SVGProps,
  useCallback,
  useContext,
} from 'react';
import { Icons, PendingContent, Spinner } from 'plume-ui';
import { AddChannelModalSubTabs, AvailableIntegrationTypes } from '../../types';
import { AddChannelModalContext } from './AddChannelModalContext';
import { useRecoilValue } from 'recoil';
import {
  AdAccountResponseItem,
  adAccountsResponseAtom,
  integrationErrorAtom,
} from '../../integrationsState';
import AddChannelIntroduction from './AddChannelIntroduction';
import useIntegrations from '../../../integrations/hooks/useIntegrations';
import AddChannelConfigureError from './AddChannelConfigureError';
import { AddChannelConfigureSelectAccount } from './AddChannelConfigureSelectAccount';

export const mapSelectedIntegrationToConfigureTitle: Record<
  AvailableIntegrationTypes,
  string
> = {
  [AvailableIntegrationTypes.Google]:
    'settings.channel.adsConfigureTitleGoogle',
  [AvailableIntegrationTypes.Facebook]:
    'settings.channel.adsConfigureTitleFacebook',
};

export const mapSelectedIntegrationToConfigureSubtitle: Record<
  AvailableIntegrationTypes,
  string
> = {
  [AvailableIntegrationTypes.Google]:
    'settings.channel.adsConfigureSubtitleGoogle',
  [AvailableIntegrationTypes.Facebook]:
    'settings.channel.adsConfigureSubtitleFacebook',
};

export const mapSelectedIntegrationToIcon: Record<
  AvailableIntegrationTypes,
  React.FC<SVGProps<SVGSVGElement>>
> = {
  [AvailableIntegrationTypes.Google]: Icons.GoogleIcon,
  [AvailableIntegrationTypes.Facebook]: Icons.FacebookIcon,
};

export const AddChannelConfigure: FunctionComponent = () => {
  const context = useContext(AddChannelModalContext);
  const adAccounts = useRecoilValue(adAccountsResponseAtom);
  const integrationError = useRecoilValue(integrationErrorAtom);
  const { beginAuthorization } = useIntegrations({
    automaticallyStartFlow: false,
  });

  const onSelectAccount = (account: AdAccountResponseItem) => {
    context.onSelectAccount(account.ad_account_id);
  };

  const onReAuthorize = () => {
    if (!context.selectedAccountType) {
      return;
    }
    beginAuthorization(context.selectedAccountType);
  };

  const renderSelectAccountSubTab = useCallback(() => {
    return (
      <PendingContent loading={context.isRequestInProgress} loader={Spinner}>
        {integrationError ? (
          <AddChannelConfigureError
            error={integrationError}
            onReAuthorize={onReAuthorize}
          />
        ) : (
          <AddChannelConfigureSelectAccount
            adAccounts={adAccounts}
            onSelectAccount={onSelectAccount}
          />
        )}
      </PendingContent>
    );
  }, [
    context.selectedAccountType,
    context.isRequestInProgress,
    integrationError,
  ]);

  return (
    <>
      {context.activeSubTabName === AddChannelModalSubTabs.SelectAccount ? (
        renderSelectAccountSubTab()
      ) : (
        <AddChannelIntroduction />
      )}
    </>
  );
};

export default AddChannelConfigure;
