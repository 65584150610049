import format from 'date-fns/format';
import {
  LineChartDataItem,
  LineChartGroup,
} from 'plume-ui/dist/utils/charts/chartTypes';
import { lineChartColorOptions } from './ChartOptions';
import { SegmentHistory, SegmentStatType } from './types';

import { SegmentSummary } from 'features/generate/audience/types';
import { convertToDateObject, formatGraphDateLabel } from './ChartDateHelpers';

export const convertSegmentDataToLineChartGroups = (segments: any[]) => {
  let groups: LineChartGroup[] = [];

  segments.forEach((segment, idx) => {
    const group = {
      label: segment.segmentName,
      stroke: lineChartColorOptions[idx],
      key: segment.segmentName,
      strokeWidth: 2,
    };
    groups.push(group);
  });

  return groups;
};

export const getDatesForSelectedTimeRange = (duration: number) => {
  const date = new Date();
  let dateArray = [format(date, 'yyyy-MM-dd')];

  for (let i = 1; i < duration; i++) {
    const nextDay = new Date(date.getTime() - 86400000 * i);

    dateArray.push(format(nextDay, 'yyyy-MM-dd'));
  }

  return dateArray.reverse();
};

const fillMissingSegments = (
  segmentHistory: SegmentHistory[],
  segmentsRequested?: SegmentSummary[],
) => {
  if (!segmentsRequested) return segmentHistory;

  const missingSegmentList = segmentsRequested.filter(
    (segmentRequested) =>
      !segmentHistory.find((id) => id.segmentId === segmentRequested.segmentId),
  );

  const missingSegmentbasicData = missingSegmentList.map((segmentRequested) => {
    let filledSegment = {} as SegmentHistory;
    filledSegment.segmentName = segmentRequested.segmentName;
    filledSegment.segmentId = segmentRequested.segmentId;
    filledSegment.segmentStats = [];
    return filledSegment;
  });
  return [...segmentHistory, ...missingSegmentbasicData];
};

// to cover the scenario where some segments have n days of data, but other segments have fewer
//   days due to being newly created, etc
//   we need all segment data to match the longest span of time returned by the API in order
//   for the chart to display correctly (otherwise the chart will only show the shortest span of dates)
const getFirstAndLastDaysOfLongestResponseDuration = (
  segmentHistory: SegmentHistory[],
) => {
  const firstSegmentStatsLength = segmentHistory[0].segmentStats.length;
  let firstDay = segmentHistory[0].segmentStats[0].dt;
  let lastDay = segmentHistory[0].segmentStats[firstSegmentStatsLength - 1].dt;

  segmentHistory.forEach((segment) => {
    if (segment.segmentStats.length) {
      if (segment.segmentStats[0].dt < firstDay) {
        firstDay = segment.segmentStats[0].dt;
      }

      const segmentStatsLength = segment.segmentStats.length;
      const lastSegmentStatDay =
        segment.segmentStats[segmentStatsLength - 1].dt;
      if (lastSegmentStatDay > lastDay) {
        lastDay = lastSegmentStatDay;
      }
    }
  });

  return {
    firstDay,
    lastDay,
  };
};

export const getDateRangeForApiResponse = (
  startDate: string,
  endDate: string,
) => {
  let dateArray = [];

  for (
    let date = convertToDateObject(startDate);
    date <= convertToDateObject(endDate);
    date.setDate(date.getDate() + 1)
  ) {
    dateArray.push(format(date, 'yyyy-MM-dd'));
  }

  return dateArray;
};

const fillMissingDates = (segmentHistory: SegmentHistory[]) => {
  const firstAndLastDay = getFirstAndLastDaysOfLongestResponseDuration(
    segmentHistory,
  );
  const dateRangeForApiResponse = getDateRangeForApiResponse(
    firstAndLastDay.firstDay,
    firstAndLastDay.lastDay,
  );

  return segmentHistory.map((segment) => {
    let filledSegment = {} as SegmentHistory;
    filledSegment.segmentName = segment.segmentName;
    filledSegment.segmentId = segment.segmentId;
    filledSegment.segmentStats = [...segment.segmentStats];

    const lengthOfResponseDateRange = dateRangeForApiResponse.length;
    const segmentStatsDates = segment.segmentStats.map((stat) => stat.dt);

    if (lengthOfResponseDateRange !== segment.segmentStats.length) {
      dateRangeForApiResponse.forEach((date, index) => {
        if (!segmentStatsDates.includes(date)) {
          filledSegment.segmentStats.splice(index, 0, {
            value: 0,
            dt: date,
          });
        }
      });
    }
    return filledSegment;
  });
};

export const createLineChartData = (
  segmentHistory: SegmentHistory[],
  firstTenSegments?: SegmentSummary[],
) => {
  let chartData: LineChartDataItem = [];
  const fullDataSet = fillMissingDates(
    fillMissingSegments(segmentHistory, firstTenSegments),
  );

  fullDataSet[0].segmentStats.forEach((stat: SegmentStatType, index) => {
    const allSegmentsChartData = {};
    fullDataSet.forEach((segments) => {
      const newRecord = {
        [segments.segmentName]: segments.segmentStats[index]?.value,
      };
      Object.assign(allSegmentsChartData, newRecord);
    });

    const convertedDate = convertToDateObject(stat.dt);

    Object.assign(allSegmentsChartData, {
      xAxisLabel: formatGraphDateLabel(convertedDate),
    });
    chartData?.push(allSegmentsChartData);
  });

  return chartData;
};
