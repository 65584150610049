import { Field } from 'formik';
import React, { FunctionComponent } from 'react';
import { CampaignType, CampaignTypes } from '../types';
import CampaignAudienceCard from './CampaignAudienceCard';
import CampaignDeliveryCard from './CampaignDeliveryCard';
import CampaignInfoCard from './CampaignInfoCard';
import CampaignMessageCard from './CampaignMessageCard';
import CampaignPushNotificationCard from './CampaignPushNotificationCard';

export type CrusadeCampaignFormProps = {
  campaignType: CampaignTypes;
};

const CrusadeCampaignForm: FunctionComponent<CrusadeCampaignFormProps> = ({
  campaignType,
}) => {
  return (
    <div className="CrusadeCampaignCardsContainer__cardsContainer">
      <Field name="campaignInformationCard" component={CampaignInfoCard} />
      <Field name="campaignAudienceCard" component={CampaignAudienceCard} />
      {campaignType === CampaignType.EMAIL ? (
        <Field name="campaignMessageCard" component={CampaignMessageCard} />
      ) : (
        <Field
          name="campaignPushNotificationCard"
          component={CampaignPushNotificationCard}
        />
      )}
      <Field
        name="campaignDeliveryCard"
        enableReinitialize
        component={CampaignDeliveryCard}
      />
    </div>
  );
};

export default CrusadeCampaignForm;
