import { atom } from 'recoil';
import { Maybe } from 'types';

export const generateApiVersionAtom = atom<Maybe<string>>({
  key: 'generateApiVersionAtom',
  default: undefined,
});

export const crusadeApiVersionAtom = atom<Maybe<string>>({
  key: 'crusadeApiVersionAtom',
  default: undefined,
});
