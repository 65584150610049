import { Maybe } from 'types';
import { DependencyContainer } from '../../../../DependencyContainer';
import { SelectValue } from '../consts';
import { AudienceSizeResponse, RowCountForAllowedValueResult } from '../types';

export default class TraitsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getMetadataTree(): Promise<any> {
    return await this.factory.traitsClient.getMetadataTree();
  }

  async getDynamicMetadataTree(
    partnerId: Maybe<string>,
    segmentNodeId: string,
    payload: any,
    isNewNode: boolean,
  ): Promise<any> {
    return await this.factory.traitsClient.getDynamicMetadataTree(
      partnerId,
      segmentNodeId,
      {
        isNew: isNewNode,
        ...payload,
      },
    );
  }

  async getAudienceSize(
    partnerId: Maybe<string>,
    segmentId: string | undefined,
  ): Promise<any> {
    if (partnerId === undefined) return;
    if (segmentId) {
      return await this.factory.traitsClient.getAudienceSize(
        partnerId,
        segmentId,
      );
    }
  }

  async getMetadataAllowedValues(
    partnerId: Maybe<string>,
    columnName: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.traitsClient.getMetadataAllowedValues(
      partnerId,
      columnName,
    );
  }
  mapTraitData = (traitData: any, audienceSizeData: any): any => {
    const parsedData: any = [];
    const treeData: any = [];

    const audienceSizeLookup: Maybe<Array<
      RowCountForAllowedValueResult
    >> = audienceSizeData
      ? this.rowCountForAllowedValue(audienceSizeData)
      : undefined;

    /**
     * put api data into treeData object:
     * {
     *  category: string,
     *  attributes: string[]
     * }
     */
    traitData.forEach((item: any) => {
      const categoryExists = treeData.some(
        (data: any) => data.category === item.category,
      );
      if (!categoryExists) {
        treeData.push({
          category: item.category,
          attributes: [
            {
              attribute: item.attribute,
              allowedValues: item.allowedValues,
              columnName: item.columnName,
            },
          ],
        });
      } else {
        const existingCategory = treeData.find((data: any) => {
          return data.category === item.category;
        });
        existingCategory.attributes.push({
          attribute: item.attribute,
          allowedValues: item.allowedValues,
          columnName: item.columnName,
        });
      }
    });

    /**
     * create tree items for each category, propertyName, and value
     * if there is no value (i.e. allowedValues is an empty array), then an
     * Advanced Filter is needed.
     * 1. The value is something that says 'Advanced Filter needed' and
     *  user selects that, adding the selected value to the top which requires
     *  further filtering
     */
    treeData.forEach((treeDataItem: any) => {
      let treeItem = {
        title: treeDataItem.category,
        expanded: false,
        type: 'group',
        items: treeDataItem.attributes.map((attr: any) => {
          return {
            type: 'group',
            title: attr.attribute,
            columnName: attr.columnName,
            checked: false,
            items: attr.allowedValues.length
              ? attr.allowedValues.map((value: any) => {
                  return {
                    type: 'item',
                    label: value,
                    info: this.audienceSizeMapping(
                      audienceSizeLookup,
                      attr.columnName,
                      value,
                    ),
                    checked: false,
                  };
                })
              : [
                  {
                    type: 'item',
                    label: SelectValue,
                    info: this.audienceSizeMapping(
                      audienceSizeLookup,
                      attr.columnName,
                      undefined,
                    ),
                    checked: false,
                  },
                ],
          };
        }),
      };

      parsedData.push(treeItem);
    });
    return parsedData;
  };

  audienceSizeMapping = (
    lookup: Maybe<Array<RowCountForAllowedValueResult>>,
    columnName: string,
    allowedValue?: string,
  ): string => {
    if (typeof lookup !== 'undefined') {
      return (
        lookup
          .find(
            (el) =>
              el.columnName === columnName && el.allowedValue === allowedValue,
          )
          ?.rowCount?.toString() || '0'
      );
    }
    return '';
  };

  rowCountForAllowedValue = (
    audienceSizeData: AudienceSizeResponse,
  ): Maybe<Array<RowCountForAllowedValueResult>> => {
    if (
      typeof audienceSizeData !== 'undefined' &&
      audienceSizeData.categories
    ) {
      const categories = Object.values(audienceSizeData.categories);
      const lookupTable: RowCountForAllowedValueResult[] = [];

      categories.forEach((category) => {
        category.forEach((attribute) => {
          if (attribute.allowedValues?.length) {
            attribute.allowedValues.forEach((allowedValues) => {
              lookupTable.push({
                columnName: attribute.columnName,
                allowedValue: allowedValues.allowedValue,
                rowCount: allowedValues.rowCount,
              });
            });
          } else {
            if (!attribute.allowedValues && attribute.rowCount) {
              lookupTable.push({
                columnName: attribute.columnName,
                rowCount: attribute.rowCount,
              });
            }
          }
        });
      });
      return lookupTable;
    }
    return [];
  };
}
