import React, { FunctionComponent } from 'react';
import {
  IntegrationErrorAction,
  IntegrationErrorComponentProps,
} from '../types';
import {
  ErrorNotValidAdsUser,
  ErrorPermissionsNotGranted,
  ErrorRequestTimedOut,
} from '../errors';
import ErrorPermissionsNotGrantedComponent from './errors/ErrorPermissionsNotGrantedComponent';
import ErrorGenericComponent from './errors/ErrorGenericComponent';
import { AvailableIntegrationTypes } from '../../configuration/types';
import ErrorNotValidAdsUserComponent from './errors/ErrorNotValidAdsUserComponent';
import { Nullable } from '../../../types';
import ErrorRequestTimedOutErrorComponent from './errors/ErrorRequestTimedOut';

export type IntegrationsErrorRendererProps = {
  error: Error;
  integrationType: AvailableIntegrationTypes;
  actions: IntegrationErrorAction[];
};

export const IntegrationsErrorRenderer: FunctionComponent<IntegrationsErrorRendererProps> = ({
  integrationType,
  error,
  actions,
}) => {
  if (!error) {
    return null;
  }
  let ErrorComponent: Nullable<FunctionComponent<
    IntegrationErrorComponentProps
  >>;
  if (error instanceof ErrorPermissionsNotGranted) {
    ErrorComponent = ErrorPermissionsNotGrantedComponent;
  } else if (error instanceof ErrorNotValidAdsUser) {
    ErrorComponent = ErrorNotValidAdsUserComponent;
  } else if (error instanceof ErrorRequestTimedOut) {
    ErrorComponent = ErrorRequestTimedOutErrorComponent;
  } else {
    ErrorComponent = null;
  }

  if (ErrorComponent === null) {
    return (
      <ErrorGenericComponent
        body={error.message}
        integrationType={integrationType}
        actions={actions}
      />
    );
  }

  return <ErrorComponent integrationType={integrationType} actions={actions} />;
};

export default IntegrationsErrorRenderer;
