import { atom } from 'recoil';
import { Maybe } from 'types';

export type ConfirmationModalAtom = {
  isOpen: boolean;
  title?: string;
  body?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const confirmationModalAtom = atom<ConfirmationModalAtom>({
  key: 'confirmationModalAtom',
  default: {
    isOpen: false,
  },
});

export const partnerIdAtom = atom<Maybe<string>>({
  key: 'partnerIdAtom',
  default: undefined,
});
