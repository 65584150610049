import { DEFAULT_PAGE_SIZE } from 'config';
import { CampaignStatus } from 'features/crusade/types';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { atom, selector, selectorFamily } from 'recoil';
import { audienceMapDataType } from '../../features/generate/audience/components/audienceFlow/AudienceFlow.types';
import {
  SegmentData,
  SegmentTreeData,
  SegmentTableEntry,
  SegmentTreeDataSyncChannel,
  SegmentType,
  LoadingStatus,
  SortableSegmentFieldNames,
} from '../../features/generate/audience/types';
import { Maybe } from '../../types';
import { syncsAtom } from './syncsState';

export type SelectedSegmentAtom = Maybe<
  Pick<SegmentTreeData, 'segmentName' | 'segmentId'>
>;

export const selectedSegmentAtom = atom<SelectedSegmentAtom>({
  key: 'selectedSegmentNameAtom',
  default: undefined,
});

export const segmentsSearchAtom = atom<string>({
  key: 'segmentsSearchAtom',
  default: '',
});

export const activeNodeAtom = atom({
  key: 'activeNodeAtom',
  default: '',
});

export const activeSyncSegmentAtom = atom({
  key: 'activeSyncSegmentAtom',
  default: false,
});

export const segmentsSelectedPageAtom = atom<number>({
  key: 'segmentsSelectedPageAtom',
  default: 0,
});

export const segmentsSelectedFilterAtom = atom<string>({
  key: 'segmentsSelectedFilterAtom',
  default: '',
});

export const segmentsLoadingStatusAtom = atom<LoadingStatus>({
  key: 'segmentsLoadingStatusAtom',
  default: 'loading',
});

export const filteredSegmentsCount = selector<number>({
  key: 'filteredSegmentsCount',
  get: ({ get }) => {
    return get(filteredSegments)?.length || 0;
  },
});

export const segmentsSortAtom = atom<FieldSort | null>({
  key: 'segmentsSortAtom',
  default: null,
});

const sortSegments = (
  segments: SegmentTableEntry[],
  sort: FieldSort,
): SegmentTableEntry[] => {
  const fieldName = sort.fieldName as SortableSegmentFieldNames;

  if (fieldName === 'updatedAt') {
    return segments.sort((a, b) => {
      const aDate = new Date(a.updatedAt);
      const bDate = new Date(b.updatedAt);
      return (aDate.getTime() - bDate.getTime()) * sort.direction;
    });
  } else {
    if (fieldName !== 'segmentName') {
      return segments.sort((a, b) => {
        return (a[fieldName] - b[fieldName]) * sort.direction;
      });
    } else {
      return segments.sort(
        (a, b) => a[fieldName]!.localeCompare(b[fieldName]!) * sort.direction,
      );
    }
  }
};

const sortedSegments = selector<SegmentTableEntry[] | null>({
  key: 'sortedSegments',
  get: ({ get }) => {
    const segments = get(segmentTableSelector);
    const sort = get(segmentsSortAtom);
    if (sort) {
      return sortSegments([...segments!], sort);
    }
    return segments;
  },
});

const filteredSegments = selector<SegmentTableEntry[] | null>({
  key: 'filteredSegments',
  get: ({ get }) => {
    let segments = get(sortedSegments);
    const filter = get(segmentsSelectedFilterAtom);

    if (filter && segments) {
      segments = segments.filter((segments) =>
        segments.segmentName
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase()),
      );
    }
    return segments;
  },
});

export const currentSegmentsPage = selector<SegmentTableEntry[]>({
  key: 'currentSegmentsPage',
  get: ({ get }) => {
    let segments = get(filteredSegments);
    const selectedPage = get(segmentsSelectedPageAtom);

    return segments!.slice(
      selectedPage * DEFAULT_PAGE_SIZE,
      (selectedPage + 1) * DEFAULT_PAGE_SIZE,
    );
  },
});

export const filteredSegmentsSelector = selector({
  key: 'filteredSegmentsSelector',
  get: ({ get }) => {
    const search = get(segmentsSearchAtom);
    const segments = get(segmentTableSelector);
    let filteredSegments: SegmentTableEntry[] = [];

    if (segments) {
      if (search) {
        segments.forEach((segment: SegmentTableEntry) => {
          if (
            segment.segmentName.toLowerCase().includes(search.toLowerCase())
          ) {
            filteredSegments.push(segment);
          }
        });
      } else {
        filteredSegments = segments;
      }
    }
    return filteredSegments;
  },
});

/**
 * This is the atom the UI components will use to display segment Treestate.
 */
export const segmentTreeAtom = atom<SegmentTreeData[]>({
  key: 'segmentTreeState',
  default: [],
});

export const segmentIdForPreviewAtom = atom<string>({
  key: 'segmentIdForPreviewAtom',
  default: '',
});

/**
 * Get the segment tree data from the atom.
 */
export const getSegmentTreeByIdSelector = selectorFamily({
  key: 'getSegmentTreeByIdSelector',
  get: (id) => ({ get }): SegmentTreeData | null | undefined => {
    const segmentTree = get(segmentTreeAtom);

    if (!segmentTree) {
      return null;
    }

    return segmentTree.find((segment) => segment.segmentId === id);
  },
});
export const segmentForPreviewAtom = atom<
  Pick<SegmentTreeData, 'segmentId' | 'segmentName'>
>({
  key: 'segmentForPreviewAtom',
  default: {} as SegmentTreeData,
});

export const segmentDataPreview = atom<SegmentData[]>({
  key: 'segmentDataPreview',
  default: [],
});

const createLabelForSyncChannels = (channels: SegmentTreeDataSyncChannel[]) => {
  var syncChannelString = '';

  channels.forEach((channel: SegmentTreeDataSyncChannel) => {
    syncChannelString === ''
      ? (syncChannelString = syncChannelString.concat(channel.name))
      : (syncChannelString = syncChannelString.concat(', ' + channel.name));
  });

  return syncChannelString;
};

/**
 * Populate the default selector
 */
export const segmentTreeSelector = selector<audienceMapDataType[] | null>({
  key: 'segmentTreeSelector',
  get: ({ get }) => {
    const segmentTree = get(segmentTreeAtom);

    if (!segmentTree) {
      return null;
    }

    let tree: audienceMapDataType[] = [];

    tree = segmentTree.map((segment) => {
      let parentNodeRowCount = 0;
      let percent = 100;

      if (segment.parentSegmentIds!.length > 0) {
        parentNodeRowCount =
          get(getSegmentTreeByIdSelector(segment.parentSegmentIds![0]))
            ?.rowCount ?? 0;
        if (parentNodeRowCount + segment.rowCount === 0) {
          percent = 0;
        } else {
          if (parentNodeRowCount <= segment.rowCount) {
            percent = 100;
          } else {
            percent = (segment.rowCount / parentNodeRowCount) * 100;
          }
        }
      }

      return {
        id: segment.segmentId,
        parent: segment.parentSegmentIds![0] || null,
        data: {
          id: segment.segmentId,
          text: segment.segmentName,
          value: segment.rowCount,
          perc: percent,
          type: segment.segmentType,
          parentId: segment.parentSegmentIds![0] || null,
          activeCampaignCount: segment.campaigns.filter((campaign) => {
            return campaign.status === CampaignStatus.Active;
          }).length,
          draftCampaignCount: segment.campaigns.filter((campaign) => {
            return campaign.status === CampaignStatus.Draft;
          }).length,
          pausedCampaignCount: segment.campaigns.filter((campaign) => {
            return campaign.status === CampaignStatus.Paused;
          }).length,
          syncCount: segment.syncChannels.length || 0,
          syncChannelNameList: createLabelForSyncChannels(segment.syncChannels),
          logicalExpression: segment.logicalExpression,
          hasSplitChildren: !!segment.childSegmentIds?.filter(
            (childSegment) => {
              return (
                childSegment &&
                get(getSegmentTreeByIdSelector(childSegment))?.segmentType ===
                  SegmentType.Split
              );
            },
          ).length,
        },
      };
    });

    return tree;
  },
});

/**
 * Populate the Segment Table
 */
export const segmentTableSelector = selector<SegmentTableEntry[] | null>({
  key: 'segmentTableSelector',
  get: ({ get }) => {
    const segmentTree = get(segmentTreeAtom);

    if (!segmentTree) {
      return null;
    }

    let segmentTable: SegmentTableEntry[] = [];

    segmentTable = segmentTree
      .map((segment) => {
        return {
          segmentId: segment.segmentId,
          segmentName: segment.segmentName,
          subSegmentCount: segment.childSegmentIds?.length || 0,
          segmentType: segment.segmentType,
          rowCount: segment.rowCount,
          activeCampaignCount:
            segment.campaigns.filter((campaign) => {
              return campaign.status === CampaignStatus.Active;
            }).length || 0,
          updatedAt: segment.updatedAt,
          updatedBy: segment.updatedBy,
        };
      })
      .sort((a, b) =>
        a.segmentName.toLowerCase() > b.segmentName.toLowerCase() ? 1 : -1,
      );

    return segmentTable;
  },
});

export const segmentDataAccountsSelector = selector<any>({
  key: 'segmentDataAccountsSelector',
  get: ({ get }) => {
    const preview = get(segmentDataPreview);

    if (!preview) {
      return null;
    }

    const accountIds: Pick<SegmentData, 'accountId'>[] = [];
    preview?.forEach((account) => {
      accountIds.push({ accountId: account.accountId });
    });
    return accountIds;
  },
});

type SyncedChannelIdsSelectorParamType = {
  segmentId: string | undefined;
};

export const syncedChannelIdsSelector = selectorFamily<
  any,
  SyncedChannelIdsSelectorParamType
>({
  key: 'syncedChannelIdsSelector',
  get: ({ segmentId }) => ({ get }) => {
    const segmentsInStore = get(segmentTreeAtom);
    let syncedChannelIds: any[] | undefined = [];

    if (segmentId) {
      const currentSegment = segmentsInStore.find(
        (storeSegment) => storeSegment.segmentId === segmentId,
      );

      syncedChannelIds = currentSegment?.syncChannels.map(
        (channel) => channel.id,
      );
    }

    return syncedChannelIds;
  },
});

export const syncedChannelsSelector = selectorFamily<any, { id: string }>({
  key: 'syncedChannelsSelector',
  get: ({ id }) => ({ get }) => {
    const syncs = get(syncsAtom);
    let syncedChannels: string[] = [];

    syncs.forEach((sync) => {
      if (sync.segmentId !== id) {
        return;
      }
      if (!syncedChannels.includes(sync.channel.channelType)) {
        syncedChannels.push(sync.channel.channelType);
      }
    });

    return syncedChannels;
  },
});

export const firstTenSegmentsSelector = selector({
  key: 'firstTenSegments',
  get: ({ get }) => {
    const segments = get(segmentTableSelector);
    const filteredSegments = segments?.filter(
      (segment) => segment.segmentName !== SegmentType.EverythingElse,
    );

    if (filteredSegments) {
      return filteredSegments.slice(0, 10).map((segment) => {
        return {
          segmentId: segment.segmentId,
          segmentName: segment.segmentName,
        };
      });
    }
  },
});
