import React, { FunctionComponent, useContext, useState } from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Dropdown, DropdownItem } from 'plume-ui';
import { AvailableIntegrationTypes } from '../../types';

import { AddChannelModalContext } from './AddChannelModalContext';
import { useTranslation } from 'react-i18next';
import { AdAccountResponseItem } from 'features/configuration/integrationsState';

type AddChannelConfigureSelectAccountProps = {
  adAccounts: AdAccountResponseItem[];
  onSelectAccount: (account: AdAccountResponseItem) => void;
};

const mapSelectedIntegrationToSelectAccountDropdownLabel: Record<
  AvailableIntegrationTypes,
  string
> = {
  [AvailableIntegrationTypes.Google]: 'settings.channel.googleDropdownLabel',
  [AvailableIntegrationTypes.Facebook]:
    'settings.channel.facebookDropdownLabel',
};

export const AddChannelConfigureSelectAccount: FunctionComponent<AddChannelConfigureSelectAccountProps> = ({
  adAccounts,
  onSelectAccount,
}) => {
  const context = useContext(AddChannelModalContext);
  const { t } = useTranslation();
  const [dropdownLabel, setDropdownLabel] = useState<string>(
    t('settings.channel.accountLabel'),
  );

  const onDropdownItemClick = (account: AdAccountResponseItem) => {
    setDropdownLabel(account.ad_account.name);
    onSelectAccount?.(account);
  };

  return (
    <>
      <FormattedMessage
        id={
          mapSelectedIntegrationToSelectAccountDropdownLabel[
            context.selectedAccountType as AvailableIntegrationTypes
          ]
        }
      />
      <div className="AddChannelModal__dropdownContainer">
        <Dropdown label={dropdownLabel}>
          {adAccounts.map((account: AdAccountResponseItem, index) => (
            <DropdownItem
              key={account.ad_account_id}
              selected={index === 0}
              onClick={() => {
                onDropdownItemClick(account);
              }}
            >
              {account.ad_account?.name || ''}
            </DropdownItem>
          ))}
        </Dropdown>
      </div>
    </>
  );
};

export default AddChannelConfigureSelectAccount;
