import { GenerateSync, SyncsByChannelType } from 'features/syncs/types';
import { atom, selector, selectorFamily } from 'recoil';

export const syncsAtom = atom<GenerateSync[]>({
  key: 'syncsAtom',
  default: [],
});

export const syncsSearchAtom = atom<string>({
  key: 'syncsSearchAtom',
  default: '',
});

export const syncSelector = selectorFamily({
  key: 'syncSelector',
  get: (syncId: string | undefined) => ({ get }) => {
    if (!syncId) {
      return;
    }

    const syncs = get(syncsAtom);
    const foundSync = syncs.find((sync) => sync.syncId === syncId);

    return foundSync;
  },
});

export const syncsByChannelSelector = selector({
  key: 'syncsByChannelSelector',
  get: ({ get }) => {
    const syncs = get(syncsAtom);
    const channels: SyncsByChannelType = {};

    if (!syncs.length) {
      return {};
    }

    syncs.forEach((sync) => {
      if (!sync.channel) {
        return;
      }

      if (channels[sync.channel.channelId] === undefined) {
        channels[sync.channel.channelId] = [];
      }

      channels[sync.channel.channelId].push(sync);
    });

    return channels;
  },
});
