import React, { FunctionComponent } from 'react';
import { IntegrationErrorComponentProps } from '../../../integrations/types';
import ErrorActionsRenderer from './ErrorActionsRenderer';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { ErrorNotValidAdsUser } from '../../errors';

export const ErrorNotValidAdsUserComponent: FunctionComponent<IntegrationErrorComponentProps> = ({
  actions,
}) => {
  return (
    <div className="IntegrationsContainer__error">
      <h1>
        <FormattedMessage id="settings.errors.notValidAdsUser.title" />
      </h1>
      <p>
        <FormattedMessage id="settings.errors.notValidAdsUser.description" />
      </p>
      <ErrorActionsRenderer actions={actions} error={ErrorNotValidAdsUser} />
    </div>
  );
};

export default ErrorNotValidAdsUserComponent;
