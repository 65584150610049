import { getDatesForSelectedTimeRange } from './SegmentHelpers';
import {
  OverviewChartColorOptions,
  NpsEnum,
  NpsScoresOverTimeType,
} from './types';
import i18next from 'i18next';

export const npsAreaChartGroups = [
  {
    key: NpsEnum.NPS_SCORE,
    label: i18next.t('overview.nps.npsScore'),
    stroke: OverviewChartColorOptions.HOT_500,
    backgroundColor: OverviewChartColorOptions.HOT_500_20,
    strokeWidth: 2,
  },
];

export const npsBarChartGroups = [
  {
    key: NpsEnum.NPS_SCORE,
    label: i18next.t('overview.nps.npsScore'),
    color: '#656cff',
    stroke: '#656cff',
  },
];

const arrayOfScores = [
  50,
  -35,
  43,
  24,
  56,
  45,
  -47,
  51,
  67,
  66,
  60,
  -71,
  22,
  55,
  88,
  -75,
  62,
  44,
  15,
  79,
  -90,
  77,
  72,
  45,
  14,
  -36,
  57,
  26,
  47,
  75,
];

export const createMock30DayResponse = () => {
  const campaignName = 'NPS Survey 1';
  const campaignId = 'campaign_5';
  const heroMetric = {
    npsScore: 30.5,
    detractors: 30,
    promoters: 60,
    passives: 150,
  };
  let scoresOverTime: NpsScoresOverTimeType[] = [];
  const dates = getDatesForSelectedTimeRange(30);

  dates.forEach((date, idx) => {
    const npsScore = arrayOfScores[idx];
    const detractors = Math.floor(Math.random() * 100);
    const promoters = Math.floor(Math.random() * 100);
    const passives = Math.floor(Math.random() * 100);
    scoresOverTime.push({
      dt: date,
      npsScore,
      detractors,
      promoters,
      passives,
    });
  });

  return {
    campaignName,
    campaignId,
    heroMetric,
    scoresOverTime,
  };
};

export const createMockAllTimeResponse = () => {
  const campaignName = 'NPS Survey 1';
  const campaignId = 'campaign_5';
  const heroMetric = {
    npsScore: 30.5,
    detractors: 30,
    promoters: 60,
    passives: 150,
  };
  let scoresOverTime: NpsScoresOverTimeType[] = [
    {
      dt: '2023-4-01',
      npsScore: 52,
      detractors: Math.floor(Math.random() * 100),
      promoters: Math.floor(Math.random() * 100),
      passives: Math.floor(Math.random() * 100),
    },
    {
      dt: '2023-05-01',
      npsScore: 55,
      detractors: Math.floor(Math.random() * 100),
      promoters: Math.floor(Math.random() * 100),
      passives: Math.floor(Math.random() * 100),
    },
    {
      dt: '2023-06-01',
      npsScore: 63,
      detractors: Math.floor(Math.random() * 100),
      promoters: Math.floor(Math.random() * 100),
      passives: Math.floor(Math.random() * 100),
    },
  ];

  return {
    campaignName,
    campaignId,
    heroMetric,
    scoresOverTime,
  };
};
