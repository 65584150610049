import moment from 'moment';
import {
  Button,
  CollapseCard,
  Divider,
  Heading,
  Icons,
  InputField,
} from 'plume-ui';
import { BlockPicker } from 'react-color';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'utils/components/FormattedMessage';
import {
  brandingCompanyNameAtom,
  brandingLogoAtom,
  brandingMobileApplicationNameAtom,
  brandingPrimaryColorAtom,
  brandingProductNameAtom,
} from 'store/state/configurationState';
import { useRecoilState } from 'recoil';
import { InputMessageStatus } from 'plume-ui/dist/components/InputField/InputField';

const BrandingCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const [brandingList, setBrandingList] = useRecoilState(brandingLogoAtom);
  const [brandingPrimaryColor, setBrandingPrimaryColor] = useRecoilState(
    brandingPrimaryColorAtom,
  );
  const [brandingCompanyName, setBrandingCompanyName] = useRecoilState(
    brandingCompanyNameAtom,
  );

  const [
    brandingMobileApplicationName,
    setBrandingMobileApplicationName,
  ] = useRecoilState(brandingMobileApplicationNameAtom);
  const [brandingProductName, setBrandingProductName] = useRecoilState(
    brandingProductNameAtom,
  );

  const newLogoHint = (input: string) => {
    return input === '' || input === null
      ? [
          {
            status: 'hint' as InputMessageStatus,
            message: t('settings.campaignSetting.BrandingCard.logoSubtitle'),
          },
        ]
      : [];
  };

  const addLogo = () => {
    const currentList = [...brandingList];
    currentList.push({
      url: '',
      date: new Date().toISOString(),
      isDefault: false,
    });
    setBrandingList(currentList);
  };

  const removeLogo = (logoIdx: number) => {
    const currentList = [...brandingList];
    currentList.splice(logoIdx, 1);
    setBrandingList(currentList);
  };

  const defaultHandler = (logoIdx: number) => {
    const currentList = [...brandingList];
    const updatedList = currentList.map((element, idx) => {
      return {
        ...element,
        isDefault: logoIdx === idx ? true : false,
      };
    });
    setBrandingList(updatedList);
  };

  const resetHandler = () => {
    setBrandingPrimaryColor('#FFFFFF');
  };

  const handleUrlInput = (logoIdx: number, value: string) => {
    const currentList = [...brandingList];
    currentList.splice(logoIdx, 1, {
      ...currentList[logoIdx],
      url: value === undefined ? null : value.trim(),
    });

    setBrandingList(currentList);
  };

  const handleBrandingCompanyInput = (input: string) => {
    const companyName = input || '';
    setBrandingCompanyName(companyName);
  };

  const handleBrandingMobileApplicationNameInput = (input: string) => {
    const appName = input || '';
    setBrandingMobileApplicationName(appName);
  };

  const handleBrandingProductNameInput = (input: string) => {
    const productName = input || '';
    setBrandingProductName(productName);
  };

  return (
    <div className="BrandingCard__cardMain">
      <CollapseCard
        classes={(current) => ({
          ...current,
          innerContent: `${current.innerContent} BrandingCard`,
        })}
        open={false}
        title={
          <Heading size="m">
            <FormattedMessage id="settings.campaignSetting.BrandingCard.title" />
          </Heading>
        }
        subtitle={t('settings.campaignSetting.BrandingCard.subtitle')}
      >
        <div className="BrandingCard__inputGroup">
          <div className="BrandingCard__head">
            <p>
              <FormattedMessage id="settings.campaignSetting.BrandingCard.companyTitle" />
            </p>
          </div>
          <div className="BrandingCard__InputWrapper">
            <InputField
              placeholder={t(
                'settings.campaignSetting.BrandingCard.companyNameInputFieldLabel',
              )}
              value={brandingCompanyName}
              onInput={(e) => handleBrandingCompanyInput(e.currentTarget.value)}
            />
          </div>
          <div className="BrandingCard__head">
            <p>
              <FormattedMessage id="settings.campaignSetting.BrandingCard.mobileApplicationTitle" />
            </p>
          </div>
          <div className="BrandingCard__InputWrapper">
            <InputField
              placeholder={t(
                'settings.campaignSetting.BrandingCard.mobileApplicationInputPlaceholder',
              )}
              value={brandingMobileApplicationName}
              onInput={(e) =>
                handleBrandingMobileApplicationNameInput(e.currentTarget.value)
              }
            />
          </div>
          <div className="BrandingCard__head">
            <p>
              <FormattedMessage id="settings.campaignSetting.BrandingCard.productNameTitle" />
            </p>
          </div>
          <div className="BrandingCard__InputWrapper">
            <InputField
              placeholder={t(
                'settings.campaignSetting.BrandingCard.productNameInputPlaceholder',
              )}
              value={brandingProductName}
              onInput={(e) =>
                handleBrandingProductNameInput(e.currentTarget.value)
              }
            />
          </div>
        </div>
        {brandingList && brandingList.length > 0 && (
          <>
            <div className="BrandingCard__head-logo">
              <p>
                <FormattedMessage id="settings.campaignSetting.BrandingCard.logoTitle" />
              </p>
            </div>
            <div className="BrandingCard__headerContainer">
              <div className="BrandingCard__headers">
                <FormattedMessage id="settings.campaignSetting.BrandingCard.urlLabel" />
              </div>
              <div className="BrandingCard__headers">
                <FormattedMessage id="settings.campaignSetting.BrandingCard.dateLabel" />
              </div>
            </div>
          </>
        )}
        {brandingList.map((logo, idx) => (
          <div className="BrandingCard__logo-row" key={`${logo}-${idx}`}>
            <div className="BrandingCard__urlInputWrapper">
              <InputField
                classes={(current) => ({
                  ...current,
                  root: `${current.root} BrandingCard__urlInput`,
                })}
                placeholder={t(
                  'settings.campaignSetting.BrandingCard.urlInputFieldLabel',
                )}
                onInput={(e) => handleUrlInput(idx, e.currentTarget.value)}
                value={logo.url || ''}
                messages={newLogoHint(logo.url as string)}
              />
            </div>
            <div className="BrandingCard__dateAddedGroup">
              <div className="BrandingCard__row-item">
                {moment(new Date(logo.date)).format('ll')}
              </div>
              <Button
                classes={(current) => ({
                  ...current,
                  root: `${current.root} BrandingCard__row-item-defaultBtn`,
                  container: `${current.root} BrandingCard__row-item-defaultBtn-container`,
                })}
                styleVariant="tertiary-grey"
                onClick={() => defaultHandler(idx)}
              >
                {logo.isDefault
                  ? t('settings.campaignSetting.defaultButton')
                  : t('settings.campaignSetting.makeDefaultButton')}
              </Button>
              <div
                className="BrandingCard__trashIcon"
                onClick={() => removeLogo(idx)}
              >
                <Icons.TrashIcon width={24} />
              </div>
            </div>
          </div>
        ))}
        <div className="BrandingCard__addLogoBtn">
          <Button onClick={() => addLogo()}>
            {t('settings.campaignSetting.BrandingCard.addLogo')}
          </Button>
        </div>
        <div className="BrandingCard__DividerWrapper">
          <Divider />
        </div>
        <div className="BrandingCard__colorsTitle">
          <FormattedMessage id="settings.campaignSetting.BrandingCard.colorsTitle" />
        </div>
        <div className="BrandingCard__colorInfo">
          <FormattedMessage id="settings.campaignSetting.BrandingCard.colorsInfo" />
        </div>
        <BlockPicker
          color={brandingPrimaryColor}
          onChange={(color) => setBrandingPrimaryColor(color.hex)}
          colors={[]}
        />
        <div className="BrandingCard__resetButtonWrapper">
          <Button onClick={() => resetHandler()}>
            <FormattedMessage id="settings.campaignSetting.BrandingCard.resetButton" />
          </Button>
        </div>
      </CollapseCard>
    </div>
  );
};

export default BrandingCard;
