import ChannelsClient from 'features/configuration/services/ChannelsClient';
import ConfigurationClient from 'features/configuration/services/ConfigurationClient';
import ConfigurationService from 'features/configuration/services/ConfigurationService';
import CreateJourneyClient from 'features/crusade/services/AddJourneyClient';
import CreateJourneyService from 'features/crusade/services/AddJourneyService';
import CampaignAudienceClient from 'features/crusade/services/CampaignAudienceClient';
import CampaignAudienceService from 'features/crusade/services/CampaignAudienceService';
import CampaignObjectiveClient from 'features/crusade/services/CampaignObjectiveClient';
import CampaignObjectiveService from 'features/crusade/services/CampaignObjectiveService';
import CampaignTemplateClient from 'features/crusade/services/CampaignTemplateClient';
import CampaignTemplateService from 'features/crusade/services/CampaignTemplateService';
import SegmentsPreviewClient from 'features/generate/audience/services/SegmentsPreviewClient';
import SegmentsPreviewService from 'features/generate/audience/services/SegmentsPreviewService';
import IntrospectClient from 'features/login/services/IntrospectClient';
import IntrospectService from 'features/login/services/IntrospectService';
import OverviewClient from 'features/overview/services/OverviewClient';
import OverviewService from 'features/overview/services/OverviewService';
import SyncsClient from 'features/syncs/services/SyncsClient';
import SyncsService from 'features/syncs/services/SyncsService';
import ChannelsService from './features/configuration/services/ChannelsService';
import CrusadeJourneyClient from './features/crusade/services/CrusadeCampaignClient';
import CrusadeJourneyService from './features/crusade/services/CrusadeCampaignService';
import AudienceClient from './features/generate/audience/services/AudienceClient';
import AudienceService from './features/generate/audience/services/AudienceService';
import TraitsClient from './features/generate/audience/services/TraitsClient';
import TraitsService from './features/generate/audience/services/TraitsService';
import IntegrationsClient from './features/integrations/services/IntegrationsClient';
import IntegrationsService from './features/integrations/services/IntegrationsService';
import LoginService from './features/login/LoginService';

export class DependencyContainer {
  // services
  loginService = new LoginService(this);
  audienceService = new AudienceService(this);
  traitsService = new TraitsService(this);
  crusadeJourneyService = new CrusadeJourneyService(this);
  createJourneyService = new CreateJourneyService(this);
  campaignAudienceService = new CampaignAudienceService(this);
  campaignObjectiveService = new CampaignObjectiveService(this);
  segmentsPreviewService = new SegmentsPreviewService(this);
  channelsService = new ChannelsService(this);
  syncsService = new SyncsService(this);
  integrationsService = new IntegrationsService(this);
  introspectService = new IntrospectService(this);
  configurationService = new ConfigurationService(this);
  campaignTemplateService = new CampaignTemplateService(this);
  overviewService = new OverviewService(this);

  // clients
  audienceClient = new AudienceClient(this);
  crusadeJourneyClient = new CrusadeJourneyClient(this);
  createJourneyClient = new CreateJourneyClient(this);
  campaignAudienceClient = new CampaignAudienceClient(this);
  campaignObjectiveClient = new CampaignObjectiveClient(this);
  traitsClient = new TraitsClient(this);
  segmentsPreviewClient = new SegmentsPreviewClient(this);
  channelsClient = new ChannelsClient(this);
  syncsClient = new SyncsClient(this);
  integrationsClient = new IntegrationsClient(this);
  introspectClient = new IntrospectClient(this);
  configurationClient = new ConfigurationClient(this);
  campaignTemplateClient = new CampaignTemplateClient(this);
  overviewClient = new OverviewClient(this);
}
