import { Maybe } from 'types';
import { DependencyContainer } from '../../../../DependencyContainer';
import {
  AudienceFlowResponse,
  SegmentSplitNodeDto,
  SegmentSplitNodeResponse,
} from '../types';
import { SegmentDtoProtocol } from '../../../../components/SegmentsModal/types';

export default class AudienceService {
  versionKnown = false;
  constructor(private readonly factory: DependencyContainer) {}

  async getVersion(): Promise<string | void> {
    if (this.versionKnown) {
      return;
    }
    this.versionKnown = true;
    const { data } = await this.factory.audienceClient.getApiVersion();
    return `${data.apiVersion} (${data.imageTag})`;
  }

  async getAudienceFlows(
    page: number = 0,
    limit: number = 10,
    query?: string,
  ): Promise<AudienceFlowResponse> {
    return await this.factory.audienceClient.getAudienceFlows(
      page,
      limit,
      query,
    );
  }

  async getSegmentDataTree(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.audienceClient.getSegmentDataTree(partnerId);
  }

  async addSegment(
    partnerId: Maybe<string>,
    segment: SegmentDtoProtocol,
  ): Promise<any> {
    if (partnerId === undefined) {
      return;
    }
    return await this.factory.audienceClient.addSegment(partnerId, segment);
  }

  async updateSegment(
    partnerId: Maybe<string>,
    segment: SegmentDtoProtocol,
    segmentId: string,
  ): Promise<any> {
    if (!partnerId) {
      return;
    }
    return await this.factory.audienceClient.updateSegment(
      partnerId,
      segment,
      segmentId,
    );
  }

  async deleteSegment(
    partnerId: Maybe<string>,
    segmentId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.audienceClient.deleteSegment(
      partnerId,
      segmentId,
    );
  }

  async getSplitNode(
    partnerId: string,
    segmentId: string,
  ): Promise<SegmentSplitNodeResponse> {
    return await this.factory.audienceClient.getSplitNode(partnerId, segmentId);
  }

  async addSplitNode(
    partnerId: Maybe<string>,
    segmentId: string,
    dto: SegmentSplitNodeDto,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.audienceClient.addSplitNode(
      partnerId,
      segmentId,
      dto,
    );
  }

  async updateSplitNode(
    partnerId: Maybe<string>,
    segmentId: string,
    dto: SegmentSplitNodeDto,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.audienceClient.updateSplitNode(
      partnerId,
      segmentId,
      dto,
    );
  }

  async deleteSplitNode(partnerId: string, segmentId: string): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.audienceClient.deleteSplitNode(
      partnerId,
      segmentId,
    );
  }
}
