import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { segmentHistoryAtom } from 'store/state/overviewState';
import { SegmentGroupType } from '../types';

type UseSegmentHistoryValues = {
  loading: boolean;
  error: any;
  runFetchSegmentHistory: (params?: any) => Promise<any>;
};

const { overviewService } = new DependencyContainer();

export const useSegmentHistory = (
  numOfDays: number,
  segments?: SegmentGroupType[],
): UseSegmentHistoryValues => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const setSegmentHistory = useSetRecoilState(segmentHistoryAtom);
  const segmentIds = segments?.map((segment) => segment.segmentId);

  const [loading, , error, runFetchSegmentHistory] = useFetchData(
    async () => {
      return await overviewService.getSegmentGrowthHistory(
        partnerId,
        numOfDays,
        segmentIds,
      );
    },
    (history) => {
      setSegmentHistory(history.data);
    },
    [partnerId, numOfDays, segments],
    () =>
      partnerId === undefined ||
      numOfDays === undefined ||
      !segments ||
      !segments.length,
  );

  return {
    loading,
    error,
    runFetchSegmentHistory,
  };
};
