export enum IntegrationErrorDetails {
  RequiredPermissionsNotGranted = 'Required permissions not granted',
  NotValidAdsUser = 'User in the cookie is not a valid Ads user.',
}

export interface CustomErrorProtocol {
  constructor: Function;
}

export class ErrorPermissionsNotGranted extends Error
  implements CustomErrorProtocol {
  constructor() {
    super('Permissions not granted');
  }
}

export class ErrorNonApprovedDeveloperToken extends Error
  implements CustomErrorProtocol {
  constructor() {
    super(
      'The developer token is not approved. Non-approved developer tokens can only be used with test accounts.',
    );
  }
}

export class ErrorNotValidAdsUser extends Error implements CustomErrorProtocol {
  constructor() {
    super('The user is not valid Ads user');
  }
}

export class ErrorMissingAccessToken extends Error
  implements CustomErrorProtocol {
  constructor() {
    super('Access token is missing in 3rd party integration response');
  }
}

export class ErrorMissingStrategy extends Error implements CustomErrorProtocol {
  constructor() {
    super('Strategy is missing for this integration flow');
  }
}

export class ErrorInvalidOAuthUrl extends Error implements CustomErrorProtocol {
  constructor() {
    super(
      'Unable to redirect to the 3rd party integration as url is missing or is invalid',
    );
  }
}

export class ErrorOAuthCancelled extends Error implements CustomErrorProtocol {
  constructor() {
    super('Linkin account cancelled by user');
  }
}

export class ErrorGeneric extends Error implements CustomErrorProtocol {}

export class ErrorRequestTimedOut extends Error implements CustomErrorProtocol {
  constructor() {
    super('Request timed out');
  }
}
