import { Maybe } from 'yup/lib/types';
import { DependencyContainer } from '../../../DependencyContainer';
import { CampaignObjective } from '../types';

export default class CampaignObjectiveService {
  constructor(private readonly factory: DependencyContainer) {}

  async getObjectivesForPartner(partnerId: Maybe<string>): Promise<any> {
    if (!partnerId) return;
    return this.factory.campaignObjectiveClient.getObjectivesForPartner(
      partnerId,
    );
  }

  async createObjective(
    partnerId: Maybe<string>,
    objective: Omit<CampaignObjective, 'id'>,
  ): Promise<any> {
    if (!partnerId) return;
    return this.factory.campaignObjectiveClient.createObjective(
      partnerId,
      objective,
    );
  }

  async updateObjective(
    partnerId: Maybe<string>,
    objective: CampaignObjective,
  ): Promise<any> {
    if (!partnerId) return;
    return this.factory.campaignObjectiveClient.updateObjective(
      partnerId,
      objective,
    );
  }
}
