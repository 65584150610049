import { FieldInputProps, FormikProps } from 'formik';
import { Maybe } from 'types';

export type CrusadeCampaignJourney = {
  campaigns: CrusadeCampaign[];
  campaignDescription: string;
  journeyId: string;
  journeyName: string;
};

export type CrusadeJourneys = {
  journeyId: string;
  journeyName: string;
  journeyDescription: string;
};

export type CrusadeJourneysResponse = {
  brazeAppId: string;
  data: CrusadeJourneys[] | null;
};

export type CrusadeCampaignAudienceType = {
  createdAt: string;
  createdBy: string;
  segmentId: string;
  segmentName: string;
  updatedAt: string;
  updatedBy: string;
}[];

export type CrusadeCampaignAudienceSingleType = {
  createdAt: string;
  createdBy: string;
  segmentId: string;
  segmentName: string;
  updatedAt: string;
  updatedBy: string;
};

export type CampaignDynamicContentType = {
  id: string;
  label: string;
  type: string;
  value: boolean;
};

// This is what the campaign becomes once we parse it to use in the app
//  it combines push and email and adds lastEdited for the table, journeyName
//  and campaignId
export type CrusadeCampaign = {
  campaignId: string;
  journeyName?: string;
  status: CampaignStatus;
  lastEdited?: string;
  campaignType: string;
  campaignName: string;
  journeyId?: string;
  description?: string;
  dynamicContent: CampaignDynamicContentType[] | null;
  tags?: Tags[];
  audienceSegments?: AudienceSegmentType[];
  audience?:
    | CrusadeCampaignAudienceType[]
    | CrusadeCampaignAudienceSingleType[];
  audienceSummary?: string;
  audienceSize?: string;
  message?: CampaignPushNotificationCardValues | undefined;
  delivery?: CampaignDeliveryType;
  immediately: boolean;
  sendToTheseUsers: SendToUsersType;
  warmup: boolean;
  from?: string;
  subject?: string;
  emailBody?: string;
  emailPreheaderOverwrite?: string;
  updatedBy?: string;
  objectives?: string[] | null;
};

export type CreateUpdateCampaignRequest = CrusadeCampaign & {
  objectiveNames?: string[];
};

export type CrusadeJourneysAndCampaigns = {
  campaigns: CrusadeCampaign[];
  description: string;
  journeyId: string;
  journeyName: string;
};

export type CrusadeJourneysAndCampaignsResponseData = {
  brazeAppId: string;
  journeys: CrusadeJourneysAndCampaigns[];
};

export type CrusadeJourneysAndCampaignsResponse = {
  data: CrusadeJourneysAndCampaignsResponseData | null;
  message: string;
  statusCode: number;
};

export enum SegmentOrigin {
  Generate = 'generate',
  Signal = 'signal',
}

export type TargetAudienceResponse = {
  segmentOrigin: SegmentOrigin;
  segmentId: string;
  segmentName: string;
};

export type PreCalculateGlobalFiltersResponse = {};

export type PreCalculateGlobalFiltersParams = Pick<
  CrusadeCampaign,
  'campaignType' | 'audienceSegments' | 'sendToTheseUsers'
>;

export type IPWarmupParams = {
  warmup: boolean;
  audienceSegments: AudienceSegmentType[];
  delivery?: Pick<
    CampaignDeliveryType,
    'startDate' | 'startTime' | 'endDate' | 'endTime'
  >;
  campaignType?: CampaignTypes;
  sendToTheseUsers?: SendToUsersType;
};

export type IPWarmupResponse = {
  sendDay: string;
  audiencesSize: number;
};

export enum CampaignStatus {
  Draft = 'Draft',
  Active = 'Active',
  Paused = 'Paused',
  Completed = 'Completed',
}

export enum CampaignJourney {
  VALUE_DISCOVERY = 'Value Discovery',
  UNCLASSIFIED = 'Unclassified',
}

export type CrusadeCampaignInfo = {
  brazeCampaignId: string;
  brazeTemplateId?: string;
  campaignId: string;
  campaignType: CampaignType;
  emailBody: string;
  emailBodyPreview: string;
  from: string;
  subject: string;
};

export type CrusadeCampaignInfoResponse = {
  data: CrusadeCampaignInfo;
  message: string;
  statusCode: number;
};

export type CreateJourney = {
  journeyName: string;
  description: string;
};

export type CreateJourneyResponse = {
  plumeJourneyName: string;
  journeyDescription: string;
  partnerId: string;
  createdBy: string;
  updatedBy: string;
  plumeJourneyId: string;
  createdAt: string;
  updatedAt: string;
};

export type HeroStats = {
  title: string;
  subtitle?: string;
  trendValue?: number;
  trendDirection?: string;
  value: number;
  valueUnit?: string;
};

export type NotificationPreviewItems = {
  title: string;
};

export type NotificationPreview = {
  title: string;
  label: string;
  items: NotificationPreviewItems[];
};

export type Sync = {
  syncId: string;
  channelName: string;
  segmentName: string;
  rows_added: number;
  rows_deleted: number;
  rows_updated: number;
  customAudience: string;
  status: string;
  syncExecutedAt: string;
};

export type AudienceDropdownItems = {
  title: string;
  summary?: string;
  size?: number;
  dbValue?: string | boolean | number;
};

export type AudienceCard = {
  title: string;
  label: string | boolean;
  items: AudienceDropdownItems[];
};

export type DisplayNameAddressCardType = {
  partnerId: string;
  fromDisplayName: string | null;
  localPart: string | null;
  isDefault: boolean;
  emailDomain: string;
  emailIpPool: string;
};

export type ReplyToAddressCardType = {
  fromDisplayName: string | null;
  isDefault: boolean;
};

export type BrandingLogoType = {
  url: string | null;
  date: string;
  isDefault: boolean;
};

export type BrandingCardType = {
  logos: BrandingLogoType[];
  colorPrimary: string;
  companyName: string;
  mobileAppName: string;
  productName: string;
};

export type CustomFooterCardType = {
  partnerId: string;
  customFooterContent: string;
  customFooterId?: number;
};

export type CampaignConfigurationPayloadType = {
  replyToAddresses: ReplyToAddressCardType[];
  displayNameAddresses: DisplayNameAddressCardType[];
  customFooter: CustomFooterCardType;
  branding: BrandingCardType;
};

export type Tags = {
  id: number;
  value: string;
};

export type NotificationDropdownItems = {
  label: string;
  value: number;
};

export type NotificationDropdownList = {
  defaultLabel: string;
  items: NotificationDropdownItems[];
};

export type CampaignPushNotification = {
  title: string;
  message: string;
};

export type CampaignInformationCardValues = {
  campaignName: string;
  description: string;
  journeyId: string | undefined;
  campaignType: CampaignTypes;
  status?: CampaignStatus;
  campaignId: string;
  objectives: string[] | null;
};

export type CampaignAudienceCardValues = {
  audienceSegments: AudienceSegmentType[];
  audienceSummary?: string;
  audienceSize?: string;
  sendToTheseUsers: SendToUsersType;
};

export type CampaignMessageCardValues = {
  from?: string;
  subject?: string;
  emailBody?: string;
  dynamicContent?: CampaignDynamicContentType[];
  emailPreheaderOverwrite?: string;
};

export type CampaignPushNotificationCardValues = {
  title?: string;
  messageBody?: string;
  onClickBehavior?: {
    action: Maybe<PushCampaignMessageOnClickActionType>;
    redirectURL?: string;
  };
};

export type CampaignDeliveryCardValues = {
  delivery: CampaignDeliveryType;
  immediately: boolean;
  warmup: boolean;
};

export type CampaignDtoType = {
  campaignInformationCard: CampaignInformationCardValues;
  campaignAudienceCard: CampaignAudienceCardValues;
  campaignPushNotificationCard: CampaignPushNotificationCardValues;
  campaignMessageCard: CampaignMessageCardValues;
  campaignDeliveryCard: CampaignDeliveryCardValues;
};

export type CampaignTypes = 'email' | 'pushNotification';

export enum CampaignType {
  EMAIL = 'email',
  PUSH_NOTIFICATION = 'pushNotification',
}

export type EndRecurrenceConditionTypes = 'neverEnds' | 'onSpecificDate';

export enum EndRecurrenceConditionEnum {
  NEVER_ENDS = 'neverEnds',
  ON_SPECIFIC_DATE = 'onSpecificDate',
}

export type EndRecurrenceType = {
  condition: EndRecurrenceConditionTypes;
  endDate?: string;
};

export enum PushCampaignMessageOnClickEnum {
  OPEN_APP = 'openApp',
  REDIRECT_TO_WEB_URL = 'redirectToWebUrl',
}

export type PushCampaignMessageOnClickActionType =
  | 'openApp'
  | 'redirectToWebUrl';

export type CampaignListType = {
  campaignId: string;
};

export type GlobalFilterType = {
  criterionName: string;
  operator: string;
  criterionValue: boolean | string | number;
  campaignList?: CampaignListType[];
};

export type SendToUsersType =
  | 'Users who are subscribed or opted in'
  | 'All users including unsubscribed users';

export type AudienceSegmentType = {
  segmentId: string;
  segmentName: string;
  segmentOrigin?: SegmentOrigin;
};

export type CampaignDeliveryType = {
  recurrence: string;
  endDate: string;
  startDate: string;
  endTime: string;
  startTime: string;
  endRecurrence?: EndRecurrenceType;
  frequency?: number;
  weekly?: number[];
};

export type CrusadeCampaignPushStatisticsResponse = {
  bounce: number; //bounce will not be used until later
  clicks: number;
  deliveries: number;
  directOpend: number;
  influenceOpen: number;
  messageSend: number;
  lastSendDate?: string; // "2022-08-08T01:01:31.198Z"
};

export type WarmupDetailsType = {
  status: string;
  counts: string;
};

export type WarmupTimeType = {
  finished: number | undefined;
  total: number | undefined;
  details: WarmupDetailsType[];
};

export type DeliveryDropdownItems = {
  title: string;
  dbValue?: string | number;
};

export type DeliveryCard = {
  title: string;
  label: string;
  items: DeliveryDropdownItems[];
};

export enum DeliveryRecurrenceEnum {
  ONCE = 'once',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum DeliveryIPWarmupCadenceEnum {
  IMMEDIATELY = 'immediately',
  CHOOSE_DATE = 'chooseDate',
}

export type CrusadeVersionResponse = {
  apiVersion: string;
  imageTag: string;
};

export enum ConfigurationEnum {
  SEGMENTS = 'segments',
  SYNCS = 'syncs',
}

export type CrusadeValidateEmailResponse = {
  message: string;
  foundEmailToExternalIds: string[];
  missingEmailToExternalIds: string[];
};

export type SortableCampaignFieldNames =
  | 'campaignName'
  | 'status'
  | 'journey'
  | 'lastEdited';

export enum CampaignFormikFields {
  CAMPAIGN_INFORMATION_CARD = 'campaignInformationCard',
  CAMPAIGN_AUDIENCE_CARD = 'campaignAudienceCard',
  CAMPAIGN_PUSH_NOTIFICATION_CARD = 'campaignPushNotificationCard',
  CAMPAIGN_MESSAGE_CARD = 'campaignMessageCard',
  CAMPAIGN_DELIVERY_CARD = 'campaignDeliveryCard',
}

export type CardErrorType = {
  [field: string]: boolean;
};

export type FormValidationSubState = {
  subheading: string;
  paragraph: string;
};

export type FormValidationState = {
  heading: string;
  subSectionsObj: FormValidationSubState[];
};

// Formik
export type CampaignGenericFormField<T> = {
  name: string;
  field: FieldInputProps<T>;
  form: FormikProps<T>;
};

// Templates
export type CampaignTemplate = {
  templateName: string;
  templateType: string;
  templateId: string;
  thumbnail?: string;
  templateDescription: string;
};

export type CampaignTemplateResponse = {
  journeyName: string;
  description: string;
  templates: CampaignTemplate[];
};

export type CampaignObjective = {
  id: number;
  content: string;
};
