import { DependencyContainer } from '../../../DependencyContainer';

import { AxiosResponse } from 'axios';
import { CrusadeApiUrls } from '../../../urls';
import { HttpClient } from '../../../HttpClient';
import { CreateJourney, CreateJourneyResponse } from '../types';

export default class AddJourneyClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async createJourney(
    partnerId: string,
    parameter: CreateJourney,
  ): Promise<any> {
    return this.post<AxiosResponse<CreateJourneyResponse>>(
      CrusadeApiUrls.createJourney(partnerId),
      parameter,
    );
  }
}
