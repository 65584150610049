import { DependencyContainer } from 'DependencyContainer';
import { Maybe } from 'types';
import { SegmentHistoryParams } from '../types';

export default class OverviewService {
  constructor(private readonly factory: DependencyContainer) {}

  async getSegmentGrowthHistory(
    partnerId: Maybe<string>,
    numOfDays: number,
    segments?: string[],
  ): Promise<any> {
    if (!segments || !numOfDays || partnerId === undefined) return;

    const serviceRes = await this.factory.overviewClient.getSegmentGrowthHistory(
      partnerId,
      {
        daysAgo: numOfDays,
        segments: segments,
      },
    );

    return serviceRes;
  }

  async getCampaignGrowthHistory(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.overviewClient.getCampaignGrowthHistory(
      partnerId,
    );
  }

  async getIndividualCampaignHistory(
    partnerId: Maybe<string>,
    campaignId: string,
    daysAgo: number,
  ): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.overviewClient.getIndividualCampaignHistory(
      partnerId,
      campaignId,
      daysAgo,
    );
  }

  async getNps30DayHistory(
    partnerId: Maybe<string>,
    campaignId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.overviewClient.getNps30DayHistory(
      partnerId,
      campaignId,
    );
  }

  async getNpsAllTimeHistory(
    partnerId: Maybe<string>,
    campaignId: string,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.overviewClient.getNpsAllTimeHistory(
      partnerId,
      campaignId,
    );
  }
}
