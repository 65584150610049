import { createPersistentState } from './core';
import { Maybe, Nullable } from '../types';
import { AvailableIntegrationTypes } from '../features/configuration/types';

export type IntegrationStrategyCache = {
  accessToken: Nullable<string>;
  expirationTime: Nullable<string>;
};

export type IntegrationStrategy = {
  integrationType: AvailableIntegrationTypes;
  channelId?: string;
  accountId?: string;
  cache?: IntegrationStrategyCache;
};

type InitialState = {
  integrationStrategy: Maybe<IntegrationStrategy>;
};

const initialState: InitialState = {
  integrationStrategy: undefined,
};

const persistentStateWrapper = createPersistentState(initialState);

export const {
  persistentState,
  usePersistentState,
  getAtom,
  initializePersistentState,
} = persistentStateWrapper;
export default persistentStateWrapper;
