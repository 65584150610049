import React from 'react';
import { Icons } from 'plume-ui';
import { MenuItems } from 'plume-ui/dist/components/Menu/Menu';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import { Routes } from '../config';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import { useTranslation } from 'react-i18next';
import { HarvestProduct } from 'features/login/types';
import { useProduct } from 'features/login/hooks/useProduct';
import { ConfigurationEnum } from 'features/crusade/types';
import { OverviewEnum } from 'features/overview/types';

const useMenu = () => {
  const getCurrentRoute = useGetCurrentRoute();
  const redirectToRoute = useRedirectToRoute();
  const { hasProduct } = useProduct();
  const { t } = useTranslation();
  const currentRoute = getCurrentRoute();

  const menuItems: MenuItems = [
    {
      name: t('menu.generate'),
      iconLeft: <Icons.GenerateIcon width={24} />,
      iconLeftSelected: <Icons.GenerateFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        redirectToRoute(Routes.Generate, {
          activeTab: ConfigurationEnum.SEGMENTS,
        }),
      isSelected: [
        Routes.Index,
        Routes.Generate,
        Routes.GenerateSpecificTab,
        Routes.CreateAudience,
        Routes.GenerateDataPreview,
      ].includes(currentRoute),
      isDisabled: !hasProduct(HarvestProduct.Generate),
    },
    {
      name: t('menu.nurture'),
      iconLeft: <Icons.CrusadeIcon width={24} />,
      iconLeftSelected: <Icons.CrusadeFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () => redirectToRoute(Routes.CrusadeCampaigns),
      isSelected: [
        Routes.CrusadeCampaigns,
        Routes.CreateCampaign,
        Routes.EditCampaign,
        Routes.FromTemplateCampaign,
      ].includes(currentRoute),
      isDisabled: !hasProduct(HarvestProduct.Crusade),
    },
    {
      name: t('menu.clarity'),
      iconLeft: <Icons.ClarityIcon width={24} />,
      iconLeftSelected: <Icons.GraphBarFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        redirectToRoute(Routes.Overview, {
          activeOverview: OverviewEnum.CAMPAIGN_OVERVIEW,
        }),
      isSelected: [Routes.Overview, Routes.NpsOverview].includes(currentRoute),
      isDisabled:
        !hasProduct(HarvestProduct.Crusade) &&
        !hasProduct(HarvestProduct.Generate),
    },
  ];
  const menuBottomItems: MenuItems = [
    {
      name: t('menu.connections'),
      iconLeft: <Icons.SyncIcon width={24} />,
      iconLeftSelected: <Icons.SyncFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () => redirectToRoute(Routes.Connections),
      isSelected: currentRoute === Routes.Connections,
      isDisabled: !hasProduct(HarvestProduct.Crusade),
    },
    {
      name: t('menu.settings'),
      iconLeft: <Icons.SettingsIcon width={24} />,
      iconLeftSelected: <Icons.SettingsFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () => redirectToRoute(Routes.Settings),
      isSelected: currentRoute === Routes.Settings,
      isDisabled:
        !hasProduct(HarvestProduct.Crusade) &&
        !hasProduct(HarvestProduct.Generate),
    },
  ];
  const grouped = [
    {
      items: menuItems,
    },
    {
      items: menuBottomItems,
    },
  ];
  return () => grouped;
};

export default useMenu;
