import { CampaignStatus } from '../types';
import { useTranslation } from 'react-i18next';

export const mapStatusToMessageId = {
  [CampaignStatus.Active]: 'crusade.campaigns.status.active',
  [CampaignStatus.Paused]: 'crusade.campaigns.status.paused',
  [CampaignStatus.Draft]: 'crusade.campaigns.status.draft',
  [CampaignStatus.Completed]: 'crusade.campaigns.status.ended',
};

export type UseCampaignStatusMappingsValues = {
  renderStatus: (status: CampaignStatus) => string;
  getCampaignStatusColorCode: (campaignStatus: CampaignStatus) => any;
};

const CampaignStatusColorCodeMap: Record<CampaignStatus, string> = {
  [CampaignStatus.Active]: 'ok',
  [CampaignStatus.Paused]: 'warning',
  [CampaignStatus.Draft]: 'unknown',
  [CampaignStatus.Completed]: 'error',
};

const useCampaignStatusMapping = (): UseCampaignStatusMappingsValues => {
  const { t } = useTranslation();
  const renderStatus = (status: CampaignStatus) => {
    const messageId = mapStatusToMessageId[status as CampaignStatus];

    return t(messageId);
  };

  const getCampaignStatusColorCode = (campaignStatus: CampaignStatus) => {
    const colorCode =
      CampaignStatusColorCodeMap[campaignStatus as CampaignStatus];
    return colorCode;
  };

  return {
    renderStatus,
    getCampaignStatusColorCode,
  };
};
export default useCampaignStatusMapping;
