import { DependencyContainer } from '../../../DependencyContainer';
import { AxiosResponse } from 'axios';
import { CrusadeApiUrls } from 'urls';
import { HttpClient } from '../../../HttpClient';
import { CampaignTemplateResponse } from '../types';

export default class CampaignTemplateClient extends HttpClient {
  constructor(public readonly factory: DependencyContainer) {
    super(factory);
  }

  async getCampaignTemplates(partnerId: string): Promise<any> {
    return this.get<AxiosResponse<CampaignTemplateResponse>>(
      CrusadeApiUrls.getCampaignTemplates(partnerId),
    );
  }

  async createDraftCampaignFromTemplate(
    partnerId: string,
    templateId: string,
  ): Promise<any> {
    return this.post<AxiosResponse>(
      CrusadeApiUrls.createDraftCampaignFromTemplate(partnerId),
      { templateId },
    );
  }
}
