import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import { DependencyContainer } from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { journeysAtom } from '../../../store/state/campaignState';

type UseJourneyValues = {
  loading: boolean;
  error: any;
};

const { crusadeJourneyService } = new DependencyContainer();

export const useJourney = (): UseJourneyValues => {
  const setJourneys = useSetRecoilState(journeysAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const [loading, , error] = useFetchData(
    async () => {
      return await crusadeJourneyService.getCrusadeJourneys(partnerId);
    },
    (response) => setJourneys(response.data.journeys),
    [partnerId],
    () => partnerId === undefined,
  );

  return {
    loading,
    error,
  };
};
