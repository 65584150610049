import React, { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export type SquareButtonWithIconPossibleProps = {
  icon: ReactNode;
  buttonType: 'email' | 'pushNotification';
};

export type SquareButtonWithIconProps = SquareButtonWithIconPossibleProps &
  JSX.IntrinsicElements['button'];

const SquareButtonWithIcon: FunctionComponent<SquareButtonWithIconProps> = ({
  icon,
  buttonType,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <button className="SquareButtonWithIcon" {...props}>
      <div className="SquareButtonWithIcon__icon">{icon}</div>
      <div className="SquareButtonWithIcon__label">
        {buttonType === 'email'
          ? t('crusade.campaigns.emailTemplateBtnCopy')
          : t('crusade.campaigns.pushTemplateBtnCopy')}
      </div>
    </button>
  );
};

export default SquareButtonWithIcon;
