import { Chip, Divider } from 'plume-ui';
import React, { FunctionComponent, useEffect, useState } from 'react';
import FormattedMessage from '../../utils/components/FormattedMessage';

export type AndDividerProps = {
  narrowLevel: number | null;
};

export const AndDivider: FunctionComponent<AndDividerProps> = ({
  narrowLevel,
}) => {
  const [indent, setIndent] = useState<number>(0);

  useEffect(() => {
    setIndent(narrowLevel ? narrowLevel - 1 : 0);
  }, []);

  return (
    <div className="AndDivider" style={{ paddingLeft: `${indent * 38}px` }}>
      <div className="AndDivider__divider">
        <Divider orientation="vertical" />
      </div>
      <Chip
        classes={(current) => ({
          ...current,
          root: `${current.root} AndDivider__chip`,
        })}
      >
        <FormattedMessage id="segmentsModal.and" />
      </Chip>
      <div className="AndDivider__divider">
        <Divider orientation="vertical" />
      </div>
    </div>
  );
};
