import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import {
  Button,
  Icons,
  Modal,
  ModalBody,
  ModalFooter,
  Chip,
  InputField,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { tagsAtom } from 'store/state/tagsState';
import { generateKey } from 'utils/helpers';

export const TagsModal: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [newTag, setNewTag] = useState('');
  const [currentTag, setCurrentTag] = useState('');
  const [activeTagId, setActiveTagId] = useState(0);
  const [tags] = useRecoilState(tagsAtom);
  const [selectedTags, setSelectedTags] = useState(tags);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const removeTag = (field: number) => {
    var index = selectedTags.filter((tag) => {
      return tag.id !== field;
    });

    setSelectedTags(index);
  };

  const addTag = () => {
    setSelectedTags([...selectedTags, { id: generateKey(), value: newTag }]);
    setNewTag('');
  };

  const editTag = (oldTag: any) => {
    var index = selectedTags.findIndex((tag) => {
      return tag.id === oldTag.id;
    });
    selectedTags.splice(index, 1, {
      id: oldTag.id,
      value: currentTag === '' ? oldTag.value : currentTag,
    });
    setIsOpenEdit(false);
    setCurrentTag('');
  };

  const enableEdit = (e: any, id: number) => {
    e.stopPropagation();
    setActiveTagId(id);
    setIsOpenEdit(true);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpenEdit(false);
  };

  return (
    <div onClick={(e: any) => handleClick(e)}>
      <Modal
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} TagsModal`,
        })}
        isOpen={true}
        onRequestClose={() => hideModal(MODAL_TYPES.TAGS_MODAL)}
      >
        <ModalBody
          classes={(current: ModalStyles) => ({
            ...current,
            root: `${current.root} TagsModal__tagsModalBody`,
          })}
        >
          <div className="TagsModal__modalTitle">
            {t('generate.tagsModal.title')}
          </div>
          <div className="TagsModal__inputContainer">
            <InputField
              type="text"
              label={t('generate.tagsModal.createNewTag')}
              value={newTag}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onInput={(e) => {
                setNewTag((e.target as HTMLInputElement).value);
              }}
            />
            {newTag && (
              <Button styleVariant="quaternary" onClick={() => addTag()}>
                <Icons.RightArrowIcon className="TagsModal__addButton" />
              </Button>
            )}
          </div>
          <div className="TagsModal__tagsContainer">
            {selectedTags.map((tag, index) => (
              <div key={index} className="TagsModal__tag">
                {isOpenEdit && activeTagId === tag.id ? (
                  <InputField
                    type="text"
                    label={t('generate.tagsModal.nameYourTag')}
                    value={tag.value}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onInput={(e) => {
                      setCurrentTag((e.target as HTMLInputElement).value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') editTag(tag);
                    }}
                  />
                ) : (
                  <>
                    <Chip
                      removable
                      color="freezeX"
                      onClick={() => removeTag(tag.id)}
                    >
                      {tag.value}
                    </Chip>
                    <Icons.EditIcon
                      className="TagsModal__editIcon"
                      onClick={(e: any) => enableEdit(e, tag.id)}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            styleVariant="tertiary-grey"
            onClick={() => hideModal(MODAL_TYPES.TAGS_MODAL)}
          >
            {t('cancel')}
          </Button>
          <Button styleVariant="superprimary">{t('save')}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
