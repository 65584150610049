import React, { FunctionComponent, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Heading } from 'plume-ui';

import { MixPanelEvents } from '../../../mixPanelEvents';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { hasAnyOfAccessTokenInUrl } from '../../integrations/helpers';
import useIntegrations, {
  getStrategy,
} from '../../integrations/hooks/useIntegrations';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { AvailableScreens } from '../../trackingAnalytics/types';
import CampaignSetting from './CampaignSettingContainer';
import './ConfigurationContainer.scss';

const ConfigurationContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const { cleanup } = useIntegrations({
    automaticallyStartFlow: false,
  });

  useEffect(() => {
    const strategy = getStrategy();
    if (strategy?.cache?.accessToken == null && !hasAnyOfAccessTokenInUrl()) {
      cleanup();
    }
  }, []);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Configuration,
      },
    });
  }, [trackEvent]);

  return (
    <div className="ConfigurationContainer p-xl">
      <Helmet>
        <title>{t('plume')}</title>
      </Helmet>
      <div className="ConfigurationContainer__header">
        <Heading
          level={1}
          medium
          size="xxxxl"
          classes={(current) => ({
            ...current,
            root: `${current.root} ConfigurationContainer__title`,
          })}
        >
          <FormattedMessage id="settings.title" />
        </Heading>
      </div>
      <CampaignSetting />
    </div>
  );
};

export default ConfigurationContainer;
