import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import OverviewContent from '../components/OverviewContent';
import OverviewHeader from '../components/OverviewHeader';

const OverviewContainer: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="OverviewContainer p-xl">
      <Helmet>
        <title>{t('overview.title')}</title>
      </Helmet>
      <OverviewHeader />
      <OverviewContent />
    </div>
  );
};

export default OverviewContainer;
