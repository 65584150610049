import { Routes } from 'config';
import { useCampaignTemplates } from 'features/crusade/hooks/useCampaignTemplates';
import { Button, Heading, Icons } from 'plume-ui';
import React, { FunctionComponent, useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  campaignListFromTemplateModal,
  campaignTabNavigationIndex,
} from 'store/state/campaignState';
import { useRedirectToRoute } from 'utils/hooks/useRedirectToRoute';
import CampaignTabNavigationHeader from '../CampaignTabNavigationHeader';
import TemplateList from '../templates/TemplateList';

const TemplateListContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const campaignListFromTempModal = useRecoilValue(
    campaignListFromTemplateModal,
  );
  const setActiveTab = useSetRecoilState(campaignTabNavigationIndex);
  useCampaignTemplates();

  useLayoutEffect(() => {
    setActiveTab(1);
  }, []);

  return (
    <>
      {campaignListFromTempModal ? (
        <div className="TemplateListHeaderContainer">
          <Button
            styleVariant="tertiary-grey"
            icon={<Icons.ArrowLeftIcon />}
            classes={(current) => ({
              ...current,
              root: `${current.root} TemplateListContainer__headerContainer-backBtn`,
            })}
            onClick={() => redirectToRoute(Routes.CrusadeCampaigns)}
          >
            {t('back')}
          </Button>
          <Heading
            size="xxl"
            medium
            classes={(current) => ({
              ...current,
              root: `${current.root} TemplateListHeaderContainer-title`,
            })}
          >
            {t('crusade.campaigns.createCampaign')}
          </Heading>
          <div className="TemplateListHeaderContainer-rightItem" />
        </div>
      ) : (
        <div className="TemplateListHeaderContainer__tabNavigationHeader">
          <CampaignTabNavigationHeader />
        </div>
      )}

      <TemplateList />
    </>
  );
};

export default TemplateListContainer;
