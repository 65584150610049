import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  fetchedChartDataCampaignIds,
  campaignHistoryCounterAtom,
  campaignOverviewGlobalTimeRangeAtom,
} from 'store/state/overviewState';
import { useState } from 'react';

const { overviewService } = new DependencyContainer();

export const useCampaignChartData = (campaignId: string, index: number) => {
  const partnerId = useRecoilValue(partnerIdAtom);
  const timeRange = useRecoilValue(campaignOverviewGlobalTimeRangeAtom);
  const counter = useRecoilValue(campaignHistoryCounterAtom);
  const [fetchedCampaignIds, setFetchedCampaignIds] = useRecoilState(
    fetchedChartDataCampaignIds,
  );

  const [campaignData, setCampaignData] = useState<any | undefined>();

  const [loading, , error, runFetch] = useFetchData(
    async () => {
      if (
        !fetchedCampaignIds.includes(campaignId) &&
        index <= counter &&
        index >= fetchedCampaignIds.length
      ) {
        return await overviewService.getIndividualCampaignHistory(
          partnerId,
          campaignId,
          timeRange.value,
        );
      } else {
        return undefined;
      }
    },
    (history) => {
      setCampaignData(history.data);
      setFetchedCampaignIds((current) => [...current, campaignId]);
    },
    [partnerId, counter],
    () => partnerId === undefined || campaignId === undefined,
  );

  if (error && !fetchedCampaignIds.includes(campaignId)) {
    const currentFetchedIds = [...fetchedCampaignIds];
    currentFetchedIds.push(campaignId);
    setFetchedCampaignIds(currentFetchedIds);
  }

  return {
    loading,
    error,
    runFetch,
    campaignData,
  };
};
