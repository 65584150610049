import classNames from 'classnames';
import {
  convertToDateObject,
  formatGraphDateLabel,
  getDayOfWeek,
} from 'features/overview/ChartDateHelpers';
import { NpsScoresOverTimeType } from 'features/overview/types';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { TooltipProps } from 'recharts/types/component/Tooltip';

export type BarChartCustomTooltipProps = {
  data: NpsScoresOverTimeType[];
} & TooltipProps<ValueType, NameType>;

const BarChartCustomTooltip: FunctionComponent<BarChartCustomTooltipProps> = ({
  data,
  active,
  payload,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {data.map((el: NpsScoresOverTimeType, idx: number) => {
        const formattedDate = formatGraphDateLabel(convertToDateObject(el.dt));
        const dayOfWeek = getDayOfWeek(convertToDateObject(el.dt));
        if (active && payload && payload[0].payload.name === formattedDate) {
          return (
            <div className="NpsOverview__customTooltip" key={`${el.dt}-${idx}`}>
              <div className="NpsOverview__customTooltip-date">
                {`${dayOfWeek}, ${formattedDate}`}
              </div>
              <div
                className={classNames(
                  'NpsOverview__customTooltip-barChartNps',
                  {
                    'NpsOverview__customTooltip-barChartNps-negative':
                      el.npsScore < 0,
                  },
                )}
              >
                <div>{t('overview.nps.npsScore')}</div>
                <div className="NpsOverview__customTooltip-barChartNps-value">
                  {el.npsScore}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-promoters">
                <div>{t('overview.nps.promoters')}</div>
                <div className="NpsOverview__customTooltip-value">
                  {el.promoters}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-detractors">
                <div>{t('overview.nps.detractors')}</div>
                <div className="NpsOverview__customTooltip-value">
                  {el.detractors}
                </div>
              </div>
              <div className="NpsOverview__customTooltip-passives">
                <div>{t('overview.nps.passives')}</div>
                <div className="NpsOverview__barChartCustomTooltip-value">
                  {el.passives}
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default BarChartCustomTooltip;
