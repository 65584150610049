import { DependencyContainer } from 'DependencyContainer';
import { useFetchData } from 'hooks/useFetchData';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { partnerIdAtom } from 'store/state/appState';
import {
  brandingCompanyNameAtom,
  campaignEmailConfigAtom,
} from 'store/state/configurationState';

type UseCampaignEmailConfigurationValues = {
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
};

const { configurationService } = new DependencyContainer();

export const useCampaignEmailConfiguration = (): UseCampaignEmailConfigurationValues => {
  const setCampaignEmailConfig = useSetRecoilState(campaignEmailConfigAtom);
  const setBrandingCompanyName = useSetRecoilState(brandingCompanyNameAtom);
  const partnerId = useRecoilValue(partnerIdAtom);

  const [loading, , error, runFetch] = useFetchData(
    async () => {
      return await configurationService.getCampaignEmailConfiguration(
        partnerId,
      );
    },
    (response) => {
      setCampaignEmailConfig(response.data);
      setBrandingCompanyName(
        response.data.branding.companyName
          ? response.data.branding.companyName
          : '',
      );
    },
    [partnerId],
  );

  return {
    loading,
    error,
    runFetch,
  };
};
