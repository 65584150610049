import { FunctionComponent } from 'enzyme';
import React from 'react';

export type IFrameProps = {
  html: string;
  title: string;
  scrolling?: 'auto' | 'yes' | 'no';
  height?: string;
};

const Iframe: FunctionComponent<IFrameProps> = ({
  html,
  title,
  scrolling = 'auto',
  height,
}) => {
  const writeHTML = (frame: any) => {
    if (!frame) {
      return;
    }
    let doc = frame.contentDocument;

    if (doc) {
      doc.open();
      doc.write(html);
      doc.close();
      frame.style.width = '100%';
      frame.style.height =
        scrolling === 'auto'
          ? `${frame.contentWindow.document.body.scrollHeight}px`
          : `${height}px`;
    }
  };

  return (
    <iframe
      src="about:blank"
      scrolling={scrolling}
      frameBorder="0"
      title={title}
      ref={writeHTML}
    />
  );
};
export default Iframe;
