import { DeliveryCard } from 'features/crusade/types';
import i18next from 'i18next';
import moment from 'moment';

const generateTimes = () => {
  let quarterHours = ['00', '15', '30', '45'];

  let times: any = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      let time = ('0' + i).slice(-2) + ':' + quarterHours[j];
      times.push({
        dbValue: moment(time, 'HH:mm').format('HH:mm'),
        title: moment(time, 'HH:mm').format('LT'),
      });
    }
  }

  return times;
};

const generateRecurrences = () => {
  let recurrences = [];

  for (let i = 1; i <= 30; i++) {
    recurrences.push({
      title: `${i}`,
    });
  }

  return recurrences;
};

export const CampaignDeliveryCardDropdown = (): Array<DeliveryCard> => {
  return [
    {
      title: 'crusade.campaigns.deliveryCard.sendLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.once',
      items: [
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.once',
          ),
          dbValue: 'once',
        },
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.daily',
          ),
          dbValue: 'daily',
        },
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.weekly',
          ),
          dbValue: 'weekly',
        },
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.monthly',
          ),
          dbValue: 'monthly',
        },
      ],
    },
    {
      title: 'crusade.campaigns.deliveryCard.startTimeLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.selectTime',
      items: generateTimes(),
    },
    {
      title: 'crusade.campaigns.deliveryCard.endTimeLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.selectTime',
      items: generateTimes(),
    },
    {
      title: 'crusade.campaigns.deliveryCard.endingParametersLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.neverEnds',
      items: [
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.neverEnds',
          ),
          dbValue: 'neverEnds',
        },
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.onASpecificDate',
          ),
          dbValue: 'onSpecificDate',
        },
      ],
    },
    {
      title: 'crusade.campaigns.deliveryCard.recurrenceLabel',
      label: '1',
      items: generateRecurrences(),
    },
    {
      title: 'crusade.campaigns.deliveryCard.selectDaysLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.sun',
      items: [
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.sun'),
          dbValue: 0,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.mon'),
          dbValue: 1,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.tue'),
          dbValue: 2,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.wed'),
          dbValue: 3,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.thu'),
          dbValue: 4,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.fri'),
          dbValue: 5,
        },
        {
          title: i18next.t('crusade.campaigns.deliveryCard.fixtureOptions.sat'),
          dbValue: 6,
        },
      ],
    },
    {
      title: 'crusade.campaigns.deliveryCard.beginLabel',
      label: 'crusade.campaigns.deliveryCard.fixtureOptions.immediately',
      items: [
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.immediately',
          ),
          dbValue: 'immediately',
        },
        {
          title: i18next.t(
            'crusade.campaigns.deliveryCard.fixtureOptions.chooseDate',
          ),
          dbValue: 'chooseDate',
        },
      ],
    },
  ];
};
