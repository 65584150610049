import {
  BrandingLogoType,
  CampaignConfigurationPayloadType,
  CustomFooterCardType,
  DisplayNameAddressCardType,
  ReplyToAddressCardType,
} from 'features/crusade/types';
import { atom, selector } from 'recoil';
import { partnerIdAtom } from './appState';

export const campaignConfigEmailDomainListAtom = atom<any>({
  key: 'campaignConfigEmailDomainListAtom',
  default: [],
});

export const campaignEmailConfigAtom = atom<CampaignConfigurationPayloadType>({
  key: 'campaignEmailConfigAtom',
  default: {} as CampaignConfigurationPayloadType,
});

export const replyToAddressAtom = atom<ReplyToAddressCardType[]>({
  key: 'replyToAddressAtom',
  default: [
    {
      fromDisplayName: null,
      isDefault: true,
    },
  ],
});

export const customFooterAtom = atom<CustomFooterCardType>({
  key: 'customFooterAtom',
  default: {} as CustomFooterCardType,
});

export const displayNameAddressAtom = atom<DisplayNameAddressCardType[]>({
  key: 'displayNameAddressAtom',
  default: [
    {
      partnerId: '',
      fromDisplayName: '',
      localPart: '',
      isDefault: false,
      emailDomain: '',
      emailIpPool: '',
    },
  ],
});

export const brandingLogoAtom = atom<BrandingLogoType[]>({
  key: 'brandingAtom',
  default: [
    {
      url: '',
      isDefault: false,
      date: '',
    },
  ],
});

export const brandingPrimaryColorAtom = atom<string>({
  key: 'brandingPrimaryColorAtom',
  default: '#FFFFFF',
});

export const brandingCompanyNameAtom = atom<string>({
  key: 'brandingCompanyNameAtom',
  default: '',
});

export const brandingMobileApplicationNameAtom = atom<string>({
  key: 'brandingMobileApplicationNameAtom',
  default: '',
});

export const brandingProductNameAtom = atom<string>({
  key: 'brandingProductNameAtom',
  default: '',
});

export const prepareCustomFooter = selector<CustomFooterCardType>({
  key: 'prepareCustomFooter',
  get: ({ get }) => {
    const customFooter = get(customFooterAtom);
    const partnerId = get(partnerIdAtom);

    const currentFooter = { ...customFooter };

    currentFooter.partnerId = partnerId || '';
    if (currentFooter.customFooterId) {
      delete currentFooter.customFooterId;
    }

    return currentFooter;
  },
});

export const campaignConfigurationPayloadSelector = selector<
  CampaignConfigurationPayloadType
>({
  key: 'campaignConfigurationPayloadSelector',
  get: ({ get }) => {
    const replyToAddressList = get(replyToAddressAtom);
    const customFooter = get(prepareCustomFooter);
    const displayNameAddressList = get(displayNameAddressAtom);
    const brandingLogoList = get(brandingLogoAtom);
    const brandingPrimaryColor = get(brandingPrimaryColorAtom);
    const brandingCompanyName = get(brandingCompanyNameAtom);
    const brandingAppName = get(brandingMobileApplicationNameAtom);
    const brandingProductName = get(brandingProductNameAtom);

    const updatedBranding = {
      logos: brandingLogoList,
      colorPrimary: brandingPrimaryColor,
      companyName: brandingCompanyName,
      mobileAppName: brandingAppName,
      productName: brandingProductName,
    };

    return {
      replyToAddresses: replyToAddressList,
      displayNameAddresses: displayNameAddressList,
      customFooter: customFooter,
      branding: updatedBranding,
    };
  },
});
