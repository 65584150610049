import {
  Button,
  Dropdown,
  DropdownSelectableItem,
  Heading,
  InputField,
  Space,
} from 'plume-ui';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getMetadataByColumnNameSelector } from 'store/state/metadataTreeState';
import { useTranslation } from 'react-i18next';
import { Operator, OperatorLabel } from './OperatorEnum';
import { PatternType } from './PatternEnum';
import { generateKey } from 'utils/helpers';
import {
  numericPatternOperators,
  timePatternOperators,
} from './PatternOperators';
import { useGlobalModalContext } from 'modal-context/GlobalModal';
import { MODAL_TYPES } from 'modal-context/ModalTypes';
import { BetweenEnum } from './BetweenEnum';
import FormattedMessage from '../../utils/components/FormattedMessage';

const SELECT_VALUE = 'Select value';

const singleInputOperators = [
  Operator.EQUAL,
  Operator.AT_LEAST,
  Operator.AT_MOST,
  Operator.MORE_THAN,
  Operator.LESS_THAN,
  Operator.NOT_EQUAL,
  Operator.NOW,
  Operator.ANY_TIME_PAST,
  Operator.DAYS_AGO,
  Operator.EACH_OF_LAST,
  Operator.NEXT,
];

const betweenInputOperators = [
  Operator.BETWEEN,
  Operator.BETWEEN_DATES,
  Operator.BETWEEN_DAYS,
];

export type PatternMatcherProps = {
  cardFilter: string;
  criteria: any;
  onSave: (pattern: any) => void;
  onCancel: () => void;
};

const PatternMatcher: FunctionComponent<PatternMatcherProps> = ({
  criteria,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();

  const initialState = {
    patternType: '',
    columnName: '',
    columnValue: '',
    operator: '',
  };
  const [patternSelection, setPatternSelection] = useState(
    criteria || initialState,
  );

  const { showModal } = useGlobalModalContext();
  let [isBetween, setIsBetween] = useState<boolean>(false);
  let [, setIsNow] = useState<boolean>(false);
  const [betweenDaysStartTimeframe, setBetweenDaysStartTimeframe] = useState<
    BetweenEnum | undefined
  >(BetweenEnum.PAST);
  const [betweenDaysEndTimeframe, setBetweenDaysEndTimeframe] = useState<
    BetweenEnum | undefined
  >(BetweenEnum.PAST);
  const [valueSelected, setValueSelected] = useState(false);
  const [singleInputValue, setSingleInputValue] = useState<string>();
  const [rangeEndInputValue, setRangeEndInputValue] = useState<string | number>(
    criteria?.columnValue?.end ? criteria.columnValue.end : '',
  );
  const [dateRangeValue, setDateRangeValue] = useState<string[]>([]);
  const [startDayValue, setStartDayValue] = useState<string | number>(
    criteria?.columnValue?.startDays
      ? Math.abs(criteria.columnValue?.startDays)
      : '',
  );
  const [endDayValue, setEndDayValue] = useState<string | number>(
    criteria?.columnValue?.endDays
      ? Math.abs(criteria.columnValue?.endDays)
      : '',
  );
  const [startValue, setStartValue] = useState<string | number>(
    criteria?.columnValue?.start ? Math.abs(criteria.columnValue?.start) : '',
  );
  const [endValue, setEndValue] = useState<string | number>(
    criteria?.columnValue?.end ? Math.abs(criteria.columnValue?.end) : '',
  );
  const metaData = useRecoilValue(
    getMetadataByColumnNameSelector(criteria.columnName),
  );

  const [selectedOperator, setSelectedOperator] = useState<string>();

  const [betweenDaysStartError, setBetweenDaysStartError] = useState<string>();
  const [betweenDaysEndError, setBetweenDaysEndError] = useState<string>();

  const [betweenStartError, setBetweenStartError] = useState<string>();
  const [betweenEndError, setBetweenEndError] = useState<string>();

  const [singleInputError, setSingleInputError] = useState('');

  const errorsPresent = useMemo(() => {
    if (patternSelection.operator === Operator.NOW) {
      return false;
    } else if (
      singleInputOperators.includes(patternSelection.operator) &&
      singleInputError !== '' &&
      singleInputError !== undefined
    ) {
      return true;
    } else if (
      (patternSelection.operator === Operator.BETWEEN_DAYS &&
        betweenDaysStartError !== '' &&
        betweenDaysStartError !== undefined) ||
      (betweenDaysEndError !== '' && betweenDaysEndError !== undefined)
    ) {
      return true;
    } else if (
      (patternSelection.operator === Operator.BETWEEN &&
        betweenStartError !== '' &&
        betweenStartError !== undefined) ||
      (betweenEndError !== '' && betweenEndError !== undefined)
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    betweenDaysStartError,
    betweenDaysEndError,
    betweenStartError,
    betweenEndError,
    singleInputError,
    patternSelection.columnValue,
  ]);

  useEffect(() => {
    const columnValueAsNumber = parseInt(patternSelection.columnValue);

    if (patternSelection.columnValue === '') {
      setSingleInputError('');
    } else if (isNaN(columnValueAsNumber) || columnValueAsNumber < 0) {
      setSingleInputError(t('patternMatcher.checkSyntax'));
    }
  }, [patternSelection.columnValue]);

  // handle patternType getting lost in between edits
  useEffect(() => {
    if (patternSelection.patternType === undefined && criteria.operator) {
      setPatternSelection({
        ...patternSelection,
        patternType: criteria.operator,
      });
    }
  }, [patternSelection, criteria]);

  useEffect(() => {
    if (
      metaData?.pattern === PatternType.STRING &&
      metaData?.allowedValues.length === 0
    ) {
      showModal(
        MODAL_TYPES.PATTERN_STRING_MODAL,
        {
          columnName: metaData!.columnName,
          patternSelection: patternSelection,
          onSave: onSave,
        },
        MODAL_TYPES.PATTERN_STRING_MODAL,
      );
    }
  }, [metaData?.pattern]);

  useEffect(() => {
    if (criteria.columnValue === SELECT_VALUE) {
      setSingleInputValue('');
    } else if (typeof criteria.columnValue === 'object') {
      setSingleInputValue(criteria.columnValue.start);
    } else {
      setSingleInputValue(criteria.columnValue);
    }
  }, []);

  useEffect(() => {
    if (betweenInputOperators.includes(criteria.operator)) {
      setIsBetween(true);
    }

    if (criteria.operator === Operator.NOW) {
      setIsBetween(false);
      setIsNow(true);
    }
  }, [criteria]);

  const validNumber = (value: any) => {
    return Number.isInteger(value);
  };

  const handleValueInputSingle = (value: string) => {
    const valueToSave = isNaN(parseInt(value)) ? '' : parseInt(value);
    setSingleInputValue(value);

    if (value === undefined || value === '') {
      setSingleInputError('');
      setValueSelected(false);
    } else if (
      !validNumber(+value) ||
      (valueToSave !== '' && valueToSave < 0)
    ) {
      setSingleInputError(t('patternMatcher.checkSyntax'));
      setValueSelected(false);
    } else {
      setSingleInputError('');
      setValueSelected(false);
      setPatternSelection({
        ...patternSelection,
        columnValue: '',
      });
    }

    setPatternSelection({
      ...patternSelection,
      columnValue: valueToSave,
      operator: patternSelection.operator,
    });
  };

  const handleBetweenStartInput = (value: string) => {
    const valueToSave = isNaN(parseInt(value)) ? '' : parseInt(value);

    if (value === undefined || value === '') {
      setBetweenStartError('');
      setValueSelected(false);
    } else if (!validNumber(+value) || parseInt(value) < 0) {
      setBetweenStartError(t('patternMatcher.checkSyntax'));
      setValueSelected(false);
    } else {
      setBetweenStartError('');
      setStartValue(valueToSave);
      setPatternSelection({
        ...patternSelection,
        columnValue: {
          ...patternSelection.columnValue,
          start: valueToSave,
        },
      });
    }
  };

  const handleBetweenEndInput = (value: string) => {
    const valueToSave = isNaN(parseInt(value)) ? '' : parseInt(value);

    if (value === undefined || value === '') {
      setBetweenEndError('');
      setValueSelected(false);
    } else if (!validNumber(+value) || parseInt(value) < 0) {
      setBetweenEndError(t('patternMatcher.checkSyntax'));
      setValueSelected(false);
    } else {
      setBetweenEndError('');
      setEndValue(valueToSave);
      setPatternSelection({
        ...patternSelection,
        columnValue: {
          ...patternSelection.columnValue,
          end: valueToSave,
        },
      });
    }
  };

  const handleBetweenDaysStartInput = (value: string) => {
    let valueToSave;

    if (value === undefined || value === '') {
      setBetweenDaysStartError('');
      setValueSelected(false);
    } else if (!validNumber(+value) || parseInt(value) < 0) {
      setBetweenDaysStartError(t('patternMatcher.checkSyntax'));
      setValueSelected(false);
    } else {
      setStartDayValue(value);
      setBetweenDaysStartError('');

      valueToSave = parseInt(value);

      valueToSave =
        betweenDaysStartTimeframe === BetweenEnum.NEXT
          ? valueToSave * -1
          : valueToSave;

      setPatternSelection({
        ...patternSelection,
        columnValue: {
          ...patternSelection.columnValue,
          startDays: valueToSave,
        },
      });
    }
  };

  const handleBetweenDaysEndInput = (value: string) => {
    let valueToSave;

    if (value === undefined || value === '') {
      setBetweenDaysEndError('');
      setValueSelected(false);
    } else if (!validNumber(+value) || parseInt(value) < 0) {
      setBetweenDaysEndError(t('patternMatcher.checkSyntax'));
      setValueSelected(false);
    } else {
      setBetweenDaysEndError('');
      setEndDayValue(value);
      valueToSave = parseInt(value);

      valueToSave =
        betweenDaysEndTimeframe === BetweenEnum.NEXT
          ? valueToSave * -1
          : valueToSave;

      setPatternSelection({
        ...patternSelection,
        columnValue: {
          ...patternSelection.columnValue,
          endDays: valueToSave,
        },
      });
    }
  };

  const selectOperator = (operator: Operator) => {
    setValueSelected(false);
    setSingleInputValue('');
    setRangeEndInputValue('');
    setDateRangeValue([]);
    setStartDayValue('');
    setEndDayValue('');
    setStartValue('');
    setEndValue('');
    setSelectedOperator(operator);
    Operator.BETWEEN === operator ||
    Operator.BETWEEN_DAYS === operator ||
    Operator.BETWEEN_DATES === operator
      ? setIsBetween(true)
      : setIsBetween(false);

    setPatternSelection({
      ...patternSelection,
      patternType: operator,
      operator: operator,
      columnValue: '',
    });
  };

  const showValueInput = () => {
    const allowedOperatorsForValueInput = [
      Operator.BETWEEN_DATES,
      Operator.BETWEEN_DAYS,
      Operator.BETWEEN,
      Operator.NOW,
      Operator.EQUAL,
    ];
    return (
      patternSelection.columnValue !== SELECT_VALUE &&
      !allowedOperatorsForValueInput.includes(patternSelection.operator)
    );
  };

  // validation for Save button
  const isValidInput = (input: any) => {
    return !isNaN(input) && input !== '' && input > 0;
  };

  useEffect(() => {
    if (patternSelection.columnValue === SELECT_VALUE) {
      setValueSelected(false);
    } else if (selectedOperator === Operator.NOW) {
      setValueSelected(true);
    } else if (
      singleInputOperators.includes(patternSelection.operator) &&
      isValidInput(singleInputValue)
    ) {
      setValueSelected(true);
    } else if (
      patternSelection.operator === Operator.BETWEEN &&
      isValidInput(startValue) &&
      isValidInput(endValue)
    ) {
      setValueSelected(true);
    } else if (
      patternSelection.operator === Operator.BETWEEN_DATES &&
      dateRangeValue.length === 2
    ) {
      setValueSelected(true);
    } else if (
      patternSelection.operator === Operator.EQUAL &&
      patternSelection.columnValue !== SELECT_VALUE &&
      patternSelection.columnValue !== ''
    ) {
      setValueSelected(true);
    } else if (
      patternSelection.operator === Operator.BETWEEN_DAYS &&
      isValidInput(startDayValue) &&
      isValidInput(endDayValue)
    ) {
      setValueSelected(true);
    } else if (
      !isValidInput(singleInputValue) ||
      !isValidInput(startDayValue) ||
      !isValidInput(endDayValue) ||
      !isValidInput(startValue) ||
      !isValidInput(endValue) ||
      !dateRangeValue.length
    ) {
      setValueSelected(false);
    }
  }, [
    patternSelection,
    singleInputValue,
    dateRangeValue,
    startDayValue,
    endDayValue,
    startValue,
    endValue,
  ]);

  const pattern = () => {
    switch (metaData && metaData.pattern) {
      case PatternType.NUMERIC:
        return (
          <div className="PatternMatcher">
            <div className="PatternMatcher__row">
              <div className="PatternMatcher__column">
                <Heading size="s">Operator</Heading>
                <Dropdown
                  expandDirection="auto"
                  label={
                    patternSelection.columnValue === SELECT_VALUE
                      ? patternSelection.columnValue
                      : (OperatorLabel[
                          patternSelection.operator as any
                        ] as string)
                  }
                >
                  {numericPatternOperators.map((item: any) => (
                    <DropdownSelectableItem
                      key={item.key || generateKey()}
                      onClick={() => selectOperator(item.value)}
                    >
                      {item.label}
                    </DropdownSelectableItem>
                  ))}
                </Dropdown>
              </div>
              <div className="PatternMatcher__column">
                <div className="PatternMatcher__between-days-wrapper">
                  <div className="">
                    {patternSelection.columnValue !== SELECT_VALUE &&
                      !isBetween && (
                        <>
                          <Heading size="s">{'Value'}</Heading>
                          <InputField
                            name="numericValue"
                            type="input"
                            value={singleInputValue}
                            required={false}
                            disabled={false}
                            onInput={(e) =>
                              handleValueInputSingle(e.currentTarget.value)
                            }
                            messages={[
                              {
                                status: 'error',
                                message: singleInputError,
                              },
                            ]}
                          />
                        </>
                      )}
                    {isBetween && (
                      <>
                        <Heading size="s">{'Start'}</Heading>
                        <InputField
                          name="numericValue"
                          type="input"
                          value={singleInputValue}
                          required={false}
                          disabled={false}
                          onInput={(e) =>
                            handleBetweenStartInput(
                              (e.target as HTMLInputElement).value,
                            )
                          }
                          messages={[
                            {
                              status: 'error',
                              message: betweenStartError,
                            },
                          ]}
                        />
                      </>
                    )}
                  </div>
                  {isBetween && (
                    <div className="and-label">
                      <div className="label">
                        <FormattedMessage id="patternMatcher.and" />
                      </div>
                    </div>
                  )}
                  {isBetween && (
                    <div className="">
                      <Heading size="s">End</Heading>
                      <InputField
                        name="numericEndValue"
                        type="input"
                        value={rangeEndInputValue}
                        required={false}
                        disabled={false}
                        onInput={(e) =>
                          handleBetweenEndInput(
                            (e.target as HTMLInputElement).value,
                          )
                        }
                        messages={[
                          {
                            status: 'error',
                            message: betweenEndError,
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case PatternType.TIME:
        return (
          <div className="PatternMatcher">
            <div className="PatternMatcher__row">
              <div className="PatternMatcher__column">
                <Heading size="s">
                  <FormattedMessage id="patternMatcher.operator" />
                </Heading>
                <Dropdown
                  expandDirection="auto"
                  label={
                    patternSelection.columnValue === SELECT_VALUE
                      ? patternSelection.columnValue
                      : (OperatorLabel[
                          patternSelection.operator as any
                        ] as string)
                  }
                >
                  {timePatternOperators.map((item: any) => (
                    <DropdownSelectableItem
                      key={item.key || generateKey()}
                      onClick={() => selectOperator(item.value)}
                    >
                      {item.label}
                    </DropdownSelectableItem>
                  ))}
                </Dropdown>
              </div>
              {showValueInput() && (
                <div className="PatternMatcher__column">
                  <Heading size="s">Days</Heading>
                  <InputField
                    name="timeValue"
                    type="input"
                    placeholder={SELECT_VALUE}
                    value={singleInputValue}
                    required={false}
                    disabled={false}
                    onInput={(e) =>
                      handleValueInputSingle(e.currentTarget.value)
                    }
                    messages={[
                      {
                        status: 'error',
                        message: singleInputError,
                      },
                    ]}
                  />
                </div>
              )}
              {patternSelection.operator !== Operator.BETWEEN_DAYS && (
                <div className="PatternMatcher__column"></div>
              )}
              {isBetween &&
                patternSelection.operator === Operator.BETWEEN_DAYS && (
                  <div className="PatternMatcher__column">
                    <div className="PatternMatcher__between-days-wrapper">
                      <div className="">
                        <Heading size="s">
                          <FormattedMessage id="patternMatcher.daysAgo" />
                        </Heading>
                        <InputField
                          name="timeValue"
                          type="input"
                          value={startDayValue}
                          required={false}
                          disabled={false}
                          messages={[
                            {
                              status: 'error',
                              message: betweenDaysStartError,
                            },
                          ]}
                          onInput={(e) =>
                            handleBetweenDaysStartInput(e.currentTarget.value)
                          }
                        />
                      </div>
                      <div className="and-label">
                        <div className="label">
                          <FormattedMessage id="patternMatcher.and" />
                        </div>
                      </div>
                      <div className="">
                        <Heading size="s">
                          <FormattedMessage id="patternMatcher.daysAgo" />
                        </Heading>
                        <InputField
                          name="timeValue"
                          type="input"
                          value={endDayValue}
                          required={false}
                          disabled={false}
                          onInput={(e) =>
                            handleBetweenDaysEndInput(e.currentTarget.value)
                          }
                          messages={[
                            {
                              status: 'error',
                              message: betweenDaysEndError,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        );

      default:
        break;
    }
  };

  const handleSave = () => {
    if (
      patternSelection.operator === Operator.BETWEEN_DAYS &&
      patternSelection.columnValue.startDays >
        patternSelection.columnValue.endDays
    ) {
      onSave({
        ...patternSelection,
        columnValue: {
          startDays: patternSelection.columnValue.endDays,
          endDays: patternSelection.columnValue.startDays,
        },
      });
    } else if (
      patternSelection.operator === Operator.BETWEEN &&
      patternSelection.columnValue.start > patternSelection.columnValue.end
    ) {
      const newPatternSelection = {
        ...patternSelection,
        columnValue: {
          start: patternSelection.columnValue.end,
          end: patternSelection.columnValue.start,
        },
      };
      onSave(newPatternSelection);
    } else if (patternSelection.patternType === Operator.NOW) {
      onSave({
        ...patternSelection,
        columnValue: '',
        operator: Operator.NOW,
      });
    } else {
      onSave({
        ...patternSelection,
        columnValue: patternSelection.columnValue,
      });
    }
  };

  return (
    <>
      {metaData && metaData.pattern !== null ? (
        <>
          {pattern()}
          <Space size="m" />
          <div className="PatternMatcher__action-wrapper">
            <Button styleVariant="tertiary-grey" onClick={() => onCancel()}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              styleVariant="primary"
              onClick={handleSave}
              disabled={!valueSelected || errorsPresent}
            >
              <FormattedMessage id="save" />
            </Button>
          </div>
          <Space size="m" />
        </>
      ) : null}
    </>
  );
};

export default PatternMatcher;
