import { Maybe } from 'types';
import { DependencyContainer } from '../../../DependencyContainer';
import {
  CampaignPushNotificationCardValues,
  CampaignStatus,
  CreateUpdateCampaignRequest,
  IPWarmupParams,
} from '../types';
export default class CrusadeJourneyService {
  versionKnown = false;
  constructor(private readonly factory: DependencyContainer) {}

  async getCrusadeJourneys(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.crusadeJourneyClient.getCrusadeJourneys(
      partnerId,
    );
  }

  async getCrusadeJourneysAndCampaigns(partnerId: Maybe<string>): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.crusadeJourneyClient.getCrusadeJourneysAndCampaigns(
      partnerId,
    );
  }

  async getCrusadeCampaignEmailData(
    partnerId: Maybe<string>,
    campaignId?: string,
  ): Promise<any> {
    if (!campaignId || partnerId === undefined) {
      return;
    }
    return await this.factory.crusadeJourneyClient.getCrusadeCampaignEmailData(
      partnerId,
      campaignId,
    );
  }

  async deleteCrusadeCampaign(
    partnerId: Maybe<string>,
    campaignId?: string,
  ): Promise<any> {
    if (!campaignId || partnerId === undefined) {
      return;
    }
    return await this.factory.crusadeJourneyClient.deleteCrusadeCampaign(
      partnerId,
      campaignId,
    );
  }

  async duplicateCrusadeCampaign(
    partnerId: Maybe<string>,
    campaignId: string,
  ): Promise<any> {
    if (!campaignId || partnerId === undefined) {
      return;
    }
    return await this.factory.crusadeJourneyClient.duplicateCrusadeCampaign(
      partnerId,
      campaignId,
    );
  }

  async createCrusadePushCampaign(
    partnerId: Maybe<string>,
    newPushCampaign: CreateUpdateCampaignRequest,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.crusadeJourneyClient.createCrusadePushCampaign(
      partnerId,
      newPushCampaign,
    );
  }

  async editCrusadeCampaign(
    partnerId: Maybe<string>,
    campaignId: string,
    updatedCampaign: CreateUpdateCampaignRequest,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.crusadeJourneyClient.editCrusadeCampaign(
      partnerId,
      campaignId,
      updatedCampaign,
    );
  }

  async sendPreviewTestMessage(
    partnerId: Maybe<string>,
    campaignId?: string,
    emails?: string[],
  ): Promise<any> {
    if (!campaignId || !emails?.length || partnerId === undefined) {
      return;
    }

    return await this.factory.crusadeJourneyClient.sendPreviewTestMessage(
      partnerId,
      campaignId,
      emails,
    );
  }

  async sendPreviewTestNotification(
    partnerId: string,
    emails?: string[],
    message?: CampaignPushNotificationCardValues,
  ): Promise<any> {
    if (!emails?.length || !message) {
      return;
    }
    return await this.factory.crusadeJourneyClient.sendPreviewTestNotification(
      partnerId,
      emails,
      message,
    );
  }

  async launchCrusadePushNotification(
    partnerId: Maybe<string>,
    campaignId: string,
    status: CampaignStatus,
  ): Promise<any> {
    if (!campaignId || partnerId === undefined) {
      return;
    }
    return await this.factory.crusadeJourneyClient.launchCrusadePushCampaign(
      partnerId,
      campaignId,
      status,
    );
  }

  async getIpWarmupPreview(
    partnerId: Maybe<string>,
    warmupParams: IPWarmupParams,
  ): Promise<any> {
    if (partnerId === undefined) return;
    return await this.factory.crusadeJourneyClient.getIpWarmupPreview(
      partnerId,
      warmupParams,
    );
  }
  async getVersion(): Promise<string | void> {
    if (this.versionKnown) {
      return;
    }
    this.versionKnown = true;
    const { data } = await this.factory.crusadeJourneyClient.getApiVersion();
    return `${data.apiVersion} (${data.imageTag})`;
  }

  async validateCampaignTestEmail(
    partnerId: Maybe<string>,
    testRecipients: string[],
  ): Promise<any> {
    if (partnerId === undefined) return;

    return await this.factory.crusadeJourneyClient.validateCampaignTestEmail(
      partnerId,
      {
        testRecipients: testRecipients,
      },
    );
  }
}
