import axios from 'axios';
import { getBackendGenerateUrl } from './environment';

export default axios.create({
  baseURL: getBackendGenerateUrl(),
  timeout: 25000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});
